<template>
  <form class="RestaurantFeatures" :style="{'max-height':screenHeight - 85 + 'px', padding:'5px 0px'}">
    <b-tabs class="tabMenuCustom" v-model="tabActive" >
      <b-tab-item label="PopUp Select">
        <div class="columns is-desktop" style="padding: 0px 10px;" :style="{'max-height':screenHeight - 165 + 'px' ,'overflow-y':'scroll'}">
          <div class="column">
            <SectionDivider title="Pop-Up al iniciar select">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <b-field expanded label="Limpiar Texto">
                    <b-button @click="cleanText" type="is-primary">Limpiar</b-button>
                  </b-field>
                </div>
                <div class="column">
                  <b-field expanded label="Contenido del pop-up">
                    <vue-editor :editor-toolbar="editorToolbar" v-model="messageText" />
                    {{ wordCounter(messageText) }}
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field
                    style="margin-top:20px"
                    v-if="!messagePopUp.content && !messagePopUp.imageMessage"
                  >
                    <b-upload drag-drop v-model="messagePopUp.imageMessage">
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <b-icon icon="upload" size="is-large"></b-icon>
                          </p>
                          <p>Suelta el archivo aquí o has click aquí</p>
                        </div>
                      </section>
                    </b-upload>
                  </b-field>
                  <div v-if="messagePopUp.imageMessage && !messagePopUp.content">
                    <span class="text">Imagen cargada, hacer click en crear link imagen</span>
                  </div>
                  <b-button
                    @click="processFile ('popupMsg')"
                    type="is-primary"
                    v-if="!messagePopUp.content"
                  >Crear link imagen</b-button>
                  <div class="cont-copy" v-if="messagePopUp.content">
                    <span
                      class="is-size-7"
                      style="margin: 10px 0"
                    >Debe copiar el texto de abajo y pegarlo arriba para poder mostrar la imagen</span>
                    <div class="text-copy">
                      <span @click="copyClose" style="font-size: 10px;">{{messagePopUp.content}}</span>
                      <button
                        @click="messagePopUp.content = null, messagePopUp.imageMessage = null"
                        class="delete is-small"
                        type="button"
                      ></button>
                    </div>
                    <input :value="messagePopUp.content" id="code-image-close" type="hidden" />
                  </div>
                </div>
              </div>
            </SectionDivider>
            <div class="columns">
              <div class="column">
                <b-button
                  :loading="sending"
                  @click="saveFeatures"
                  icon-left="content-save"
                  type="is-primary"
                >
                  <span>Guardar</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="PopUp Menú Digital">
        <div class="columns is-desktop is-multiline" style="padding: 10px 10px;">
          <div class="column is-12">
            <b-checkbox
              :false-value="0"
              :true-value="1"
              @input="update({
            type: 'activeModalWithImageMenuDigital',
            vendorId: restaurant.vendorId,
            url: restaurant.activeModalWithImageMenuDigital,
            userId: user.id
          }, 'noUpdate')"
              v-model="restaurant.activeModalWithImageMenuDigital"
            >Activar Modal Con Imagen</b-checkbox>
          </div>
          <div class="column">
            <SectionDivider title="Modal Menú Digital">
              <div class="RestaurantProcess-logo">
                <b-upload v-model="fileRestaurantModalSelect">
                  <div class="RestaurantProcess-logo-thumbnail">
                    <img :src="modalURL" class="RestaurantProcess-logoImage" />
                    <div class="RestaurantProcess-logoButton">
                      <b-icon icon="camera" size="is-small"></b-icon>
                      <span>Click para cambiar imagen</span>
                    </div>
                  </div>
                </b-upload>
                <div
                  class="columns is-multiline"
                  style="margin-top: 2rem !important; margin-left:0.4rem;"
                >
                  <div class="column is-half-tablet is-one-third-desktop">
                    <b-checkbox
                      :false-value="0"
                      :true-value="1"
                      v-model="restaurantData.vendor.activeUrlModal"
                    >Redirigir a otra url</b-checkbox>
                  </div>
                  <div class="column is-6" v-if="restaurantData.vendor.activeUrlModal === 1">
                    <b-input
                      @input="validateTextUrl($event)"
                      expanded
                      id="modalExternalUrl"
                      name="modalExternalUrl"
                      placeholder="Url"
                      type="text"
                      v-model="restaurantData.vendor.modalExternalUrl"
                      v-on:keydown.enter.prevent="save"
                    ></b-input>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6" style="margin-top: 1rem !important;">
                    <b-button
                      @click="updateModalExternalUrl()"
                      style="margin-left: 10px;"
                      type="is-info"
                    >
                      <span>Guardar URL Modal</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </SectionDivider>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </form>
</template>

<script>
import SectionDivider from '@/components/_shared/SectionDivider.vue';
import { VueEditor } from 'vue2-editor';
import he from 'he';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import serviceAdmin from '@/helpers/apis/serviceAdmin';
import serviceVendor from '@/helpers/apis/serviceVendor';
export default {
  props: ['restaurant'],
  components: { SectionDivider, VueEditor },
  data () {
    return {
      messageText: null,
      screenHeight: 0,
      editorToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ color: [] }, { background: [] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ list: 'ordered' }],
        ['clean', 'link']
      ],
      sending: false,
      messagePopUp: {
        content: null,
        imageMessage: null
      },
      tabActive: 0,
      modalURL: null,
      fileRestaurantModalSelect: null
    };
  },
  mounted () {
    this.screenHeight = window.innerHeight;
    this.modalURL = this.restaurantData.vendor.modalURL;
    this.messageText = this.restaurant.popupMsg;
  },
  computed: {
    ...mapGetters({
      restaurantData: [globalTypes.getters.vendor]
    })
  },
  methods: {
    wordCounter (string) {
      // return string ? string.replace(/<[^>]*>?/gm, '').replace(/ +(?= )/g, '').split(' ').length : 0
    },
    validateTextUrl (text) {
      if (this.validateUrl(text)) {
        document.getElementById('urlCustom').style.borderColor = 'green';
        this.stateBtnQrCustom = 1;
      } else {
        document.getElementById('urlCustom').style.borderColor = 'red';
        this.stateBtnQrCustom = 0;
      }
    },
    updateModalExternalUrl () {
      const data = {
        type: 'modalExternalUrlDashboard',
        vendorId: this.restaurant.vendorId,
        url: 0,
        activeUrlModal: this.restaurantData.vendor.activeUrlModal,
        modalExternalUrl: (this.restaurantData.vendor.activeUrlModal === 0) ? null : this.restaurantData.vendor.modalExternalUrl,
        userId: this.user.id
      };
      serviceAdmin.put('upload/vendor', data).then(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Se ha actualizado la url externa del modal',
          type: 'is-success'
        });
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    },
    cleanText () {
      this.messageText = null;
    },
    saveFeatures () {
      this.sending = true;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      const features = {
        popupMsg: (this.messageText) ? he.decode(this.messageText) : null,
        dashboard: true,
        vendorId: this.restaurant.vendorId,
        userId: this.user.id
      };
      serviceVendor.put(['/params', this.restaurant.vendorId].join('/'), features).then(() => {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Se ha actualizado el mensaje ',
          type: 'is-success'
        });
      }).catch(() => {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    },
    async processFile (modal) {
      const file = this.messagePopUp.imageMessage;
      this.isLoading = true;
      const { data, code } = await this.uploadImageGlobal(
        file,
        modal,
        null,
        this.restaurantData.vendor,
        modal
      );
      if (code === 200) {
        this.messagePopUp.content = '<img src="' + data.data + '" />';
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.$buefy.toast.open({
        message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
        type: 'is-danger'
      });
    },
    copyClose () {
      const testingCodeToCopy = document.querySelector('#code-image-close');
      testingCodeToCopy.setAttribute('type', 'text');
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        this.$buefy.toast.open({
          message: 'texto copiado' + msg,
          type: 'is-success'
        });
      } catch (err) {
        this.$buefy.toast.open({
          message: 'Error al copiar texto' + msg,
          type: 'is-danger'
        });
      }
      testingCodeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
    async uploadImage (value, typeUpload, fieldUpload) {
      this.isLoading = true;
      const { data, code, type, field } = await this.uploadImageGlobal(
        value,
        typeUpload,
        fieldUpload,
        this.restaurantData.vendor,
        'restaurant'
      );
      if (code === 200) {
        this.modalURL = null;
        this.update(
          {
            type: type,
            vendorId: this.restaurant.vendorId,
            url: data.data
          },
          field
        );
        return;
      }
      this.isLoading = false;
      this.$buefy.toast.open({
        message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
        type: 'is-danger'
      });
    },
    update (data, field, urlChange = null) {
      serviceAdmin.put('upload/vendor', data).then(() => {
        this.isLoading = false;
        if (field !== 'noUpdate') {
          this.modalURL = data.url;
        }
        this.$buefy.toast.open({
          message: 'Se han actualizado los datos del restaurante ',
          type: 'is-success'
        });
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    }
  },
  watch: {
    fileRestaurantModalSelect (value) {
      this.uploadImage(value, 'modalURL', 'modalURL');
    }
  }
};
</script>

<style lang="scss" scoped>
.listReferrerCompany {
  overflow: hidden;
}
.btn_edit_type_client {
  background-color: #78b538;
}
.btn_delete_type_client {
  background-color: red;
}
.btn_delete_type_client,
.btn_edit_type_client {
  color: #fff;
  border: none;
  margin: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 3px 0 3px;
}
.content-type-custom {
  display: block;
  padding: 10px;
  label {
    padding-left: 8px;
  }
}
.RestaurantFeatures {
  margin-top: 52px;
  overflow-y: hidden;
  &-toolbar {
    background: #fff;
    position: fixed;
    top: 72px;
    left: 82px;
    right: 12px;
    z-index: 5;
  }
  &-toolbarTitle {
    border-left: 2px solid #b5b5b5;
    color: #6c757d;
    font-weight: lighter;
    padding: 6px 10px;
  }
}
.Timetable {
  font-size: 0.8rem;
  &-rowSchedule {
    margin-bottom: 0;
  }

  &-rowScheduleField {
    padding: 0.5rem;
  }
}
.listPeopleCompany {
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
}

.listReferrerCompany {
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
  padding-top: 10px;
}

.text-span {
  display: flex;
  justify-content: flex-start;
}

.listPeopleCompany::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.listPeopleCompany::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
.btnAddPeopleCompany {
  top: -60px;
  float: right;
}
.articleBranch {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #55a9b5;
  font-size: 1rem;
  margin-bottom: 5px;
  &-Body {
    border-color: #55a9b5;
    color: #000;
    padding: 1.25em 1.5em;
    border-radius: 4px;
    border-style: solid;
    border-width: 0 0 0 4px;
  }
  &-Media {
    padding-top: 0;
    border: 0;
    align-items: flex-start;
    display: flex;
    text-align: left;
  }
  &-MediaLeft {
    margin-right: 1rem;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &-MediaRigth {
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    text-align: right;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
.text-copy {
  background: #91d18b;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: fit-content;
}
.cont-copy {
  display: flex;
  flex-direction: column;
}
.RestaurantProcess {
  &-toolbar {
    background: #fff;
    position: fixed;
    top: 72px;
    left: 82px;
    right: 12px;
    z-index: 5;
  }
  &-toolbarTitle {
    border-left: 2px solid #b5b5b5;
    color: #6c757d;
    font-weight: lighter;
    padding: 6px 10px;
  }
  &-cover {
    background-color: #f1f1f1;
    background-size: cover;
    height: 160px;
    width: 100%;
    cursor: pointer;
  }
  &-coverButton,
  &-logoButton {
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    color: #00a7b5;
    display: initial;
    font-size: 0.75rem;
    margin: 1px;
    padding: 0.3rem;
    white-space: nowrap;
  }
  &-coverButtonText {
    display: none;
    opacity: 0;
    transition: all 1s;
  }
  &-logo {
    display: block;
    padding: 10px 0;
    margin-bottom: 30px;
    position: relative;
    &:before {
      background: #00adc6;
      content: "";
      width: 50px;
      height: 2px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &-logoImage {
    height: 100px;
    width: 100px;
    background-color: #f1f1f1;
    border: 1px solid transparent;
    border-radius: 50%;
  }
  &-logo-thumbnail {
    display: flex;
    margin: 0px auto;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
}
.RestaurantProcess-cover:hover .RestaurantProcess-coverButtonText {
  display: block;
  opacity: 1;
}
</style>
