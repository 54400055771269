<template>
  <div class="program">
    <div class="program-title">
      <span>¡Selecciona la fecha y hora en la que quieres enviar tu mensaje!</span>
    </div>
    <div class="program-calendar">
      <CalendarMarketing :programingDate="programming.date" @dateSelected="programming.date = $event"></CalendarMarketing>
    </div>
    <div class="program-hour">
      <div class="program-hour-item">
        <span>Hora</span>
        <b-select placeholder="00:00" expanded v-model="programming.hour" class="selectHour">
          <option v-for="item in hours" :key="'hour#' + item">{{ item }}</option>
        </b-select>
      </div>
    </div>
    <div class="program-actions">
      <button class="program-actions-active" @click="setAccept()" :disabled="(programming.date == null || programming.hour == null)">
        <span>
          Seleccionar
        </span>
      </button>
      <button @click="setClose()">
        <span style="color: black">
          Cerrar
        </span>
      </button>
    </div>
  </div>
</template>
<script>
import CalendarMarketing from '@/components/Marketing/Calendar';
export default {
  props: {
    type: {
      type: String
    }
  },
  components: {
    CalendarMarketing
  },
  data () {
    return {
      hours: [],
      minutes: [],
      programming: {
        date: null,
        hour: '00'
      },
      actualDate: null
    };
  },
  methods: {
    setHours () {
      const now = this.actualDate.clone().add(10, 'minutes'); // Añadir 10 minutos al tiempo actual
      const currentHour = now.hours();
      const currentMinute = now.minutes();

      const horas = [];
      const minutos = [];

      const startHour = this.$moment(this.programming.date).isSame(now, 'day') ? currentHour : 0;

      // Generar horas
      for (let hour = startHour; hour < 24; hour++) {
        const adjustedHour = hour < 10 ? `0${hour}` : `${hour}`;
        horas.push(adjustedHour);
      }

      // Generar minutos
      for (let minute = 0; minute < 60; minute += 5) {
        const adjustedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        minutos.push(adjustedMinute);
      }

      // Ajustar los minutos según la lógica
      const roundedMinute = Math.ceil(currentMinute / 5) * 5; // Redondear al próximo múltiplo de 5
      const adjustedMinutes = minutos.filter(min => parseInt(min) >= roundedMinute);
      if (adjustedMinutes.length > 0) {
        minutos.splice(0, minutos.length, ...adjustedMinutes);
      }

      const hourLists = [];
      for (const hour of horas) {
        const adjustedMinutes = [];
        const startMinutes = parseInt(hour) === currentHour ? roundedMinute : 0;
        for (let minute = startMinutes; minute < 60; minute += 5) {
          const adjustedMinute = minute < 10 ? `0${minute}` : `${minute}`;
          adjustedMinutes.push(`${hour}:${adjustedMinute}`);
        }
        hourLists.push(adjustedMinutes);
      }

      this.hours = hourLists.flat();
    },
    setAccept () {
      this.$emit('accept', this.programming);
      this.$parent.close();
    },
    setClose () {
      this.programming = {
        date: null,
        hour: null
      };
      this.$emit('close');
      this.$parent.close();
    },
    getActualTime () {
      if (this.vendor !== undefined) {
        if (this.vendor.timezone) {
          if (this.vendor.timezone !== 'America/Bogota') {
            this.$store.dispatch({ type: 'global:getServerTime' }).then((data) => {
              const fecha = this.$moment(data.serverTime);
              this.actualDate = fecha;
              this.setHours();
            });
          } else {
            this.$store.dispatch({ type: 'global:getServerTime', data: { timezone: this.vendor.timezone } }).then((data) => {
              const fecha = this.$moment(data.serverTime);
              this.actualDate = fecha;
              this.setHours();
            });
          }
        }
      }
    }
  },
  watch: {
    'programming.date': {
      handler () {
        if (this.programming.date !== null) {
          this.getActualTime();
        }
      }
    }
  }
};
</script>
<style lang="scss">
.program {
  width: 350px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  margin: 0 auto;
  padding: 30px 0px;
  &-title {
    width: 268px;
    margin: 0px 41px 10px 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: #444B57;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &-calendar {
    height: fit-content;
    display: flex;
    justify-content: center;
    position: relative;
  }
  &-hour {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      gap: 20px;
      span {
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .control .select {
        height: 30px;
        select {
          height: 30px !important;
        }
      }
      .selectHour {
        width: 107px;
        height: 30px;
        select {
          text-align: center;
          border-color: #444B57!important;
          border-radius: 6px;
        }
        span::after {
          border-color: #444B57!important;
        }
      }
    }
  }
  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 22px;
    button {
      width: 140px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid #444B57;
      background: #FFF;
      cursor: pointer;
      user-select: none;
      span {
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    &-active {
      background: #444B57 !important;
      span {
        color: #fff;
      }
    }
  }
}
</style>
