<template>
  <div @click="showDetails" class="column is-6 card-poll-box">
    <div class="card card-poll">
      <div
        :style="`border-left: 4px solid ${changeColor(pollDetails)}`"
        class="card-poll__first_info columns is-multiline is-mobile position-relative"
        style="padding-left: 8px;"
      >
        <div
          class="card-poll__type"
          v-if="pollDetails && pollDetails.reservation && pollDetails.reservation.referrer && pollDetails.reservation.referrer != 'walking' && pollDetails.reservation.referrer != 'dashboard' && pollDetails.reservation.referrer != 'yate' && pollDetails.qr === false"
        >
          <img alt src="@/assets/icons/polls/world.svg" srcset />
        </div>
        <div
          class="card-poll__type"
          v-else-if="pollDetails && pollDetails.reservation && pollDetails.reservation.referrer && pollDetails.reservation.referrer === 'walking'"
        >
          <img alt src="@/assets/icons/polls/walking.svg" srcset />
        </div>
        <div class="card-poll__type" v-else-if="pollDetails.qr === true">
          <img alt src="@/assets/icons/polls/qr.svg" srcset />
        </div>
        <div class="card-poll__type" v-else>
          <img alt src="@/assets/icons/polls/cel.svg" srcset />
        </div>
        <div class="column is-12 card-poll__first_info__title">{{pollDetails.client.name}}</div>
        <div class="column is-6 card-poll__first_info__contact ml-8-neg">
          <img alt class="card-poll__first_info__icon" src="@/assets/icons/polls/mail.svg" />
          {{pollDetails.client.email}}
        </div>
        <div class="column is-6 card-poll__first_info__contact">
          <img alt class="card-poll__first_info__icon" src="@/assets/icons/polls/cel.svg" />
          {{pollDetails.client.phone}}
        </div>
        <div class="button-actions-parent" v-if="pollDetails.client.phone != null">
          <button @click="goToAction('phone')" class="button-actions-phone-whatsapp-poll">
            <img alt="phone" src="@/assets/images/viewReservation/Union.svg" />
          </button>
          <button @click="goToAction('whatsapp')" class="button-actions-phone-whatsapp-poll">
            <img alt="whatsapp" src="@/assets/images/viewReservation/wa.svg" />
          </button>
        </div>
      </div>
      <div class="card-poll__first_info columns is-mobile line-h-11">
        <div class="column is-4" v-if="pollDetails.dateSend">
          <div class="fw-300">Encuesta enviada</div>
          <div class="fw-400">{{ pollDetails.dateSend | moment('DD[/]MM[/]YYYY[, ]hh:mm A') }}</div>
        </div>
        <div class="column is-4" v-if="pollDetails.created_at">
          <div class="fw-300">Fecha reserva</div>
          <div class="fw-400">{{ pollDetails.created_at | moment('DD[/]MM[/]YYYY[, ]hh:mm A') }}</div>
        </div>
        <div class="column is-4" v-if="pollDetails.dateAnswer">
          <div class="fw-300">Fecha respuesta</div>
          <div class="fw-400">{{ pollDetails.dateAnswer | moment('DD[/]MM[/]YYYY[, ]hh:mm A') }}</div>
        </div>
      </div>
      <div class="column card-poll__description line is-mobile">
        <img alt class="w-14 card-poll__description__icon" src="@/assets/icons/polls/comment.svg" />
        <div class>
          <p class="fw-300">{{ pollDetails.comments ? pollDetails.comments : 'No hay comentarios' }}</p>
        </div>
      </div>
      <div class="columns is-mobile">
        <div
          class="column is-9 card-poll__rating is-multiline"
          v-if="(pollDetails.response.length !== 0 && pollDetails.response.stars && pollDetails.response.stars.length) || (pollDetails.response.multiRadio && pollDetails.response.multiRadio.length)"
        >
          <div
            :key="index"
            class="column is-4 is-half-mobile"
            v-for="(question, index) in pollDetailQuestions"
          >
            <div class="columns is-mobile">
              <div class="column is-7">{{question.question}}</div>
              <div class="column is-5 rating__star">
                <div v-if="question.qualify > 0">{{question.qualify}}</div>
                <div v-else>-</div>
                <img alt src="@/assets/icons/polls/star.svg" srcset />
              </div>
            </div>
          </div>
          <div class="column is-4 is-half-mobile">
            <div class="columns is-mobile no_bg">
              <div class="column is-9">Más preguntas...</div>
            </div>
          </div>
        </div>
        <div class="column is-9 card-poll__rating is-multiline" v-else>
          <div
            :key="index"
            class="column is-4 is-half-mobile"
            v-for="(question, index) in pollDetailQuestionsAswered"
          >
            <div class="columns is-mobile" v-if="index < 5">
              <div class="column is-7">{{question.question}}</div>
              <div class="column is-5 rating__star">
                <div>-</div>
                <img alt src="@/assets/icons/polls/star_disabled.svg" srcset />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3 has-text-right card-poll__score">
          <label for>Promedio</label>
          <div>{{ promedio() }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pollDetails'],
  mounted () {
  },
  methods: {
    promedio () {
      if (this.pollDetails.length < 0) {
        return 'N/A';
      }
      if (this.pollDetails.response.stars && this.pollDetails.response.stars.length > 0) {
        return this.avg(this.pollDetails.response.stars);
      }
      if (this.pollDetails.response.multiRadio && this.pollDetails.response.multiRadio.length > 0) {
        return this.mostRepeated(this.pollDetails.response.multiRadio);
      }
      return 'N/A';
    },
    mostRepeated (data) {
      // Creamos un objeto para almacenar la cantidad de repeticiones de cada elemento
      const counts = {};
      // Iteramos sobre el array y contamos las repeticiones de cada elemento
      for (let i = 0; i < data.length; i++) {
        const element = data[i].qualify;
        counts[element] = (counts[element] || 0) + 1;
      }
      let mostRepeatedElement;
      let maxCount = 0;
      // Iteramos sobre el objeto de conteos para encontrar el elemento más repetido
      for (const element in counts) {
        if (counts[element] > maxCount) {
          mostRepeatedElement = element;
          maxCount = counts[element];
        }
      }
      return mostRepeatedElement;
    },
    changeColor (type) {
      let color = [
        {
          type: 'reserve',
          color: '#00adc6'
        },
        {
          type: 'prepurchase',
          color: '#BDC30E'
        },
        {
          type: 'walkin',
          color: '#8a51b4'
        },
        {
          type: 'qr',
          color: '#8a51b4'
        }
      ];
      if (type.reservation) {
        color = color.filter((item) =>
          (type.reservation.balancePaid === 0 && type.reservation.referrer !== 'walking' && item.type === 'reserve') ||
          (type.reservation.balancePaid > 0 && item.type === 'prepurchase') ||
          (type.reservation.referrer === 'Walk-In' && item.type === 'walkin') ||
          (type.qr && item.type === 'qr')
        );
        return color[0].color;
      } else if (type.qr) {
        return '#8a51b4';
      } else {
        return '#000';
      }
    },
    avg (array) {
      let sum = 0;
      array.forEach((el) => {
        sum += parseInt(el.qualify);
      });
      const avg = sum / array.length;
      return avg.toFixed(2);
    },
    showDetails () {
      this.$emit('showDetails');
    },
    goToAction (action) {
      switch (action) {
        case 'phone':
          window.location.href = `tel:${this.pollDetails.client.phone}`;
          break;
        case 'whatsapp':
          window.open(`https://api.whatsapp.com/send?phone=${this.pollDetails.client.indicativo}${this.pollDetails.client.phone}&text=Hola`, 'blank');
          break;
      };
    }
  },
  computed: {
    pollDetailQuestions: function () {
      if (this.pollDetails.response.stars) {
        return this.pollDetails.response.stars.filter((item, index) => index < 5);
      }
      if (this.pollDetails.response.multiRadio) {
        return this.pollDetails.response.multiRadio.filter((item, index) => index < 5);
      }
      return null;
    },
    pollDetailQuestionsAswered: function () {
      return this.pollDetails.questions.filter((item, index) => index < 5);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/polls/_card_poll";
.button-actions-phone-whatsapp-poll {
  background-color: #444b57;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  border-radius: 5px;
  border-color: transparent;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  img {
    height: 16px;
    width: 17px;
  }
}
.button-actions-parent {
  display: flex;
  padding-left: 5px;
}
</style>
