<template>
  <div id="modalCreateEditProducts">
    <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <div class="container-create-edit-products" v-if="productForm">
      <div class="container-create-edit-products__header" :class="{'container-create-edit-products__header-with-category-subcategory-names' : typeLangFromSectionCategorySubcategory}">
        <div class="container-create-edit-products__header__tabs" v-if="!typeLangFromSectionCategorySubcategory && params.languageMenuActivate">
          <div class="" :class="computedClasses('spanish')" @click="changeTypeLang('spanish')">Español</div>
          <div class="" :class="computedClasses('english')" @click="changeTypeLang('english')">Inglés</div>
          <div class="" :class="computedClasses('french')" @click="changeTypeLang('french')">Frances</div>
        </div>
        <div class="container-create-edit-products__header-with-category-subcategory-names__titles" v-if="typeLangFromSectionCategorySubcategory">
          <div class="container-create-edit-products__header-with-category-subcategory-names__titles__categories" v-if="nameCategoryFromSectionCategorySubcategory">
            <div>Categoría</div>
            <div>{{nameCategoryFromSectionCategorySubcategory}}</div>
          </div>
          <div class="container-create-edit-products__header-with-category-subcategory-names__titles__subcategories" v-if="nameSubCategoryFromSectionCategorySubcategory">
            <div>Subcategoría</div>
            <div>{{nameSubCategoryFromSectionCategorySubcategory}}</div>
          </div>
        </div>
        <i class="icon-mks icon_close_alternative" @click="$parent.close()"></i>
      </div>
      <div class="container-create-edit-products__content">
        <div class="container-create-edit-products__content__check-as-image">
          <b-checkbox v-model="productForm.isImage" :true-value="1" :false-value="0"></b-checkbox>
          <label for="">Producto como imagen</label>
        </div>
        <b-upload
          @input="fileUpload(fileImageOnlyProduct, 'imageOnlyProduct')"
          class="file-label"
          v-model="fileImageOnlyProduct"
          v-if="productForm.isImage === 1"
        >
        <div class="container-create-edit-products__content__only-image" :style="{'background-image': `url('${getPreviewOnlyImageByLang}')`, backgroundSize: 'cover', backgroundPosition: 'center'}" >
          <div v-if="!getPreviewOnlyImageByLang">
            <i class="icon-mks icon_no_image_darker"></i>
            <div>Subir imagen</div>
          </div>
        </div>
        </b-upload>
        <b-upload
          @input="fileUpload(fileImageProduct, 'imageProduct')"
          class="file-label"
          v-model="fileImageProduct"
          v-if="productForm.isImage === 0"
        >
          <div class="container-create-edit-products__content__image" :style="{'background-image': `url('${getPreviewImageByLang}')`, backgroundSize: 'cover', backgroundPosition: 'center'}" >
            <div v-show="!getPreviewImageByLang">
              <i class="icon-mks icon_no_image_darker"></i>
              <div>Subir imagen</div>
            </div>
          </div>
        </b-upload>
        <div class="container-create-edit-products__content__info container-create-edit-products__content__info-es" v-show="typeLang === 'spanish' && productForm.isImage === 0">
          <div>
            <label for="">Nombre del producto</label>
            <b-input v-model="productForm.names.name" />
          </div>
          <div>
            <label for="">Descripción</label>
            <vue-editor :editorOptions="editorSettings" v-model="descriptionEditorEs" />
          </div>
        </div>
        <div class="container-create-edit-products__content__info container-create-edit-products__content__info-eng" v-show="typeLang === 'english' && productForm.isImage === 0">
          <div >
            <label for="">Nombre del producto</label>
            <b-input v-model="productForm.names.nameEnglish" />
          </div>
          <div>
            <label for="">Descripción</label>
            <vue-editor :editorOptions="editorSettings" v-model="descriptionEditorEn" />
          </div>
        </div>
        <div class="container-create-edit-products__content__info container-create-edit-products__content__info-fr" v-show="typeLang === 'french' && productForm.isImage === 0">
          <div>
            <label for="">Nombre del producto</label>
            <b-input :state="false" :message="''" v-model="productForm.names.nameFrench" />
          </div>
          <div>
            <label for="">Descripción</label>
            <vue-editor :editorOptions="editorSettings" v-model="descriptionEditorFr" />
          </div>
        </div>
        <div class="container-create-edit-products__content__options">
          <div v-if="idMenuMultipleForCategoriesProducts === null">
            <label for="">Menú</label>
            <b-select :state="false" :message="''" placeholder="Seleccione un menú" v-model="productForm.vendorMenuId">
              <option v-for="(menu, index) in listMenus" :value="menu.id" :key="index">{{menu.label}}</option>
            </b-select>
          </div>
          <div v-if="!createProductGlobal">
            <label for="">Categoría</label>
            <b-select :state="false" :message="''" v-model="productForm.categoryId" placeholder="Seleccione una categoría" @input="() => this.productForm.subcategoryId = null">
              <option value="" disabled v-if="categories.length === 0">No hay categorías creadas</option>
              <option :value="category._id" v-for="(category, index) in categories" :key="index">{{getCategorySubcategoryByLang(category)}}</option>
            </b-select>
          </div>
          <div v-if="!createProductGlobal">
            <label for="">Subcategoría</label>
            <b-select :state="false" :message="''" v-model="productForm.subcategoryId" placeholder="Opcional">
              <option value="" disabled v-if="subCategories.length === 0">No hay subcategorías disponibles</option>
              <option :value="subCategory._id" v-for="(subCategory, index) in subCategories" :key="index">{{getCategorySubcategoryByLang(subCategory)}}</option>
            </b-select>
          </div>
          <div class="">
            <div class="dietary-restrictions-options">
              <label for="">Restricciones alimentarias</label>
              <div class="">
                <div class="container-dietary-restriction" :class="`${getClassActive(dietary.index)}`" v-for="(dietary, index) in dietaryRestrictions" :key="index" @click="selectDietary(dietary.index)">
                  <b-tooltip
                    :label="dietary.name"
                    type="is-light"
                    position="is-bottom"
                    square
                  >
                    <i class="icon-mks icon-dietary-restriction-general" :style="{'-webkit-mask-image': `url(${dietary.iconURL})`, maskImage: `url('${dietary.iconURL}')`}" :class="`dietary-${formatNameDietary(dietary.name)}`"></i>
                  </b-tooltip>
                </div>
              </div>
            </div>
            <div>
              <div>
                <label for="">Orden</label>
                <b-input v-model="productForm.order" />
              </div>
              <div :style="productForm.price === '' || productForm.price === null  ? {opacity: '0.4'} : ''" v-if="productForm.isImage === 0">
                <label for="">Precio derecha</label>
                <div class="control is-clearfix">
                  <input
                    ref="priceInput"
                    type="text"
                    :controls="false"
                    v-model="productForm.price"
                    placeholder="-"
                    @input="validatePriceInputRight($event)"
                  />
                </div>
              </div>
              <div v-if="productForm.isImage === 0 && isTwoPrices" :style="productForm.priceTwo === null || productForm.priceTwo === ''  ? {opacity: '0.4'} : ''">
                <label for="">Precio izquierda</label>
                <div class="control is-clearfix">
                  <input
                    ref="priceTwoInput"
                    type="text"
                    :controls="false"
                    v-model="productForm.priceTwo"
                    placeholder="-"
                    @input="validatePriceInputLeft($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-create-edit-products__content__actions" >
          <b-button @click="createEditProduct" :disabled="!formIsValid">{{type === 'create' ? 'Crear producto' : 'Guardar cambios'}}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import { VueEditor } from 'vue2-editor';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  components: {
    VueEditor
  },
  props: [
    'restaurant',
    'listCategories',
    'product',
    'type',
    'idMenuMultipleForCategoriesProducts',
    'createProductGlobal',
    'idCategoryFromCreateCategory',
    'idSubcategoryFromCreateCategory',
    'isTwoPricesFromCategory',
    'typeLangFromSectionCategorySubcategory',
    'nameCategoryFromSectionCategorySubcategory',
    'nameSubCategoryFromSectionCategorySubcategory'
  ],
  data () {
    return {
      isLoadingCategories: false,
      dietaryRestrictions: [],
      dietaryRestrictionSelected: [],
      categories: [],
      subCategories: [],
      isLoading: false,
      typeLang: 'spanish',
      isTwoPrices: false,
      fileImageProduct: null,
      fileImageOnlyProduct: null,
      imagePreview: {
        es: null,
        en: null,
        fr: null
      },
      imageOnlyPreview: {
        es: null,
        en: null,
        fr: null
      },
      descriptionEditorEs: null,
      descriptionEditorEn: null,
      descriptionEditorFr: null,
      editorSettings: {
        modules: {
          toolbar: false
        }
      },
      productForm: {
        isImage: 0,
        name: null,
        names: {
          name: null,
          nameEnglish: null,
          nameFrench: null
        },
        description: {
          description: null,
          descriptionEnglish: null,
          descriptionFrench: null
        },
        order: 0,
        price: null,
        priceTwo: null,
        image: null,
        imageEnglish: null,
        imageFrench: null,
        imageProducts: null,
        imageProductsEnglish: null,
        imageProductsFrench: null,
        active: 1,
        vendorId: this.restaurant.vendorId,
        vendorMenuId: null,
        typeLanguage: null,
        typePrice: 'COP',
        categoryId: null,
        subcategoryId: null,
        dietaryRestrictions: []
      },
      // data para creación de productos globales con selector de menu
      listMenus: []
    };
  },
  computed: {
    computedClasses () {
      return (lang) => ({
        'active-tab': this.typeLang === lang
      });
    },
    getPreviewImageByLang () {
      if (this.typeLang === 'spanish') {
        return this.imagePreview.es;
      }
      if (this.typeLang === 'english') {
        return this.imagePreview.en;
      }
      if (this.typeLang === 'french') {
        return this.imagePreview.fr;
      }
      return null;
    },
    getPreviewOnlyImageByLang () {
      if (this.typeLang === 'spanish') {
        return this.imageOnlyPreview.es;
      }
      if (this.typeLang === 'english') {
        return this.imageOnlyPreview.en;
      }
      if (this.typeLang === 'french') {
        return this.imageOnlyPreview.fr;
      }
      return null;
    },
    getCategorySubcategoryByLang () {
      return (data) => {
        if (this.typeLang === 'spanish') {
          return data.name ? data.name : `(ES) ${data.nameEnglish ? data.nameEnglish : ''}`;
        }
        if (this.typeLang === 'english') {
          return data.nameEnglish ? data.nameEnglish : `(IN) ${data.name}`;
        }
        if (this.typeLang === 'french') {
          return data.nameFrench ? data.nameFrench : `(FR) ${data.name}`;
        }
        return null;
      };
    },
    formIsValid () {
      const hasValidPrices = (this.productForm.price !== null && this.productForm.price !== '') && (this.isTwoPrices ? this.productForm.priceTwo !== null && this.productForm.priceTwo !== '' : true);
      const hasCategory = this.productForm.categoryId !== undefined && this.productForm.categoryId !== null;
      const hasMenu = this.productForm.vendorMenuId !== undefined && this.productForm.vendorMenuId !== null;
      if (this.productForm.isImage === 1) {
        if (this.createProductGlobal) {
          return true;
        } else {
          return hasCategory;
        }
      } else {
        const hasName = this.productForm.names.name !== null && this.productForm.names.name !== '';
        if (this.createProductGlobal) {
          return hasName && hasValidPrices;
        } else {
          if (this.idMenuMultipleForCategoriesProducts === null) {
            return hasName && hasMenu && hasValidPrices;
          } else {
            return hasName && hasCategory && hasValidPrices;
          }
        }
      }
    }
  },
  mounted () {
    this.getListDietary();
    if (this.isTwoPricesFromCategory) {
      this.isTwoPrices = true;
    }
    if (this.idMenuMultipleForCategoriesProducts !== null) {
      this.getListCategories();
    }
    if (this.idMenuMultipleForCategoriesProducts === null) {
      this.getAllMenus();
    }
    if (this.type === 'edit') {
      const productsAux = { ...this.product };
      productsAux.categoryId = null;
      this.productForm = {
        ...this.productForm,
        ...productsAux
      };
      /* this.productForm.vendorMenuId */
      this.imagePreview = {
        es: this.productForm.image,
        en: this.productForm.imageEnglish,
        fr: this.productForm.imageFrench
      };
      this.imageOnlyPreview = {
        es: this.productForm.imageProducts,
        en: this.productForm.imageProductsEnglish,
        fr: this.productForm.imageProductsFrench
      };
      if (this.productForm.names === null) {
        this.productForm.names = {
          name: null,
          nameEnglish: null,
          nameFrench: null
        };
      }
      this.productForm.names.name = this.productForm.name;
      this.productForm.names.nameEnglish = this.productForm.nameEnglish;
      this.productForm.names.nameFrench = this.productForm.nameFrench;
      this.descriptionEditorEs = this.productForm.description ? (typeof this.productForm.description === 'object' ? this.productForm.description.description : this.productForm.description) : null;
      this.descriptionEditorEn = this.productForm.descriptionEnglish;
      this.descriptionEditorFr = this.productForm.descriptionFrench;
      if (this.productForm.dietaryRestrictions !== null) {
        this.dietaryRestrictionSelected = this.productForm.dietaryRestrictions;
      }
    }
    if (this.typeLangFromSectionCategorySubcategory) {
      this.typeLang = this.typeLangFromSectionCategorySubcategory;
    }
  },
  methods: {
    validatePriceInputRight (event) {
      const value = event.target.value;
      const filteredValue = value.replace(/[^0-9,.]/g, '');
      const finalValue = filteredValue.replace(/([,.])(.*?)([,.])/g, '$1$2');
      event.target.value = finalValue;
      this.productForm.price = finalValue;
    },
    validatePriceInputLeft (event) {
      const value = event.target.value;
      const filteredValue = value.replace(/[^0-9,.]/g, '');
      const finalValue = filteredValue.replace(/([,.])(.*?)([,.])/g, '$1$2');
      event.target.value = finalValue;
      this.productForm.priceTwo = finalValue;
    },
    getClassActive (dietaryId) {
      return this.dietaryRestrictionSelected && this.dietaryRestrictionSelected.includes(dietaryId)
        ? 'dietary-restriction-selected'
        : '';
    },
    selectDietary (id) {
      if (!this.dietaryRestrictionSelected.includes(id)) {
        this.dietaryRestrictionSelected.push(id);
      } else {
        this.dietaryRestrictionSelected = this.dietaryRestrictionSelected.filter((idDietary) => idDietary !== id);
      }
    },
    formatNameDietary (word) {
      let formattedString = word.toLowerCase();
      formattedString = formattedString.replace(/\s+/g, '');
      return formattedString;
    },
    getListDietary () {
      this.isLoading = true;
      serviceQr.get(`/menuCustom/DietaryRestrictions/${this.restaurant.vendorId}`).then(({ data }) => {
        this.dietaryRestrictions = data.data;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    getListCategories (idMenuList = null) {
      this.isLoadingCategories = true; // Iniciar carga
      serviceQr.get(`/menuCustom/categories/${idMenuList === null ? this.idMenuMultipleForCategoriesProducts : idMenuList}?all=true`)
        .then(({ data }) => {
          this.categories = data.data;
          this.isLoadingCategories = false; // Finalizar carga
        })
        .catch(() => {
          this.isLoadingCategories = false; // Si hay error también se finaliza la carga
        });
    },
    changeTypeLang (type) {
      this.typeLang = type;
    },
    fileUpload (file, type) {
      this.isLoading = true;
      const name = file.name.split('.');
      this.uploadImageGlobalCloud(file, name[0], null, this.$store.getters['global:vendor'].vendor, 'menu', 1)
        .then(({ data, code }) => {
          if (code === 200) {
            if (type === 'imageProduct') {
              if (this.typeLang === 'spanish') {
                this.imagePreview.es = data.data;
              }
              if (this.typeLang === 'english') {
                this.imagePreview.en = data.data;
              }
              if (this.typeLang === 'french') {
                this.imagePreview.fr = data.data;
              }
            }
            if (type === 'imageOnlyProduct') {
              if (this.typeLang === 'spanish') {
                this.imageOnlyPreview.es = data.data;
              }
              if (this.typeLang === 'english') {
                this.imageOnlyPreview.en = data.data;
              }
              if (this.typeLang === 'french') {
                this.imageOnlyPreview.fr = data.data;
              }
            }
            this.isLoading = false;
            this.$buefy.toast.open({
              message: 'Se ha subido la imagen',
              type: 'is-success'
            });
            return;
          }
          this.isLoading = false;
          this.$buefy.toast.open({
            message: this.$t('errors.request'),
            type: 'is-danger'
          });
        });
    },
    createEditProduct () {
      this.isLoading = true;
      if (this.productForm.isImage === 0) {
        this.productForm.image = this.imagePreview.es;
        this.productForm.imageEnglish = this.imagePreview.en;
        this.productForm.imageFrench = this.imagePreview.fr;
        this.productForm.name = this.productForm.names.name;
        this.productForm.nameEnglish = this.productForm.names.nameEnglish;
        this.productForm.nameFrench = this.productForm.names.nameFrench;
        this.productForm.description = this.descriptionEditorEs;
        this.productForm.descriptionEnglish = this.descriptionEditorEn;
        this.productForm.descriptionFrench = this.descriptionEditorFr;
      }
      if (this.productForm.names.nameEnglish === null || this.productForm.names.nameEnglish === '') {
        this.productForm.nameEnglish = this.productForm.name;
      }
      if (this.productForm.names.nameFrench === null || this.productForm.names.nameFrench === '') {
        this.productForm.nameFrench = this.productForm.name;
      }
      if (this.descriptionEditorEn === null || this.descriptionEditorEn === '') {
        this.productForm.descriptionEnglish = this.productForm.description;
      }
      if (this.descriptionEditorFr === null || this.descriptionEditorFr === '') {
        this.productForm.descriptionFrench = this.productForm.description;
      }
      if (this.productForm.names.nameFrench === null || this.productForm.names.nameFrench === '') {
        this.productForm.nameFrench = this.productForm.name;
      }
      if (this.productForm.isImage === 1) {
        this.productForm.name = null;
        this.productForm.nameEnglish = null;
        this.productForm.nameFrench = null;
        this.productForm.description = null;
        this.productForm.descriptionEnglish = null;
        this.productForm.descriptionFrench = null;
        this.productForm.price = null;
        this.productForm.priceTwo = null;
        this.productForm.imageProducts = this.imageOnlyPreview.es;
        this.productForm.imageProductsEnglish = this.imageOnlyPreview.en;
        this.productForm.imageProductsFrench = this.imageOnlyPreview.fr;
        this.productForm.names = {
          name: null,
          nameEnglish: null,
          nameFrench: null
        };
      }
      if (this.productForm.categoryId !== null && this.productForm.subcategoryId !== null) {
        this.productForm.categoryId = this.productForm.subcategoryId;
      }
      // me permite validar si la creación de producto se está haciendo externa, si createProductGlobal es true o existe, creará el producto por defecto para un menú
      // también valida que si alguno de estos 2 parametros (idCategoryFromCreateCategory, idSubcategoryFromSubCreateCategory) existe, creará el producto para una categoria o una subcategoria por defecto
      if (this.createProductGlobal) {
        if (!this.idCategoryFromCreateCategory && !this.idSubcategoryFromCreateCategory) {
          if (this.idMenuMultipleForCategoriesProducts !== null) {
            this.productForm.vendorMenuId = this.idMenuMultipleForCategoriesProducts;
          }
        } else {
          if (this.idCategoryFromCreateCategory !== null) {
            this.productForm.categoryId = this.idCategoryFromCreateCategory;
          }
          if (this.idSubcategoryFromCreateCategory !== null) {
            this.productForm.categoryId = this.idSubcategoryFromCreateCategory;
          }
        }
      }
      // permite crear productos con selector de menus
      if (this.idMenuMultipleForCategoriesProducts === null) {
        if (this.productForm.vendorMenuId !== null) {
          if (this.productForm.categoryId !== null) {
            this.productForm.vendorMenuId = null;
            if (this.productForm.subcategoryId !== null) {
              this.productForm.categoryId = this.productForm.subcategoryId;
            }
          }
        }
      }
      this.productForm.dietaryRestrictions = this.dietaryRestrictionSelected;
      this.productForm.typeLanguage = 'both';
      this.productForm.order = parseInt(this.productForm.order);
      if (this.type === 'create') {
        serviceQr.post('/menuCustom/product', this.productForm).then((response) => {
          setTimeout(() => {
            this.$parent.close();
          }, 500);
          if (response.data.code === 200) {
            this.executeAlert('success', 'Producto creado con éxito');
            this.$emit('getListProducts');
            if (this.createProductGlobal) {
              this.$emit('listProductsCategorySubcategory');
            }
            this.isLoading = false;
            return;
          } else {
            this.$buefy.toast.open({
              duration: 2000,
              message: 'Ha ocurrido un error, por favor, vuelve a intentarlo.',
              position: 'is-top',
              type: 'is-danger'
            });
          }
          this.isLoading = false;
        }).catch(() => {
          setTimeout(() => {
            this.$parent.close();
          }, 500);
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Ha ocurrido un error, por favor, vuelve a intentarlo.',
            position: 'is-top',
            type: 'is-danger'
          });
          this.isLoading = false;
        });
      }
      if (this.type === 'edit') {
        serviceQr.put(`/menuCustom/editProduct/${this.productForm._id}`, this.productForm).then((response) => {
          setTimeout(() => {
            this.$parent.close();
          }, 500);
          if (response.data.code === 200) {
            this.executeAlert('success', 'Producto editado con éxito');
            this.$emit('getListProducts');
            this.isLoading = false;
            return;
          } else {
            this.$buefy.toast.open({
              duration: 2000,
              message: 'Ha ocurrido un error, por favor, vuelve a intentarlo.',
              position: 'is-top',
              type: 'is-danger'
            });
          }
          this.isLoading = false;
        }).catch(() => {
          setTimeout(() => {
            this.$parent.close();
          }, 500);
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Ha ocurrido un error, por favor, vuelve a intentarlo.',
            position: 'is-top',
            type: 'is-danger'
          });
          this.isLoading = false;
        });
      }
    },
    executeAlert (type, title) {
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete alert-success',
        props: {
          idMenu: null,
          type: type,
          title: title,
          subtitle: '',
          typeDelete: ''
        },
        events: {
        }
      });
    },
    findCategoryByIdSubcategory (subcategoryId) {
      const searchSubcategory = (categories, id) => {
        for (const category of categories) {
          if (category._id === id) {
            return { category, subcategory: null };
          }
          if (category.children && category.children.length > 0) {
            const subcategory = category.children.find(child => child._id === id);
            if (subcategory) {
              return { category, subcategory };
            }
            const result = searchSubcategory(category.children, id);
            if (result) {
              return result;
            }
          }
        }
        return null;
      };
      return searchSubcategory(this.categories, subcategoryId);
    },
    getAllMenus () {
      this.isLoading = true;
      serviceQr.get(`/menuCustom/listMenu/${this.restaurant.vendorId}`).then(({ data }) => {
        const arrayList = [];
        data.data.forEach(element => {
          arrayList.push({ id: element._id, label: element.name });
        });
        this.listMenus = arrayList;
        this.isLoading = false;
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
        this.isLoading = false;
      });
    }
  },
  watch: {
    'productForm.categoryId': {
      handler (value) {
        // Asegurarse de que categories haya sido cargado
        if (this.isLoadingCategories || this.categories.length === 0) {
          return; // Salir si no ha terminado de cargar o categories está vacío
        }
        this.isTwoPrices = false;
        // Buscar la categoría en categories
        const haveTwoPriceshaveSubcategory = this.categories.find(category => category._id === value);
        // Lógica para manejar precios y subcategorías
        if (haveTwoPriceshaveSubcategory) {
          if (haveTwoPriceshaveSubcategory.twoPrices === 1) {
            this.isTwoPrices = true;
          }
          this.subCategories = [];
          if (haveTwoPriceshaveSubcategory.children !== undefined) {
            this.subCategories = haveTwoPriceshaveSubcategory.children;
          }
        }
      }
    },
    listMenus () {
      if (this.type === 'edit') {
        this.productForm.vendorMenuId = this.productForm.categoryInfo.menuId;
      }
    },
    categories () {
      if (this.type === 'edit') {
        this.productForm.categoryId = this.product.categoryId;
        if (this.productForm.categoryInfo && this.productForm.categoryInfo.id !== null) {
          this.productForm.categoryId = this.productForm.categoryInfo.id;
        }
        if (this.productForm.categoryInfo && this.productForm.categoryInfo.subcategory.id !== null) {
          const categoryResult = this.findCategoryByIdSubcategory(this.productForm.categoryInfo.subcategory.id);
          if (categoryResult) {
            this.productForm.categoryId = categoryResult.category._id;
            this.productForm.subcategoryId = categoryResult.subcategory._id;
          }
        }
      }
    },
    'productForm.vendorMenuId': {
      handler (value) {
        if (value !== null) {
          this.getListCategories(value);
        }
      }
    }
  }
};
</script>

<style>
</style>
