var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-menu-qr-config",staticStyle:{"padding-top":"0!important"}},[(!_vm.createEditCategorySection)?_c('div',[_c('b-loading',{attrs:{"active":_vm.isLoading,"can-cancel":true,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-tabs',{class:{'custom-margin' : _vm.activeTab === 0},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{staticClass:"categories-tab",attrs:{"label":"Categoría"}},[_c('div',{staticClass:"categories-tab__actions"},[_c('div',{staticClass:"categories-tab__actions--back",on:{"click":function($event){return _vm.$emit('goBackMenus')}}},[_c('i',{staticClass:"icon-mks icon_arrow_down_general"}),_c('div',{style:(`background-image: url(${_vm.bgImageMenu ? _vm.bgImageMenu.img: ''}); background-size: cover;background-position: center;`)},[_vm._v(_vm._s(_vm.bgImageMenu ? _vm.bgImageMenu.name : ''))])]),_c('div',{staticClass:"categories-tab__actions__create-search"},[_c('b-button',{on:{"click":() => {
              this.changeStatusSectionCreateEditCategories(true);
              this.typeSectionCategory = 'create';
            }}},[_vm._v(" Crear categoría ")]),_c('div',{staticClass:"categories-tab__actions__create-search--searcher-list-products"},[_c('img',{attrs:{"src":require("@/assets/icons/menuQr/search.svg"),"alt":""}}),_c('b-input',{attrs:{"placeholder":"Bucar por nombre","autocomplete":"off"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1)]),(_vm.activeTab === 0)?_c('section',{staticClass:"container-menu-qr-config__categories",attrs:{"id":"listCategories"}},[(_vm.listCategories.length === 0)?_c('h3',[_vm._v("No hay categorías disponibles")]):_vm._e(),_c('div',{staticClass:"container-list-categories-aux"},_vm._l((_vm.listCategories),function(category,index){return _c('CardCategories',{key:index,attrs:{"idMenuMultipleForCategoriesProducts":_vm.idMenuMultipleForCategoriesProducts,"restaurant":_vm.restaurant,"category":category},on:{"getListCategories":_vm.getListCategories,"changeStatusLoader":_vm.changeStatusLoader,"setCategoryDetail":_vm.setCategoryDetail,"clearListCategories":_vm.clearListCategories}})}),1)]):_vm._e()]),_c('b-tab-item',{staticClass:"products-tab",attrs:{"label":"Productos"}},[(_vm.activeTab === 1)?_c('section',{staticClass:"container-menu-qr-config__products"},[_c('ListProducts',{attrs:{"restaurant":_vm.restaurant,"idMenuMultipleForCategoriesProducts":_vm.idMenuMultipleForCategoriesProducts,"listCategories":_vm.listCategories}})],1):_vm._e()]),_c('b-tab-item',{staticClass:"info-tab",attrs:{"label":"Información"}},[_c('InfoOptimizer')],1)],1)],1):_c('div',{},[_c('SectionCreateEditCategory',{attrs:{"restaurant":_vm.restaurant,"idMenuMultipleForCategoriesProducts":_vm.idMenuMultipleForCategoriesProducts,"type":_vm.typeSectionCategory,"dataCategorySelected":_vm.dataCategorySelected},on:{"changeStatusSectionCreateEditCategories":_vm.changeStatusSectionCreateEditCategories,"getListCategories":() => {
      _vm.clearListCategories();
      _vm.getListCategories();
    }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }