<template>
  <div id="infoOptimizer">
    <section class="info-tab__general">
      <p>1. Verifica que tu archivo este en jpg, de no ser así debes dar clic en  <img style="margin-bottom: -2px;" @click="redirectUrl('ilovepdf')" src="@/assets/icons/menuQr/ilovepdf-min.svg" alt="" srcset=""> para cambiar el formato.</p>
      <p>2. La imagen no debe pesar mas de 2 MB, si necesitas optimizar la imagen puedes dar clic en <img style="margin-bottom: -2px;" @click="redirectUrl('optimizilla')" src="@/assets/icons/menuQr/optimizilla-min.svg" alt="" srcset=""></p>
      <p>3. Ya con tus imágenes lista puedes seguir con la configuración de tu menú.</p>
      <div class="info-tab__general__optimizers">
        <div>
          <h3>De PDF a JPG</h3>
          <div @click="redirectUrl('ilovepdf')">
            <img src="@/assets/icons/menuQr/ilovepdf-min.svg" alt="" srcset="">
          </div>
        </div>
        <div>
          <h3>Optimizador de imagenes</h3>
          <div @click="redirectUrl('optimizilla')">
            <img src="@/assets/icons/menuQr/optimizilla-min.svg" alt="" srcset="">
          </div>
        </div>
      </div>
      <div class="info-tab__general__max-size">
        <h3>¡A tener en cuenta!</h3>
        <p>
          El peso máximo de las imágenes es de que no se quieran optimizar, es de <b>2 MB</b>.<br>
          Si se van a subir múltiples imágenes, ninguna puede pesar más de <b>1 MB</b>.<br>
          Solo se acepta formato <b>JPG, JPEG, PNG</b>.
        </p>
      </div>
    </section>
    <div class="divider-info"></div>
    <section class="info-tab__measures">
      <h3>Medidas para el menú</h3>
      <div>
        <div>
          Espacio del logo<br>
          (274-123px)
        </div>
        <div>
          Botón con imagen<br>
          (342-130px)
        </div>
        <div>
          Botón icono<br>
          (300x80px)
        </div>
      </div>
      <p>La imagen de fondo para el menú en mobile puede ser de  320x972px.</p>
    </section>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
    };
  },
  methods: {
    redirectUrl (type) {
      switch (type) {
        case 'ilovepdf':
          window.open('https://www.ilovepdf.com/es/pdf_a_jpg', '_blank');
          break;
        case 'optimizilla':
          window.open('https://imagecompressor.com/es/', '_blank');
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/menuQr/info/_info_optimizers.scss";
</style>
