<template>
  <div class="sidebar-info-container__section-filter-dates__date__ranges" :class="{'sidebar-info-container__section-filter-dates__date__ranges-modal' : modeModal}">
    <i class="icon-mks icon_close" @click="()=>{
      !modeModal ? $emit('closeFilters') : $parent.close();
    }"></i>
    <h3 v-if="!modeModal">
      Seleccione el rango de fechas
    </h3>
    <div v-else class="sidebar-info-container__section-filter-dates__date__ranges__start-final-dates">
      <div class="sidebar-info-container__section-filter-dates__date__ranges__start-final-dates__date">
        <h3>Seleccione la fecha inicio</h3>
        <span class="no-opacity-date" v-if="dateDisplay && dateDisplay.start">{{dateDisplay && dateDisplay.start | moment("DD/MM/YYYY") }}</span>
        <span v-else>00/00/2024</span>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="12" viewBox="0 0 51 12" fill="none">
        <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM51 6L41 0.226497V11.7735L51 6ZM1 7H42V5H1V7Z" fill="#444B57"/>
      </svg>
      <div class="sidebar-info-container__section-filter-dates__date__ranges__start-final-dates__date">
        <h3>Selecciona la fecha fin</h3>
        <span class="no-opacity-date" v-if="dateDisplay && dateDisplay.end">{{dateDisplay && dateDisplay.end | moment("DD/MM/YYYY") }}</span>
        <span v-else>00/00/2024</span>
      </div>
    </div>
    <v-date-picker
      id="daysContainer"
      :value="valueDate"
      @input="changeDataAux"
      is-range
      :columns="2"
      transition="fade"
      :theme-styles="themeStyles"
      :masks="dateMasks"
      :show-edge-dates="false"
    >
    </v-date-picker>
    <b-button v-if="!modeModal" :disabled="!valueDate || (!valueDate.start && !valueDate.end)" @click="() =>{
      $emit('filterWithDates');
    }">Buscar</b-button>
    <b-button v-else :disabled="!dateDisplay || (!dateDisplay.start && !dateDisplay.end)" @click="() =>{
      $emit('filterWithDates');
      $parent.close();
    }">Buscar</b-button>
  </div>
</template>

<script>
export default {
  props: ['valueDate', 'dateMasks', 'themeStyles', 'modeModal'],
  data () {
    return {
      dateDisplay: null
    };
  },
  mounted () {
    if (!this.valueDate) {
      this.dateDisplay = null;
    }
  },
  methods: {
    changeDataAux (e) {
      this.dateDisplay = e;
      this.$emit('changeDate', e);
    }
  }
};
</script>

<style>

</style>
