import api from '@/helpers/api';
import authTypes from '@/store/types/auth';
import globalTypes from '@/store/types/global';
import pollTypes from '@/store/types/poll';
import reservationTypes from '@/store/types/reservation';
import marketingTypes from '@/store/types/marketing';
import searchsTypes from '@/store/types/searchs';
import sectionTypes from '@/store/types/section';
import { getColombiaHolidaysByYear } from 'colombia-holidays';
import LCrypt from 'lcrypt';
import _ from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';

const lcrypt = new LCrypt(process.env.VUE_APP_TOKEN_API);
const holidays = getColombiaHolidaysByYear(new Date().getFullYear());

export default {
  computed: {
    ...mapGetters({
      filterDateIn: [searchsTypes.getters.dateInFilter],
      filterDateOut: [searchsTypes.getters.dateOutFilter],
      filterExecute: [searchsTypes.getters.executeFilter],
      filterLoading: [searchsTypes.getters.loadingFilter],
      filterStatus: [searchsTypes.getters.statusFilter],
      filterName: [searchsTypes.getters.nameFilter],
      filterEmail: [searchsTypes.getters.emailFilter],
      filterPhone: [searchsTypes.getters.phoneFilter],
      filterReservationId: [searchsTypes.getters.reservationIdFilter],
      changedPassword: [authTypes.getters.changedPassword],
      changedVendor: [authTypes.getters.changedVendor],
      idUser: [authTypes.getters.userId],
      user: [authTypes.getters.user],
      getEditMap: [globalTypes.getters.editMap],
      getUrl: [globalTypes.getters.url],
      socket: [globalTypes.getters.socket],
      getStatusOnline: [globalTypes.getters.online],
      getVendor: [globalTypes.getters.vendor],
      getVersion: [globalTypes.getters.version],
      getVersionApp: [globalTypes.getters.versionApp],
      getPermissions: [globalTypes.getters.permissions],
      getSelectedDate: [reservationTypes.getters.selectedDate],
      getSelectedMonth: [reservationTypes.getters.selectedMonth],
      getLoadingReservation: [reservationTypes.getters.loadingReservation],
      reservationDashboard: [reservationTypes.getters.reservationDashboard],
      reservationDashboardClean: [reservationTypes.getters.reservationDashboardClean],
      getSchedule: [reservationTypes.getters.schedules],
      getSections: [sectionTypes.getters.sections],
      requirements: [reservationTypes.getters.requirements],
      getTables: [reservationTypes.getters.tables],
      getVendorId: [globalTypes.getters.vendorId],
      getId: [globalTypes.getters.idVendor],
      getReservation: [reservationTypes.getters.reservations],
      getReservationsDay: [reservationTypes.getters.reservationsDay],
      getMap: [reservationTypes.getters.map],
      getList: [reservationTypes.getters.list],
      getTimes: [reservationTypes.getters.times],
      blocked: [reservationTypes.getters.blocked],
      getDateFilterSelect: [reservationTypes.getters.dateFilterSelect],
      getSectionFilterSelect: [reservationTypes.getters.sectionFilterSelect],
      getSelectTableId: [reservationTypes.getters.selectTableId],
      getSelectTableInfo: [reservationTypes.getters.selectTableInfo],
      getInfoReservation: [reservationTypes.getters.infoReservation],
      getNewReservation: [reservationTypes.getters.addReservation],
      getNewWalkIn: [reservationTypes.getters.addWalkIn],
      isViewReservation: [reservationTypes.getters.viewReservation],
      isEditReservation: [reservationTypes.getters.editReservation],
      isType: [reservationTypes.getters.type],
      getSectionSelected: [reservationTypes.getters.sectionSelected],
      getTableSelected: [reservationTypes.getters.tableSelected],
      getTableSelectedId: [reservationTypes.getters.tableSelectedId],
      getNotifications: [reservationTypes.getters.notifications],
      apiReportDownload: [reservationTypes.getters.apiReportDownload],
      typeApiReportDownload: [reservationTypes.getters.typeApiReportDownload],
      poll: [pollTypes.getters.poll],
      pollInformation: [pollTypes.getters.pollInformation],
      groupVendor: [reservationTypes.getters.groupVendor],
      groupUrlVendor: [reservationTypes.getters.groupUrlVendor],
      originVendor: [reservationTypes.getters.originVendor],
      originVendorPeople: [reservationTypes.getters.originVendorPeople],
      updateSystem: [reservationTypes.getters.updateSystem],
      allEvents: [reservationTypes.getters.allEvents],
      fromMap: [reservationTypes.getters.fromMap],
      modules: [reservationTypes.getters.modules],
      rotation: [reservationTypes.getters.rotation],
      package: [marketingTypes.getters.package],
      integrations: [marketingTypes.getters.integrations],
      integrationDefaults: [marketingTypes.getters.integrationDefaults],
      cardTypeInfo: [marketingTypes.getters.cardTypeInfo],
      blockingTime: [reservationTypes.getters.blockingTime],
      showTempPassword: [authTypes.getters.showTempPassword],
      blockingDay: [reservationTypes.getters.blockingDay],
      showTurnDetail: [globalTypes.getters.showTurnDetail]
    }),
    deviceId () {
      const deviceId = window.localStorage.getItem('deviceId_dashboard');
      if (deviceId) {
        return deviceId;
      } else {
        const key = uuidv4();
        window.localStorage.setItem('deviceId_dashboard', key);
        return key;
      }
    },
    today () {
      return moment().format('YYYY-MM-DD');
    },
    selectedDates () {
      return this.getDateFilterSelect;
    },
    iconPage () {
      return (this.getVendor.vendor || {}).thumbnailURL;
    },
    url () {
      return this.getUrl;
    },
    notifications () {
      return this.getNotifications;
    },
    getDayOfWeek () {
      const d = this.getSelectedDate + 'T00:00:00';
      const dayOfWeek = moment(d).day();
      return dayOfWeek;
    },
    tableInfo () {
      return this.getSelectTableInfo;
    },
    tableId () {
      return this.getSelectTableId;
    },
    dateFilterSelect () {
      return this.getDateFilterSelect;
    },
    sectionFilterSelect () {
      return this.getSectionFilterSelect;
    },
    timesDashboard () {
      return this.getTimes;
    },
    hours () {
      return this.blocked;
    },
    map () {
      return this.getMap;
    },
    mapList () {
      return this.getList;
    },
    selectedDate () {
      return this.getSelectedDate;
    },
    selectedMonth () {
      return this.getSelectedMonth;
    },
    loadingReservationCalendar () {
      return this.getLoadingReservation;
    },
    reservations () {
      return this.getReservation;
    },
    sections () {
      return this.getSections;
    },
    tables () {
      return this.getTables;
    },
    vendorParams () {
      return this.getVendor.desing;
    },
    design () {
      return this.getVendor.desing;
    },
    vendorId () {
      return this.getVendorId;
    },
    idVendor () {
      return this.getId;
    },
    vendorOpParams () {
      return this.getVendor.params;
    },
    params () {
      return this.getVendor.params;
    },
    vendor () {
      return this.getVendor.vendor;
    },
    schedules () {
      return this.getSchedule;
    },
    isConfirmadas () {
      if (this.getVendor.params.disableConfirmation === undefined) {
        return false;
      } else {
        return this.getVendor.params.disableConfirmation;
      }
    },
    dateComplete () {
      const date = this.getSelectedDate + 'T00:00:00';
      return moment(date).format();
    },
    monthComplete () {
      const date = this.getSelectedMonth + 'T00:00:00';
      return moment(date).format();
    },
    dayCurrent () {
      const date = this.getSelectedDate + 'T00:00:00';
      return parseInt(moment(date).format('x'));
    },
    selectedBlocked () {
      return this.getBlocked(this.getSelectedDate);
    },
    selectedBlockedHours () {
      return this.getHoursBlocked(this.getSelectedDate);
    },
    getStateMobile () {
      return this.$store.state.isMobile;
    },
    getStateWidth () {
      return this.$store.state.width;
    },
    getStateHeight () {
      return this.$store.state.height;
    },
    getStateOrientation () {
      return this.$store.state.orientation;
    },
    infoReservation () {
      return (this.getInfoReservation || {});
    },
    getAddReservation () {
      return (this.getNewReservation || false);
    },
    getAddReservationWalkIn () {
      return (this.getNewWalkIn || false);
    },
    getViewReservation () {
      return (this.isViewReservation || false);
    },
    getType () {
      return this.isType;
    },
    getEditReservation () {
      return (this.isEditReservation || false);
    },
    sectionSelected () {
      return (this.getSectionSelected || 0);
    },
    tableSelected () {
      return (this.getTableSelected || 0);
    },
    tableSelectedId () {
      return (this.getTableSelectedId || 0);
    },
    timezone () {
      let timezone = 'America/Bogota';
      if ((this.vendor || {}).timezone) {
        timezone = this.vendor.timezone;
      }
      return timezone;
    }
  },
  methods: {
    ...mapActions({
      logout: authTypes.actions.logout,
      login: authTypes.actions.login,
      reset: authTypes.actions.reset,
      validateToken: authTypes.actions.validateToken
    }),
    showSchedules (scheType, date) {
      if (date > 0) {
        const f = new Date(parseInt(date));
        const dayOfWeek = moment(f).day();
        const info = _.find(this.schedules[scheType], { dayOfWeek: dayOfWeek });
        return info.purchaseAdult || 0;
      } else {
        const dayOfWeek = moment().day();
        const info = _.find(this.schedules[scheType], { dayOfWeek: dayOfWeek });
        return info.purchaseAdult || 0;
      }
    },
    monthConvert (month) {
      if (month === 1) {
        return 0;
      } else {
        return month - 1;
      }
    },
    encrypt (value) {
      return lcrypt.encode(value);
    },
    decryptMx (value) {
      return lcrypt.decode(value);
    },
    getTimestamp () {
      const m = moment();
      const data = m.hours(0).minutes(0).seconds(0).milliseconds(0).format('x') + m.zoneAbbr();
      return parseInt(data.replace(m.zoneAbbr(), ''));
    },
    listHoliday () {
      return holidays;
    },
    isDayHoliday (date) {
      const data = date + 'T00:00:00';
      const fecha = moment(data).format('YYYY-MM-DD');
      const info = _(holidays).filter(day => {
        if (day.holiday === fecha) {
          return day;
        }
      }).value();
      if (info.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    showStatus (data) {
      this.$store.commit(globalTypes.mutations.setOnline, data);
    },
    getBlocked (date) {
      const dateOk = moment(date).format('YYYY-MM-DD');
      const m = moment(date).format('MM');
      const y = moment(date).format('YYYY');
      const d = moment(date).format('DD');
      const dayOfWeek = moment(date).format('d');
      const datesFinal = [];
      let blockedStatus = false;
      if (this.blocked !== undefined) {
        const b = this.blocked[dateOk];
        const propOwn = (b !== undefined && b !== null) ? Object.keys(b) : undefined;
        const countBlocked = (propOwn !== undefined && propOwn !== null) ? propOwn.length : 0;
        const timesBlocked = Object.keys(this.timesDashboard[dayOfWeek]).length;
        if (countBlocked > 0 && timesBlocked > 0) {
          this.timesDashboard[dayOfWeek].forEach(function (element) {
            const hora = element.hora;
            const horas = hora.split(':');
            const dates = moment({ y: y, month: m, d: d, h: horas[0], m: horas[1], s: 0, ms: 0 }).format('x');
            datesFinal.push(dates);
          });
          blockedStatus = (countBlocked === datesFinal.length) ? 1 : 0;
        }
      }
      return blockedStatus;
    },
    getCount (data) {
      return data.length;
    },
    getInitials (name) {
      return _.upperCase(_((name || '').split(' '))
        .filter(text => text)
        .map(text => text[0])
        .take(2).value()
        .join(''))
        .trim();
    },
    getHoursBlocked (date) {
      const dateOk = moment(date).format('YYYY-MM-DD');
      const b = this.hours;
      const propOwn = (b !== undefined && b !== null) ? Object.keys(b) : undefined;
      const hay = (propOwn !== undefined && propOwn !== null) ? propOwn.length : 0;
      let blockeds = false;
      if (hay > 0) {
        if (this.hours[dateOk]) {
          blockeds = true;
        }
      }
      return blockeds;
    },
    getMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.$store.state.isMobile = true;
      } else {
        this.$store.state.isMobile = false;
      }
    },
    getOrientation () {
      this.$store.commit(globalTypes.mutations.setWidth, window.screen.availWidth);
      this.$store.commit(globalTypes.mutations.setHeight, window.screen.availHeight);
    },
    setAddWalkin () {
      this.$store.commit(reservationTypes.mutations.setType, 'Walk-In');
      this.$store.commit(reservationTypes.mutations.setAddWalkIn, true);
      this.$store.commit(reservationTypes.mutations.setSideLateralSearch, false);
    },
    setAddReservation () {
      this.$store.commit(reservationTypes.mutations.setType, 'Reserva');
      this.$store.commit(reservationTypes.mutations.setAddReservation, true);
      this.$store.commit(reservationTypes.mutations.setSideLateralSearch, false);
    },
    setAddPrecompra () {
      this.$store.commit(reservationTypes.mutations.setType, 'Precompra');
      this.$store.commit(reservationTypes.mutations.setAddReservation, true);
      this.$store.commit(reservationTypes.mutations.setSideLateralSearch, false);
    },
    setCloseWalkin () {
      this.$store.commit(reservationTypes.mutations.setType, '');
      this.$store.commit(reservationTypes.mutations.setAddWalkIn, false);
      this.$store.commit(reservationTypes.mutations.setSectionSelected, 0);
      this.$store.commit(reservationTypes.mutations.setTableSelected, 0);
      this.$store.commit(reservationTypes.mutations.setTableSelectedId, 0);
    },
    setCloseReservation () {
      this.$store.commit(reservationTypes.mutations.setType, '');
      this.$store.commit(reservationTypes.mutations.setAddReservation, false);
    },
    setViewReservation (data) {
      const infoReserva = JSON.parse(JSON.stringify(data));
      this.$store.commit(reservationTypes.mutations.setViewReservation, true);
      this.$store.commit(reservationTypes.mutations.setInfoReservation, infoReserva);
    },
    setCloseViewReservation (clear) {
      this.$store.commit(reservationTypes.mutations.setViewReservation, false);
    },
    setEditReservation (data) {
      const infoReserva = JSON.parse(JSON.stringify(data));
      this.$store.commit(reservationTypes.mutations.setViewReservation, false);
      const type = (data.balancePaid > 0) ? 'Precompra' : 'Reserva';
      const types = (data.referrer === 'Walk-In') ? 'Walk-In' : type;
      this.$store.commit(reservationTypes.mutations.setType, types);
      this.$store.commit(reservationTypes.mutations.setInfoReservation, infoReserva);
      this.$store.commit(reservationTypes.mutations.setEditReservation, true);
      this.$store.commit(reservationTypes.mutations.setFrontModal, true);
    },
    setCloseEditReservation () {
      this.$store.commit(reservationTypes.mutations.setEditReservation, false);
      this.$store.commit(reservationTypes.mutations.setFrontModal, false);
    },
    setClosePassword () {
      this.$store.commit(authTypes.mutations.setShowChangedPassword, false);
      this.$store.commit(reservationTypes.mutations.setViewReservation, false);
    },
    dateNowInitial () {
      const hoy = new Date();
      const dia = hoy.getDate();
      const mes = hoy.getMonth() + 1;
      const anio = hoy.getFullYear();
      const now = String(anio + '/' + mes + '/' + dia);
      return now;
    },
    chargueScript (url, async = false) {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', url);
      if (async) {
        recaptchaScript.setAttribute('async', 'async');
      }
      document.body.appendChild(recaptchaScript);
    },
    subDomain () {
      let subdomain = window.location.hostname.split('.')[0];
      if (subdomain === 'www') {
        subdomain = window.location.hostname.split('.')[1];
      }
      const vendorUrl = (
        subdomain === 'dash' ||
        subdomain === 'dashboard' ||
        subdomain === 'localhost'
      ) ? localStorage.getItem('vendorUrl') : subdomain;

      return vendorUrl;
    },
    proccessData (obj, type, name, sectionId) {
      this.totalReservationPhoneDay = null;
      const data = obj.map(function (response) {
        const aux = {};
        if (name === 'day') {
          aux.dia = response.dia;
          aux.dias = response.dias;
        }
        if (name === 'range') {
          aux.Rangos = response.Rangos;
        }
        if (type === 1) {
          aux.Web = response.webReservations;
          aux.Telefono = response.phoneReservations;
        }
        if (type === 2) {
          aux.Web = response.webPeople;
          aux.Telefono = response.phonePeople;
        }
        if (sectionId === 3 && type === 1) {
          aux.WalkIn = response.walkInReservations;
        }
        if (sectionId === 3 && type === 2) {
          aux.WalkIn = response.walkInPeople;
        }
        return aux;
      });
      return data;
    },
    findExperience () {
      const experience = [];
      _.map(this.allEvents, (item) => {
        item.map(info => {
          if (info.type === 'experience' && info.alwaysActive) {
            experience.push(info.displayName);
          }
        });
      });
      return experience;
    },
    iconReferrer (referrer) {
      let icon = referrer.trim();
      icon = icon.toLowerCase();
      switch (icon) {
        case 'whitelabel':
          return 'icon_reserva_web';
        case 'dashboard':
          return 'icon_phone';
        case 'facebook':
          return 'icon_facebook';
        case 'google':
          return 'icon_by_google';
        case 'whatsapp':
          return 'icon_reserva_whatsapp';
        case 'precompro':
          return 'icon_reserva_precompro';
        case 'instagram':
          return 'icon_by_instagram';
        case 'twitter':
          return 'icon_twitter';
        case 'mail':
          return 'icon_email_metric';
        case 'googleads':
          return 'icon_google_ads';
        case 'ads':
          return 'icon_ads';
        case 'adsinstagram':
          return 'icon_ads_instagram';
        case 'tripadvisor':
          return 'icon_tripadvisor';
        case 'reservations':
          return 'icon_reservation';
        case 'others':
          return 'icon_other_channels';
        case 'walkIn':
          return 'icon_walkin';
        case 'precompra':
          return 'icon_reserva_precompro';
        case 'yate':
          return 'icon_yate';
        case 'panamericana':
          return 'icon_p1';
        case 'flyerrestaurantes':
          return 'icon_fr';
        case 'cross-selling':
          return 'icon_cross_selling';
        case 'resy':
          return 'icon_resy';
        case 'activacionhoteles':
          return 'icon_activacion_hoteles';
        case 'palejandria':
          return 'icon_palejandria';
        case 'pplaza':
          return 'icon_pplaza';
        case 'pautafacebook':
          return 'icon_fb_ads';
        case 'mercadeo':
          return 'icon_mercadeo';
        case 'referido':
          return 'icon_referido';
        case 'establecimiento':
          return 'icon_establecimiento';
        case 'evento':
          return 'icon_evento';
        case 'reservacionpresencial':
          return 'icon_reservacion-presencial';
        case 'dm':
          return 'icon_dm';
        case 'stories':
          return 'icon_stories';
        case 'waitinglist':
          return 'icon_waiting_list';
        case 'tiktok':
          return 'icon_tik_tok';
        case 'finetable':
          return 'icon_fine_table';
        default:
          return 'icon_other_channels';
      }
    },
    getUrlImageReferrer (name) {
      const referrer = this.$store.state.referrers.find(referrer => referrer.name === name);
      return (referrer && referrer.image) ? referrer.image : null;
    },
    validatePermission (permission) {
      if (parseInt(this.user.isAdmin) === 1) {
        if (permission === 'ReadOnly' || permission === 'MixSFCN') {
          return false;
        }
        return true;
      } else {
        const exists = _.includes(this.getPermissions, permission);
        if (exists) {
          return true;
        } else {
          return false;
        }
      }
    },
    setTokenHeader () {
      api.interceptors.request.use(
        config => {
          config.headers.common.Authorization = 'Bearer ' + this.user.api_token;
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  }
};
