<template>
  <div id="index">
    <LoaderPrecompro />
    <Metrics v-if="!loader "></Metrics>
  </div>
</template>
<script>
/* import Metrics from '@/components/Metrics'; */
import Metrics from '@/components/MetricsV2';
import authTypes from '@/store/types/auth';
import globalTypes from '@/store/types/global';
import LoaderPrecompro from '@/components/_shared/LoaderPrecompro';
import metricsTypes from '@/store/types/metrics';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import verifyUser from '@/helpers/verifyUser';

export default {
  name: 'metrics',
  components: {
    Metrics,
    LoaderPrecompro
  },
  metaInfo: {
    title: 'Métricas'
  },
  data () {
    return {
      loader: true
    };
  },
  computed: {
    ...mapGetters({
      year: [metricsTypes.getters.year]
    })
  },
  mounted () {
    const vendorUrl = this.subDomain();
    if (vendorUrl !== undefined && vendorUrl != null) {
      this.$store.commit(authTypes.mutations.setUser);
      this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 1 });
      this.$store.dispatch({
        type: 'reservation:getModules',
        data: {
          vendorId: this.idVendor,
          userId: this.user.id
        }
      });
      this.$store.dispatch({
        type: 'global:vendorId',
        data: {
          url: vendorUrl
        }
      }).then(({ data }) => {
        if (data.vendorId) {
          localStorage.setItem('vendorUrl', vendorUrl);
          localStorage.setItem('_id', data.Id);
          localStorage.setItem('keyWs', data.Id);
          this.$store.dispatch({
            type: 'global:getPermissions',
            data: { userId: this.user.id }
          });
          this.$store.dispatch({ type: 'global:getReferrers' });
          this.$store.dispatch({
            type: 'global:vendor',
            data: {
              vendorId: this.idVendor
            }
          }).finally(() => {
            if (this.vendor.timezone != null) {
              moment.tz.setDefault(this.vendor.timezone);
            } else {
              moment.tz.setDefault('America/Bogota');
            }
            document.querySelector('title').text = this.vendor.displayName + ' - Métricas';
            document.querySelector("link[rel*='icon']").href = this.vendor.thumbnailURL;
          });
          this.$store.dispatch({
            type: 'metrics:getMetricReservations',
            data: {
              year: this.year,
              vendorId: this.$store.state.idVendor,
              type: 1
            }
          });
          this.$store
            .dispatch({
              type: 'reservation:tables',
              data: { vendorId: data.vendorId }
            });
          this.$store.dispatch({
            type: 'metrics:getDataReservation',
            data: {
              vendorId: this.$store.state.idVendor,
              type: 1,
              year: this.year
            }
          }).then(() => {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            this.loader = false;
          });
        }
      });
    }
  },
  watch: {
    user () {
      verifyUser(this.user);
    }
  }
};
</script>
