<template>
  <div id="schedules">
    <div>
      <Sidenav @change-full="toggle"></Sidenav>
      <div class="content-wrap content-wrap-schedule">
        <Toolbar @showPage="showPage($event)"></Toolbar>
        <div class="input-product" v-if="false">
          <span class="fa fa-search"></span>
          <input placeholder="Buscar por nombre"
            type="text"
            v-model="search"
            class="search-input"
          />
        </div>
        <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
        <nav
          aria-label="sub navigation"
          class="navbar navbarSchedule"
          role="navigation"
          v-if="(scheduleType !== 3 && scheduleType !== 4 && scheduleType !== 5 && scheduleType !== 15 && scheduleType !== 16)"
        >
          <div class="navbar-menu columns_schedule">
            <b-navbar-item
              :class="{ active: verificarActive(0) }"
              @click="activeSchedule(0)"
              class="itemSchedules column is-2 is-4-mobile"
            >
              <i class="icon_schedules_atention"></i>
              Horarios de atención
            </b-navbar-item>
            <b-navbar-item
              :class="{ active: verificarActive(1) }"
              @click="activeSchedule(1)"
              class="itemSchedules column is-2 is-4-mobile"
            >
              <i class="icon_schedules_reservation"></i>
              Horarios de reservas
            </b-navbar-item>
            <b-navbar-item
              :class="{ active: verificarActive(6) }"
              @click="activeSchedule(6)"
              class="itemSchedules column is-2 is-4-mobile"
            >
              <i class="icon_schedules_reservation"></i>
              Horarios específico gratis
            </b-navbar-item>
            <b-navbar-item
              :class="{ active: verificarActive(7) }"
              @click="activeSchedule(7)"
              class="itemSchedules column is-2 is-4-mobile"
            >
              <i class="icon_schedules_reservation"></i>
              Horarios específico atención
            </b-navbar-item>
            <b-navbar-item
              :class="{ active: verificarActive(8) }"
              @click="activeSchedule(8)"
              class="itemSchedules column is-2 is-4-mobile"
            >
              <i class="icon_schedules_reservation"></i>
              Horarios específico zona
            </b-navbar-item>
            <b-navbar-item
              :class="{ active: verificarActive(9) }"
              @click="activeSchedule(9)"
              class="itemSchedules column is-2 is-4-mobile"
            >
              <i class="icon_schedules_reservation"></i>
              Horarios de zonas
            </b-navbar-item>
          </div>
        </nav>
        <SchedulesVendor
          :scheduleType="scheduleType"
          @activeSchedule="activeSchedule"
          @updateType="updateType"
        ></SchedulesVendor>
      </div>
    </div>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import Sidenav from '@/components/_shared/Sidenav';
// import Toolbar from '@/components/_shared/Toolbar';
import Toolbar from '@/components/_shared/ToolbarV2';
import SchedulesVendor from './schedule/SchedulesVendor.vue';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import TempPassword from '@/components/_shared/TempPassword';
export default {
  components: { Sidenav, Toolbar, SchedulesVendor, ChangedPassword, TempPassword },
  data () {
    return {
      scheduleType: 0,
      bodyIndex: document.body,
      type: null
    };
  },
  mounted () {
    // eslint-disable-next-line
    classie.add(this.bodyIndex, "show-right-bar");
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: this.user.id
      }
    });
  },
  destroyed () {
    // eslint-disable-next-line
    classie.remove(this.bodyIndex, 'show-right-bar')
  },
  methods: {
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    showPage (value) {
      this.scheduleType = value.page;
    },
    activeSchedule (scheduleType) {
      if (scheduleType !== null) {
        this.scheduleType = scheduleType;
      }
    },
    updateType (type) {
      this.type = type;
    },
    verificarActive (valor) {
      if (this.scheduleType === valor) {
        return true;
      }
      if (this.scheduleType === 2 && this.type === 'public' && valor === 0) {
        return true;
      }
      if (this.scheduleType === 2 && this.type === 'free' && valor === 1) {
        return true;
      }
      if (this.scheduleType === 2 && this.type === 'dateFree' && valor === 6) {
        return true;
      }
      if (this.scheduleType === 2 && this.type === 'dateAtention' && valor === 7) {
        return true;
      }
      if (this.scheduleType === 2 && this.type === 'dateZone' && valor === 8) {
        return true;
      }
      if (this.scheduleType === 2 && this.type === 'zone' && valor === 9) {
        return true;
      }
    }
  }
};
</script>
<style lang="scss">
#schedules {
  .navbarSchedule{
    margin-top: 52px!important;
  }
  .itemNavSchedules {
    align-items: center;
    height: 100%;
    margin: unset;
    padding-top: 20px;
  }
  .columns_schedule {
    flex-wrap: wrap;
    height: 100% !important;
  }
  .columns_schedules {
    justify-content: center !important;
  }
  .itemSchedules {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #fff;
    justify-content: center;
    background-color: #444B57 !important;
    padding: 20px;
    &.active {
      background-color: #373d46 !important;
      font-weight: 600;
      font-size: 12px;
      &:hover {
        background-color: #373d46 !important;
      }
    }
    &.column {
      padding: 11px 30px;
    }
  }
  .content-wrap-schedule {
    /* overflow-y: auto; */
    height: 100vh !important;
  }
  .icon_schedules_atention {
    background-image: url(../../assets/icons/icon_schedules_atention.svg);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
  }
  .icon_schedules_reservation {
    background-image: url(../../assets/icons/icon_schedules_reservation.svg);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
  }
  .navbar {
    height: 52px !important;
    display: flex !important;
  }
  .columns_schedule {
    padding: 0;
    i {
      display: flex;
    }
  }
  .input-product {
    padding: 8px 8px 0px;
    width: 100%;
    display: none;
    & span {
      position: absolute;
      z-index: 2;
      display: block;
      width: 2.375rem;
      height: 2.375rem;
      line-height: 2rem;
      text-align: center;
      pointer-events: none;
      color: #444B57;
    }
    & input {
      width: 100%;
      height: 30px;
      border: 0;
      padding: 10px;
      border-radius: 6px;
      color: #A8ADB5;
      font-family: "Source Sans Pro";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 2.3rem;
    }
  }
  @media screen and (max-width: 1200px) {
    .navbar {
      height: 45px !important;
    }
    .navbarSchedule {
      flex-direction: row !important;
    }
  }
  @media screen and (max-width: 600px) {
    .navbar {
      height: 65px !important;
    }
    .input-product {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    .navbarSchedule {
      height: max-content !important;
    }
  }

  @media only screen and (max-width: 400px) {
    .columns_schedule,
    .itemNavSchedules {
      padding: 0;
    }
  }
  @media only screen and (max-width: 1023px) {
    .navbar {
      display: flex !important;
    }
    .columns_schedule {
      padding: 0;
      i {
        display: flex;
      }
    }
    .itemSchedules {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 565px) {
    .itemSchedules {
      font-size: 10px;
      padding: 0;
      text-align: center;
      &.active {
        font-size: 10px;
      }
    }
    .icon_schedules_atention,
    .icon_schedules_reservation {
      width: 20px;
      height: 20px;
    }
    .columns_schedule {
      flex-direction: row !important;
    }
  }
  @media only screen and (max-width: 375px) {
    .itemNavSchedules {
      height: 50px;
    }
  }
}
</style>
