<template>
  <div id="alertConfirmDelete">
    <div class="container-alert-confirm-delete">
      <div class="container-alert-confirm-delete__icon">
        <img src="@/assets/icons/menuQr/trash-alert.svg" v-if="type === 'delete'" alt="bg image" />
        <img src="@/assets/icons/menuQr/success-alert.svg" v-if="type === 'success'" alt="bg image" />
        <img src="@/assets/icons/menuQr/warning.svg" v-if="type === 'capacity' || type === 'optimize'" alt="bg image" />
        <img src="@/assets/icons/menuQr/file_size.svg" v-if="type === 'file_size'" alt="bg image" />
        <div class="container-alert-confirm-delete__icon__active-all" v-if="type === 'all-products'">
          <img src="@/assets/icons/menuQr/check.svg" alt="bg image" />
        </div>
      </div>
      <h2 class="container-alert-confirm-delete__title">{{title}}</h2>
      <div class="container-alert-confirm-delete__subtitle" v-if="type === 'all-products' || type === 'delete' || type === 'capacity' || type === 'optimize' || type === 'file_size' || subtitle !== ''" v-html="subtitle"></div>
      <div class="container-alert-confirm-delete--actions" v-if="type === 'delete' || type === 'all-products'">
        <button class="container-alert-confirm-delete--actions__yes" @click="modeDelete">Si</button>
        <button class="container-alert-confirm-delete--actions__no" @click="closeCustom">No</button>
      </div>
      <div class="container-alert-confirm-delete--actions" v-if="type === 'success' || type === 'capacity' || type === 'file_size'">
        <button class="container-alert-confirm-delete--actions__close" @click="onCancelAux">Cerrar</button>
      </div>
      <div class="container-alert-confirm-delete--actions" v-if="type === 'optimize'">
        <button class="container-alert-confirm-delete--actions__yes" @click="()=>{
          this.$emit('onConfirm');
          this.$parent.close();
        }">Si, subir</button>
        <button class="container-alert-confirm-delete--actions__no" @click="()=>{
          this.$emit('onCancel');
          this.$parent.close();
        }">No, cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['idMenu', 'type', 'title', 'subtitle', 'typeDelete'],
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {
    modeDelete () {
      if (this.typeDelete === 'menu') {
        this.deleteMenu();
      }
      if (this.typeDelete === 'menu_massive') {
        this.$emit('executeMassiveDelete');
        this.$parent.close();
      }
      if (this.typeDelete === 'multimenu') {
        this.deleteMultiMenu();
      }
      if (this.typeDelete === 'multimenu_images') {
        this.$emit('deleteImagenMultimenu');
        this.$parent.close();
      }
      if (this.typeDelete === 'category') {
        this.$emit('deleteCategory');
        this.$parent.close();
      }
      if (this.typeDelete === 'product') {
        this.$emit('deleteProducts');
        this.$parent.close();
      }
      if (this.type === 'all-products') {
        this.$emit('checkProducts');
        this.$parent.close();
      }
    },
    closeCustom () {
      if (this.type === 'all-products') {
        this.$emit('inactiveCheckbox');
      }
      this.$parent.close();
    },
    deleteMultiMenu () {
      this.$emit('changeStatusLoader', true);
      serviceQr.delete('/menudigital/multimenu/' + this.idMenu + '/' + this.user.id).then(({ data }) => {
        this.$emit('changeStatusLoader', true);
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('getMultiMenu');
        this.$parent.close();
      }).catch(() => {
        this.$emit('changeStatusLoader', false);
        this.$buefy.toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    deleteMenu () {
      this.$emit('changeStatusLoader', true);
      serviceQr.delete('/menudigital/single/' + this.idMenu + '/' + this.user.id).then(({ data }) => {
        this.$emit('changeStatusLoader', false);
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('getMenu');
        this.$parent.close();
      }).catch(() => {
        this.$emit('changeStatusLoader', false);
        this.$buefy.toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    onCancelAux () {
      if (this.type === 'capacity' || this.type === 'file_size') {
        this.$emit('onCancel');
      }
      this.$parent.close();
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/menuQr/_alerts.scss";
</style>
