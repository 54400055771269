var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-wrap",class:{isHiddenNav:!_vm.showSide},attrs:{"id":"sidenav-precompro"}},[_c('nav',{staticClass:"menu-elastic",style:({ '--backgroundColor': _vm.vendorParams.sidenavColor || '#444b57'})},[_c('div',{staticClass:"icon-list"},[_c('div',{staticClass:"client",class:{opened:_vm.isOpen}},[_c('div',{staticClass:"clientImg",on:{"click":function($event){return _vm.openChooseVendor()}}},[_c('img',{attrs:{"src":_vm.vendor.thumbnailURL}})]),_c('h2',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v(_vm._s(_vm.vendorName))])]),(_vm.vendor && _vm.user)?_c('UserEl',{attrs:{"isOpen":_vm.isOpen}}):_vm._e(),_c('div',{staticClass:"cont-menu"},[_c('button',{staticClass:"menu-button",attrs:{"id":"open-button"},on:{"click":_vm.toggleMenu}},[_c('div'),_c('div'),_c('div')])]),_c('div',{staticClass:"fatherNavURLContainer"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.overflowMainBar && !_vm.showDown),expression:"overflowMainBar && !showDown"}],staticClass:"topArrow"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.overflowMainBar && _vm.showDown),expression:"overflowMainBar && showDown"}],staticClass:"bottomArrow"}),_c('section',{ref:"scrollContainerSideNavbar",staticClass:"navURLContainer",class:{opened:_vm.isOpen},on:{"scroll":_vm.manageScroll}},[(_vm.validateAccessModule('reservations'))?_c('a',{class:{ active: _vm.$route.path.split('/')[2] === 'reservations' },on:{"click":function($event){return _vm.toUrl('/dashboard/reservations')}}},[_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'reservations',
              }},[_c('i',{staticClass:"icon-mks white icon_reservation",style:({width:_vm.isOpen ? '60%' : '88%',transform :_vm.isOpen ? 'translateY(2px)' : ''})})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Reservas")])]):_vm._e(),(_vm.validateAccessModule('users'))?_c('a',{class:{ active: _vm.$route.path.split('/')[2] === 'users' },on:{"click":function($event){return _vm.toUrl('/dashboard/users')}}},[_c('div',{class:{ icon_selected: _vm.$route.path.split('/')[2] === 'users' }},[_c('i',{staticClass:"icon-mks white icon_users"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Usuarios")])]):_vm._e(),(_vm.validateAccessModule('metrics'))?_c('a',{class:{ active: _vm.$route.path.split('/')[2] === 'metrics' },on:{"click":function($event){return _vm.toUrl('/dashboard/metrics')}}},[_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'metrics',
              }},[_c('i',{staticClass:"icon-mks white icon_metrics",staticStyle:{"max-width":"37px"}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Metricas")])]):_vm._e(),(_vm.validateAccessModule('polls'))?_c('a',{class:{ active: _vm.$route.path.split('/')[2] === 'polls' },on:{"click":function($event){return _vm.toUrl('/dashboard/polls')}}},[_c('div',{class:{ icon_selected: _vm.$route.path.split('/')[2] === 'polls' }},[_c('i',{staticClass:"icon-mks white icon_polls"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Encuestas")])]):_vm._e(),(_vm.validateAccessModule('searchs'))?_c('a',{class:{ active: _vm.$route.path.split('/')[2] === 'searchs' },on:{"click":function($event){return _vm.toUrl('/dashboard/searchs')}}},[_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'searchs',
              }},[_c('i',{staticClass:"icon-mks white icon_consult"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Consultas")])]):_vm._e(),(_vm.validateAccessModule('giftCards'))?_c('a',{class:{ active: _vm.$route.path.split('/')[2] === 'giftCards' },on:{"click":function($event){return _vm.toUrl('/dashboard/giftCards')}}},[_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'giftCards',
              }},[_c('i',{staticClass:"icon-mks white icon_gift"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("GiftCards")])]):_vm._e(),(_vm.validateAccessModule('schedules'))?_c('a',{staticClass:"icon_config",class:{ active: _vm.$route.path.split('/')[2] === 'schedules' },on:{"click":function($event){return _vm.toUrl('/dashboard/schedules')}}},[_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'schedules',
              }},[_c('i',{staticClass:"icon-mks white icon_config_schedule"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Configuración")])]):_vm._e(),(_vm.validateAccessModule('contactless'))?_c('a',{staticClass:"icon_config",class:{ active: _vm.$route.path.split('/')[2] === 'contactless' },on:{"click":function($event){return _vm.toUrl('/dashboard/contactless')}}},[_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'contactless',
              }},[_c('i',{staticClass:"icon-mks white icon_contactless_payment"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Pago sin Contacto")])]):_vm._e(),(_vm.validateAccessModule('marketing'))?_c('a',{staticClass:"icon_config",class:{ active: _vm.$route.path.split('/')[2] === 'marketing' },on:{"click":function($event){return _vm.toUrl('/dashboard/marketing')}}},[_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'marketing',
              }},[_c('i',{staticClass:"icon-mks white icon_config_marketing"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Marketing")])]):_vm._e(),(_vm.allowTermsConditionsIfExist)?_c('a',{class:{ active: _vm.activeTermsIcon },on:{"click":_vm.loadPDF}},[_c('div',{class:{
                icon_selected: _vm.activeTermsIcon,
              }},[_c('i',{staticClass:"icon-mks white icon_terms_conditions",style:({width:_vm.isOpen ? '60%' : '88%',transform :_vm.isOpen ? 'translateY(2px)' : ''})})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Términos")])]):_vm._e(),((_vm.vendorsForSwitch.length > 1 || _vm.user.isAdmin) && _vm.isDashboardDomain())?_c('a',{on:{"click":function($event){return _vm.changeVendorUrl()}}},[_vm._m(0),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Cambiar")])]):_vm._e(),(_vm.validateAccessModule('onboarding'))?_c('a',{staticClass:"icon-onboarding-sidebar",class:{ active: _vm.$route.path.split('/')[2] === 'onboarding' },on:{"click":function($event){return _vm.toUrl('/dashboard/onboarding')}}},[(_vm.newThingsOnboarding)?_c('div',{staticClass:"icon-onboarding-sidebar__activity"}):_vm._e(),_c('div',{class:{
                icon_selected: _vm.$route.path.split('/')[2] === 'onboarding',
              }},[_c('i',{staticClass:"icon-mks white icon_onboarding"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_vm._v("Onboarding")])]):_vm._e()])]),_c('a',{staticClass:"close-button",attrs:{"id":"close-button"},on:{"click":_vm.closeSideBar}}),_c('section',{staticClass:"lastSection",class:{opened:_vm.isOpen}},[_c('section',[_c('a',{staticClass:"btn-fullsize",on:{"click":_vm.toggleFull}},[_c('i',{staticClass:"icon_fullscream icon-mks white"})]),_c('a',{staticClass:"close-logout",on:{"click":function($event){return _vm.logOutModal()}}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" power_settings_new ")])]),(_vm.getVersionApp && _vm.isOpen)?_c('span',{staticClass:"version"},[_vm._v(_vm._s(_vm.getVersionApp))]):_vm._e()])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"icon_shop white icon-mks"})])
}]

export { render, staticRenderFns }