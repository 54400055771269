<template>
  <div id="container">
    <b-loading :active.sync="isLoading" :is-full-page="true" :can-cancel="false"></b-loading>
    <i class="icon-mks icon_alert icon_alert_marketing"></i>
    <span class="description">Por requerimiento de ley los correos promocionales deben tener la opción de cancelar la suscripción</span>
    <span class="quest">¿Deseas desactivar esta opción para este correo?</span>
    <div class="card-btn">
      <button class="card-btn-accept" @click="confirm()">Si</button>
      <button class="card-btn-close" @click="cancel()">No</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data () {
    return {
      isLoading: false
    };
  },
  methods: {
    confirm () {
      this.$emit('onConfirmSubscription', true);
      this.$parent.close();
    },
    cancel () {
      this.$emit('onCancelUnSubscription', true);
      this.$parent.close();
    }
  }
};
</script>
<style lang="scss">
  #container {
    width: 421px;
    max-height: 250px;
    flex-shrink: 0;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #444B57;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
    padding: 20px 30px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 500px) {
      width: 400px;
    }
    .icon_alert {
      -webkit-mask-size: contain;
      width: 50px;
      height: 50px;
      background: #FF0101;
      margin-bottom: 20px;
    }
    .description {
      color: #444B57;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .quest {
      color: #444B57;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .card-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      &-accept {
        width: 120px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid #C9C9C9;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: #444B57;
        color: #FFF;
        cursor: pointer;
      }
      &-close {
        margin-left: 20px;
        width: 120px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid #C9C9C9;
        background: #FFF;
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
</style>
