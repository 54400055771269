import authTypes from '@/store/types/auth';
import { v4 as uuidv4 } from 'uuid';
import LocalStorage from 'store';
import serviceAuth from '@/helpers/serviceAuth';
import serviceUser from '@/helpers/apis/serviceUser';

const state = {
  user: [],
  userId: null,
  changedPassword: false,
  changedVendor: false,
  showTempPassword: false
};

const actions = {

  [authTypes.actions.login]: ({ commit }, userInput) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('login', userInput)
        .then(({ data, status }) => {
          if (parseInt(status) === 200) {
            window.localStorage.setItem('_token', data.token);
            window.localStorage.setItem('_user', btoa(JSON.stringify(data.user)));
            window.localStorage.setItem('_vendors', btoa(JSON.stringify(data.user.vendors)));
            window.localStorage.setItem('_userId', parseInt(data.user.id));
            if (LocalStorage.get('keyWs') === undefined) {
              LocalStorage.set('keyWs', uuidv4());
            }
            commit(authTypes.mutations.setUser);
          }
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
        });
    });
  },
  [authTypes.actions.reset]: (state, userInput) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('reset', userInput)
        .then(user => {
          resolve(user);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
        });
    });
  },
  [authTypes.actions.logout]: ({ commit }, user) => {
    user.deviceId = window.localStorage.getItem('deviceId_dashboard');
    user.token = window.localStorage.getItem('_token');
    return new Promise((resolve, reject) => {
      serviceAuth.post('logout', user)
        .then((response) => {
          window.localStorage.removeItem('_token');
          window.localStorage.removeItem('_user');
          window.localStorage.removeItem('_userId');
          window.localStorage.removeItem('listLength');
          window.localStorage.removeItem('version');
          window.localStorage.removeItem('userModules');
          window.localStorage.removeItem('vendorUrl');
          window.localStorage.removeItem('_vendorId');
          window.localStorage.removeItem('_vendors');
          window.localStorage.removeItem('_id');
          commit(authTypes.mutations.setUser);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
        });
    });
  },
  [authTypes.actions.checkEmail]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('checkEmail', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.updateUser]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceUser.post('update-user', data)
        .then(response => {
          if (parseInt(response.data.code) === 200) {
            window.localStorage.setItem('_user', btoa(JSON.stringify(response.data.user)));
            commit(authTypes.mutations.setUser);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.changedPassword]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('updatePassword', data)
        .then(response => {
          if (parseInt(response.data.code) === 200) {
            window.localStorage.removeItem('_token');
            window.localStorage.removeItem('_user');
            window.localStorage.removeItem('_userId');
            commit(authTypes.mutations.setUser);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.refreshUser]: ({ commit }, data) => {
    data.data.token = window.localStorage.getItem('_token');
    data.data.deviceId = window.localStorage.getItem('deviceId_dashboard');
    return new Promise((resolve, reject) => {
      serviceAuth.post('refreshUser', data.data)
        .then(response => {
          if (response.data.isActive) {
            window.localStorage.setItem('_user', btoa(JSON.stringify(response.data.user)));
            window.localStorage.setItem('_userId', parseInt(response.data.user.id));
            window.localStorage.setItem('_vendors', btoa(JSON.stringify(response.data.user.vendors)));
            window.localStorage.setItem('_token', response.data.token);
            commit(authTypes.mutations.setUser);
          } else {
            window.localStorage.removeItem('_token');
            window.localStorage.removeItem('_user');
            window.localStorage.removeItem('_userId');
            window.localStorage.removeItem('_vendors');
            commit(authTypes.mutations.setUser);
          }
          resolve(response.data);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            window.localStorage.removeItem('_token');
            window.localStorage.removeItem('_user');
            window.localStorage.removeItem('_userId');
            window.localStorage.removeItem('_vendors');
          }
          commit(authTypes.mutations.setUser);
          reject(error);
        });
    });
  },
  [authTypes.actions.validateToken]: (state, token) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('validateToken', { token })
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [authTypes.actions.changedTempPassword]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceAuth.post('updateTempPassword', data)
        .then(response => {
          if (parseInt(response.status) === 200) {
            if (data.logout) {
              window.localStorage.removeItem('_token');
              window.localStorage.removeItem('_user');
              window.localStorage.removeItem('_userId');
              commit(authTypes.mutations.setUser);
            } else {
              window.localStorage.setItem('_user', btoa(JSON.stringify(response.data.user)));
              window.localStorage.setItem('_userId', parseInt(response.data.userId));
              commit(authTypes.mutations.setUser);
              commit(authTypes.mutations.setShowTempPassword, false);
            }
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {
  [authTypes.getters.changedPassword]: (state) => {
    return state.changedPassword;
  },
  [authTypes.getters.user]: (state) => {
    return state.user;
  },
  [authTypes.getters.userId]: (state) => {
    return state.userId;
  },
  [authTypes.getters.changedVendor]: (state) => {
    return state.changedVendor;
  },
  [authTypes.getters.showTempPassword]: (state) => {
    return state.showTempPassword;
  }
};

const mutations = {
  [authTypes.mutations.setShowChangedPassword]: (state, data) => {
    state.changedPassword = data;
  },
  [authTypes.mutations.setShowChangedVendor]: (state, data) => {
    state.changedVendor = data;
  },
  [authTypes.mutations.setUser]: (state) => {
    if (window.localStorage.getItem('_user') && window.localStorage.getItem('_userId') && window.localStorage.getItem('_token')) {
      const json = JSON.parse(atob(window.localStorage.getItem('_user')));
      state.userId = parseInt(window.localStorage.getItem('_userId'));
      state.user = json;
    } else {
      state.user = [];
      state.userId = null;
    }
  },
  [authTypes.mutations.setShowTempPassword]: (state, data) => {
    state.showTempPassword = data;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
