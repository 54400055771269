<template>
  <div id="modalSelectMenuForCategories">
    <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <i class="icon-mks icon_close_alternative" @click="$parent.close()"></i>
    <div class="menu-list-for-category">
      <h2>Selecciona el menú/boton</h2>
      <section class="menu-list-for-category__list">
        <div
          v-for="(menu, index) in menuList"
          :key="index" :style="`background: url(${changeImagesByLanguage(menu)}) lightgray 50% / cover no-repeat;`"
          :class="{'active-menu-for-category' : idBtn === menu.id}"
          @click="selectMenu(menu.vendorCustomId, menu.id, changeImagesByLanguage(menu))"
        >
          <h3>{{changeTitleByLanguage(menu)}}</h3>
        </div>
      </section>
      <div class="menu-list-for-category__actions">
        <button :disabled="!idMenu" @click="saveChanges">Aceptar</button>
      </div>
    </div>
  </div>
</template>

<script>
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['restaurant', 'typeLang', 'idBtnMenuSelected'],
  data () {
    return {
      isLoading: false,
      menuList: [],
      idMenu: null,
      idBtn: null,
      bgMenu: null
    };
  },
  computed: {
    changeTitleByLanguage () {
      return (menu) => {
        switch (this.typeLang) {
          case 'spanish':
            return menu.title;
          case 'english':
            return menu.titleEnglish;
          case 'french':
            return menu.titleFrench;
          default:
            return null;
        }
      };
    },
    changeImagesByLanguage () {
      return (menu) => {
        switch (this.typeLang) {
          case 'spanish':
            return menu.image;
          case 'english':
            return menu.imageEnglish;
          case 'french':
            return menu.imageFrench;
          default:
            return null;
        }
      };
    }
  },
  mounted () {
    this.getListMenu();
  },
  methods: {
    getListMenu () {
      this.isLoading = true;
      serviceQr.get(`/menudigital/multimenu/${this.restaurant.vendorId}?type=interactive`).then(({ data }) => {
        this.menuList = data.data;
        this.idBtn = this.idBtnMenuSelected;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Ha ocurrido un error en el servidor',
          type: 'is-danger'
        });
      });
    },
    selectMenu (id, idBtn, image) {
      this.idMenu = id;
      this.idBtn = idBtn;
      this.bgMenu = image;
    },
    saveChanges () {
      this.$emit('saveIdMenuForCategory', this.idMenu, this.idBtn, this.bgMenu);
      this.$parent.close();
    }
  },
  watch: {
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/menuQr/categories/_modal_select_menu_for_categories.scss";
</style>
