<template>
  <section class="modalWrapper">
    <div class="reservations-content-box" id="add_walking">
      <div class="columns title-bar">
        <div class="column">
        </div>
        <div class="column center-box">
          <span class="headerTitle" v-if="getEditReservation">Editar {{ type }}</span>
          <span class="headerTitle" v-if="!getEditReservation && !toList">Agregar {{ type }}</span>
          <span class="headerTitle" v-if="toList && !itemList.date">Agregar a lista de espera</span>
          <span class="headerTitle" v-if="toList && itemList.date">Editar lista de espera</span>
          <span class="icons-box iconBoxHeader">
            <div class="box1">
              <i class="icon-mks black icon_person calendarIcon"></i>
              <span>{{ reservation.people }}</span>
            </div>
            <div class="box2">
              <i class="icon-mks black icon_calendar_voild calendarIcon"></i>
              <span v-if="reservation.date !== null">
                <div class="is-year">{{ reservation.date | moment("MMM") }}</div>
                <div class="the_day">{{ reservation.date | moment("DD") }}</div>
              </span>
              <span v-if="reservation.date === null">-</span>
            </div>
            <div class="box3">
              <i class="icon-mks black icon_time calendarIcon"></i>
              <span v-if="reservation.hour !== null">
                {{
                hourFormat(reservation.hour)
                }}
              </span>
              <span v-if="reservation.hour === null">-</span>
            </div>
          </span>
        </div>
        <div class="column close-later">
          <button
            @click="
              () => {
                isOpenset();
              }
            "
            class="close-btn"
          >
            <p class="closeButton">+</p>
          </button>
        </div>
      </div>
      <div :class="{toList:toList , walk:toList}" class="Contenedor walkIN">
        <div class="columns content-modal-mobile">
          <div :class="{toList:toList}" class="divisor"></div>
          <div class="column left-later main_content">
            <div class="barra-left-top">
              <div class="barra-left">
                <span class="title-box">Datos del Walk-In</span>
                <span class="icons-box iconBoxBody">
                  <div class="box1">
                    <i class="icon-mks black icon_person calendarIcon"></i>
                    <span>{{ reservation.people }}</span>
                  </div>
                  <div class="box2">
                    <i class="icon-mks black icon_calendar_voild calendarIcon"></i>
                    <span v-if="reservation.date !== null">
                      <div class="is-year">{{ reservation.date | moment("MMM") }}</div>
                      <div class="the_day">{{ reservation.date | moment("DD") }}</div>
                    </span>
                    <span v-if="reservation.date === null">-</span>
                  </div>
                  <div class="box3">
                    <i class="icon-mks black icon_time calendarIcon"></i>
                    <span v-if="reservation.hour !== null">
                      {{
                      hourFormat(reservation.hour)
                      }}
                    </span>
                    <span v-if="reservation.hour === null">-</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="peopleContainer">
              <People :people="reservation.people" :adults="reservation.adult" :boys="reservation.boy" @setAdultsBoys="setAdultsBoys" @selected="(e) => (reservation.people = e)"></People>
            </div>
            <div class="clockContainer" v-if="toList">
              <Select
                :field="'waitingTime'"
                :iconLeft="'clock'"
                :initValue="initTimeQueue"
                :options="itemsForClock"
                :placeholder="'Tiempo de espera'"
                :typeData="'clock'"
                @valueChange="getWaitingTime"
              ></Select>
            </div>
            <div class="zoneContainer" v-if="!toList">
              <Zone
                :people="reservation.people"
                :timeHour="reservation.hour"
                :zoneSelected="initZone"
                @seatSelected="(e) => (reservation.selectZone = e)"
              ></Zone>
            </div>

            <div class="subZoneContainer" v-if="!toList">
              <Subzone
                :hour="reservation.hour"
                :people="reservation.people"
                :subzoneIdValue="initSubZone"
                :zoneId="reservation.selectZone"
                @subZoneSelected="
                  (subzone) => (reservation.selectSubzone = subzone)
                "
                v-if="tableSelId == 0"
              ></Subzone>
            </div>

            <div class="TablesContainer" v-if="!toList">
              <Table
                :hour="reservation.hour"
                :people="reservation.people"
                :selectedTablesClient="reservation.selectTable"
                :subZoneId="reservation.selectSubzone"
                :tablesValue="initTable"
                :zoneId="reservation.selectZone"
                @tableSelected="
                  (selectedTable) => (reservation.selectTable = selectedTable)
                "
              ></Table>
            </div>
            <div class="leyendContainer" v-if="!toList">
              <Leyend></Leyend>
            </div>
          </div>
          <div class="column rigth-later">
            <span class="title-box">Datos del usuario</span>
            <User
              :email="reservation.email"
              :indicativo="reservation.indicativo"
              :infoObject="formInformation"
              :initInfo="infoUserInit"
              :toList="toList"
              :walk="true"
              @formInfo="(e) => (formInformation = e)"
              @getRequeriments="(e)=>{initNotes=e.notes;initTags=e.tags;}"
            ></User>
            <div class="requirementContainer">
              <Requirement
                :ammenitisValue="initamenities"
                :notesValue="initNotes"
                :queueType="toList"
                :tagsValue="initTags"
                :textBoxComment="initComment"
                @change="getRequirementValue"
              ></Requirement>
            </div>
              <div class="consumeValue" v-if="params.saveValueConsumed && edit">
              <div>
                <p>$</p>
              </div>
              <input type="text" :placeholder="'ingresar valor de consumo'" @input.prevent="cambioAlValuedConsume" :value="reservation.valueConsumed">
            </div>
            <div class="notificationContainer" v-if="toList">
              <Notification
                :phoneExist="reservation.phone ? true : false"
                :reservationInfo="reservation"
                @change="(e)=>reservation={...reservation,...e}"
                :noWssp="true"
              ></Notification>
            </div>
          </div>
        </div>
        <!-- <validateReservation v-if="isTableSelectedAlready"  :typeMethod="edit ? 'edit': ''" :reservation="reservation"></validateReservation > -->
        <div class="button-box-action" v-if="!itemList">
          <button
            class="btn-walkin disabled"
            v-if="reservation.people == ''"
          >Selecciona cantidad de personas</button>
          <button
            class="btn-walkin disabled"
            v-if="reservation.people != '' && reservation.date == null"
          >Seleccione una fecha</button>
          <button
            class="btn-walkin disabled"
            v-if="
              reservation.people != '' &&
              reservation.date != null &&
              reservation.hour != null &&
              reservation.selectZone.length == 0
            "
          >Seleccione una Zona</button>
          <button
            class="btn-walkin disabled"
            v-if="
              reservation.people != '' &&
              reservation.date != null &&
              reservation.hour != null &&
              reservation.selectZone.length > 0 &&
              reservation.selectTable.length == 0
            "
          >Seleccione una Mesa</button>
          <button
            class="btn-walkin disabled"
            v-if="
              reservation.people != '' &&
              reservation.date != null &&
              reservation.hour != null &&
              !reservation.loading &&
              reservation.errorMail != null &&
              reservation.selectZone.length > 0 &&
              reservation.selectTable.length > 0
            "
          >{{ reservation.errorMail }}</button>
          <button
            :disabled="buttonDisabled "
            class="btn-walkin disabled"
            v-if="
              reservation.people != '' &&
              reservation.date != null &&
              reservation.hour != null &&
              !reservation.loading &&
              reservation.errorMail == null &&
              reservation.selectZone.length > 0 &&
              reservation.selectTable.length > 0 &&
              reservation.errorTel != null
            "
          >{{ reservation.errorTel }}</button>
          <button
            @click="validateBeforeSubmit"
            class="btn-walkin"
            v-if="
              !getEditReservation &&
              reservation.people != '' &&
              reservation.date != null &&
              reservation.hour != null &&
              !reservation.loading &&
              reservation.errorMail == null &&
              reservation.selectZone.length > 0 &&
              reservation.selectTable.length > 0 &&
              reservation.errorTel == null
            "
          >
            Agregar {{ type }}
            <span class="buttonIcon">+</span>
          </button>
          <button
            :disabled="buttonDisabled"
            @click="validateBeforeSubmit"
            class="btn-walkin"
            v-if="
              getEditReservation &&
              reservation.people != '' &&
              reservation.date != null &&
              reservation.hour != null &&
              !reservation.loading &&
              reservation.errorMail == null &&
              reservation.selectZone.length > 0 &&
              reservation.selectTable.length > 0 &&
              reservation.errorTel == null
            "
          >
            Editar {{ type }}
            <span class="buttonIcon">+</span>
          </button>
          <span class="btn-walkin" href="javascript:void(0)" v-if="reservation.loading">
            <i class="fa fa-spinner fa-spin"></i>
            Agregando {{ type }}...
          </span>
        </div>
        <div class="button-box-action noPadding" v-else>
          <button
            class="btn-walkin disabled"
            v-if="!reservation.people "
          >Selecciona cantidad de personas</button>
          <button
            :disabled="saving ? true : false"
            @click="saveItemList(false)"
            class="btn-walkin"
            v-if="isListEdit"
          >Editar Walk-In de lista de espera</button>
          <button
            :disabled="saving ? true : false"
            @click="saveItemList(true)"
            class="btn-walkin"
            v-if="isListEdit"
          >
            <i class="icon_dashboard_metro icon-mks white"></i>Sentar Walk-In
          </button>
          <button
            :disabled="saving ? true : false"
            @click="saveItemList(false)"
            class="btn-walkin"
            v-if="!isListEdit"
          >Agregar Walk-In a lista de espera</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Leyend from './FormReservation/Leyend.vue';
import People from '@/components/_shared/FormReservation/People';
import Zone from '@/components/_shared/FormReservation/Zone';
import Subzone from '@/components/_shared/FormReservation/Subzone';
import User from '@/components/_shared/FormReservation/User';
import Table from '@/components/_shared/FormReservation/Table';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import _ from 'lodash';
import Requirement from '@/components/_shared/FormReservation/Requirement';
import Select from '../Extends/Select.vue';
import Notification from './FormReservation/Notification.vue';

export default {
  props: ['type', 'sectionSel', 'tableSel', 'tableSelId', 'edit', 'toList', 'itemList'],
  components: {
    People,
    Zone,
    Subzone,
    Table,
    User,
    Leyend,
    Requirement,
    Select,
    Notification
  },
  data () {
    return {
      initTimeQueue: '',
      saving: false,
      waitTimes: [
        {
          label: '1 minuto',
          value: 60000
        }
      ],
      itemsForClock: [
      ],
      formInformation: {},
      window: {
        width: 0,
        height: 0
      },
      notificationEmail: true,
      notificationSms: false,
      reservation: {
        valueConsumed: null,
        people: 2,
        adult: 2,
        boy: 0,
        date: null,
        hour: null,
        selectZone: [],
        selectTable: [],
        selectSubzone: [],
        celebrationId: null,
        displayName: '',
        email: '',
        phone: '',
        indicativo: '57',
        pais: 'Colombia ( +57 )',
        paises: [],
        bandera: 'https://api.precompro.com/banderas/co.png',
        loading: false,
        errorMail: null,
        errorTel: null,
        hostId: null,
        referrer: 'Walk-In',
        subscribed: null,
        birthday: null,
        typeDocument: 'CC',
        notificationSms: false,
        notificationEmail: true,
        typeNotification: 'email'
      },
      initZone: [],
      initSubZone: [],
      initTable: [],
      initamenities: [],
      initTags: [],
      initNotes: '',
      initComment: null,
      InitidentityDocument: null,
      buttonDisabled: false

    };
  },
  created () {
    if (this.fromTimeLine) {
      const bodyEl = document.body;
      // eslint-disable-next-line no-undef
      classie.remove(bodyEl, 'cronology');
    }
  },
  beforeMount () {
    this.$store.commit(reservationTypes.mutations.setFrontModal, true);
    const bodyEl = document.body;
    // eslint-disable-next-line
    classie.remove(bodyEl, "show-right-bar");
    window.addEventListener('resize', this.handleResize);
    this.reservation.date = this.$moment().format('YYYY-MM-DD');
    if (this.sectionSelected !== 0) {
      this.reservation.selectZone.push(this.sectionSelected);
    }
    if (this.tableSelectedId !== 0) {
      this.initTable = [this.tableSelectedId];
    }
    const dateM = this.$moment().format('mm');
    let date = this.$moment()
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .format('x');
    if (dateM >= 16 && dateM <= 45) {
      date = this.$moment()
        .minutes(30)
        .seconds(0)
        .milliseconds(0)
        .format('x');
    } else if (dateM >= 46 && dateM <= 59) {
      date = this.$moment()
        .add(1, 'hours')
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x');
    }
    this.reservation.hour = parseInt(date);
    if (this.getEditReservation && this.infoReservation && this.edit) {
      const infoReser = JSON.parse(JSON.stringify(this.infoReservation));
      if (this.infoReservation) {
        this.reservation.people = infoReser.people;
        this.reservation.adult = infoReser.adult === 0 && infoReser.boy === 0 && infoReser.people > 0 ? infoReser.people : infoReser.adult;
        this.reservation.boy = infoReser.boy;
        const opciones = { maximumFractionDigits: 0, currency: 'COP' };
        const formatoNumero = new Intl.NumberFormat('es-CO', opciones);
        this.reservation.valueConsumed = formatoNumero.format(infoReser.valueConsumed);
        this.reservation.displayName = infoReser.displayName;
        this.reservation.phone = infoReser.phone;
        this.reservation.email = infoReser.email;
        this.reservation.peopleId = infoReser.peopleId;
        this.reservation.hour = parseInt(infoReser.date);
        this.reservation.date = infoReser.fecha;
        this.reservation.typeNotification = infoReser.typeNotification;
        let helper = [];
        if (infoReser.selectZone) {
          infoReser.selectZone.forEach((id) => {
            if (typeof id === 'string') {
              helper.push(parseInt(id.trim()));
            } else {
              helper.zoneId.push(id);
            }
          });
        } else {
          helper = infoReser.sectionId.split(',');
          helper = helper.map(id => {
            return parseInt(id.trim());
          });
        }
        this.initZone = [...helper];
        this.initSubZone = infoReser.selectSubzone;
        this.initTable = infoReser.tableId.split(',');
        this.initTable = this.initTable.map((value) => parseInt(value.trim()));
        this.initamenities = infoReser.services;
        this.initComment = infoReser.comments;
        this.InitidentityDocument = infoReser.typeIdentityDocument;
        this.reservation.reservationId = infoReser.id;
        if (infoReser.peopleId != null) {
          this.$store.dispatch({ type: 'people:getOneUser', data: { id: infoReser.peopleId } }).then(({ data }) => {
            if (data.tags !== undefined) {
              this.reservation.tags = data.tags;
              this.initTags = data.tags;
            }
            if (data.notes !== undefined) {
              this.reservation.notes = data.notes;
              this.initNotes = data.notes;
            }
            this.reservation.isVip = data.isVip;
            if (data.isRecommended) {
              this.reservation.isRecommend = data.isRecommended;
            }
            if (data.isHabitual) {
              this.reservation.isHabitual = data.isHabitual;
            }
          });
        }
      }
    }
    if (this.toList) {
      for (let i = 5; i <= 120; i = i + 5) {
        const data = i * 60000;
        const dataLabel = i + ' minutos';
        this.waitTimes.push({
          value: data,
          label:
            dataLabel
        });
        this.itemsForClock.push(
          dataLabel
        );
      }
    } if (this.itemList && this.toList) {
      if (this.itemList.id) {
        this.reservation.date = this.itemList.date;
        this.reservation.email = this.itemList.email ? this.itemList.email : '';
        this.reservation.displayName = this.itemList.name ? this.itemList.name : '';
        this.reservation.name = this.itemList.name ? this.itemList.name : '';
        this.reservation.phone = this.itemList.phone ? this.itemList.phone : '';
        this.reservation.people = this.itemList.people ? this.itemList.people : 2;
        this.reservation.adult = (this.itemList.adult && this.itemList.adult === 0) &&
          (this.itemList.boy && this.itemList.boy === 0) &&
          (this.itemList.people && this.itemList.people > 0) ? this.itemList.people
          : (this.itemList.adult ? this.itemList.adult : 2);
        this.reservation.boy = this.itemList.boy ? this.itemList.boy : 0;
        this.initTimeQueue = `${Number(this.itemList.waitingTime) / 60000} minutos`;
        this.reservation.id = this.itemList.id;
        this.initComment = this.itemList.comment;
        this.reservation.notes = this.itemList.notes ? this.itemList.notes : '';
        this.initNotes = this.itemList.notes ? this.itemList.notes : '';
        this.reservation.tags = this.itemList.tags ? this.itemList.tags : [];
        this.initTags = this.itemList.tags ? this.itemList.tags : [];
        this.reservation.peopleId = this.itemList.peopleId;
        this.reservation.typeNotification = this.itemList.typeNotification;
      }
    }
    if (this.fromMap) {
      if (this.sectionSelected !== 0) {
        this.initZone.push(this.sectionSelected);
      }
    }
  },
  computed: {
    ...mapGetters({
      getTables: [reservationTypes.getters.tableSelectedId],
      fromTimeLine: [reservationTypes.getters.fromTimeLine]
    }),
    infoUserInit () {
      if (this.getEditReservation) {
        return this.infoReservation;
      } else if (this.toList) {
        return { ...this.itemList, displayName: this.itemList.name };
      } else {
        return {};
      }
    },
    isListEdit () {
      if (this.itemList) {
        if (this.itemList.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isTableSelectedAlready () {
      if (this.initTable) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      companionsData: reservationTypes.getters.companionsData
    }),
    isOpen: {
      get () {
        return this.value;
      },
      set () {
        this.setCloseViewReservation();
        this.setCloseWalkin();
      }
    }
  },
  methods: {
    cambioAlValuedConsume (e) {
      let number = e.target.value;
      if (/[^0-9.]/g.test(number)) {
        number = number.slice(0, -1);
      }
      const numberWithoutFormat = number.replace(/[.,]/g, '');
      const opciones = { maximumFractionDigits: 0, currency: 'COP' };
      const formatoNumero = new Intl.NumberFormat('es-CO', opciones);
      this.reservation.valueConsumed = formatoNumero.format(numberWithoutFormat);
      e.target.value = this.reservation.valueConsumed;
    },
    getWaitingTime (data) {
      const minute = 60000;
      if (data.waitingTime.length === 9) {
        this.reservation.waitingTime = minute * 5;
      } else if (data.waitingTime.length === 10) {
        this.reservation.waitingTime = Number.parseInt(data.waitingTime.slice(0, 2)) * minute;
      } else {
        this.reservation.waitingTime = Number.parseInt(data.waitingTime.slice(0, 3)) * minute;
      }
    },
    saveItemList (sitDown) {
      this.saving = true;
      this.reservation.referrer = 'Walk-In';
      this.reservation.vendorId = this.vendorId;
      this.reservation.name = this.reservation.displayName;
      this.reservation.userTags = this.reservation.tags;
      this.reservation.userNotes = this.reservation.notes;
      this.reservation.userNotes = this.reservation.userNotes ? this.reservation.userNotes : '';
      this.reservation.typeNotification = 'email';
      if (this.reservation.notificationSms) {
        this.reservation.typeNotification = 'sms';
      }
      if (this.reservation.notificationEmail) {
        this.reservation.typeNotification = 'email';
      }
      const dateM = this.$moment().format('mm');
      let date = this.$moment()
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x');
      if (dateM >= 16 && dateM <= 45) {
        date = this.$moment()
          .minutes(30)
          .seconds(0)
          .milliseconds(0)
          .format('x');
      } else if (dateM >= 46 && dateM <= 59) {
        date = this.$moment()
          .add(1, 'hours')
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .format('x');
      }
      this.reservation.date = parseInt(date);
      if (this.reservation.id == null) {
        this.$store
          .dispatch({
            type: 'waitingList:addWaitingListItem',
            data: this.reservation
          })
          .then(response => {
            if (response.code === 200) {
              this.$emit('close-modal-itemList', sitDown);
              this.saving = false;
              var bodyEl = document.body;
              // eslint-disable-next-line
              classie.remove(bodyEl, 'show-right-bar')
              // eslint-disable-next-line
              classie.add(bodyEl, 'show-right-bar')
            }
          });
      } else {
        this.$store
          .dispatch({
            type: 'waitingList:updateWaitingListItem',
            data: this.reservation
          })
          .then(response => {
            if (response.code === 200) {
              this.$emit('close-modal-itemList', sitDown);
              this.saving = false;
              var bodyEl = document.body;
              // eslint-disable-next-line
              classie.remove(bodyEl, 'show-right-bar')
              // eslint-disable-next-line
              classie.add(bodyEl, 'show-right-bar')
            }
          });
      }
    },
    handleResize () {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    getUserInfo (data) {
      this.reservation.displayName = data.displayName;
      this.reservation.email = data.email;
      this.reservation.phone = data.phone;
      this.reservation.indicativo = data.indicativo;
      this.reservation.identityDocument = data.identityDocument;
    },
    getRequirementValue (e) {
      this.reservation = { ...this.reservation, ...e };
      if (this.toList && e.comments) {
        this.reservation.comment = e.comments;
      }
    },
    dateFormat (date) {
      return this.$moment(date).format('ddd DD');
    },
    hourFormat (date) {
      return this.$moment(date).format('hh:mm a');
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (
            this.reservation.people !== '' &&
            this.reservation.date !== '' &&
            this.reservation.hour !== ''
          ) {
            this.errores = null;
            this.reservation.loading = true;
            this.sendReservation();
          }
        }
      });
    },
    isOpenset () {
      if (this.itemList) {
        this.$emit('close-modal-itemList', false);
        var bodyEl = document.body;
        // eslint-disable-next-line
        classie.remove(bodyEl, 'show-right-bar')
        // eslint-disable-next-line
        classie.add(bodyEl, 'show-right-bar')
      } else {
        this.isOpen = false;
        const bodyEl = document.body;
        // eslint-disable-next-line
        classie.remove(bodyEl, "show-right-bar");
        // eslint-disable-next-line
      classie.add(bodyEl, "show-right-bar");
        if (this.fromTimeLine) {
        // eslint-disable-next-line no-undef
          classie.remove(bodyEl, 'show-right-bar');
        }
        this.$emit('companionClose', false);
        this.isOpen = false;
      }
    },
    sendReservation () {
      this.buttonDisabled = true;
      let {
        displayName,
        hostId,
        people,
        adult,
        boy,
        selectZone,
        celebrationId,
        selectTable,
        email,
        phone,
        indicativo,
        pais,
        bandera,
        subscribed,
        temperature,
        identityDocument,
        comments,
        amenities,
        tags,
        notes,
        hour,
        typeDocument,
        peopleId,
        valueConsumed,
        habitation,
        isHotel
      } = this.reservation;
      const userId = this.user.id;
      const vendorId = this.vendorId;
      const dateM = this.$moment().format('mm');
      let date = this.$moment()
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('x');
      if (dateM >= 16 && dateM <= 45) {
        date = this.$moment()
          .minutes(30)
          .seconds(0)
          .milliseconds(0)
          .format('x');
      } else if (dateM >= 46 && dateM <= 59) {
        date = this.$moment()
          .add(1, 'hours')
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .format('x');
      }
      selectZone = this.purgeZone(selectZone, selectTable);
      const dataReservationWalkIn = {
        typeDocument: typeDocument || null,
        displayName: displayName ? displayName.trimStart() : '',
        createdAt: hostId,
        people,
        adult,
        boy,
        valueConsumed,
        seatingSectionId: selectZone,
        seatingTableId: selectTable,
        email: email || null,
        phone: phone || null,
        vendorId,
        userId,
        peopleId: peopleId || null,
        celebrationId,
        date,
        indicativo,
        pais,
        bandera,
        subscribed,
        isVip: this.reservation.isVip,
        isHabitual: this.reservation.isHabitual,
        isRecommended: this.reservation.isRecommended,
        isYate: this.reservation.isYate,
        isHotel,
        habitation,
        temperature,
        identityDocument: identityDocument || null,
        comments,
        amenities,
        tags,
        notes,
        userTags: tags,
        userNotes: notes || ' ',
        hour,
        _id: this.infoReservation._id,
        reservationId: this.infoReservation.id,
        referrer: this.reservation.referrer != null
          ? this.reservation.referrer
          : 'Walk-In',
        birthday:
          this.reservation.birthday !== undefined
            ? this.reservation.birthday
            : null
      };
      if (this.companionsData !== null) {
        dataReservationWalkIn.companions = this.companionsData;
      }
      if (this.edit) {
        this.$store
          .dispatch({
            type: 'reservation:updateReservationWalkIn',
            data: dataReservationWalkIn
          })
          .then(({ data }) => {
            if (data.code === 200) {
              this.$buefy.notification.open({
                message:
                  '<i class="icon_alert_success icon-mks green"></i>Se ha editado una reserva con éxito',
                type: 'is-success'
              });
              this.reservation.loading = false;
              this.buttonDisabled = false;
              this.isOpen = false;
              var bodyEl = document.body;
              this.$store.commit(
                reservationTypes.mutations.setCompanionsData,
                null
              );
              // eslint-disable-next-line
              classie.add(bodyEl, "show-right-bar");
              if (this.fromTimeLine) {
                const bodyEl = document.body;
                // eslint-disable-next-line no-undef
                classie.add(bodyEl, 'cronology');
                // eslint-disable-next-line
                classie.remove(bodyEl, 'show-right-bar')
              }
            } else {
              this.$buefy.notification.open({
                message:
                  '<i class="icon_alert_danger icon-mks red"></i> Ocurrió un error creando la reserva',
                type: 'is-danger'
              });
              this.reservation.loading = false;
              this.error = data.message;
            }
          })
          .catch((fallo) => {
            this.$buefy.notification.open({
              message:
                '<i class="icon_alert_danger icon-mks red"></i> Ocurrió un error creando la reserva',
              type: 'is-danger'
            });
            this.reservation.loading = false;
            this.error = fallo.request;
          });
      } else {
        this.$store
          .dispatch({
            type: 'reservation:addWalkIn',
            data: dataReservationWalkIn
          })
          .then(({ data }) => {
            if (data.code === 200) {
              this.$buefy.notification.open({
                message:
                  '<i class="icon_alert_success icon-mks green"></i>Se ha creado una reserva con éxito',
                type: 'is-success'
              });
              this.reservation.loading = false;
              this.buttonDisabled = false;
              this.isOpen = false;
              var bodyEl = document.body;
              this.$store.commit(
                reservationTypes.mutations.setCompanionsData,
                null
              );
              // eslint-disable-next-line
              classie.add(bodyEl, "show-right-bar");
            } else {
              this.$buefy.notification.open({
                message:
                  '<i class="icon_alert_danger icon-mks red"></i> Ocurrió un error creando la reserva',
                type: 'is-danger'
              });
              this.reservation.loading = false;
              this.error = data.message;
            }
          })
          .catch((fallo) => {
            this.$buefy.notification.open({
              message:
                '<i class="icon_alert_danger icon-mks red"></i> Ocurrió un error creando la reserva',
              type: 'is-danger'
            });
            this.reservation.loading = false;
            this.error = fallo.request;
          });
      }
    },
    purgeZone (selectZone, selectTable) {
      let purgedZones = [];
      if (selectZone.length > 1) {
        _.forEach(selectTable, function (value) {
          purgedZones = _.concat(purgedZones, value.idSection);
        });
        purgedZones = _.uniq(purgedZones);
      } else {
        purgedZones = selectZone;
      }
      return purgedZones;
    },
    // companion
    companionActions () {
      this.$emit('openCompanion', true);
      this.$emit('newCompanions', true);
    },
    setAdultsBoys (type, value) {
      if (type === 'people') {
        this.reservation.adult = value;
      } else {
        this.reservation.boy = value;
      }
    }
  },
  watch: {
    formInformation: {
      handler: function (value) {
        this.reservation = { ...this.reservation, ...value };
        if (!this.reservation.email) {
          this.reservation.errorMail = null;
          return;
        }
        // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const regex = /^[a-zA-Z0-9_%+-]+(\.[a-zA-Z0-9_%+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i;
        if (!regex.test(this.reservation.email)) this.reservation.errorMail = 'Correo inválido';
        else this.reservation.errorMail = null;
      },
      deep: true
    },
    'reservation.adult': {
      handler () {
        this.reservation.people = this.reservation.adult + this.reservation.boy;
      }
    },
    'reservation.boy': {
      handler () {
        this.reservation.people = this.reservation.adult + this.reservation.boy;
      }
    }
  },
  destroyed () {
    this.$store.commit(reservationTypes.mutations.setEditReservation, false);
    this.$store.commit(reservationTypes.mutations.setFromMap, false);
    this.$store.commit(reservationTypes.mutations.setFrontModal, false);
    window.removeEventListener('resize', this.handleResize);
    if (this.fromTimeLine) {
      const bodyEl = document.body;
      // eslint-disable-next-line no-undef
      classie.add(bodyEl, 'cronology');
      // eslint-disable-next-line
      classie.remove(bodyEl, 'show-right-bar')
    }
  }
};
</script>

<style lang="scss" scoped>
.closeButton {
  font-size: 35px;
  transform: rotate(45deg);
  max-height: 15px;
  line-height: 10px;
}
.clockContainer {
  width: 100%;
  margin-top: 15px;
  @media screen and (min-width: 1000px) {
    width: 60%;
    align-self: flex-start;
    position: relative;
    bottom: 15px;
  }
}
.requirementContainer {
  margin-top: 10px;
}
.title-bar {
  @media screen and (max-width: 600px) {
    border-bottom: 4px solid #8a51b4;
  }
}
@media (min-width: 1400px) {
  .content-modal-mobile {
    min-height: 80%;
  }
}

.calendarIcon {
  width: 10px;
}
.buttonIcon {
  font-weight: 400;
  font-size: 2rem;
  line-height: 0.5em;
  position: relative;
  bottom: 3px;
  padding-left: 0.8rem;
}
#Reservations .reservations-content-box .Contenedor .main_content {
  padding-left: 1.5% !important;
  padding-right: 1.5% !important;
  @media (max-width: 550px) {
    padding-top: 0.5rem !important;
  }
}
.iconBoxHeader {
  i {
    background-color: white;
  }
  @media (min-width: 550px) {
    display: none !important;
  }
}
.iconBoxBody {
  @media (max-width: 550px) {
    display: none !important;
  }
}
.headerTitle {
  @media (max-width: 550px) {
    display: none;
  }
}
.rigth-later .title-box {
  font-size: 14px;
  color: #444b57;
  font-weight: 500;
  font-family: "Roboto";
  padding-bottom: 1rem;
}
.modalWrapper #Reservations #add_walking .Contenedor {
  justify-content: flex-start;
  @media (min-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 76%;
    overflow-x: hidden;
  }
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    overflow-x: hidden;
  }
  @media (min-width: 1400px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92%;
    overflow-x: hidden;
  }
  @media (min-width: 1000px) {
    height: 78%;
    min-height: 600px !important;
  }

  &.toList {
    @media screen and (max-width: 1000px) {
      min-height: 668px;
      max-height: 668px !important;
      padding-bottom: 10px !important;
      overflow: hidden;
    }
    @media (min-width: 1000px) {
      min-height: 65%;
      min-height: unset !important;
      max-height: 540px !important;
      overflow: hidden;
    }
  }
}

.divisor {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
  height: 75%;
  width: 1px;
  background-color: lightgray;
  position: absolute;
  // &.toList{
  //     @media (min-width: 1000px) {
  //   height: 35%;
  //   top: 90px;
  // }
  //     @media (min-width: 1250px) {
  //   height: 25%;
  // }
  // }
  @media (max-width: 1000px) {
    display: none;
  }
}
.barra-left-top {
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: 550px) {
    display: none !important;
  }
}
.barra-left {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
}
.title-box {
  width: fit-content;
  max-width: 8rem;
}
.icons-box {
  width: fit-content;
  display: flex;
  align-items: center;
  box-shadow: 4px 0px 10px rgba(68, 75, 87, 0.1);
  .box1,
  .box2,
  .box3 {
    font-size: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 1rem;
    border: solid 1px #a8adb5;
    align-items: center;
    padding: 6px;
    min-width: 57px;
  }
  .box1 {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .box3 {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .box2 {
    display: flex;
    justify-content: center;

    span {
      gap: 0.2rem;
      display: flex;
      justify-content: center;
    }
  }
}
.buttonDisabled {
  pointer-events: none;
}
.selectPeople {
  background: black;
  color: white !important;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: unset;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}

.left-later {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: fit-content;
  height: 100%;
}
.peopleContainer {
  width: 100%;
}

.zoneContainer {
  width: 100%;
}

.subZoneContainer {
  width: 100%;
}
.TablesContainer {
  width: 100%;
}
.Contenedor {
}
.content-modal-mobile {
  @media (min-width: 1000px) {
    min-height: 80%;
    overflow: hidden;
  }
  padding-bottom: 20px;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.noPadding {
  position: relative;
  padding-bottom: 10px!important;
}
// @media (min-width: 1400px) {
//   .modalWrapper #add_walking .Contenedor{
//     min-height: 80%;
//   }
// }

#Reservations #add_reserva.reservations-content-box .Contenedor,
#Reservations #add_walking.reservations-content-box .Contenedor.toList {
  padding-bottom: 40px !important;
}

#add_walking.consumeValue{
  margin-top: 10px;
    border: 1px solid rgba(68, 75, 87, 0.5);
    border-radius: 10px;
    height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  @media screen and (min-width:600px) and (max-width:900px) {
    width: 33%;
  }
  @media screen and (min-width:1000px) {
    margin-bottom: 10px;
  }
  div{
    border-right: solid rgba(68, 75, 87, 0.5) 1px;
    border-radius: 1px;
    height: 80%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      font-size: 16px;
      color: #606773;
    }
  }
  input{
    flex: 1;
    border: unset;
    outline: unset;
    height: 100%;
    background-color: transparent;
    text-indent: 6px;
  }
}
</style>
