<template>
  <div id="Reservations">
    <notifications group="reservationNotch" position="right bottom" style="width: 340px!important; bottom: 85.5%!important; right: -1%!important"/>
    <notifications group="undoReservationNotch" position="right bottom" style="width: 420px!important; bottom: 80.5%!important; right: -1%!important"/>
    <Sidenav @change-full="toggleFull" @toggleSideBar="toggleSideBar"></Sidenav>
    <div class="content-wrap" style="display: flex; flex-direction: column;" v-if="!getEditMap">
      <Toolbar @toggleSideBar="toggleSideBar" @change-full="toggleFull" :filterActivate="filterActivate" @activateFilterF="activateFilterF"></Toolbar>
      <TimeEl class="for-mobile-times" v-if="showFilterButtons" :isMap="map" @activatefilter="activateFilterF" :filterActivateSelected="filterActivate"></TimeEl>
      <div class="columns reservations-container " id="Content-reservations" @click="CloseCalendar" v-show="!verticalReservation" :class="{mapList: mapList, 'show-map': map, backgroundTurnDetail: showTurnDetail}">
        <div id="desconectado" class="notification-offline notifications" v-if="getStatusOnline === false">
          <b-message>
            <div class="noti-tittle margin-bottom center">
              <i class="fa fa-warning"></i>&nbsp;Señor Usuario
            </div>
            <p
              class="fw-700"
            >En estos momentos no tienes acceso a internet, por favor verifica que tengas conexión.</p>
          </b-message>
        </div>
        <div
          class="notification-offline notifications"
          id="actualizar"
          v-if="showMessage && loader === false"
        >
          <b-message>
            <p class="noti-tittle margin-bottom">¡Hemos realizado mejoras!</p>
            <p
              class="margin-bottom"
            >• Hay actualizaciones disponibles, por favor recarga la página para obtener las nuevas mejoras de rendimiento.</p>
            <p>• Al aceptar se recargara la página y se aplicaran los cambios. Si esta agregando o editando una reserva por favor guarda antes de aceptar.</p>
            <hr />
            <div class="btn-noti">
              <a @click="reloadPage()" class="btn-noti">
                <i class="icon_reload refresh icon-mks"></i>&nbsp;ACEPTAR
              </a>
            </div>
          </b-message>
        </div>
        <div class="notification-offline notifications" id="online" v-if="internetIn">
          <b-message>
            <div class="noti-tittle margin-bottom center">
              <i class="fa fa-check-circle"></i>
              &nbsp;{{ user.displayName }}
            </div>
            <p class="text">Ya estamos en Línea.</p>
          </b-message>
        </div>
        <Cronology v-if="!map && !mapList" :filterActivate="filterActivate"/>
        <AddReservation v-if="getAddReservation && vendor" :type="getType" @openCompanion=" newCompanions = $event" @companionClose ="companionShow = false, newCompanions = false" :toList="false"></AddReservation>
        <AddWalkIn v-if="getAddReservationWalkIn && vendor || getEditReservation && isType==='Walk-In'" :type="getType" :sectionSel="sectionSelected" :tableSel="tableSelected" :tableSelId="tableSelectedId" @openCompanion=" newCompanions = $event" @companionClose ="companionShow = false, newCompanions = false" :edit="getEditReservation" :toList="false"></AddWalkIn>
        <ViewReservation v-if="getViewReservation && vendor" :type="getType" :infoReserva="infoReservation" @openCompanion="companionShow = $event" @companionClose ="companionShow = false, newCompanions = false" ></ViewReservation>
        <AddReservation v-if="getEditReservation && vendor  && (isType === 'Reserva' || isType === 'Precompra')" :type="getType" :infoReserva="infoReservation" @rerender="forceRerender" :editReservation="true" :key="componentKey" :editR="getEditReservation"> </AddReservation>
        <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
        <ChangedVendor v-if="changedVendor" v-model="changedVendor"></ChangedVendor>
        <AddWalkIn
          :itemList="selectedListItem"
          :toList="true"
          @close-modal-itemList="closeModal"
          v-if="typeItem == 'Walk-In'"
        ></AddWalkIn>
        <AddReservation
          :editItem="edit"
          :itemList="selectedListItem"
          :toList="true"
          @close-modal-itemList="closeModal"
          v-if="typeItem == 'reservation' || typeItem == 'dashboard' || typeItem == 'whitelabel'"
        ></AddReservation>
        <TurnDetail v-if="showTurnDetail" :filterTurn="filterActivate"></TurnDetail>
        <div :class="{'desplegar': toggle, backgroundTurnDetail: showTurnDetail}" class="is-4 column lateral" id="left_panel" v-show="map">
          <TimeEl
            :filterActivateSelected="filterActivate"
            :isMap="map"
            :lateral="toggle"
            @activatefilter="activateFilterF"
            @toggle="toggle = $event"
            class="no-in-mobile"
            v-if="map && showFilterButtons"
          ></TimeEl>
          <Reservations
            :lateral="toggle"
            :showFilterButtons="showFilterButtons"
            @toggle="toggle = $event"
            v-show="map"
          ></Reservations>
        </div>
        <div class="column center" id="right_panel" v-show="map" :class="{backgroundTurnDetail: showTurnDetail}">
          <div id="right_panel_mover"></div>
          <div class="fullheigth">
            <Floorplan @dataAlertYellow="infoAlertYellow=$event" v-show="map"></Floorplan>
          </div>
        </div>
        <div
          :class="{'desplegar':toggle}"
          class="is-4 column lateral timer-movile timer-List"
          v-show="!map && mapList"
        >
          <TimeEl
            :filterActivateSelected="filterActivate"
            @activatefilter="activateFilterF"
            @toggle="toggle = $event"
            class="list-mode"
            v-if="!map && mapList"
          ></TimeEl>
        </div>
        <div class="column center" v-show="!map && mapList">
          <div class="fullheigth" style="width:100%;">
            <Listview @dataAlertYellow="infoAlertYellow=$event" v-show="!map && mapList"></Listview>
          </div>
        </div>
      </div>
      <div class="not-show-alert-yellow" id="alertYellow" v-if="infoAlertYellow.show">
        <div class="bars">
          <span class="title-alert">
            <img class="icon-mks" src="@/assets/icons/alert_yellow.svg" />
            &nbsp;¡Atención!
          </span>
          <div class="cont-alert">
            <span
              class="comentarios"
            >Este usuario tiene una reserva en otros restaurantes a la misma hora.</span>
          </div>
          <hr />
          <div class="cont-logo" v-if="infoAlertYellow.vendors.length > 0">
            <img
              :class="{'display_none': !vendor.logo}"
              :id="'alert-yellow#' + llave"
              :key="'alert-yellow#' + llave"
              :src="vendor.logo"
              class="icon-style-alert"
              v-for="(vendor, llave) in infoAlertYellow.vendors"
            />
          </div>
        </div>
      </div>
      <div
        :class="{hiddenByModal:frontModal,showInModal:canWatchButtonsModal,mapList:mapList}"
        class="btn-box-2"
        v-if="(!validatePermission('ReadOnly') && !validatePermission('MixSFCN')) && map || mapList && (!validatePermission('ReadOnly') && !validatePermission('MixSFCN'))"
      >
        <a
          :class="{ 'btn-reserva-lateral': toggle, 'buttons-change-size': params.prePurchaseDashboard && selectedDate == today}"
          @click.prevent.stop="setAddReservation()"
          class="buton-action reservation"
          href="javascript:void(0)"
        >
          <div>Reserva</div>
          <i class="icon_buttonPlus"></i>
        </a>
        <a
          :class="{ 'btn-reserva-lateral': toggle, 'buttons-change-size': params.prePurchaseDashboard && selectedDate == today }"
          @click.prevent.stop="setAddPrecompra()"
          class="buton-action precompra"
          href="javascript:void(0)"
          v-if="params.prePurchaseDashboard"
        >
          <div>Precompra</div>
          <i class="icon_buttonPlus"></i>
        </a>
        <a
          :class="{ 'btn-walkin-lateral': toggle, 'buttons-change-size': params.prePurchaseDashboard }"
          @click.prevent.stop="setAddWalkin()"
          class="buton-action walking"
          href="javascript:void(0)"
          v-if="selectedDate == today"
        >
          <div>Walk-In</div>
          <i class="icon_buttonPlus"></i>
        </a>
        <button
          :class="{peopleWaiting:listLength>0}"
          :peopleAwaiting="listLength"
          @click="toggleMenuL"
          class="buttonList"
        >
          <img alt="icon" src="@/assets/icons/addReservation/clock.svg" />
        </button>
      </div>
      <div :class="{enter:sidelateral}" id="lgMenu">
        <!-- <span @click="CloseMenuL" id="exit" style="left: 96%;">&times;</span> -->
        <WaitingList
          :key="componentKey"
          @editItem="getEditItem"
          @selectTable="selectTableModal"
          @selectTableMulti="selectTableModalMulti"
          @selectDelete="selectDelete"
          @typeItem="getTtypeItem"
          class="absolute-fill margin-quarter"
        ></WaitingList>
      </div>
      <div
        :class="{enter:companionShow || newCompanions, companion: companionShow || newCompanions}"
        id="lgMenu"
      >
        <span
          @click="companionShow = false, newCompanions = false"
          id="exit"
          style="left: 96%;margin-left: -35px;"
        >&times;</span>
        <Companion :infoReserva="infoReservation" v-if="companionShow == true"></Companion>
        <NewCompanion v-if="newCompanions == true"></NewCompanion>
      </div>
      <div :class="{enter:sidelateralMove}" class="MoveLateral" id="lgMenu">
        <span @click="CloseMenuLMove" id="exit">&times;</span>
        <SectionsList :reservMove="sidelateralMove" ref="moveReservationComp"></SectionsList>
      </div>
      <div :class="{enter:sidelateralSearch}" class="SearchLateral" id="lgMenu">
        <SearchList @exitSearch="CloseMenuLSearch()"></SearchList>
      </div>
      <div
        @click="setEditMap(1)"
        class="wait-button"
        style="bottom: 22%!important; max-width: 50px; display: flex; justify-content: center; align-items: center;"
        v-if="!frontModal && !fromTimeLine && (!validatePermission('ReadOnly') && validatePermission('EditMap')) && !frontModal && !fromTimeLine"
        v-tooltip="{content: 'Editar Mapa', placement: 'top'}"
      >
        <div class="settings-icon" style="max-height: 35px; max-width: 35px;">
          <i class="icon_edit icon-mks small white"></i>
        </div>
      </div>
    </div>
    <div class="content-wrap" v-if="getEditMap">
      <EditMap></EditMap>
      <div
        @click="setEditMap(0)"
        class="wait-button"
        style="bottom: 1%!important; max-width: 60px; display: flex; justify-content: center; align-items: center;"
        v-if="validatePermission('EditMap') && !frontModal || user.isAdmin && !frontModal"
        v-tooltip="{content: 'Cancelar', placement: 'top'}"
      >
        <div class="settings-icon">
          <i class="icon_close icon-mks small white"></i>
        </div>..
      </div>
    </div>
    <b-modal :active.sync="showModalTable" id="WaitingList-Sit">
      <div class="modal-content">
        <div class="body-modal">
          <div class="header-modal">
            <span>Secciona Mesa y Zona</span>
            <i @click="closeModalTables()" class="icon_material_close icon-mks small white"></i>
          </div>
          <div class="body-container">
            <div class="column">
              <div class="column content">
                <Zone
                  :people="selectedListItem.people"
                  :timeHour="selectedListItem.date"
                  @seatSelected="(e) => (selectedListItem.selectZone = e)"
                ></Zone>
              </div>
              <div class="column content" style="margin-top: 20px; margin: 0px;">
                <Table
                  :dataNotPrecharged="true"
                  :hour="selectedListItem.hour"
                  :people="selectedListItem.people"
                  :selectedTablesClient="selectedListItem.selectTable"
                  :subZoneId="[]"
                  :zoneId="selectedListItem.selectZone"
                  @tableSelected="
                (selectedTable) => (selectedListItem.selectTable = selectedTable)
              "
                ></Table>
              </div>
              <div class="table-convention sitdown" v-if="selectedListItem.selectZone.length > 0">
                <div class="convention-msg">
                  <i class="convention-msg-red fa fa-circle"></i>
                  Ocupado
                </div>
                <div class="convention-msg">
                  <i class="convention-msg-green fa fa-circle"></i>Disponible
                </div>
                <div class="convention-msg">
                  <i class="convention-msg-orange fa fa-circle"></i>Disponible para restaurante
                </div>
              </div>
            </div>
            <div class="button-container">
              <button
                :disabled=" (selectedListItem.selectZone.length <= 0 || selectedListItem.selectTable.length <= 0) || isEjecution"
                @click="addReservation()"
                class="btn-add-reserva-espera-waiting comfirm"
                title="agregar Reserva"
                v-if="(selectedListItem.referrer == 'reservation' || selectedListItem.referrer == 'whitelabel' || selectedListItem.referrer == 'dashboard') && multiSelection == false && !validatePermission('ReadOnly')"
              >Realizar Reserva</button>
              <button
                :disabled=" (selectedListItem.selectZone.length <= 0 || selectedListItem.selectTable.length <= 0) || isEjecution"
                @click="addReservation()"
                class="btn-add-walk-espera-waiting comfirm"
                title="agregar Reserva"
                v-if="selectedListItem.referrer == 'Walk-In' && multiSelection == false && !validatePermission('ReadOnly')"
              >Agregar Walk-In</button>
              <button
                :disabled=" (selectedListItem.selectZone.length <= 0 || selectedListItem.selectTable.length <= 0) || isEjecution"
                @click="addMultiWalk()"
                :class="[selectedListItem.referrer == 'Walk-In' ? 'btn-add-walk-espera-waiting' : 'btn-add-reserva-espera-waiting', 'comfirm']"
                title="agregar Reserva"
                v-if="multiSelection == true && !validatePermission('ReadOnly')"
              >Realizar {{selectedListItem.referrer == 'Walk-In' ? 'Walk-In' : 'Reserva'}}</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="showModalConfirm">
      <div class="modal-content modal-delete">
        <div class="zone-container">
          <i class="icon-mks alertIconTrash icon_waiting_alert"></i>
          <span class="titleModalWaiting">ELIMINAR {{selectedListItem.referrer == 'Walk-In' ? 'WALK-IN' : 'RESERVA'}} DE LA LISTA DE ESPERA</span>
          <span class="msgModalWaiting">¿Estás seguro(a) que quieres eliminar la {{selectedListItem.referrer == 'Walk-In' ? 'Walk-in' : 'reserva'}} de la lista de espera?</span>
          <div class="confirm-button">
            <button @click="deleteItem(selectedListItem.id)" class="btn-confirm-yes">Si</button>
            <button @click="closeModalConfirm" class="btn-confirm-no">No</button>
          </div>
        </div>
      </div>
    </b-modal>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import Toolbar from '@/components/_shared/Toolbar';
import EditMap from '@/components/reservations/EditMap';
import Sidenav from '@/components/_shared/Sidenav';
import Reservations from './Reservations';
import TimeEl from './Time';
import AddReservation from '@/components/_shared/AddReservation';
import AddWalkIn from '@/components/_shared/AddWalkIn';
import ViewReservation from '@/components/_shared/ViewReservation';
// import EditReservation from '@/components/_shared/EditReservation';
import WaitingList from '@/components/_shared/WaitingList';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import ChangedVendor from '@/components/_shared/ChangedVendor';
// import itemListFormWalkIn from '@/components/_shared/WaitingList/itemListFormWalkIn';
// import itemListFormReservation from '@/components/_shared/WaitingList/itemListFormReservation';
import Zone from '@/components/_shared/FormReservation/Zone';
import Table from '@/components/_shared/FormReservation/Table';
import Listview from '@/components/reservations/Center/Lista';
import Floorplan from '@/components/reservations/Center/Mapa';
import SectionsList from '@/components/reservations/Center/Lista/SectionsList';
import Cronology from '@/components/Cronology/Cronology/';
import _ from 'lodash';
import SearchList from '@/components/_shared/SearchList/';
import Companion from '@/components/_shared/Companion';
import NewCompanion from '@/components/_shared/NewCompanion';
import TempPassword from '@/components/_shared/TempPassword';
import NewInformation from '@/components/_shared/NewInformation';
import TurnDetail from '@/components/_shared/TurnChart/TurnDetail';
import serviceVendor from '@/helpers/apis/serviceVendor';
import serviceAdmin from '@/helpers/apis/serviceAdmin';
export default {
  props: ['internetIn', 'showMessage', 'loader'],
  components: {
    Cronology,
    Sidenav,
    Toolbar,
    Reservations,
    TimeEl,
    AddReservation,
    AddWalkIn,
    ViewReservation,
    // EditReservation,
    ChangedPassword,
    ChangedVendor,
    WaitingList,
    // itemListFormWalkIn,
    // itemListFormReservation,
    Zone,
    Table,
    Listview,
    Floorplan,
    SectionsList,
    SearchList,
    Companion,
    NewCompanion,
    EditMap,
    TempPassword,
    TurnDetail
  },
  data () {
    return {
      m: null,
      y: null,
      d: null,
      componentKey: 0,
      windowWidth: 1000,
      isOpenL: false,
      subZoneId: [],
      selectedListItem: {
        id: null,
        name: null,
        email: null,
        phone: null,
        waitingTime: null,
        comment: null,
        notificationType: null,
        indicativo: null,
        bandera: '',
        people: 2,
        date: null,
        hour: null,
        selectZone: [],
        selectTable: [],
        referrer: null
      },
      waitingList: [],
      typeItem: null,
      edit: null,
      showModalTable: false,
      toggle: false,
      isOpenMobile: false,
      filterActivate: 0,
      showFilterButtons: false,
      infoAlertYellow: {
        show: false,
        vendors: []
      },
      newVersion: null,
      multiSelection: false,
      itemsMulti: [],
      companionShow: false,
      newCompanions: false,
      isEjecution: false,
      listLength: 0,
      showSideBar: false,
      showModalConfirm: false,
      intervalNew: null
    };
  },
  created () {
    window.setInterval(this.getListLength, 600);
  },
  mounted () {
    this.windowWidth = window.innerWidth;
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: this.user.id
      }
    });
    this.reloadList();
    setTimeout(() => {
      this.resizer();
    }, 300);
    this.getTurns();
    this.isShowFilterButtons();
    this.getNewInformation('alerts');
    this.getNewInformation('onboarding');
    this.intervalNew = setInterval(() => {
      this.getNewInformation('alerts');
      this.getNewInformation('onboarding');
    }, 60000);
  },
  computed: {
    ...mapGetters({
      verticalReservation: reservationTypes.getters.verticalReservation,
      sidelateral: reservationTypes.getters.sidelateral,
      sidelateralMove: reservationTypes.getters.sidelateralMove,
      sidelateralSearch: reservationTypes.getters.sidelateralSearch,
      turns: reservationTypes.getters.theTurns,
      companionsData: reservationTypes.getters.companionsData,
      type: reservationTypes.getters.type,
      frontModal: [reservationTypes.getters.frontModal],
      fromTimeLine: [reservationTypes.getters.fromTimeLine]
    }),
    canWatchButtonsModal () {
      if (this.typeItem === 'Walk-In' || this.typeItem === 'reservation') {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    deleteItem (id) {
      this.$store
        .dispatch({
          type: 'waitingList:deleteWaitingListItem',
          data: { id: id }
        })
        .then(() => {
          this.reloadList();
          this.resetSelected();
          this.showModalConfirm = !this.showModalConfirm;
        });
    },
    closeModalConfirm () {
      this.showModalConfirm = !this.showModalConfirm;
      // this.reloadList();
      this.resetSelected();
    },
    selectDelete (item) {
      this.selectedListItem = item;
      this.showModalConfirm = !this.showModalConfirm;
    },
    toggleSideBar (open) {
      this.showSideBar = open;
    },
    toggleMenuL () {
      this.$store.commit(
        reservationTypes.mutations.setSideLateral,
        !this.sidelateral
      );
    },
    getListLength () {
      this.listLength = window.localStorage.getItem('listLength');
    },
    setEditMap (value) {
      this.$buefy.dialog.confirm({
        message: (value) ? 'Estás seguro que quieres <b>editar</b> el mapa?' : 'Estás seguro que quieres cancelar la <b>edición</b> del mapa? <br> No se guardarán los cambios',
        cancelText: 'Cerrar',
        confirmText: (value) ? 'Si, Editar' : 'Si, Cancelar',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: () => {
          this.$store.commit(globalTypes.mutations.setEditMap, value);
        }
      });
    },
    reloadPage () {
      this.$emit('closeMessage', false);
      window.location.reload(true);
    },
    selectTableModal (item) {
      this.selectedListItem = item;
      this.selectedListItem.hour = item.date;
      this.showModalTable = !this.showModalTable;
    },
    selectTableModalMulti (item) {
      this.itemsMulti = item;
      this.multiSelection = true;
      this.selectedListItem = item[0];
      this.selectedListItem.hour = item[0].date;
      this.showModalTable = !this.showModalTable;
    },
    closeModalTables () {
      this.showModalTable = !this.showModalTable;
      this.reloadList();
      this.resetSelected();
    },
    toggleFull () {
      this.$emit('change-full', true);
    },
    forceRerender () {
      //* this.$store.commit(reservationTypes.mutations.setSideLateral, false);
      this.componentKey += 1;
    },
    changeVertical () {
      this.$store.commit(
        reservationTypes.mutations.setVerticalReservation,
        false
      );
    },
    CloseMenuL () {
      this.$store.commit(reservationTypes.mutations.setSideLateral, false);
    },
    CloseMenuLMove () {
      this.$store.commit(reservationTypes.mutations.setSideLateralMove, false);
      this.$refs.moveReservationComp.resetOptions();
    },
    CloseMenuLSearch () {
      this.$store.commit(reservationTypes.mutations.setSideLateralSearch, false);
    },
    closeModal (e) {
      this.reloadList();
      if (!e) {
        this.resetSelected();
      }
      this.edit = false;
      if (e) {
        this.typeItem = null;
        this.itemListSelectTable(this.selectedListItem);
      }
    },
    itemListSelectTable (item) {
      this.selectedListItem = item;
      this.selectedListItem.hour = item.date;
      this.showModalTable = !this.showModalTable;
    },
    getTtypeItem (typed, reloadItem) {
      if (reloadItem) {
        this.resetSelected();
      }
      this.typeItem = typed;
    },
    getEditItem (edit) {
      this.$store.commit(reservationTypes.mutations.setSideLateral, false);
      this.selectedListItem = edit;
    },
    reloadList () {
      this.$store.dispatch({
        type: 'waitingList:getWaitingList',
        data: { vendorId: this.vendorId, selectedDate: this.selectedDate }
      })
        .then(response => {
          if (parseInt(response.code) === 200) {
            this.waitingList = response.data;
            let countList = 0;
            _.forEach(this.waitingList, function (value) {
              _.forEach(value, function () {
                countList++;
              });
            });
            window.localStorage.setItem('listLength', countList);
            this.forceRerender();
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    resetSelected () {
      this.typeItem = null;
      this.selectedListItem = {
        id: null,
        name: null,
        email: null,
        phone: null,
        waitingTime: null,
        comment: null,
        notificationType: null,
        indicativo: null,
        people: 2,
        date: null,
        hour: null,
        selectZone: [],
        selectTable: [],
        referrer: null,
        panel: null
      };
    },
    CloseCalendar () {
      var bodyEl = document.body;
      // eslint-disable-next-line
      classie.remove(bodyEl, 'show-menu-calendar')
    },
    addReservation () {
      this.isEjecution = true;
      const selectZone = this.purgeZone(
        this.selectedListItem.selectZone,
        this.selectedListItem.selectTable
      );
      if (this.selectedListItem.referrer === 'Walk-In') {
        const dateM = this.$moment()
          .format('mm');
        let date = this.$moment()
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .format('x');
        if (dateM >= 16 && dateM <= 45) {
          date = this.$moment()
            .minutes(30)
            .seconds(0)
            .milliseconds(0)
            .format('x');
        } else if (dateM >= 46 && dateM <= 59) {
          date = this.$moment()
            .add(1, 'hours')
            .minutes(0)
            .seconds(0)
            .milliseconds(0)

            .format('x');
        }
        this.selectedListItem.date = date;
        this.selectedListItem.originalDate = this.$moment().format('x');
      }
      this.selectedListItem.selectZone = selectZone;
      this.selectedListItem.vendorId = this.vendorId;
      this.selectedListItem.userId = this.user.id;
      this.selectedListItem.userTags = this.selectedListItem.tags;
      this.selectedListItem.userNotes = this.selectedListItem.notes;
      this.$store
        .dispatch({
          type: 'waitingList:addReservationFromWaitingList',
          data: this.selectedListItem
        })
        .then(response => {
          if (response.code === 200) {
            this.$store
              .dispatch({
                type: 'waitingList:deleteWaitingListItem',
                data: { id: this.selectedListItem.id }
              })
              .then(() => {
                this.reloadList();
                this.resetSelected();
                this.isEjecution = false;
              }).catch(() => {
                this.isEjecution = false;
              });
            this.showModalTable = !this.showModalTable;
          } else {
            this.isEjecution = false;
          }
        }).catch(() => {
          this.isEjecution = false;
        });
    },
    addMultiWalk () {
      this.isEjecution = true;
      const selectZone = this.purgeZone(
        this.selectedListItem.selectZone,
        this.selectedListItem.selectTable
      );
      let date = null;
      const dateM = this.$moment().format('mm');
      if (this.selectedListItem.referrer === 'Walk-In') {
        date = this.$moment().minutes(0).seconds(0).milliseconds(0).format('x');
        if (dateM >= 16 && dateM <= 45) {
          date = this.$moment().minutes(30).seconds(0).milliseconds(0).format('x');
        } else if (dateM >= 46 && dateM <= 59) {
          date = this.$moment().add(1, 'hours').minutes(0).seconds(0).milliseconds(0).format('x');
        }
      }
      this.itemsMulti.forEach((data) => {
        data.selectTable = this.selectedListItem.selectTable;
        data.selectZone = selectZone;
        if (date !== null) {
          data.date = date;
        }
        data.originalDate = this.$moment().format('x');
        data.vendorId = this.vendorId;
        data.userId = this.user.id;
        this.$store.dispatch({
          type: 'waitingList:addReservationFromWaitingList',
          data: data
        }).then(response => {
          if (response.code === 200) {
            this.$store.dispatch({
              type: 'waitingList:deleteWaitingListItem',
              data: { id: data.id }
            }).then(() => {
              this.reloadList();
              this.isEjecution = false;
            }).catch(err => {
              console.error(err);
              this.isEjecution = false;
            });
          } else {
            this.isEjecution = false;
          }
        }).catch(err => {
          console.error(err);
          this.isEjecution = false;
        });
      });

      this.resetSelected();
      this.showModalTable = !this.showModalTable;
    },
    purgeZone (selectZone, selectTable) {
      let purgedZones = [];
      if (selectZone.length > 1) {
        _.forEach(selectTable, function (value) {
          purgedZones = _.concat(purgedZones, value.idSection);
        });
        purgedZones = _.uniq(purgedZones);
      } else {
        purgedZones = selectZone;
      }
      return purgedZones;
    },
    resizer () {
      const element = document.querySelector('#right_panel');
      const element2 = document.querySelector('#left_panel');
      window.addEventListener('resize', (e) => {
        if (window.innerWidth > 1000) {
          element.style.height = 100 + '%';
          element2.style.height = 100 + '%';
        }
      });
      const header = parseFloat(getComputedStyle(document.querySelector('.navbar'), null).getPropertyValue('height').replace('px', ''));
      const contenedor = parseFloat(getComputedStyle(document.querySelector('#Content-reservations'), null).getPropertyValue('height').replace('px', ''));
      const currentResizer = document.querySelector('#right_panel_mover');

      currentResizer.addEventListener('touchstart', function (e) {
        window.addEventListener('touchmove', resize, { passive: false });
        window.addEventListener('touchend', stopResize, { passive: false });
      });

      function resize (e) {
        const height = e.changedTouches[0].clientY - header;
        element.style.height = (contenedor - height) + 'px';
        element2.style.height = height + 'px';
      }
      function stopResize (e) {
        window.removeEventListener('touchmove', resize, { passive: false });
      }
    },
    getTurns () {
      this.$store.dispatch({ type: 'reservation:theTurns', data: this.decryptMx(this.$store.state.vendorId) })
        .then(response => {
          this.$store.commit(reservationTypes.mutations.setTurns, response.data);
        })
        .catch(err => {
          console.error(err);
        }).finally(() => {
          this.isShowFilterButtons();
        });
    },
    activateFilterF (value) {
      this.filterActivate = value;
    },
    isShowFilterButtons () {
      const dayOfWeek = this.$moment(this.dateComplete).format('d');
      let hay = 0;
      if (this.turns) {
        const tipo = ['desayuno', 'almuerzo', 'cena'];
        tipo.forEach((type) => {
          if (this.turns[type] !== undefined) {
            if (this.turns[type][this.selectedDate] !== undefined) {
              hay++;
            } else if (this.turns[type][dayOfWeek] !== undefined) {
              hay++;
            }
          }
        });
      }
      if (hay > 0) {
        this.showFilterButtons = true;
      } else {
        this.showFilterButtons = false;
      }
    },
    getValidationAlertsOnboardingByDate (data) {
      const currentDate = this.$moment().format('YYYY-MM-DD');
      const currentTime = this.$moment().format('HH:mm');
      const alertOnboardingToShow = data.find(item => {
        const isDateMatch = item.date === currentDate || item.date == null;
        const isHourMatch = (item.hourOne === currentTime || item.hourTwo === currentTime);
        return isDateMatch && isHourMatch;
      });
      return alertOnboardingToShow;
    },
    getNewInformation (type = 'alerts') {
      if (type === 'alerts') {
        const deviceId = window.localStorage.getItem('deviceId_dashboard');
        serviceVendor.post('/alerts', { deviceId }).then(({ data }) => {
          const alerts = data.data || [];
          // Si hay alertas, verifica cuál mostrar
          const alertToShow = this.getValidationAlertsOnboardingByDate(alerts);
          // Si encontramos una alerta que mostrar, la mostramos
          if (alertToShow) {
            this.$buefy.modal.open({
              parent: this,
              component: NewInformation,
              props: {
                info: alertToShow, // Pasar solo la alerta que se va a mostrar
                alerts: true
              },
              hasModalCard: true,
              customClass: 'ajust-new-information',
              trapFocus: true,
              canCancel: false
            });
          }
        });
      }
      if (type === 'onboarding') {
        serviceAdmin.get('/onboarding').then(({ data }) => {
          const onboardings = data || [];
          const onboardingToShow = this.getValidationAlertsOnboardingByDate(onboardings);
          if (onboardingToShow) {
            this.$buefy.modal.open({
              parent: this,
              component: NewInformation,
              props: {
                info: onboardingToShow,
                alerts: false
              },
              hasModalCard: true,
              customClass: 'ajust-new-information',
              trapFocus: true,
              canCancel: false
            });
          }
        });
      }
    }
  },
  watch: {
    selectedDate () {
      this.reloadList();
      this.resetSelected();
      this.isShowFilterButtons();
      this.$store.dispatch({
        type: 'section:sections',
        data: { vendorId: this.idVendor }
      });
    },
    map () {
      if (this.map) {
        this.toggle = false;
      } else {
        this.toggle = false;
      }
    }
  },
  destroyed () {
    const element = document.querySelector('#right_panel');
    const element2 = document.querySelector('#left_panel');
    window.removeEventListener('resize', function (e) {
      if (window.innerWidth > 1000) {
        element.style.height = 100 + '%';
        element2.style.height = 100 + '%';
      }
    });
    clearInterval(this.intervalNew);
  }
};
</script>
<style lang="scss">
.ajust-new-information {
  .card {
    border-radius: 10px;
    background: #FFF;
    width: 424px;
    height: auto;
    /* max-height: 581px; */
    @media screen and (max-width: 500px) {
      max-width: 390px;
    }
  }
  .modal-background {
    background-color: rgba(10, 10, 10, 0.6)!important;
  }
  .card-content {
    width: 424px;
    height: auto;
    /* max-height: 581px; */
    flex-shrink: 0;
    display: flex;
    padding: 40px;
    @media screen and (max-width: 500px) {
      padding: 40px 23px;
      max-width: 390px;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .animation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    /* Estilo para el scrollbar */
    &::-webkit-scrollbar {
      width: 2px!important; /* Ancho del scrollbar */
    }

    /* Estilo para la línea del scrollbar */
    &::-webkit-scrollbar-thumb {
      background-color: #444B57!important; /* Color de la línea del scrollbar */
      outline: unset !important;
      border-radius: unset !important;
      border: unset !important;
      -webkit-box-shadow: unset !important;
      box-shadow: unset !important;
    }

    /* Estilo para el fondo del scrollbar */
    &::-webkit-scrollbar-track {
      background-color: #ffffff!important; /* Color del fondo del scrollbar */
    }
  }
}
#Reservations .reservations-container.mapList {
  background: #f5f5f5;
  overflow: hidden !important;
  @media screen and (max-width: 900px) {
    padding: 8px 2px 0px 4px;
    gap: 4px;
    .center {
      min-height: 40px;
      flex: 1;
    }
  }
}
@import "@/assets/styles/reservation/index.scss";
.hideOverflow {
  overflow: hidden !important;
}
@media only screen and (min-width: 1001px) and (max-width: 1280px) {
  .component-box .table-component {
    width: 100%;
    max-width: unset !important;
  }
}
.table-component {
  .table-item:last-child {
    border-right: unset;
  }
}
#Reservations .table-convention.sitdown {
  justify-content: center;
  margin-top: 10px;
}
.header-component {
  background: #ebeced;
  padding: 5px 10px;
  border-radius: 8px 8px 0 0;
  font-size: 11px;
  font-family: "Roboto";
  color: #444b57;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 26px;
}
.select-please {
  text-align: center;
}
.modal-content {
  height: 100%;
  overflow: hidden !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .zone-container {
    min-height: fit-content;
  }
  .close-btn {
    @media screen and (min-width: 800px) {
      display: none !important;
    }
  }
}
.modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
}
.zone-container {
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.20);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.alertIcon {
  -webkit-mask-size: contain;
  width: 60px;
  height: 60px;
}
.alertIconTrash {
  -webkit-mask-size: contain;
  width: 70px;
  height: 70px;
  background: #FF0101;
}
.modal-close {
  display: none!important;
}
.titleModalWaiting {
  color: #444B57;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}
.msgModalWaiting {
  color:#444B57;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 250px;
}
.confirm-button {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}
.btn-confirm-no {
  color: #444B57;
  background: #FFF;
  border: 1px solid #444B57;
  color: #444B57;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 6px 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 132px;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
  }
}
.btn-confirm-yes {
  color: #fff;
  background: #444B57;
  width: 132px;
  height: 30px;
  font-weight: bold;
  border-radius: 15px;
  padding: 6px 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: 415px) {
  .modal-content {
    overflow: auto!important;
  }
  .modal-delete {
    margin: 0 1px!important;
  }
  .listing {
    height: calc(100vh - 220px);
  }
}
</style>
