<template>
  <div id="modalCombineUserManual" class="modal-unify-users">
    <section class="modal-unify-users__header">
      <h2>Unificar contactos manualmente</h2>
      <i id="icon_close_modal" class="icons icon_close_modal" @click="$parent.close()"></i>
      <div class="modal-unify-users__header__action">
        <div>
          <span class="text">Buscar contacto</span>
          <input type="text" name="search" v-model="inputSearchName" autocomplete="off" @keyup.enter="fetchDataToMerge()" />
        </div>
        <b-button @click="fetchDataToMerge()" outlined :disabled="isProcessing"><span class="textBtn">Buscar</span><i class="icon-mks icon_search_normal"></i></b-button>
      </div>
    </section>
    <section class="modal-unify-users__content">
      <div class="modal-unify-users__content__header" v-if="dataUsers.cantidad>0">
        <h3>Resultados de: <span>{{this.nameSearched}}</span></h3>
        <span>Selecciona un contacto principal, y luego selecciona lo contactos a combinar</span>
      </div>
      <div class="modal-unify-users__content__header" v-else>
        <h3>Ingresa la información para realizar una búsqueda</h3>
      </div>
      <table class="modal-unify-users__content__aux" v-if="dataUsers.cantidad>0">
        <thead>
          <tr>
            <th class="first_column">Principal</th>
            <th></th>
            <th class="last_column">Combinar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in dataUsers.users" :key="index" class="modal-unify-users__content__info">
            <td class="modal-unify-users__content__info__first_column">
              <input type="radio" name="isPrincipal" :id="'radio_user_'+user._id" @click="setPrincipal(index)" :checked="user.isPrincipal" />
            </td>
            <td>
              <CombineUserItem :user="user" :name="user.name" :identityDocument="user.identityDocument" :email="user.email" :phone="user.phone"/>
            </td>
            <td class="modal-unify-users__content__info__last_column">
              <input type="checkbox" name="combinar" :id="'checkbox_user_'+user._id" :checked="user.isChecked" @change="setChecked($event, index)" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="modal-unify-users__content__footer" v-if="dataUsers.cantidad>0">
        <b-button @click="$parent.close()" outlined :disabled="isProcessing">Cerrar</b-button>
        <b-button @click="saveDataToMerge()" class="btn-unificar" v-if="(this.isChecked && this.isPrincipalSelected)" >Unificar contactos</b-button>
        <b-button class="btn-unificar" disabled v-else >Unificar contactos</b-button>
      </div>
    </section>
  </div>
</template>
<script>
import Axios from 'axios';
import globalTypes from '@/store/types/global';
import serviceImprove from '@/helpers/apis/serviceImprove';
import CombineUserItem from '@/components/users/users/combineUserItem';

export default {
  components: {
    CombineUserItem
  },
  name: 'addUserModalManual',
  props: ['value'],
  data: () => ({
    inputSearchName: '',
    dataUsers: {
      message: '',
      vendorId: null,
      // data: [],
      cantidad: 0,
      csrfToken: '',
      users: [
        {
          _id: '',
          phone: '',
          email: '',
          identityDocument: '',
          isPrincipal: false,
          isChecked: false
        }
      ]
    },
    isProcessing: false,
    nameSearched: '',
    isPrincipalSelected: false,
    isChecked: false,
    loading: false,
    error: null, // hasta aqui
    modalTags: false
  }),
  mounted () {
    // this.fetchDataToMerge();
  },
  methods: {
    setPrincipal (index) {
      this.dataUsers.users.forEach((user, idx) => {
        user.isPrincipal = idx === index;
      });
      this.isPrincipalSelected = true;
      this.dataUsers.users[index].isChecked = true;
      document.getElementById('checkbox_user_' + this.dataUsers.users[index]._id).checked = true;
    },
    setChecked (event, index) {
      this.dataUsers.users[index].isChecked = event.target.checked;
      const checkedCount = this.dataUsers.users.filter(user => user.isChecked).length;
      this.isChecked = checkedCount >= 2;
    },
    saveDataToMerge () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      const queryData = this.dataUsers;
      queryData.vendor_id = this.vendor.id;
      console.log(queryData);
      serviceImprove.post('/save_user/', queryData, { headers: { 'X-CSRFToken': this.dataUsers.csrfToken } }
      ).then(({ data }) => {
        console.log('la data', data, (data.status === 'ok'));
        document.getElementById('icon_close_modal').click();
      }).finally(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }).catch((error) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        console.log(error);
      });
    },
    openModal () {
      this.loading = true; // Reiniciar el estado de carga al abrir el modal
    },
    fetchDataToMerge () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceImprove.get(`/search_user?vendorId=${this.vendor.id}&search=${this.inputSearchName}`).then(({ data }) => {
        this.dataUsers = data;
        this.nameSearched = this.inputSearchName;
      }).finally(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.isPrincipalSelected = false;
      }).catch((error) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        console.log(error);
        this.isPrincipalSelected = false;
      });
    },
    deleteNT (type, i) {
      if (type === 'tag') {
        this.userTags.splice(i, 1);
      }
    },
    async actionDataToMerge (processData, id) {
      this.isProcessing = true;
      try {
        const params = {
          vendorId: this.vendor.id,
          process: processData,
          id: id
        };
        console.log(params);
        const response = await Axios.get(process.env.VUE_APP_IMPROVE_DATA + '/set', {
          params: params
        });
        console.log(response);
        // this.dataUsers = response.data;
        console.log(this.dataUsers);
        if (id == null) {
          this.fetchDataToMerge();
        } else {
          this.dataUsers.data = this.dataUsers.data.filter(user => user._id !== id);
        }
      } catch (err) {
        this.error = 'Error al obtener los datos: ' + err.message; // Manejo de errores
      } finally {
        this.isProcessing = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import '../../../assets/styles/users/modalCombineUserManual';
</style>
