<template>
  <div id="modalCombineUser" class="modal-unify-users">
    <section class="modal-unify-users__header">
      <h2>Unificar contactos</h2>
      <i class="icons icon_close_modal" @click="$parent.close()"></i>
      <div class="modal-unify-users__header__action">
        <div>
          Puedes unificar uno a uno o si lo prefieres unificarlos todos.<br/>
          <b>Tienes <span id="cantidadPorCombinar">{{dataUsers.data.length}}</span> contactos para unificar.</b>
        </div>
        <b-button @click="actionDataToMerge(1, null)" :disabled="isProcessing">Unificar todos los contactos</b-button>
      </div>
    </section>
    <section class="modal-unify-users__content">
      <div v-for="user in dataUsers.data" :key="user.__id" class="modal-unify-users__content__aux">
        <h2 class="capitalize text-center">{{ user.principal_data.name }}</h2>
        <div class="modal-unify-users__content__info">
          <CombineUserItem :user="user.principal_data" :name="user.principal_data.name" :identityDocument="user.principal_data.identityDocument" :email="user.principal_data.email" :phone="user.principal_data.phone"/>
          <div v-for="otro_user in user.otros_docs" :key="otro_user._id" >
            <CombineUserItem :name="otro_user.name" :identityDocument="otro_user.identityDocument" :email="otro_user.email" :phone="otro_user.phone"/>
          </div>
          <div class="modal-unify-users__content__info__buttons">
            <b-button type="is-dark" @click="actionDataToMerge(0, user._id)" outlined :disabled="isProcessing">Descartar</b-button>
            <b-button type="is-dark" @click="actionDataToMerge(1, user._id)" outlined :disabled="isProcessing">Combinar</b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Axios from 'axios';
import globalTypes from '@/store/types/global';
import serviceImprove from '@/helpers/apis/serviceImprove';
import CombineUserItem from '@/components/users/users/combineUserItem';
export default {
  components: {
    CombineUserItem
  },
  name: 'addUserModal',
  props: ['value'],
  data: () => ({
    dataUsers: {
      message: '',
      vendorId: null,
      data: []
    },
    isProcessing: false,
    loading: false,
    error: null, // hasta aqui
    modalTags: false
  }),
  mounted () {
    this.fetchDataToMerge();
  },
  methods: {
    openModal () {
      this.loading = true; // Reiniciar el estado de carga al abrir el modal
      // this.error = null; // Reiniciar el error
      this.fetchDataToMerge(); // Llamar a la función para obtener los datos
    },
    fetchDataToMerge () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceImprove.get(`/get?vendorId=${this.vendor.id}`).then(({ data }) => {
        this.dataUsers = data;
        // console.log(data);
      }).finally(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }).catch((error) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        console.log(error);
      });
      /*  const response = await Axios.get(process.env.VUE_APP_IMPROVE_DATA + '/get', {
        params: {
          vendorId: this.vendor.id
        }
      }); */
    },
    deleteNT (type, i) {
      if (type === 'tag') {
        this.userTags.splice(i, 1);
      }
    },
    async actionDataToMerge (processData, id) {
      this.isProcessing = true;
      try {
        const params = {
          vendorId: this.vendor.id,
          process: processData,
          id: id
        };
        console.log(params);
        const response = await Axios.get(process.env.VUE_APP_IMPROVE_DATA + '/set', {
          params: params
        });
        console.log(response);
        // this.dataUsers = response.data;
        console.log(this.dataUsers);
        if (id == null) {
          this.fetchDataToMerge();
        } else {
          this.dataUsers.data = this.dataUsers.data.filter(user => user._id !== id);
        }
      } catch (err) {
        this.error = 'Error al obtener los datos: ' + err.message; // Manejo de errores
      } finally {
        this.isProcessing = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import '../../../assets/styles/users/modalCombineUser';
</style>
