<template>
  <fullscreen class="views reservations-view" ref="fullscreen">
    <LoaderPrecompro />
    <b-modal :active.sync="showMsgGlobal" :can-cancel="false" id="modalMsgGlobal">
      <div class="content-msgPay" style="background-color:#06111B !important;">
        <i @click="showMsgGlobal = false" class="fa fa-times times-right"></i>
        <div class="content-msgPay-title f25 mb-15">!Ten en Cuenta!</div>
        <div class="content-msgPay-title content-msgPay-title-center">
          <span class="f25-l" v-html="params.messagePopUpGlobal" v-if="params"></span>
        </div>
      </div>
    </b-modal>
    <Reservations
      :internetIn="internetIn"
      :loader="$store.state.loaderPrecompro.status"
      :showMessage="showMessage"
      @change-full="toggle"
      @closeMessage="showMessage = $event"
      v-if="!$store.state.loaderPrecompro.status && vendor"
    ></Reservations>
  </fullscreen>
</template>
<script>
import Reservations from '@/components/reservations';
import reservationTypes from '@/store/types/reservation';
import serviceBlocking from '@/helpers/apis/serviceBlocking';
import _ from 'lodash';
import verifyUser from '@/helpers/verifyUser';
import authTypes from '@/store/types/auth';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import LoaderPrecompro from '@/components/_shared/LoaderPrecompro';
import moment from 'moment-timezone';

export default {
  metaInfo: {
    title: ' - Reservas'
  },
  data () {
    return {
      notifyView: [],
      internetIn: false,
      fullscreen: false,
      intervalMsg: null,
      intervalGlobal: null,
      showMsgGlobal: false
    };
  },
  computed: {
    ...mapGetters({
      systemDate: [globalTypes.getters.serverTime],
      showMessage: [globalTypes.getters.showMessage]
    })
  },
  components: {
    Reservations,
    LoaderPrecompro
  },
  mounted () {
    this.getSystemDate();
    const vendorUrl = this.subDomain();
    if (vendorUrl !== undefined && vendorUrl != null) {
      const id = window.localStorage.getItem('notifyView');
      if (id !== null) {
        this.notifyView = id;
      }
      const version = window.localStorage.getItem('version');
      if (version === undefined) {
        window.localStorage.setItem('version', 0);
      }
      this.$store.commit(authTypes.mutations.setUser);
      this.getDataVendor(vendorUrl);
    }
  },
  methods: {
    getSystemDate () {
      this.$store.dispatch({
        type: 'global:getServerTime'
      });
    },
    showGlobalMsg () {
      const instance = this;
      this.intervalGlobal = setInterval(() => {
        instance.showMsgGlobal = true;
      }, (this.params.frecuency * 1000));
    },
    toggle () {
      this.$refs.fullscreen.toggle(); // recommended
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false, typeMessage: 4 });
    },
    isResponsive () {
      if (window.innerWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen;
    },
    isExist (info) {
      const data = _.includes(this.notifyView, info.id);
      return data;
    },
    getDataVendor (vendorUrl) {
      this.$store.dispatch({
        type: 'global:getServerTime'
      }).finally(() => {
        let today = this.$moment(this.systemDate).format('YYYY-MM-DD');
        this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 4 });
        this.$store
          .dispatch({
            type: 'global:vendorId',
            data: {
              url: vendorUrl
            }
          })
          .then(({ data }) => {
            if (data.vendorId) {
              localStorage.setItem('vendorUrl', vendorUrl);
              localStorage.setItem('_id', data.Id);
              localStorage.setItem('keyWs', data.Id);
              this.$store
                .dispatch({
                  type: 'global:vendor',
                  data: {
                    vendorId: data.Id
                  }
                })
                .then(resp => {
                  if (resp.data.vendor.timezone != null) {
                    moment.tz.setDefault(resp.data.vendor.timezone);
                    today = this.$moment(this.systemDate)
                      .format('YYYY-MM-DD');
                  } else {
                    moment.tz.setDefault('America/Bogota');
                  }
                  this.$store.commit(
                    reservationTypes.mutations.setSelectedDate,
                    today
                  );
                  this.$store.commit(
                    reservationTypes.mutations.setSelectedMonth,
                    today
                  );
                  document.querySelector('title').text =
                    resp.data.vendor.displayName + ' - Reservas';
                  document.querySelector('link[rel*="icon"]').href =
                    resp.data.vendor.thumbnailURL;
                  if (resp.data.params.activateGlobalPopUp) {
                    this.showGlobalMsg();
                  } else {
                    this.showMsgGlobal = false;
                    clearInterval(this.intervalGlobal);
                    this.intervalGlobal = null;
                  }
                });
              this.$store.dispatch({
                type: 'reservation:blocked',
                data: { vendorId: data.Id }
              });
              this.$store.dispatch({
                type: 'reservation:allEvents',
                data: { vendorId: data.Id }
              });
              this.$store.dispatch({
                type: 'reservation:schedules',
                data: { vendorId: data.Id }
              });
              this.$store.dispatch({
                type: 'reservation:times',
                data: { vendorId: data.Id }
              });
              this.$store.dispatch({
                type: 'reservation:notifications',
                data: {
                  vendorId: data.vendorId,
                  userId: this.encrypt(this.user.id)
                }
              });
              this.$store.dispatch({ type: 'global:getReferrers' });
              this.$store.dispatch({
                type: 'global:getPermissions',
                data: { userId: this.user.id }
              });
              this.$store.dispatch({
                type: 'reservation:getGroup',
                data: { vendorId: data.Id }
              });
              this.$store.dispatch({
                type: 'reservation:getModules',
                data: {
                  vendorId: data.Id,
                  userId: this.user.id
                }
              });
              this.$store.dispatch({
                type: 'reservation:getRotation',
                data: {
                  vendorId: data.Id
                }
              });
              serviceBlocking.post('/blocked/day', { vendorId: data.Id }).then(({ data }) => {
                this.$store.commit(reservationTypes.mutations.setBlockingDay, data.data);
              });
              this.$store
                .dispatch({
                  type: 'section:sections',
                  data: { vendorId: data.Id }
                })
                .finally(() => {
                  this.$store
                    .dispatch({
                      type: 'reservation:tables',
                      data: { vendorId: data.vendorId }
                    })
                    .finally(() => {
                      this.$store.dispatch({
                        type: 'reservation:reservationsDay',
                        data: {
                          vendorId: data.vendorId,
                          month: this.selectedMonth
                        }
                      });
                      this.$store
                        .dispatch({
                          type: 'reservation:reservations',
                          data: {
                            vendorId: data.vendorId,
                            date: this.selectedDate
                          }
                        })
                        .finally(() => {
                          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false, typeMessage: 4 });
                        });
                    });
                });
            }
          }).catch((err) => {
            console.log(err);
          });
        setInterval(function () {
          this.show = false;
        }, 5000);
      });
    }
  },
  watch: {
    user () {
      verifyUser(this.user);
    },
    getStatusOnline () {
      if (this.getStatusOnline) {
        this.internetIn = true;
        setTimeout(() => {
          const vendorUrl = this.subDomain();
          this.getDataVendor(vendorUrl);
          this.internetIn = false;
        }, 2000);
      }
    },
    groupUrlVendor () {
      const vendorUrl = this.subDomain();
      this.getDataVendor(vendorUrl);
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 4 });
    }
  },
  destroyed () {
    clearInterval(this.interval);
    this.interval = null;
    clearInterval(this.intervalMsg);
    this.intervalMsg = null;
    clearInterval(this.intervalGlobal);
    this.intervalGlobal = null;
  }
};
</script>
<style lang="scss">
.notification-offline {
  position: absolute;
  z-index: 9999;
  &.notifications {
    width: 100%;
    text-align: center;
    top: 0;
  }
  &.notification {
    text-align: center;
  }
}

#actualizar {
  z-index: 10;
  .message {
    background-color: rgba(212, 237, 219, 1) !important;
    .message-body {
      font-family: helvetica;
      color: rgba(35, 97, 48, 1);
    }
  }
}

#desconectado {
  .message {
    width: 50%;
    background-color: rgb(232, 123, 107) !important;
    max-width: 600px !important;
    min-height: 140px !important;
    .message-body {
      color: rgb(173, 56, 14);
    }
  }
}

#online {
  .message {
    width: 50%;
    max-width: 400px !important;
    min-height: 120px !important;
  }
  .text {
    text-align: center;
    font-size: 17px;
  }
}

.message {
  width: 70%;
  min-height: 170px;
  min-width: 364px;
  max-width: 925px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.3);
  animation: 1s noti-down;
  a {
    text-decoration: none !important;
  }
  .message-body {
    border-style: none;
  }
}

.noti-tittle {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
  &.margin-bottom {
    margin-bottom: 10px;
  }
  &.center {
    text-align: center;
  }
}

hr {
  background-color: rgba(0, 0, 0, 0.03) !important;
  margin: 16px 0 !important;
}

.btn-noti {
  text-align: right;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: -0.4px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.icon-mks {
  &.refresh {
    transform: rotateY(180deg);
    background: rgba(35, 97, 48, 1);
    height: 18px;
    width: 18px;
  }
}

@keyframes noti-down {
  0% {
    transform: translate(-50%, -500px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

#modalMsgPay {
  .animation-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#modalMsgGlobal {
  .animation-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.times-right {
  position: absolute;
  right: 15px;
  top: 10px;
}
.content-msgPay {
  width: 500px;
  min-height: 300px;
  height: auto;
  background-color: rgb(224, 34, 42);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  &-title {
    display: flex;
    justify-content: center;
    letter-spacing: 0px;
    &-center {
      text-align: center;
    }
  }
  .f20 {
    font: normal normal normal 20px/23px Comfortaa;
  }
  .f25 {
    font: normal normal bold 25px/28px Comfortaa;
  }
  .f25-l {
    font: normal normal 300 25px/28px Comfortaa;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .content-msgPay {
    width: 320px;
    height: auto;
    min-height: 180px;
    .f20 {
      font: normal normal normal 15px/17px Comfortaa;
    }
    .f25 {
      font: normal normal bold 15px/17px Comfortaa;
    }
    .f25-l {
      font: normal normal 300 15px/17px Comfortaa;
    }
    .mb-15 {
      margin-bottom: 15px;
    }
  }
}
</style>
