<template>
  <div id="Cronology" :style="{'--screenHeight':screenSize+'px'}">
    <transition name="fade">
      <div
        v-show="isLoading || mapLoading"
        :backgroundColor="'#fafafa'"
        class="Loading-big-box-2 timeLine"
      >
        <div class="icon-mks icon_reservations loading rotating"></div>
      </div>
    </transition>
    <Header  @recents="filterRecents" @filterByName="SetnameTofilter" @mealTime="setMealTime" :coincidences="rawList" :modalOpenFromMap="modalOpenFromMap" :filterActivate="filterActivate"/>
    <div class="space" id="space" :style="{left:this.startPosition+'px'}"></div>
    <section ref="mainContainerz" id="mainContainer" class="mainContainer" :class="{modalOpen:modalOpenFromMap}" @scroll.self.passive="scrollChange">
      <TablesContainer @extraRows="(extraRowsObject)=>extraRows=extraRowsObject"  @blockedHours="blockedHours" :modalOpenFromMap="modalOpenFromMap" :zones="Zones" @tablesInfo="getTablesWidthZise" @tablesOrderByZoneInfo="(e)=>tablesOrderByZoneInfo=e" @reload="getReservations" @tableSelected="(e)=>tableModalOpen=e" @hideZone="(value)=>hideZones=value" :lastScroll="lastScroll"/>
      <div id="mapContainer" class="mapContainer" :class={modalOpenFromMap:modalOpenFromMap}>
        <div id="scrollXContainer" class="xd" ref="scrollContainer" @scroll.self.passive="scrollChange "  :class={modalOpenFromMap:modalOpenFromMap}>
        <Map :extraRowsObject="extraRows" @loading="(e)=>mapLoading=e" @openModalFromMap="(e)=>modalOpenFromMap=e" :lastScroll="lastScroll" :totalHours="totalHours" :hoursAvailable="hoursForMap" :allTables="allTables" :reservationList="reservationList" :tableModalOpen="tableModalOpen" :hideZones="hideZones" :tablesOrderByZoneInfo="tablesOrderByZoneInfo" :nameTofilter="nameTofilter" :lastScrollY="lastScrollY" :lastScrollX="lastScrollX" :minutesBetweenTurns="minutesBetweenTurns"/></div>
      </div>
    </section>
    <div class="overlay"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import Map from './Map';
import Header from './Header.vue';
import TablesContainer from './TablesContainer.vue';
import serviceReservation from '@/helpers/apis/serviceReservation';
import moment from 'moment-timezone';
export default {
  props: ['filterActivate'],
  components: {
    Header,
    TablesContainer,
    Map
  },
  data () {
    return {
      extraRows: {},
      hideZones: [],
      Zones: [],
      allTables: [],
      hoursForMap: null,
      reservationList: [],
      filter: '',
      tableModalOpen: false,
      totalHours: 0,
      tablesOrderByZoneInfo: [],
      recents: true,
      nameTofilter: '',
      mealTime: ['Desayuno', 'Almuerzo', 'Cena'],
      lastScroll: 0,
      lastScrollY: 0,
      lastScrollX: 0,
      modalOpenFromMap: false,
      rawList: [],
      isLoading: false,
      mapLoading: true,
      dateUsedInMap: this.selectedDate,
      throttled: false,
      screenSize: '',
      minutesBetweenTurns: 30,
      mapSize: 0,
      startPosition: 0,
      resizeObserver: null,
      resizeObserverTwo: null
    };
  },
  beforeMount () {
    var bodyEl = document.body;
    // eslint-disable-next-line no-undef
    classie.remove(bodyEl, 'show-right-bar');
    // eslint-disable-next-line no-undef
    classie.add(bodyEl, 'cronology');
    this.$store.commit(reservationTypes.mutations.setFromTimeLine, true);
  },
  mounted () {
    let timerScroll;
    this.screenSize = window.innerHeight;
    this.resizeObserver = new ResizeObserver(() => {
      const element = document.getElementById('mainContainer');
      if (element) {
        this.startPosition = element.offsetLeft;
      }
    });
    window.addEventListener('resize', () => {
      this.screenSize = window.innerHeight;
    });
    window.addEventListener('orientationchange', () => {
      this.screenSize = window.innerHeight;
    });
    const element = document.getElementById('mainContainer');
    if (element) {
      this.resizeObserver.observe(element);
      this.startPosition = document.getElementById('mainContainer').offsetLeft;
      document.getElementById('mainContainer').addEventListener('scroll', function (e) {
        clearTimeout(timerScroll);
        timerScroll = setTimeout(() => {
          const modal = document.getElementById('modalForTable');
          const extraReservationModal = document.getElementById('extraInfoModal');
          const itemModal = document.getElementById('modalOfItem');
          if (modal) {
            const scrollPoint = parseInt(getComputedStyle(document.getElementById('Cronology')).getPropertyValue('--lastScrollY').slice(0, -2));
            modal.style.transform = `translateY(${scrollPoint - e.target.scrollTop}px)`;
          }
          if (extraReservationModal) {
            const scrollPoint = parseInt(getComputedStyle(document.getElementById('Cronology')).getPropertyValue('--lastScrollYForExtraModal'));
            extraReservationModal.style.transform = `translateY(${scrollPoint - e.target.scrollTop}px)`;
          }
          if (itemModal) {
            const scrollPoint = parseInt(getComputedStyle(document.getElementById('Cronology')).getPropertyValue('--lastScrollYmodalOfItem').slice(0, -2));
            itemModal.style.transform = `translateY(${scrollPoint - e.target.scrollTop}px)`;
          }
          this.lastScroll = e.target.scrollTop;
          this.lastScrollY = e.target.scrollTop;
          // Aquí puedes llamar a la función o realizar las acciones deseadas
        }, { passive: true });
      }, 50);
    }
    this.getReservations();
    this.getTimesAvailabilityList();
    this.reservationList = this.getReservationList();
    if (window.innerWidth <= 600) {
      this.selfWidth = document.getElementById('mainContainer').offsetWidth - 140;
    } else {
      this.selfWidth = document.getElementById('mainContainer').offsetWidth - 160;
    }
  },
  methods: {
    blockedHours () {
      this.reservationList = this.getReservationList();
    },
    getReservationList () {
      const filter = this.filter;
      const arrayOfReservationList = this.reservations[this.selectedDate] ? [...this.reservations[this.selectedDate]] : [];
      const list = arrayOfReservationList
        .sort(reservation => (parseInt(reservation.date)))
        .filter(reservation => {
          let returnable = true;
          switch (filter) {
            case 'active':
              returnable = reservation.codeStatus === 1 || reservation.codeStatus === 0 || reservation.codeStatus === 7;
              break;
            case 'finish':
              returnable = reservation.codeStatus === 4 || reservation.codeStatus === 5 || reservation.codeStatus === 3;
              break;
            case 'partialSeated':
              returnable = reservation.codeStatus === 6;
              break;
            case 'seated':
              returnable = reservation.codeStatus === 2;
              break;
          }
          return (
            returnable &&
              (!this.selectedDates.length ||
                (this.selectedDates.indexOf(this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss'))))
          );
        });
      list.map(reservation => {
        return reservation.groupBy;
      });
      let listFiltered = [];
      if (this.recents) {
        listFiltered = list.filter(reservation => !reservation.isFinish && reservation.status !== 'Cancelada' && reservation.status !== 'No Llego');
      } else {
        listFiltered = list.filter(reservation => reservation.isFinish || reservation.status === 'Cancelada' || reservation.status === 'No Llego');
      }
      let desayunoHours;
      let almuerzoHours;
      let cenaHours;
      const dayOfWeek = this.$moment(this.selectedDate).format('d');
      if (this.turns.desayuno) {
        desayunoHours = this.turns.desayuno[dayOfWeek] ? this.turns.desayuno[dayOfWeek][0].times : null;
      } else {
        desayunoHours = null;
      }
      if (this.turns.almuerzo) {
        almuerzoHours = this.turns.almuerzo[dayOfWeek] ? this.turns.almuerzo[dayOfWeek][0].times : null;
      } else {
        almuerzoHours = null;
      }
      if (this.turns.cena) {
        cenaHours = this.turns.cena[dayOfWeek] ? this.turns.cena[dayOfWeek][0].times : null;
      } else {
        cenaHours = null;
      }
      if ((this.mealTime.includes('Cena') && this.mealTime.includes('Almuerzo') && this.mealTime.includes('Desayuno')) || (this.mealTime.includes('Almuerzo') && this.mealTime.includes('Cena') && !desayunoHours)) {
      } else if (this.mealTime.includes('Cena') && this.mealTime.includes('Almuerzo')) {
        listFiltered = listFiltered.filter((reservation) => {
          const formattedDate = this.$moment(parseInt(reservation.date)).tz(this.timezone).format('HH:mm');
          return almuerzoHours.includes(formattedDate) || cenaHours.includes(formattedDate);
        });
      } else if (this.mealTime.includes('Cena') && this.mealTime.includes('Desayuno')) {
        listFiltered = listFiltered.filter((reservation) => {
          const formattedDate = this.$moment(parseInt(reservation.date)).tz(this.timezone).format('HH:mm');
          return desayunoHours.includes(formattedDate) || cenaHours.includes(formattedDate);
        });
      } else if (this.mealTime.includes('Almuerzo') && this.mealTime.includes('Desayuno')) {
        listFiltered = listFiltered.filter((reservation) => {
          const formattedDate = this.$moment(parseInt(reservation.date)).tz(this.timezone).format('HH:mm');
          return desayunoHours.includes(formattedDate) || almuerzoHours.includes(formattedDate);
        });
      } else if (this.mealTime.includes('Almuerzo')) {
        listFiltered = listFiltered.filter((reservation) => {
          const formattedDate = this.$moment(parseInt(reservation.date)).tz(this.timezone).format('HH:mm');
          return almuerzoHours.includes(formattedDate);
        });
      } else if (this.mealTime.includes('Cena')) {
        listFiltered = listFiltered.filter((reservation) => {
          const formattedDate = this.$moment(parseInt(reservation.date)).tz(this.timezone).format('HH:mm');
          return cenaHours.includes(formattedDate);
        });
      } else if (this.mealTime.includes('Desayuno')) {
        listFiltered = listFiltered.filter((reservation) => {
          const formattedDate = this.$moment(parseInt(reservation.date)).tz(this.timezone).format('HH:mm');
          return desayunoHours.includes(formattedDate);
        });
      }
      this.rawList = listFiltered;
      const reservationsOrderByTable = {};
      listFiltered.forEach((reservation) => {
        const reservationWithSquare = { ...reservation };
        reservationWithSquare.squareHour = this.$moment(parseInt(reservation.date)).tz(this.timezone).format('hh:mm a');
        const tableId = reservationWithSquare.tableId;
        if (tableId.includes(',')) {
          this.orderReservationWithMultipleTablesByArray(tableId, reservationsOrderByTable, reservationWithSquare);
        } else {
          this.orderReservationWithSingleTableByArray(tableId, reservationsOrderByTable, reservationWithSquare);
        }
      });
      return { ...reservationsOrderByTable };
    },
    getReservations () {
      const data = {
        vendorId: this.idVendor,
        date: this.selectedDate
      };
      serviceReservation.post('reservation/reservationsTimeline', data).then(response => {
        this.Zones = response.data.zones;
      }).catch(err => {
        console.error(err);
      });
    },
    isConfigRotation (reservation) {
      let response = null;
      const dayOfWeek = this.$moment(parseInt(reservation.date)).format('d');
      if (this.params.rotationTime) {
        response = this.params.rotationTime;
      }
      if (Object.values(this.rotation).length > 0) {
        this.rotation.map(item => {
          if (item.dayOfWeek == null && item.people != null) {
            if (reservation.people === item.people) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people != null) {
            if (reservation.people === item.people && item.dayOfWeek === dayOfWeek) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people == null) {
            if (item.dayOfWeek === dayOfWeek) {
              response = item.rotationTime;
            }
          } else if (reservation.sectionId === item.zoneId + '') {
            response = item.rotationTime;
          }
        });
      }
      return response ? moment.duration(response).hours() : 1;
    },
    getTimesAvailabilityList () {
      this.dateUsedInMap = this.selectedDate;
      this.dates = {};
      const datesFinal = [];
      const m = this.monthConvert(this.$moment(this.selectedDate).format('M'));
      this.m = this.monthConvert(this.$moment(this.selectedDate).format('M'));
      const y = this.$moment(this.selectedDate).format('YYYY');
      this.y = this.$moment(this.selectedDate).format('YYYY');
      const d = this.$moment(this.selectedDate).format('DD');
      this.d = this.$moment(this.selectedDate).format('DD');
      const dayOfWeek = this.$moment(this.selectedDate).format('d');
      this.dayOfWeek = this.$moment(this.selectedDate).format('d');
      const $moment = this.$moment;
      if (this.timesDashboard.extraPublic.length > 0) {
        if (this.timesDashboard.extraPublic[this.selectedDate] !== undefined) {
          this.timesDashboard.extraPublic[this.selectedDate].forEach(function (element) {
            const hora = element.hora;
            const horas = hora.split(':');
            const dat = new Date(Date.UTC(y, m, d, horas[0], horas[1], 0, 0));
            const dates = $moment.utc(dat).format('YYYY-MM-DD HH:mm:ss');
            datesFinal.push({ dateComplete: dates, date: parseInt($moment(dates).format('x')) });
          });
        }
      } else if (this.timesDashboard[dayOfWeek].length > 0) {
        this.timesDashboard[dayOfWeek].forEach(function (element) {
          const hora = element.hora;
          const horas = hora.split(':');
          const dat = new Date(Date.UTC(y, m, d, horas[0], horas[1], 0, 0));
          const dates = $moment.utc(dat).format('YYYY-MM-DD HH:mm:ss');
          datesFinal.push({ dateComplete: dates, date: parseInt($moment(dates).format('x')) });
        });
      } else if (this.timesDashboard[dayOfWeek].length === 0) {
        alert('Recuerda que para ver la linea del tiempo correctamente debes poner un dia con disponibilidad de horarios');
        return;
      }
      datesFinal.sort((a, b) => {
        const dateA = a.date;
        const dateB = b.date;
        return dateA - dateB;
      });
      const firstDate = datesFinal[0];
      const lastDate = datesFinal[datesFinal.length - 1];
      this.minutesBetweenTurns = this.$moment(datesFinal[1].date).diff(this.$moment(datesFinal[0].date), 'minutes');
      let dateInWhile = firstDate;
      this.hoursForMap = [];
      while (dateInWhile.date < lastDate.date) {
        let date;
        if (datesFinal[0] === dateInWhile) {
          date = $moment(dateInWhile.date);
        } else {
          date = $moment(dateInWhile.date).add(30, 'minutes');
        }
        const newDate = { date: parseInt(date.format('x')), dateComplete: date.format('YYYY-MM-DD HH:mm:ss') };
        dateInWhile = newDate;
        this.hoursForMap.push(newDate);
      }
      this.setResize();
    },
    getTablesWidthZise (tableWidthSizes) {
      this.allTables = tableWidthSizes;
    },
    setResize () {
      this.resizeObserverTwo = new ResizeObserver((entries) => {
        const todayHour = parseInt(this.$moment().format('x'));
        const firstHour = parseInt(this.$moment(this.hoursForMap[0].dateComplete).format('x'));
        const totalTimePassed = todayHour - firstHour;
        const seconds = Math.floor(totalTimePassed / 1000);
        const minutes = Math.floor(seconds / 60);
        let sizeForSqueares;
        if (window.innerWidth < 600) {
          sizeForSqueares = minutes * 2.28;
        } else {
          sizeForSqueares = minutes * 2.66667 + 38.5;
        }
        if (entries[0]) {
          document.getElementById('virtualScrollMap').scrollLeft = sizeForSqueares;
          document.getElementById('scrollXContainer').scrollLeft = sizeForSqueares;
        }
      });

      this.resizeObserverTwo.observe(this.$refs.scrollContainer);
    },
    filterRecents (value) {
      this.recents = value;
      this.reservationList = this.getReservationList();
    },
    scrollChange (e) {
      clearTimeout(this.throttled);
      this.throttled = setTimeout(() => {
        this.lastScroll = e.timeStamp;
      }, 80);
    },

    SetnameTofilter (value) {
      this.nameTofilter = value ? value.toLowerCase() : '';
      this.reservationList = this.getReservationList();
    },
    setMealTime (value) {
      this.mealTime = value;
      this.reservationList = this.getReservationList();
    },
    orderReservationWithMultipleTablesByArray (tableId, reservationsOrderByTable, reservationWithSquare) {
      const $moment = this.$moment;
      const idByOne = tableId.split(', ');
      idByOne.forEach(id => {
        const hour = reservationWithSquare.squareHour;
        const hourType = hour[6] + hour[7];
        const hours = hourType === 'pm' && parseInt(hour[0] + hour[1]) < 12 ? parseInt(hour[0] + hour[1]) + 12 : parseInt(hour[0] + hour[1]);
        const minutes = parseInt(hour[3] + hour[4]);
        const rotation = this.isConfigRotation(reservationWithSquare);
        const date = this.$moment(this.dateComplete).set({ hour: hours, minute: minutes }).format('x');
        const dateEnd = this.$moment(this.dateComplete).set({ hour: hours, minute: minutes }).add(rotation, 'hours').format('x');
        if (reservationsOrderByTable[id]) {
          let done = false;
          for (const hourKey in reservationsOrderByTable[id]) {
            if ((parseInt(reservationsOrderByTable[id][hourKey].date) < parseInt(dateEnd)) && (parseInt(date) < parseInt(reservationsOrderByTable[id][hourKey].dateEnd)) &&
             (id === reservationsOrderByTable[id][hourKey].table)) {
              if (parseInt(date) < parseInt(reservationsOrderByTable[id][hourKey].date)) {
                reservationsOrderByTable[id][hourKey].hourInObject = $moment(parseInt(date)).format('hh:mm a');
                reservationsOrderByTable[id][hourKey].date = date;
                reservationWithSquare.hoursUsed = rotation;
                reservationsOrderByTable[id][hourKey].reservations.push(reservationWithSquare);
                reservationsOrderByTable[id][$moment(parseInt(date)).format('hh:mm a')] = { ...reservationsOrderByTable[id][hourKey] };
                reservationsOrderByTable[id][hourKey].dateEnd = dateEnd;
                delete reservationsOrderByTable[id][hourKey];
              } else {
                reservationWithSquare.hoursUsed = rotation;
                reservationsOrderByTable[id][hourKey].reservations.push(reservationWithSquare);
                reservationsOrderByTable[id][hourKey].dateEnd = dateEnd;
              }
              done = true;
              break;
            }
          }
          if (!done) {
            reservationWithSquare.hoursUsed = rotation;
            const blockObject = {
              date,
              hoursUsed: [rotation],
              dateEnd,
              table: id,
              hourInObject: hour,
              reservations: [reservationWithSquare]
            };
            reservationsOrderByTable[id][hour] = blockObject;
          }
        } else {
          reservationWithSquare.hoursUsed = rotation;
          const blockObject = {
            date,
            hoursUsed: [rotation],
            dateEnd,
            table: id,
            hourInObject: hour,
            reservations: [reservationWithSquare]
          };
          reservationsOrderByTable[id] = { [hour]: blockObject };
        }
      });
    },
    orderReservationWithSingleTableByArray (tableId, reservationsOrderByTable, reservationWithSquare) {
      const hour = reservationWithSquare.squareHour;
      const hourType = hour[6] + hour[7];
      const hours = hourType === 'pm' && parseInt(hour[0] + hour[1]) < 12 ? parseInt(hour[0] + hour[1]) + 12 : parseInt(hour[0] + hour[1]);
      const minutes = parseInt(hour[3] + hour[4]);
      const rotation = this.isConfigRotation(reservationWithSquare);
      const date = this.$moment(this.dateComplete).set({ hour: hours, minute: minutes }).format('x');
      const dateEnd = this.$moment(this.dateComplete).set({ hour: hours, minute: minutes }).add(rotation, 'hours').format('x');

      if (reservationsOrderByTable[tableId]) {
        let done = false;
        for (const hourKey in reservationsOrderByTable[tableId]) {
          if ((parseInt(reservationsOrderByTable[tableId][hourKey].date) < parseInt(dateEnd)) && (parseInt(date) < parseInt(reservationsOrderByTable[tableId][hourKey].dateEnd)) &&
             (tableId === reservationsOrderByTable[tableId][hourKey].table)) {
            if (parseInt(date) < parseInt(reservationsOrderByTable[tableId][hourKey].date)) {
              reservationsOrderByTable[tableId][hourKey].hourInObject = this.$moment(parseInt(date)).format('hh:mm a');
              reservationsOrderByTable[tableId][hourKey].date = date;
              reservationWithSquare.hoursUsed = rotation;
              reservationsOrderByTable[tableId][hourKey].reservations.push(reservationWithSquare);
              reservationsOrderByTable[tableId][this.$moment(parseInt(date)).format('hh:mm a')] = { ...reservationsOrderByTable[tableId][hourKey] };
              reservationsOrderByTable[tableId][hourKey].dateEnd = dateEnd;
              delete reservationsOrderByTable[tableId][hourKey];
            } else {
              reservationWithSquare.hoursUsed = rotation;
              reservationsOrderByTable[tableId][hourKey].reservations.push(reservationWithSquare);
              reservationsOrderByTable[tableId][hourKey].dateEnd = dateEnd;
            }
            done = true;
            break;
          }
        }
        if (!done) {
          reservationWithSquare.hoursUsed = rotation;
          const blockObject = {
            date,
            dateEnd,
            table: tableId,
            hourInObject: hour,
            reservations: [reservationWithSquare]

          };
          reservationsOrderByTable[tableId][hour] = blockObject;
        }
      } else {
        reservationWithSquare.hoursUsed = rotation;
        const blockObject = {
          date,
          dateEnd,
          table: tableId,
          hourInObject: hour,
          reservations: [reservationWithSquare]
        };
        reservationsOrderByTable[tableId] = { [hour]: { ...blockObject } };
      }
    }
  },
  beforeDestroy () {
    var bodyEl = document.body;
    // eslint-disable-next-line no-undef
    classie.add(bodyEl, 'show-right-bar');
    // eslint-disable-next-line no-undef
    classie.remove(bodyEl, 'cronology');
    this.$store.commit(reservationTypes.mutations.setFromTimeLine, false);
    window.removeEventListener('resize', () => {
      this.screenSize = window.innerHeight;
    });
    window.removeEventListener('orientationchange', () => {
      this.screenSize = window.innerHeight;
    });
    if (this.resizeObserver) {
      const element = document.getElementById('mainContainer');
      if (element) {
        this.resizeObserver.unobserve(element);
      }
    }
    if (this.resizeObserverTwo) {
      this.resizeObserverTwo.disconnect();
    }
  },
  computed: {
    ...mapGetters({
      turns: reservationTypes.getters.theTurns
    }),
    dateFromCalendarSelected () {
      return this.$store.getters['reservation:selectedDate'];
    }
  },
  watch: {
    reservations () {
      if (this.dateUsedInMap !== this.selectedDate) {
        this.getTimesAvailabilityList();
      }
      this.getReservations();
      this.reservationList = this.getReservationList();
    },
    modalOpenFromMap (value) {
      if (value) {
        const scroll = this.$refs.scrollContainer.scrollLeft;
        this.$refs.mainContainerz.scrollLeft = scroll;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
 #Cronology{
  z-index: 11;
      padding-top: unset;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      @media screen and (min-width:800px) and (max-width:1100px) {
        max-height: calc(100vh - 55px);
      }
      @media screen and (max-width:800px) {
        max-height: calc(100vh - 115px);
      }
    @media screen and (max-width:600px) {
      padding: unset;
      max-height: calc(100vh - 90px);
    }

    background-color: white;
    width: 100%;
    overflow: hidden;
 }
 .mapContainer{
  max-width: calc(100% - 155px);
  height: fit-content;
    @media screen and (max-width:600px) {
  max-width: calc(100% - 105px);
  }
  .xd{
&::-webkit-scrollbar {
  display: none;
  height: 0px;
  width: 0px;
}

/* Track */
&::-webkit-scrollbar-track {
background: #a8adb55f !important;
border-radius: 3px;
}

/* Handle */
&::-webkit-scrollbar-thumb {
    box-sizing: border-box;
  background-color: #606773 !important;
border-radius: 8px !important;
border: unset !important;
border: solid 2px rgb(223 224 227) !important;
box-shadow: unset !important;
outline: unset;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
    overflow-x: scroll;
    overflow-y: hidden;
    height: fit-content;

    &.modalOpenFromMap{
    min-height: 260px;
    }
  }
        &::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;

}
 }
 .mainContainer{
    display: flex;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x:hidden;
    max-height: calc(var(--screenHeight) - 145px);
    min-height: calc(var(--screenHeight) - 145px);
    position: relative;
    z-index: 10;
    margin: auto;
    margin-top: unset;
    max-width: calc(100% - 26px);
    scroll-behavior: smooth;
          @media screen and (min-width:800px) and (max-width:1100px) {
      max-width: calc(100% - 26px);
    max-height: calc(var(--screenHeight) - 155px);
    min-height: calc(var(--screenHeight) - 155px);
    }
          @media screen and (max-width:800px) {
      max-width: calc(100% - 26px);
    max-height: calc(var(--screenHeight) - 195px);
    min-height: calc(var(--screenHeight) - 195px);
    }
          @media screen and (max-width:600px) {
             max-width: 98%;
             min-height:calc(var(--screenHeight) - 165px) ;
            max-height:calc(var(--screenHeight) - 165px) ;
    }

        &::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
    @media screen and (max-width:600px) {
    width: 6px !important;
  }

}

/* Track */
&::-webkit-scrollbar-track {
background: #a8adb55f !important;
border-radius: 3px;
}

/* Handle */
&::-webkit-scrollbar-thumb {
    box-sizing: border-box;
  background-color: #606773 !important;
border-radius: 8px !important;
border: unset !important;
border: solid 2px rgb(223 224 227) !important;
box-shadow: unset !important;
outline: unset;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
 }
 .Loading-big-box-2 {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
 }
 .Loading-big-box-2 .loading {
    width: 100px;
    height: 100px;
    background: rgba(67, 74, 86, 0.8);
    margin-bottom: 40px;
  }
 .Loading-big-box-2.timeLine{
  position: absolute;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: white;
  max-height: 100vh;
  z-index: 10000000000000000000;
 }
 .modalOpenFromMap{
  z-index: 35 !important;
  // @media screen and (max-width:600px) {
  //       overflow: visible !important;
  // }
 }
.space{
  background-color: transparent;
  position: relative;
  z-index: 9;
  height: 27px;
    width: 100%;
    display: flex;
    @media screen and (max-width:600px) {
      height: 22px;
    }
}
</style>
