<template>
  <div>
    <div>
      <div
        :class="{mobileSchedule:screenSizeW <= 600}"
        :style="{'--heightM':screenSizeH - 139+'px'}"
        class="card card_schedule"
        v-if="(typeSchedule !== 2  && typeSchedule !==3 && typeSchedule !==4 && typeSchedule !== 5 && typeSchedule !== 15 && typeSchedule !== 16)"
      >
        <div class="card-content card_schedule_content">
          <div class="content">
            <div class="columns is-gapless is-multiline" v-if="typeSchedule == 0">
              <label
                v-if="
                  schedulesGeneral.public == null ||
                  schedulesGeneral.public == undefined
                "
              >No hay horarios agregados</label>
              <div
                :key="index"
                class="column is-half columns_cards_schedule"
                v-for="(item, index) in getValuesArray(schedulesGeneral.public)"
              >
                <div class="card card_info" v-if="item.type == 'public'">
                  <div class="card-content">
                    <h5 class="titulo_card">INTERVALO DE {{ item.interval }} MINUTOS</h5>
                    <div class="content_card">
                      <div class="columns_card">
                        <div class="first_card">
                          <b-field>
                            <b-checkbox
                              :id="'checkbox' + item.id"
                              @input="pushDelete(item)"
                              class="checkbox_info"
                              type="is-info"
                              v-model="item.selected"
                            ></b-checkbox>
                          </b-field>
                        </div>
                        <div class="middle_card days_hours_schedule">
                          <button class="button_days">
                            <div>
                              <i class="icon-mks black-pre icon_days_schedule"></i>
                              <label>
                                {{
                                weekdays[item.dayOfWeek]
                                | capitalize({ onlyFirstLetter: true })
                                }}
                              </label>
                            </div>
                          </button>
                          <button class="button_hours">
                            <div>
                              <i class="icon-mks white icon_hours_schedule"></i>
                              <div>
                                <label>
                                  {{
                                  new Date(item.open)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                  a
                                  <br />
                                  {{
                                  new Date(item.close)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                </label>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="end_card edit_delete_schedule">
                        <label @click="deleteSchedule(item)" class="label_info">
                          <div class="icons_info_schedules_delete">
                            <i class="icon-mks white icon_delete_info_schedule"></i>
                          </div>Eliminar
                        </label>
                        <label @click="editSchedule(item, schedulesGeneral.public, schedulesGeneral.dateAtention, schedulesGeneral)" class="label_info">
                          <div class="icons_info_schedules_edit">
                            <i class="icon-mks white icon_edit_info_schedule"></i>
                          </div>Editar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-gapless is-multiline" v-if="typeSchedule == 1">
              <label
                v-if="
                  schedulesGeneral.free == null ||
                  schedulesGeneral.free == undefined
                "
              >No hay horarios agregados</label>
              <div
                :key="index"
                class="column is-half columns_cards_schedule"
                v-for="(item, index) in getValuesArray(schedulesGeneral.free)"
              >
                <div class="card card_info" v-if="item.type == 'free'">
                  <div class="card-content">
                    <h5 class="titulo_card">INTERVALO DE {{ item.interval }} MINUTOS</h5>
                    <div class="content_card">
                      <div class="columns_card">
                        <div class="first_card">
                          <b-field>
                            <b-checkbox
                              :id="'checkbox' + item.id"
                              @input="pushDelete(item)"
                              class="checkbox_info"
                              type="is-info"
                              v-model="item.selected"
                            ></b-checkbox>
                          </b-field>
                        </div>
                        <div class="middle_card days_hours_schedule">
                          <button class="button_days">
                            <div>
                              <i class="icon-mks black-pre icon_days_schedule"></i>
                              <label>
                                {{
                                weekdays[item.dayOfWeek]
                                | capitalize({ onlyFirstLetter: true })
                                }}
                              </label>
                            </div>
                          </button>
                          <button class="button_hours">
                            <div>
                              <i class="icon-mks white icon_hours_schedule"></i>
                              <div>
                                <label>
                                  {{
                                  new Date(item.open)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                  a
                                  <br />
                                  {{
                                  new Date(item.close)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                </label>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="end_card edit_delete_schedule">
                        <label @click="deleteSchedule(item)" class="label_info">
                          <div class="icons_info_schedules_delete">
                            <i class="icon-mks white icon_delete_info_schedule"></i>
                          </div>Eliminar
                        </label>
                        <label
                          @click="editSchedule(item, schedulesGeneral.public, schedulesGeneral.dateAtention, schedulesGeneral)"
                          class="label_info"
                        >
                          <div class="icons_info_schedules_edit">
                            <i class="icon-mks white icon_edit_info_schedule"></i>
                          </div>Editar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-gapless is-multiline" v-if="typeSchedule == 6">
              <label
                v-if="
                  schedulesGeneral.dateFree == null ||
                  schedulesGeneral.dateFree == undefined
                "
              >No hay horarios agregados</label>
              <div
                :key="index"
                class="column is-half columns_cards_schedule"
                v-for="(item, index) in schedulesGeneral.dateFree"
              >
                <div class="card card_info" v-if="item.type == 'dateFree'">
                  <div class="card-content">
                    <h5 class="titulo_card">INTERVALO DE {{ item.interval }} MINUTOS</h5>
                    <div class="content_card">
                      <div class="columns_card">
                        <div class="first_card">
                          <b-field>
                            <b-checkbox
                              :id="'checkbox' + item.id"
                              @input="pushDelete(item)"
                              class="checkbox_info"
                              type="is-info"
                              v-model="item.selected"
                            ></b-checkbox>
                          </b-field>
                        </div>
                        <div class="middle_card days_hours_schedule">
                          <button class="button_days">
                            <div>
                              <i class="icon-mks black-pre icon_days_schedule"></i>
                              <label>{{ item.fecha }}</label>
                            </div>
                          </button>
                          <button class="button_hours">
                            <div>
                              <i class="icon-mks white icon_hours_schedule"></i>
                              <div>
                                <label>
                                  {{
                                  new Date(item.open)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                  a
                                  <br />
                                  {{
                                  new Date(item.close)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                </label>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="end_card edit_delete_schedule">
                        <label @click="deleteSchedule(item)" class="label_info">
                          <div class="icons_info_schedules_delete">
                            <i class="icon-mks white icon_delete_info_schedule"></i>
                          </div>Eliminar
                        </label>
                        <label @click="editSchedule(item, schedulesGeneral.public, schedulesGeneral.dateAtention, schedulesGeneral)" class="label_info">
                          <div class="icons_info_schedules_edit">
                            <i class="icon-mks white icon_edit_info_schedule"></i>
                          </div>Editar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-gapless is-multiline" v-if="typeSchedule == 7">
              <label
                v-if="
                  schedulesGeneral.dateAtention == null ||
                  schedulesGeneral.dateAtention == undefined
                "
              >No hay horarios agregados</label>
              <div
                :key="index"
                class="column is-half columns_cards_schedule"
                v-for="(item, index) in schedulesGeneral.dateAtention"
              >
                <div class="card card_info" v-if="item.type == 'dateAtention'">
                  <div class="card-content">
                    <h5 class="titulo_card">INTERVALO DE {{ item.interval }} MINUTOS</h5>
                    <div class="content_card">
                      <div class="columns_card">
                        <div class="first_card">
                          <b-field>
                            <b-checkbox
                              :id="'checkbox' + item.id"
                              @input="pushDelete(item)"
                              class="checkbox_info"
                              type="is-info"
                              v-model="item.selected"
                            ></b-checkbox>
                          </b-field>
                        </div>
                        <div class="middle_card days_hours_schedule">
                          <button class="button_days">
                            <div>
                              <i class="icon-mks black-pre icon_days_schedule"></i>
                              <label>{{ item.fecha }}</label>
                            </div>
                          </button>
                          <button class="button_hours">
                            <div>
                              <i class="icon-mks white icon_hours_schedule"></i>
                              <div>
                                <label>
                                  {{
                                  new Date(item.open)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                  a
                                  <br />
                                  {{
                                  new Date(item.close)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                </label>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="end_card edit_delete_schedule">
                        <label @click="deleteSchedule(item)" class="label_info">
                          <div class="icons_info_schedules_delete">
                            <i class="icon-mks white icon_delete_info_schedule"></i>
                          </div>Eliminar
                        </label>
                        <label @click="editSchedule(item, schedulesGeneral.public, schedulesGeneral.dateAtention, schedulesGeneral)" class="label_info">
                          <div class="icons_info_schedules_edit">
                            <i class="icon-mks white icon_edit_info_schedule"></i>
                          </div>Editar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-gapless is-multiline" v-if="typeSchedule == 8">
              <label
                v-if="
                  schedulesGeneral.dateZone == null ||
                  schedulesGeneral.dateZone == undefined
                "
              >No hay horarios agregados</label>
              <div
                :key="index"
                class="column is-half columns_cards_schedule"
                v-for="(item, index) in schedulesGeneral.dateZone"
              >
                <div class="card card_info" v-if="item.type == 'dateZone'">
                  <div class="card-content">
                    <h5 class="titulo_card">INTERVALO DE {{ item.interval }} MINUTOS</h5>
                    <div class="content_card">
                      <div class="columns_card">
                        <div class="first_card">
                          <b-field>
                            <b-checkbox
                              :id="'checkbox' + item.id"
                              @input="pushDelete(item)"
                              class="checkbox_info"
                              type="is-info"
                              v-model="item.selected"
                            ></b-checkbox>
                          </b-field>
                        </div>
                        <div class="middle_card days_hours_schedule">
                          <button class="button_days">
                            <div>
                              <i class="icon-mks black-pre icon_days_schedule"></i>
                              <label>
                                {{ item.fecha }}
                                <br />
                                {{
                                nameZone(item.seatingSectionId)
                                }}
                              </label>
                            </div>
                          </button>
                          <button class="button_hours">
                            <div>
                              <i class="icon-mks white icon_hours_schedule"></i>
                              <div>
                                <label>
                                  {{
                                  new Date(item.open)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                  a
                                  <br />
                                  {{
                                  new Date(item.close)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                </label>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="end_card edit_delete_schedule">
                        <label @click="deleteSchedule(item)" class="label_info">
                          <div class="icons_info_schedules_delete">
                            <i class="icon-mks white icon_delete_info_schedule"></i>
                          </div>Eliminar
                        </label>
                        <label @click="editSchedule(item, schedulesGeneral.public, schedulesGeneral.dateAtention, schedulesGeneral)" class="label_info">
                          <div class="icons_info_schedules_edit">
                            <i class="icon-mks white icon_edit_info_schedule"></i>
                          </div>Editar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-gapless is-multiline" v-if="typeSchedule == 9">
              <label
                v-if="
                  schedulesGeneral.zone == null ||
                  schedulesGeneral.zone == undefined
                "
              >No hay horarios agregados</label>
              <div
                :key="index"
                class="column is-half columns_cards_schedule"
                v-for="(item, index) in getValuesArray(schedulesGeneral.zone)"
              >
                <div class="card card_info" v-if="item.type == 'zone'">
                  <div class="card-content">
                    <h5 class="titulo_card">INTERVALO DE {{ item.interval }} MINUTOS</h5>
                    <div class="content_card">
                      <div class="columns_card">
                        <div class="first_card">
                          <b-field>
                            <b-checkbox
                              :id="'checkbox' + item.id"
                              @input="pushDelete(item)"
                              class="checkbox_info"
                              type="is-info"
                              v-model="item.selected"
                            ></b-checkbox>
                          </b-field>
                        </div>
                        <div class="middle_card days_hours_schedule">
                          <button class="button_days">
                            <div>
                              <i class="icon-mks black-pre icon_days_schedule"></i>
                              <label>{{ weekdays[item.dayOfWeek] | capitalize({ onlyFirstLetter: true }) }}</label>
                            </div>
                          </button>
                          <button class="button_hours">
                            <div>
                              <i class="icon-mks white icon_hours_schedule"></i>
                              <div>
                                <label>
                                  {{
                                  new Date(item.open)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                  a
                                  <br />
                                  {{
                                  new Date(item.close)
                                  | moment("timezone", timezone, "h:mm a")
                                  }}
                                </label>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="end_card edit_delete_schedule">
                        <label @click="deleteSchedule(item)" class="label_info">
                          <div class="icons_info_schedules_delete">
                            <i class="icon-mks white icon_delete_info_schedule"></i>
                          </div>Eliminar
                        </label>
                        <label @click="editSchedule(item, schedulesGeneral.public, schedulesGeneral.dateAtention, schedulesGeneral)" class="label_info">
                          <div class="icons_info_schedules_edit">
                            <i class="icon-mks white icon_edit_info_schedule"></i>
                          </div>Editar
                        </label>
                      </div>
                    </div>
                    <div class="container_zone">
                      <label>Zona: {{ item.zones.displayName }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer horarios buttons_list" v-if="typeSchedule !== 2">
          <button @click="removeScheduleArray()">
            <i class="icon-mks white icon_delete_schedule"></i>Eliminar
          </button>
          <button @click="actualizarSection(2)">
            <i class="icon-mks white icon_new_schedule"></i>Nuevo
          </button>
        </div>
      </div>
    </div>
    <div class="card card_schedule_new" v-if="this.scheduleType == 2">
      <div style="margin-bottom: -8px; margin-left: 5px">
        <label
          class="tittle_card_type"
          v-if="this.type == 'public' && this.scheduleType == 2"
        >Horarios de atención</label>
        <label
          class="tittle_card_type"
          v-if="this.type == 'free' && this.scheduleType == 2"
        >Horarios de reservas</label>
        <label
          class="tittle_card_type"
          v-if="this.type == 'dateFree' && this.scheduleType == 2"
        >Horarios específico gratis</label>
        <label
          class="tittle_card_type"
          v-if="this.type == 'dateAtention' && this.scheduleType == 2"
        >Horarios específico atención</label>
        <label
          class="tittle_card_type"
          v-if="this.type == 'dateZone' && this.scheduleType == 2"
        >Horarios específico zona</label>
        <label
          class="tittle_card_type"
          v-if="this.type == 'zone' && this.scheduleType == 2"
        >Horarios de zonas</label>
      </div>
      <NewSchedule
        :publicTime="schedulesGeneral.public"
        :publicTimeFixed="schedulesGeneral.dateAtention"
        :type="type"
        :general="schedulesGeneral"
        @newSchedules="newSchedules"
        v-if="typeSchedule === 2"
      />
    </div>
    <div class="card" style="margin: 20px" v-if="scheduleType === 3">
      <MenuCustom :restaurant="params" />
    </div>
    <div v-if="scheduleType === 4">
      <!-- <QrView :restaurant="params" /> -->
      <MenuQrView :restaurant="params" />
    </div>
    <div class="card" style="margin:20px" v-if="scheduleType === 5">
      <PopUp :restaurant="params" />
    </div>
    <div class="card" style="margin:20px" v-if="scheduleType === 15">
      <Integrations :restaurant="params" />
    </div>
    <div class="card" style="margin:20px" v-if="scheduleType === 16">
      <Host :restaurant="params" />
    </div>
  </div>
</template>
<script>
import NewSchedule from './NewSchedule.vue';
import ModalEditSchedule from './ModalEditSchedule.vue';
import ModalDeleteSchedules from './ModalDeleteSchedules.vue';
import PopUp from '../popup/PopUp.vue';
import Integrations from '../integrations/Integrations.vue';
import Host from '../host/Host.vue';
import Menu from '../menu/Menu.vue';
/* import QrView from '../qr/QrView.vue'; */
import MenuQrView from '../menu-qr/index.vue';
import _ from 'lodash';
import serviceVendor from '@/helpers/apis/serviceVendor';

export default {
  components: {
    NewSchedule,
    MenuCustom: Menu,
    PopUp,
    Integrations,
    /* QrView, */
    MenuQrView,
    Host
  },
  name: 'SchedulesVendor',
  props: ['scheduleType'],
  data () {
    return {
      screenSizeH: 0,
      screenSizeW: 0,
      radio: null,
      radioNew: null,
      schedulesAtention: [],
      schedulesReservation: [],
      weekdays: [],
      scheduleVendor: {},
      typeSchedule: 0,
      type: '',
      schedulesGeneral: {
        free: [],
        public: [],
        dateFree: [],
        dateAtention: [],
        dateZone: [],
        zone: []
      },
      zonas: [],
      arrayDelete: {
        vendorId: null,
        data: []
      }
    };
  },
  mounted () {
    this.getSchedules();
    this.weekdays = this.$moment.weekdays();
    this.typeSchedule = this.scheduleType;
    this.arrayDelete.vendorId = this.idVendor;
    this.getZone();
    this.getWindowSize();
    window.addEventListener('resize', this.getWindowSize);
  },
  watch: {
    scheduleType () {
      this.typeSchedule = this.scheduleType;
    }
  },
  methods: {
    getValuesArray (schedules) {
      const schedulesToShow = schedules || [];
      const finalSchedules = [];
      schedulesToShow.forEach((element) => {
        if (element.length > 0) {
          element.forEach((sche) => {
            finalSchedules.push(sche);
          });
        }
      });
      return finalSchedules;
    },
    getSchedules () {
      var schedulesPerDay = {};
      this.schedulesAtention = [];
      this.schedulesReservation = [];
      serviceVendor
        .get('/get/schedules/' + this.$store.state.vendor.vendor.id)
        .then(({ data }) => {
          var schedules = this.groupBy(data, 'type');
          Object.keys(schedules).map((schedule) => {
            var dayOfWeek;
            schedules[schedule] = schedules[schedule].sort((a, b) =>
              a.open > b.open ? 1 : b.open > a.open ? -1 : 0
            );
            if (
              schedule !== 'dateFree' &&
              schedule !== 'datePay' &&
              schedule !== 'dateAtention' &&
              schedule !== 'dateZone' &&
              schedule !== 'dateZonePay'
            ) {
              dayOfWeek = this.groupBy(schedules[schedule], 'dayOfWeek');
              for (let i = 0; i <= 6; i++) {
                if (!dayOfWeek[i]) {
                  dayOfWeek[i] = [];
                }
              }
            } else {
              dayOfWeek = schedules[schedule];
            }
            schedulesPerDay[schedule] = Object.values(dayOfWeek);
          });
          this.schedulesGeneral = schedulesPerDay;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getZone () {
      const vendorId = this.idVendor;
      serviceVendor
        .post('section/dashboard', { vendorId })
        .then(({ data }) => {
          this.zonas = data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    nameZone (zoneId) {
      let zona = null;
      zona = _.find(this.zonas, { id: zoneId });
      if (zona) {
        return zona.displayName;
      }
    },
    groupBy (xs, key) {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    actualizarSection (section) {
      switch (this.typeSchedule) {
        case 0:
          this.type = 'public';
          break;
        case 1:
          this.type = 'free';
          break;
        case 6:
          this.type = 'dateFree';
          break;
        case 7:
          this.type = 'dateAtention';
          break;
        case 8:
          this.type = 'dateZone';
          break;
        case 9:
          this.type = 'zone';
          break;
      }
      this.$emit('activeSchedule', section);
      this.$emit('updateType', this.type);
      this.typeSchedule = section;
    },
    editSchedule (scheduleVendor, publicTime = null, dateAtention = null, general = null) {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          info: scheduleVendor,
          publicTime: publicTime,
          publicTimeFixed: dateAtention,
          general
        },
        canCancel: false,
        events: {
          update () {
            instance.getSchedules();
          }
        },
        customClass: 'custom-modal',
        component: ModalEditSchedule
      });
    },
    pushDelete (id) {
      if (this.schedulesGeneral !== undefined && this.schedulesGeneral.free !== undefined) {
        if (id.type === 'public' && this.schedulesGeneral.free[id.dayOfWeek].length > 0) {
          this.$buefy.toast.open({
            message: 'No es posible eliminar el horario porque existen horarios configurados',
            type: 'is-danger'
          });
          return;
        }
      }
      const search = {};
      search.id = id.id;
      search.type = id.type;
      let contenido = -1;
      for (var i = 0; i < this.arrayDelete.data.length; i += 1) {
        if (
          this.arrayDelete.data[i].id === search.id &&
          this.arrayDelete.data[i].type === search.type
        ) {
          contenido = i;
        }
      }
      if (contenido >= 0) {
        this.arrayDelete.data.splice(contenido, 1);
      } else {
        const data = {};
        data.id = id.id;
        data.type = id.type;
        this.arrayDelete.data.push(data);
      }
    },
    removeScheduleArray () {
      if (this.arrayDelete.data.length === 0) {
        this.$buefy.toast.open({
          message: 'No ha seleccionado ningun horario.',
          type: 'is-danger'
        });
        return;
      }
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          arrayDeletes: this.arrayDelete,
          scheduleDelete: null
        },
        canCancel: false,
        events: {
          updateSchedule () {
            instance.getSchedules();
            instance.arrayDelete.data = [];
          }
        },
        customClass: 'delete_modal',
        component: ModalDeleteSchedules
      });
    },
    deleteSchedule (item) {
      if (this.schedulesGeneral !== undefined && this.schedulesGeneral.free !== undefined) {
        if (item.type === 'public' && this.schedulesGeneral.free[item.dayOfWeek].length > 0) {
          this.$buefy.toast.open({
            message: 'No es posible eliminar el horario porque existen horarios de reservas configurados',
            type: 'is-danger'
          });
          return;
        }
      }
      if (this.schedulesGeneral !== undefined && this.schedulesGeneral.zone !== undefined) {
        if (item.type === 'public' && this.schedulesGeneral.zone[item.dayOfWeek].length > 0) {
          this.$buefy.toast.open({
            message: 'No es posible eliminar el horario porque existen horarios de zonas configurados',
            type: 'is-danger'
          });
          return;
        }
      }
      if (this.schedulesGeneral !== undefined && this.schedulesGeneral.dateFree !== undefined) {
        if (item.type === 'dateAtention' && this.schedulesGeneral.dateFree !== undefined && this.schedulesGeneral.dateFree.find(i => i.fecha === item.fecha)) {
          this.$buefy.toast.open({
            message: 'No es posible eliminar el horario porque existen horarios específicos configurados',
            type: 'is-danger'
          });
          return;
        }
      }
      if (this.schedulesGeneral !== undefined && this.schedulesGeneral.dateZone !== undefined) {
        if (item.type === 'dateAtention' && this.schedulesGeneral.dateZone !== undefined && this.schedulesGeneral.dateZone.find(i => i.fecha === item.fecha)) {
          this.$buefy.toast.open({
            message: 'No es posible eliminar el horario porque existen horarios específicos por zonas configurados',
            type: 'is-danger'
          });
          return;
        }
      }
      const deleteSchedule = {
        type: item.type,
        id: item.id
      };
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          arrayDeletes: null,
          scheduleDelete: deleteSchedule
        },
        canCancel: false,
        events: {
          updateSchedule () {
            instance.getSchedules();
          }
        },
        customClass: 'delete_modal',
        component: ModalDeleteSchedules
      });
    },
    newSchedules (type) {
      let section;
      switch (type) {
        case 'public':
          section = 0;
          break;
        case 'free':
          section = 1;
          break;
        case 'dateFree':
          section = 6;
          break;
        case 'dateAtention':
          section = 7;
          break;
        case 'dateZone':
          section = 8;
          break;
        case 'zone':
          section = 9;
          break;
      }
      this.$emit('activeSchedule', section);
      this.typeSchedule = section;
      this.getSchedules();
    },
    getWindowSize () {
      this.screenSizeH = window.innerHeight;
      this.screenSizeW = window.innerWidth;
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowSize);
  }
};
</script>

<style lang="scss">
.card_schedule_new {
  height: 86vh;
  max-height: 86vh;
  margin: 15px 10px 0 10px;
  .tittle_card_type {
    font-size: 24px;
    font-weight: 500;
  }
}
.container_zone {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: #239fb8;
  font-weight: 500;
}
.modal {
  .modal-content {
    min-width: 70% !important;
  }
}
.delete_modal {
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content {
  .columns {
    height: auto;
  }
}
.card_schedule {
  height: 86vh;
  max-height: 86vh;
  margin: 15px 10px 0 10px;
  &.mobileSchedule {
    height: var(--heightM);
  }
  .card_schedule_content {
    height: 76vh;
    max-height: 76vh;
    overflow-y: auto;
    @media screen and (max-width: 600px) {
      height: 85% !important;
      max-height: 78% !important;
    }
  }
}
.horarios {
  border-top: 0px !important;
  justify-content: center;
  button {
    margin: 30px 20px 30px 20px;
    width: 150px;
    height: 40px;
    background: #905eb5;
    box-shadow: 0px 3px 6px #0000004d !important;
    opacity: 1;
    border-radius: 37px;
    text-align: left;
    font-weight: normal;
    font-size: 22px;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    i {
      width: 50px;
      height: 45px;
      margin-left: -11px !important;
      margin-right: 0 !important;
      float: left;
      margin-top: 1px;
    }
  }
}
.card_info {
  min-height: 160px;
  margin-right: 10px;
  .card-content {
    margin-top: 10px;
  }
}
.titulo_card {
  margin: 10px 0 0 0 !important;
  text-align: center;
  color: #239fb8 !important;
  font-weight: bold !important;
  font-size: 18px !important;
}
.content_card {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.columns_card {
  display: flex;
  align-items: center;
  width: 80%;
  .first_card,
  .middle_card,
  .end_card {
    margin: auto;
  }
}
.button_days {
  i {
    margin-left: 20px;
    margin-right: 20px;
  }
  width: 300px;
  height: 58px;
  position: relative;
  background-color: #ffffff;
}
.button_hours {
  i {
    margin-left: 30px;
    margin-right: 10px;
  }
  width: 195px;
  height: 58px;
  margin-left: -40px;
  background-color: #1cc9ed;
  color: white;
}
.button_hours,
.button_days {
  border-radius: 25px;
  font-weight: normal;
  font-size: 16px;
  border: none;
  box-shadow: 0px 3px 6px #9c9c9c !important;
  i {
    float: left;
    width: 24px;
    height: 24px;
  }
  div {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
.checkbox_info {
  float: right;
  margin-right: 25px;
  .check {
    border-radius: 10px !important;
  }
}
.first_card {
  .b-checkbox.checkbox input[type="checkbox"]:checked + .check {
    border: 2px solid #7a7a7a !important;
    background-color: #1cc9ed !important;
    background-image: none !important;
  }
}
.icons_info_schedules_delete,
.icons_info_schedules_edit {
  width: 23px;
  height: 23px;
  text-align: center;
  border-radius: 20px;
  float: left;
  font-size: 18px !important;
  margin-right: 3px;
  i {
    width: 15px;
    height: 15px;
    margin: auto;
    display: inline-block;
    margin-top: 3px;
  }
}
.icons_info_schedules_delete {
  background-color: #1cc9ed;
}
.icons_info_schedules_edit {
  background-color: #905eb5;
  margin-top: 3px;
}
.label_info {
  font-size: 12px;
  cursor: pointer;
}
.days_hours_schedule {
  display: flex !important;
  justify-content: center;
}
.first_card,
.end_card {
  width: 20%;
}
.end_card {
  display: flex;
  flex-direction: column;
}
.horarios {
  button {
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
  .button_hours,
  .button_days {
    i {
      height: 50px;
    }
    div {
      align-items: center;
    }
  }
  .edit_delete_schedule {
    margin-left: 15px !important;
  }
  .label_info {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1420px) {
  .button_days {
    width: 200px;
  }
}

@media only screen and (max-width: 1250px) {
  .content_card {
    flex-direction: column;
  }
  .end_card {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 1100px) {
  .columns_card {
    justify-content: center;
  }
}
@media only screen and (max-width: 1000px) {
  .columns_cards_schedule {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1023px) {
  .content_card {
    flex-direction: column;
  }
  .first_card {
    float: left;
    width: 0%;
  }
  .button_days {
    width: 220px;
  }
  .button_hours {
    width: 170px;
  }
  .end_card {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .card_info {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 530px) {
  .button_hours,
  .button_days {
    font-size: 11px;
    height: 45px;
  }
  .button_days {
    width: 230px;
  }
  .button_hours {
    width: 165px;
  }
  .label_info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .horarios {
    button {
      width: 327px;
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
      i {
        margin-top: 1px;
      }
    }
  }
  .card_schedule,
  .card_info {
    margin: 0;
  }
  .card_info {
    margin-top: 10px;
    .card-content {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .card_schedule_content {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .titulo_card {
    margin: 0 !important;
  }
  .content_card {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 430px) {
  .button_days,
  .button_hours {
    width: 140px;
  }
  .button_days {
    display: flex;
    align-items: center;
  }
  .first_card {
    margin-left: 15px !important;
  }
}
@media only screen and (max-width: 375px) {
  .buttons_list {
    button {
      width: 90px;
      height: 29px;
      font-size: 15px;
    }
    i {
      width: 35px !important;
      height: 35px !important;
    }
  }
}

@media only screen and (max-height: 850px) {
  .card_schedule {
    .card_schedule_content {
      max-height: 72vh;
    }
  }
}

@media only screen and (max-height: 680px) {
  .card_schedule {
    .card_schedule_content {
      max-height: 68vh;
    }
  }
}

@media only screen and (max-height: 550px) {
  .card_schedule {
    .card_schedule_content {
      max-height: 60vh;
    }
  }
}

@media screen and (max-width: 600px) {
  .buttons_list {
    height: 15%;
    display: flex;
    align-items: center !important;
    justify-content: space-evenly;
    width: 100%;
    button {
      margin: auto;
      padding-left: 5px;
      padding-right: 5px;
      width: 110px;
      font-size: 14px;
    }
  }
}
</style>
