var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.vendorParams)?_c('Sidenav',{on:{"change-full":_vm.toggle}}):_vm._e(),_c('div',{attrs:{"id":"newMetrics"}},[_c('HeaderActions',{attrs:{"sectionId":_vm.sectionId},on:{"sectionId":function($event){_vm.sectionId = $event}}}),_c('div',{staticClass:"general-metrics-layer"},[_c('div',{staticClass:"general-metrics-layer__container"},[_c('SideBarInfo',{attrs:{"dataReservations":_vm.dataReservation,"totalPeopleEfective":_vm.getTotalsByRange(_vm.byRange, 'people'),"totalReservationsEfective":_vm.getTotalsByRange(_vm.byRange, 'reservations'),"totalPeople":_vm.dataReservation.people,"totalReservations":_vm.dataReservation.reservas,"type":_vm.type,"sectionId":_vm.sectionId,"totalReservationGeneral":_vm.totalReservationGeneral,"totalPeopleGeneral":_vm.totalPeopleGeneral,"totalReservationPrePurchaseGeneral":_vm.totalReservationPrePurchaseGeneral,"totalPeoplePrepurchaseGeneral":_vm.totalPeoplePrepurchaseGeneral}}),_c('GeneralCharts',{attrs:{"sectionId":_vm.sectionId,"peoplePerDay":_vm.byDayD,"dataConventionsPerDay":_vm.sectionId === 4 ? _vm.dataReservation.byDay : _vm.byReferrerD,"peoplePerGroup":_vm.byRangeD,"dataConventionsPerGroup":_vm.byRange,"peoplePerTime":_vm.sectionId === 3 ? _vm.timeStateN3 : (_vm.sectionId === 4 ? _vm.timeStateN : _vm.timeStateN2),"typeByDay":_vm.typeByDay,"typeByRange":_vm.typeByRange,"walkingData":{
            reservations: _vm.dataReservationWalkIn,
            people: _vm.dataPeopleWalkIn
          },"generalData":_vm.byAllDataD,"temporalDataOldGraphs":{
            dataReservationRange: _vm.dataReservationRange,
            byMonthD: _vm.byMonthD,
            notEffectiveD: _vm.notEffectiveD,
            byHour: _vm.byHour,
            typeNotEffective: _vm.typeNotEffective,
            byIsCancelledD: _vm.byIsCancelledD,
            byNotShowD: _vm.byNotShowD,
            byAllReservationsD: _vm.byAllReservationsD,
            byAllReservations: _vm.byAllReservations,
            byHourD: _vm.byHourD,
            byHourDGeneral: _vm.byHourDGeneral,
            byLastHourD: _vm.byLastHourD,
            byReservationS: _vm.byReservationS,
            byCountry: _vm.byCountry,
            byCountryReservations: _vm.byCountryReservations,
            byGuestD: _vm.byGuestD,
            byClientTypeDGeneral: _vm.byClientTypeDGeneral,
            metricReservations: _vm.metricReservations,
            globalD: _vm.globalD
          }},on:{"getColorByName":_vm.getColorByName,"changeFilter":_vm.changeFilter}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }