<template>
  <div class="container-menu-qr-config" style="padding-top:0!important">
    <div v-if="!createEditCategorySection">
      <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
      <b-tabs v-model="activeTab" :class="{'custom-margin' : activeTab === 0}">
        <b-tab-item label="Categoría" class="categories-tab">
          <div class="categories-tab__actions">
            <div class="categories-tab__actions--back" @click="$emit('goBackMenus')">
              <i class="icon-mks icon_arrow_down_general"></i>
              <div :style="`background-image: url(${bgImageMenu ? bgImageMenu.img: ''}); background-size: cover;background-position: center;`">{{bgImageMenu ? bgImageMenu.name : ''}}</div>
            </div>
            <div class="categories-tab__actions__create-search">
              <b-button @click="() => {
                this.changeStatusSectionCreateEditCategories(true);
                this.typeSectionCategory = 'create';
              }">
                Crear categoría
              </b-button>
              <div class="categories-tab__actions__create-search--searcher-list-products">
                <img src="@/assets/icons/menuQr/search.svg" alt="">
                <b-input placeholder="Bucar por nombre" v-model="keyword" autocomplete="off" />
              </div>
            </div>
          </div>
          <section class="container-menu-qr-config__categories" id="listCategories" v-if="activeTab === 0">
            <h3 v-if="listCategories.length === 0">No hay categorías disponibles</h3>
            <div class="container-list-categories-aux">
              <CardCategories :idMenuMultipleForCategoriesProducts="idMenuMultipleForCategoriesProducts" :restaurant="restaurant" v-for="(category, index) in listCategories" :key="index" :category="category" @getListCategories="getListCategories" @changeStatusLoader="changeStatusLoader" @setCategoryDetail="setCategoryDetail" @clearListCategories="clearListCategories" />
            </div>
          </section>
        </b-tab-item>
        <b-tab-item label="Productos" class="products-tab">
          <section class="container-menu-qr-config__products" v-if="activeTab === 1">
            <ListProducts :restaurant="restaurant" :idMenuMultipleForCategoriesProducts="idMenuMultipleForCategoriesProducts" :listCategories="listCategories" />
          </section>
        </b-tab-item>
        <b-tab-item label="Información" class="info-tab">
          <InfoOptimizer />
        </b-tab-item>
      </b-tabs>
    </div>
    <div v-else class="">
      <SectionCreateEditCategory :restaurant="restaurant" :idMenuMultipleForCategoriesProducts="idMenuMultipleForCategoriesProducts" :type="typeSectionCategory" :dataCategorySelected="dataCategorySelected" @changeStatusSectionCreateEditCategories="changeStatusSectionCreateEditCategories" @getListCategories="() => {
        clearListCategories();
        getListCategories();
      }" />
    </div>
  </div>
</template>
<script>
import CardCategories from '@/components/schedules/menu-qr/complements/categories/CardCategories.vue';
import ListProducts from '@/components/schedules/menu-qr/complements/products/ListProducts.vue';
import SectionCreateEditCategory from '@/components/schedules/menu-qr/complements/categories/SectionCreateEditCategory.vue';
import InfoOptimizer from '@/components/schedules/menu-qr/complements/info/InfoOptimizer.vue';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['restaurant', 'idMenuMultipleForCategoriesProducts'],
  components: {
    CardCategories,
    ListProducts,
    SectionCreateEditCategory,
    InfoOptimizer
  },
  data () {
    return {
      isLoading: false,
      listCategories: [],
      activeTab: 0,
      createEditCategorySection: false,
      dataCategorySelected: null,
      typeSectionCategory: 'create',
      currentPage: 1,
      noMoreData: false,
      bgImageMenu: null,
      keyword: '',
      timeout: null
    };
  },
  computed: {
  },
  mounted () {
    document.getElementById('listCategories').addEventListener('scroll', this.handleScroll);
    document.getElementById('listCategories').addEventListener('touchend', this.handleScroll);
    this.getListCategories();
    this.bgImageMenu = localStorage.getItem('btnMenuBackground') ? JSON.parse(localStorage.getItem('btnMenuBackground')) : null;
  },
  beforeDestroy () {
    const categoriesMenu = document.getElementById('listCategories');
    if (categoriesMenu) {
      categoriesMenu.removeEventListener('scroll', this.handleScroll);
      categoriesMenu.removeEventListener('touchend', this.handleScroll);
    }
  },
  methods: {
    getListCategories (withKeyWord = false) {
      if (this.noMoreData) return;
      this.isLoading = true;
      /* if (this.currentPage === 1) {
        this.listCategories = [];
      } */
      const params = {
        page: this.currentPage
      };
      if (this.keyword) {
        params.q = this.keyword;
      }
      serviceQr.get(`/menuCustom/categories/${this.idMenuMultipleForCategoriesProducts}`, { params }).then(({ data }) => {
        const newCategories = data.data.data;
        this.listCategories = this.listCategories.concat(newCategories);
        if (withKeyWord) {
          this.listCategories = newCategories;
        }
        if (data.data.last_page === this.currentPage) {
          this.noMoreData = true;
        }
        this.isLoading = false;
      }).catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
    },
    changeStatusLoader (status) {
      this.isLoading = status;
    },
    setCategoryDetail (data) {
      this.dataCategorySelected = data;
      this.changeStatusSectionCreateEditCategories(true);
      this.typeSectionCategory = 'edit';
    },
    changeStatusSectionCreateEditCategories (status) {
      this.createEditCategorySection = status;
    },
    handleScroll (event) {
      const categoriesSection = event.target;
      if (categoriesSection.scrollHeight - categoriesSection.scrollTop === categoriesSection.clientHeight) {
        // Si el usuario ha llegado al final de la tabla
        if (!this.noMoreData) {
          this.currentPage++;
          this.getListCategories();
        }
      }
    },
    clearListCategories () {
      this.currentPage = 1;
      this.noMoreData = false;
      this.listCategories = [];
    }
  },
  watch: {
    keyword () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.currentPage = 1;
        this.noMoreData = false;
        this.getListCategories(true);
      }, 1500);
    },
    activeTab (value) {
      if (value === 0) {
        // Caso cuando activeTab es 0
        this.clearListCategories();
        this.getListCategories();
        // Si el elemento existe, elimina el event listener y detiene el intervalo
        const existElementInterval = setInterval(() => {
          const categoriesMenu = document.getElementById('listCategories');
          if (categoriesMenu) {
            categoriesMenu.addEventListener('scroll', this.handleScroll);
            categoriesMenu.addEventListener('touchend', this.handleScroll);
            clearInterval(existElementInterval);
          }
        }, 300);
      } else {
        const categoriesMenu = document.getElementById('listCategories');
        if (categoriesMenu) {
          categoriesMenu.removeEventListener('scroll', this.handleScroll);
          categoriesMenu.removeEventListener('touchend', this.handleScroll);
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/menuQr/_config_categories_products.scss";
</style>
