import Vue from 'vue';
import LocalStorage from 'store';
import Router from 'vue-router';
import Login from '@/views/Auth/Login';
import ChooseVendor from '@/views/Auth/ChooseVendor';
import Reservations from '@/views/Reservations';
import Users from '@/views/Users';
import Searchs from '@/views/Searchs';
import Polls from '@/views/Polls';
import Metrics from '@/views/Metrics/index_old';
import MetricsV2 from '@/views/Metrics';
import GifCards from '@/views/GifCards';
import Schedules from '@/views/Schedules';
import Marketing from '@/views/Marketing';
import ContactlessPayments from '@/views/ContactlessPayments';
import Onboarding from '@/views/Onboarding';
import Error404 from '@/views/Error404';
import Error401 from '@/views/Error401';
import Maintenance from '@/views/Maintenance';
import ErrorGeneral from '@/views/Error';
import store from '@/store';
import _ from 'lodash';
import mixinDashboard from '@/mixins';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: () => {
        return '/dashboard/login';
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: () => {
        return '/dashboard/reservations';
      }
    },
    {
      path: '/dashboard/login',
      name: 'login',
      component: Login,
      meta: {
        secure: false
      }
    },
    {
      path: '/dashboard/choose-vendor',
      name: 'choose-vendor',
      component: ChooseVendor,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/reservations',
      name: 'reservations',
      component: Reservations,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/users',
      name: 'users',
      component: Users,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/searchs',
      name: 'searchs',
      component: Searchs,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/metrics_old',
      name: 'metrics_old',
      component: Metrics,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/metrics',
      name: 'metrics',
      component: MetricsV2,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/polls',
      name: 'polls',
      component: Polls,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/giftCards',
      name: 'giftCards',
      component: GifCards,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/schedules',
      name: 'schedules',
      component: Schedules,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/marketing',
      name: 'marketing',
      component: Marketing,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/contactless',
      name: 'contactless',
      component: ContactlessPayments,
      meta: {
        secure: true
      }
    },
    {
      path: '/dashboard/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: {
        secure: false
      }
    },
    {
      path: '/dashboard/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        secure: false
      }
    },
    {
      path: '/dashboard/error-401',
      name: 'error-401',
      component: Error401,
      meta: {
        secure: false
      }
    },
    {
      path: '/dashboard/maintenance',
      name: 'maintenance',
      component: Maintenance,
      meta: {
        secure: false
      }
    },
    {
      path: '/dashboard/error',
      name: 'error',
      component: ErrorGeneral,
      meta: {
        secure: false
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const userSet = LocalStorage.get('_token') !== undefined && LocalStorage.get('_user') !== undefined && LocalStorage.get('_userId') !== undefined;
  const vendorUrl = mixinDashboard.methods.subDomain();
  if (to.name === 'maintenance') {
    next();
  } else if (to.name === 'error') {
    next();
  } else if (to.name === 'login') {
    if (!userSet) {
      next();
    } else {
      if (!vendorUrl) {
        next({ path: '/dashboard/choose-vendor' });
      } else {
        moduleSelect(next, vendorUrl, userSet, to);
      }
    }
  } else {
    if (!userSet) {
      if (to.name === 'login') {
        next();
      } else {
        next({ path: '/dashboard/login' });
      }
    } else {
      if (!vendorUrl) {
        if (to.name === 'choose-vendor') {
          next();
        } else {
          next({ path: '/dashboard/choose-vendor' });
        }
      } else {
        moduleSelect(next, vendorUrl, userSet, to);
      }
    }
  }
});

function moduleSelect (next, vendorUrl, userSet, to) {
  if (['login', 'error-404', 'error-401', 'maintenance', 'error'].includes(to.name)) {
    next();
  } else if (to.path === '/dashboard/login' && userSet) {
    next({ path: '/dashboard/reservations' });
  } else if (!to.matched.length) {
    next({ path: '/dashboard/error-404' });
  } else {
    const userLS = LocalStorage.get('_user');
    const user = JSON.parse(atob(userLS));
    if (user.isAdmin) {
      next();
    } else if ((user.vendors || []).length === 0) {
      LocalStorage.clearAll();
      next({ path: '/dashboard/login' });
    } else {
      store.dispatch({
        type: 'reservation:getModules',
        data: {
          vendorId: LocalStorage.get('_id'),
          userId: LocalStorage.get('_userId')
        }
      })
        .finally(() => {
          const userModules = LocalStorage.get('userModules');
          if (!userModules) {
            LocalStorage.clearAll();
            next({ path: '/dashboard/login' });
          }
          const userModuleItems = JSON.parse(atob(userModules));
          if (userModuleItems.length) {
            const exists = _.find(userModuleItems, {
              name: to.name
            });
            if (!exists) {
              next({ path: `/dashboard/${userModuleItems[0].name}` });
            }
            next();
          } else {
            next({ path: '/dashboard/reservations' });
          }
        });
    }
  }
}

export default router;
