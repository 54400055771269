<template>
  <div @click="$emit('changeSection')" class="general-container-onboarding__cards-container__section" :class="sectionActive">
    <div class="ribbon-precompro-new" v-if="somethingNew">
      <div class="ribbon-precompro-new__content">
        <div>New</div>
      </div>
    </div>
    <div class="general-container-onboarding__cards-container__section--count" v-if="!somethingNew && countActive">
      <div v-if="carousels.length > 0">{{carousels.length}}</div>
    </div>
    <i class="icon-mks" :class="`${icon} ${iconActive}`"></i>
    <h2 v-html="title"></h2>
    <p>{{description}}</p>
  </div>
</template>

<script>
export default {
  props: ['carousels', 'typeSection', 'title', 'description', 'icon', 'iconActive', 'sectionActive', 'countActive', 'somethingNew']

};
</script>

<style>

</style>
