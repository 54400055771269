<template>
  <div id="historicoReserva">
    <div class="close" @click="$emit('close')">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" class="close_history">
        <path d="M11.7208 0.279164C11.542 0.100415 11.2996 0 11.0467 0C10.7939 0 10.5514 0.100415 10.3726 0.279164L6 4.65179L1.62737 0.279164C1.44857 0.100415 1.2061 0 0.953269 0C0.700442 0 0.457967 0.100415 0.279164 0.279164C0.100415 0.457967 0 0.700442 0 0.953269C0 1.2061 0.100415 1.44857 0.279164 1.62737L4.65179 6L0.279164 10.3726C0.100415 10.5514 0 10.7939 0 11.0467C0 11.2996 0.100415 11.542 0.279164 11.7208C0.457967 11.8996 0.700442 12 0.953269 12C1.2061 12 1.44857 11.8996 1.62737 11.7208L6 7.34821L10.3726 11.7208C10.5514 11.8996 10.7939 12 11.0467 12C11.2996 12 11.542 11.8996 11.7208 11.7208C11.8996 11.542 12 11.2996 12 11.0467C12 10.7939 11.8996 10.5514 11.7208 10.3726L7.34821 6L11.7208 1.62737C11.8996 1.44857 12 1.2061 12 0.953269C12 0.700442 11.8996 0.457967 11.7208 0.279164Z" fill="#444B57"/>
      </svg>
    </div>
    <div class="container-columns">
      <div class="sub-tittle">Histórico</div>
      <b-tabs v-model="activeTab">
        <b-tab-item label="Movimientos de reserva"></b-tab-item>
        <b-tab-item label="Estado notificaciones"></b-tab-item>
        <b-tab-item label="Histórico reservas"></b-tab-item>
      </b-tabs>
      <div class="content-history">
        <div v-if="activeTab === 0 && infoAdmin" class="content-history-tab">
          <div class="content-history-tab-title">A continuación podrás ver los cambios realizados a esta reserva:</div>
          <div class="content-history-tab-subtitle">Administrador</div>
          <div class="content-history-tab-header">
            <span class="w-1">ID</span>
            <span class="w-2">Movimiento</span>
            <span class="w-3">Fecha</span>
            <span class="w-4">Realizado por</span>
          </div>
          <div class="content-history-tab-body">
            <div v-for="(item, index) in dataReserva['Admin']" :key="'admin-' + index" class="content-history-tab-body-item">
              <span class="w-1">{{ item.id }}</span>
              <span class="w-2" style="padding: 5px;">{{ item.accion }}</span>
              <span class="w-3">{{ dateFormatter(item.created_at) }}</span>
              <span class="w-4">{{ validateReferrer(dataInfoReserva.referrer, item) }}</span>
            </div>
          </div>
          <br>
          <div class="content-history-tab-subtitle">Usuario</div>
          <div class="content-history-tab-header">
            <span class="w-1">ID</span>
            <span class="w-2">Movimiento</span>
            <span class="w-3">Fecha</span>
            <span class="w-4">Realizado por</span>
          </div>
          <div class="content-history-tab-body">
            <div v-for="(item, index) in dataReserva['NoAdmin']" :key="'no-admin-' + index" class="content-history-tab-body-item">
              <span class="w-1">{{ item.id }}</span>
              <span class="w-2" style="padding: 5px;">{{ item.accion }}</span>
              <span class="w-3">{{ dateFormatter(item.created_at) }}</span>
              <span class="w-4">{{ validateReferrer(dataInfoReserva.referrer, item) }}</span>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 0 && !infoAdmin" class="content-history-tab">
          <div class="content-history-tab-title">A continuación podrás ver los cambios realizados a esta reserva:</div>
          <div class="content-history-tab-header">
            <span class="w-1">ID</span>
            <span class="w-2">Movimiento</span>
            <span class="w-3">Fecha</span>
            <span class="w-4">Realizado por</span>
          </div>
          <div class="content-history-tab-body">
            <div v-for="(item, index) in dataReserva['NoAdmin']" :key="'no-admin-' + index" class="content-history-tab-body-item">
              <span class="w-1">{{ item.id }}</span>
              <span class="w-2" style="padding: 5px;">{{ item.accion }}</span>
              <span class="w-3">{{ dateFormatter(item.created_at) }}</span>
              <span class="w-4">{{ validateReferrer(dataInfoReserva.referrer, item) }}</span>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 1" class="content-history-tab">
          <div class="content-history-tab-title">A continuación podrás ver las notificaciones para este cliente</div>
          <div class="content-history-tab-header">
            <span class="wn-1">ID</span>
            <span class="wn-2">Movimiento</span>
            <span class="wn-3">Fecha</span>
          </div>
          <div class="content-history-tab-body">
            <div v-for="(item, index) in dataReserva['Notification']" :key="'notification-' + index" class="content-history-tab-body-item">
              <span class="wn-1">{{ item.id }}</span>
              <span class="wn-2" style="padding: 5px;">{{ validateAction(item.accion) }}</span>
              <span class="wn-3">{{ dateFormatter(item.created_at) }}</span>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 2" class="content-history-tab" style="padding: 0px 10px;">
          <div class="content-history-tab-title">A continuación podrás ver el histórico de reservas y visitas realizadas por el cliente</div>
          <div class="content-history-tab-header">
            <span class="w-1">ID</span>
            <span class="w-2">Fecha</span>
            <span class="w-3">Estado</span>
            <span class="w-4">Realizado por</span>
          </div>
          <div class="content-history-tab-body">
            <div v-for="(item, index) in historyForUser" :key="'forUser-' + index" class="content-history-tab-body-item">
              <span class="w-1">{{ item.id }}</span>
              <span class="w-2">{{ item.fechaCompleta }}</span>
              <span class="w-3">{{ item.status }}</span>
              <span class="w-4">{{ item.creator }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import serviceUser from '@/helpers/apis/serviceUser.js';
export default {
  props: ['dataReserva', 'dataInfoReserva', 'infoAdmin', 'userId', 'showTab'],
  data () {
    return {
      data: this.dataReserva,
      activeTab: 0,
      historyForUser: []
    };
  },
  mounted () {
    if (this.showTab > 0) {
      this.activeTab = this.showTab;
    }
  },
  methods: {
    validateAction (accion) {
      let str = accion;
      str = str.replace('WhiteLabel', 'Página Web');
      return str;
    },
    dateFormatter (date) {
      return this.$moment(date).format('YYYY-MM-DD h:mm:ss');
    },
    validateReferrer (referrer, data) {
      return data.usuario;
    },
    getHistoryForUser () {
      serviceUser.post('/findHistoryByUserId', { id: this.dataInfoReserva.peopleId }).then(({ data }) => {
        this.historyForUser = data.data || [];
      }).catch(() => {
        this.historyForUser = [];
      });
    }
  },
  watch: {
    activeTab () {
      if (this.activeTab === 2) {
        this.getHistoryForUser();
      }
    }
  }
};
</script>
<style lang="scss">
#historicoReserva {
  width: auto;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  overflow: auto;
  top: 0px;
  right: 0;
  padding: 20px 9px;
  z-index: 99;
  .b-tabs {
    margin-bottom: unset;
    .tabs {
      display: flex !important;
      ul {
        border-bottom-color: transparent;
      }
      a {
        border-bottom-color: #EDEDED;
      }
      li {
        color: #7D848F;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        &.is-active {
          color: #444B57;
          a {
            border-bottom-color: #444B57;
            color: #444B57;
          }
        }
        @media screen and (max-width: 440px) {
          font-size: 11px;
        }
      }
    }
    .tab-content {
      display: none;
    }
  }
  .close_history {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
  }
  .container-columns {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .content-history {
    margin-top: 8px;
    display: flex;
    height: 100%;
    &-tab {
      display: flex;
      height: 100%;
      flex-direction: column;
      width: 100%;
      &-title {
        color: #444B57;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 13px;
        font-style: italic;
        font-weight: 300;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        @media screen and (max-width: 440px) {
          font-size: 11px;
        }
      }
      &-subtitle {
        color: #444B57;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 13px;
        font-style: italic;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        @media screen and (max-width: 440px) {
          font-size: 11px;
        }
      }
      &-header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 20px;
        width: 100%;
        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid #EDEDED;
        background: #FFF;
        span {
          color: #444B57;
          text-align: center;
          font-family: "Source Sans Pro";
          font-size: 13px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          @media screen and (max-width: 440px) {
            font-size: 11px;
          }
        }
      }
      &-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &-item {
          display: flex;
          align-items: center;
          min-height: 30px;
          width: 100%;
          flex-shrink: 0;
          background: #FFF;
          padding-bottom: 3px;
          border-bottom: 1px solid #EDEDED;
          span {
            color: #444B57;
            text-align: center;
            font-family: "Source Sans Pro";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media screen and (max-width: 440px) {
              font-size: 11px;
            }
          }
        }
      }
      .w-1 {
        width: 15%;
      }
      .wn-1 {
        width: 15%;
      }
      .w-2 {
        width: 35%;
      }
      .wn-2 {
        width: 55%;
      }
      .w-3 {
        width: 26%;
      }
      .wn-3 {
        width: 30%;
      }
      .w-4 {
        width: 24%;
      }
    }
  }
  .sub-tittle {
    color: #444B57;
    text-align: center;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 18px;
    margin-bottom: 17px;
  }
}
</style>
