<template>
  <section class="modalWrapper">
    <div class="reservations-content-box" id="viewReservation">
      <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <!--CABECERA-->
      <div class="columns header_view">
        <div
          class="column text_header left"
          v-if="!validatePermission('ReadOnly') && (infoReserva.fecha >= getValidateDay() || user.isAdmin)"
        >
          <a
            @click="setEditReservation(infoReserva)"
            class="edit_btn"
            v-if="(infoReserva.codeStatus === 0 || infoReserva.codeStatus === 1 || infoReserva.codeStatus === 2) && infoReserva.edit && validateReservationEditable() && (!validatePermission('ReadOnly') && !validatePermission('MixSFCN'))"
          >
            <i class="icons icon_edit_reservation"></i>
            <span v-if="type == 'Precompra'">Editar precompra</span>
            <span v-if="type == 'Reserva'">Editar reserva</span>
            <span v-if="type == 'Walk-In'">Editar Walk-In</span>
          </a>
        </div>
        <div class="column text_header center">
          <span>{{ type }}</span>
        </div>
        <div class="column text_header right">
          <a @click="isOpenSet()">
            <i class="icons icon_cerrar_view"></i>
          </a>
        </div>
      </div>
      <!--CONTENIDO ARRIBA-->
      <div class="columns content_top">
        <!--LEFT-->
        <div class="column is-6 is-12-mobile" style="display:flex;">
          <div :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In' && infoReserva.displayName == 'Walk-In', 'walk_info' : type == 'Walk-In' && infoReserva.displayName != 'Walk-In'}" class="info_client">
            <i class="indicator_info_client" v-if="type != 'Walk-In'"></i>
            <i :class="{'indicator_walkin_info' : infoReserva.displayName != 'Walk-In'}" class="indicator_walkin" v-else></i>
            <div class="data_client">
              <div class="container_first_line">
                <div class="container_client_name">
                  <label class="client_name" v-if="infoReserva.displayName != '' && infoReserva.displayName != 'Walk-In'">
                    {{ infoReserva.displayName | capitalize }}
                  </label>
                  <label class="client_name" v-else>Walk-In</label>
                </div>
                <GeneralTags :infoReserva="infoReserva" :existTag="userTags.length > 0" :customClass="'container-tags-reservation-global-in-detail'" />
              </div>
              <div class="user_email container_user" v-if="type != 'Walk-In' || (type == 'Walk-In' && infoReserva.displayName != '' && infoReserva.displayName != 'Walk-In')">
                <div class="container_icons">
                  <i class="icons icon_mail_user"></i>
                </div>
                <span v-if="infoReserva.email">{{ infoReserva.email }}</span>
                <span class="noInfo" v-else>Sin información</span>
              </div>
              <div class="user_phone container_user" v-if="type != 'Walk-In' || (type == 'Walk-In' && infoReserva.displayName != '' && infoReserva.displayName != 'Walk-In')">
                <div class="container_icons">
                  <i class="icons icon_phone_user"></i>
                </div>
                <div class="container_phone">
                  <img :src="infoReserva.bandera" alt="imagen" class="flag_user" v-if="infoReserva.bandera && infoReserva.indicativo && infoReserva.phone"/>
                  <span class="text" v-if="infoReserva.indicativo && infoReserva.phone">({{infoReserva.indicativo}})&nbsp;</span>
                  <span class="container_phone_show" v-if="infoReserva.phone">{{ infoReserva.phone }}</span>
                  <span class="noInfo no_info_mobile" v-else>Sin información</span>
                  <img @click="goToAction('phone')" class="button-actions-phone-whatsapp" v-if="infoReserva.phone" alt="phone" src="@/assets/icons/viewReservation/celular.svg" />
                  <img @click="goToAction('whatsapp')" class="button-actions-phone-whatsapp" v-if="infoReserva.phone" alt="whatsapp" src="@/assets/icons/viewReservation/whatsapp.svg" />
                </div>
              </div>
              <div class="user_date container_user" v-if="params.enableIdentityDocument && (type != 'Walk-In' || (type == 'Walk-In' && infoReserva.displayName != '' && infoReserva.displayName != 'Walk-In'))">
                <div class="container_icons">
                  <i class="icons icon_cedula_user"></i>
                </div>
                <span v-if="infoReserva.identityDocument">{{infoReserva.identityDocument}}</span>
                <span class="noInfo" v-else>Sin información</span>
              </div>
              <div class="user_date container_user" v-if="type != 'Walk-In' || (type == 'Walk-In' && infoReserva.displayName != '' && infoReserva.displayName != 'Walk-In')">
                <div class="container_icons">
                  <i class="icons icon_calendar_user"></i>
                </div>
                <span v-if="infoReserva.birthday">{{infoReserva.birthday | moment('DD/MM/YYYY')}}</span>
                <span class="noInfo" v-else>Sin información</span>
              </div>
              <div class="edit_user" v-if="(infoReserva.codeStatus === 0 || infoReserva.codeStatus === 1 || infoReserva.codeStatus === 2) && infoReserva.edit && !validatePermission('ReadOnly') && !validatePermission('MixSFCN')" v-show="infoReserva.peopleId">
                <i @click="showEditUser(infoReserva.peopleId)" class="icons icon_edit_reservation user" v-if="infoReserva.peopleId"></i>
              </div>
            </div>
          </div>
        </div>
        <!--RIGHT-->
        <div class="column is-6 is-12-mobile main_metrics">
          <div :class="{'walk' : type == 'Walk-In' && infoReserva.displayName == 'Walk-In'}" class="activity_client metrics_desktop">
            <div class="metrics_client" v-if="type != 'Walk-In' || (type == 'Walk-In' && infoReserva.displayName != 'Walk-In')">
              <div class="title_activity">
                <i :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In'}" class="icons icon_metrics"></i>
                <span>Actividad del comensal</span>
              </div>
              <div class="columns container_metrics" :class="{ 'with-assist-progress-bar' : type == 'Walk-In' && infoReserva.displayName != 'Walk-In'}">
                <!--LEFT-->
                <div class="column is-5 info_client_metric">
                  <span class="text_client client_type" :class="{mT20: !lastReservation}">
                    Cliente:
                    <span class="infoType" v-if="totalReservations > 1">Recurrente</span>
                    <span class="infoType" v-else>Nuevo</span>
                  </span>
                  <span class="text_client total" :class="{mT8: !lastReservation}">
                    Total de reservas:
                    <span class="infoType">{{totalReservations}}</span>
                  </span>
                  <div class="text_client total last_res" v-if="lastReservation">
                    <div class="last_res_text">Última visita:
                      <strong>{{lastReservation}}</strong>
                    </div>
                    <i
                      @click="historial(infoReserva.id, 2)"
                      class="icons icon_new_alert"
                    ></i>
                  </div>
                  <span
                    class="text_client stars"
                    :class="{mT208: !lastReservation}"
                    v-if="(infoReserva.codeStatus == 2) ||
                    (infoReserva.codeStatus == 0 || infoReserva.codeStatus == 4 || infoReserva.codeStatus == 3 || infoReserva.codeStatus == 5)"
                  >Califica a tu comensal</span>
                  <b-rate v-model="reservationScore"></b-rate>
                  <button
                    :class="{
                      'stars_active' : (infoReserva.codeStatus == 2) || (infoReserva.codeStatus == 0 || infoReserva.codeStatus == 4 || infoReserva.codeStatus == 3 || infoReserva.codeStatus == 5),
                      mT7: !lastReservation
                    }"
                    class="btn_historic"
                    @click="historial(infoReserva.id)"
                  >
                    <i class="icons icon_historic" v-if="!historyLoading"></i>
                    <span
                      class="sub-tittle"
                      v-if="!historyLoading"
                    >Histórico</span>
                    <span v-if="historyLoading" class="sub-tittle">
                      Cargando
                      <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </button>
                </div>
                <!--RIGHT-->
                <div class="column is-7 content_circles">
                  <div class="columns progress_up">
                    <div class="column is-6 user_present">
                      <radial-progress-bar
                        :completed-steps="percentReservation(showUser)"
                        :diameter="54"
                        :innerStrokeColor="'#ededed'"
                        :innerStrokeWidth="5"
                        :strokeWidth="5"
                        :total-steps="100"
                        start-color="#54d401"
                        stop-color="#54d401"
                      >
                        <p>{{percentReservationInt(showUser)}} %</p>
                      </radial-progress-bar>
                      <div class="info_circles">
                        <span class="title_circle">Llegó</span>
                        <span class="value_circle">{{showUser}}/{{totalReservations}}</span>
                      </div>
                    </div>
                    <div class="column is-6 user_no_present">
                      <radial-progress-bar
                        :completed-steps="percentReservation(notShowUser)"
                        :diameter="54"
                        :innerStrokeColor="'#ededed'"
                        :innerStrokeWidth="5"
                        :strokeWidth="5"
                        :total-steps="100"
                        start-color="#FF7001"
                        stop-color="#FF7001"
                      >
                        <p>{{percentReservationInt(notShowUser)}} %</p>
                      </radial-progress-bar>
                      <div class="info_circles">
                        <span class="title_circle">No Llegó</span>
                        <span class="value_circle">{{notShowUser}}/{{totalReservations}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="columns progress_down">
                    <div
                      :class="{'is-6' : type == 'Walk-In', 'is-12' : (type != 'Walk-In' && (!infoReserva.peopleMetrics.show > 1 || !infoReserva.peopleMetrics.noShow > 1))}"
                      class="column user_cancelled"
                    >
                      <radial-progress-bar
                        :completed-steps="percentReservation(cancelUser)"
                        :diameter="54"
                        :innerStrokeColor="'#ededed'"
                        :innerStrokeWidth="5"
                        :strokeWidth="5"
                        :total-steps="100"
                        start-color="#FF0101"
                        stop-color="#FF0101"
                      >
                        <p>{{percentReservationInt(cancelUser)}} %</p>
                      </radial-progress-bar>
                      <div class="info_circles">
                        <span class="title_circle">Canceladas</span>
                        <span class="value_circle">{{cancelUser}}/{{totalReservations}}</span>
                      </div>
                    </div>
                    <div
                      class="column is-6 user_visits"
                      v-if="type == 'Walk-In' && infoReserva.displayName != 'Walk-In'"
                    >
                      <radial-progress-bar
                        :completed-steps="percentReservation(walkin)"
                        :diameter="54"
                        :innerStrokeColor="'#ededed'"
                        :innerStrokeWidth="5"
                        :strokeWidth="5"
                        :total-steps="100"
                        start-color="#8A51B4"
                        stop-color="#8A51B4"
                      >
                        <p>{{percentReservationInt(walkin)}} %</p>
                      </radial-progress-bar>
                      <div class="info_circles">
                        <span class="title_circle">Visitas</span>
                        <span class="value_circle">{{walkin}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="columns progress_down">
                    <div class="column is-12 client-alerts-container">
                      <span class="client-alert visit" v-if="infoReserva.peopleMetrics.show && infoReserva.peopleMetrics.show > 1">
                        <span class="alert-text">Asistencias:</span>
                        <span class="client-icon visit icon_star_fill_visit icon-mks black-pre"
                          v-tooltip="{ content: `Asistencias: ${infoReserva.peopleMetrics.show}`, placement: 'bottom' }">
                          <span class="visit-number">{{ infoReserva.peopleMetrics.show }}</span>
                        </span>
                      </span>
                      <span class="client-alert no-show" v-if="infoReserva.peopleMetrics.noShow && infoReserva.peopleMetrics.noShow > 1 && type !== 'Walk-In'">
                        <span class="alert-text">No-show:</span>
                        <span class="client-icon no-show icon_rombo_no_show icon-mks red"
                          v-tooltip="{ content: `No-show: ${infoReserva.peopleMetrics.noShow}`, placement: 'bottom' }">
                          <span class="visit-number">{{ infoReserva.peopleMetrics.noShow }}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn_historic" v-else>
              <i class="icons icon_historic" v-if="!historyLoading"></i>
              <a
                @click="historial(infoReserva.id)"
                class="sub-tittle"
                v-if="!historyLoading"
              >Histórico</a>
              <span v-if="historyLoading">
                <a class="sub-tittle">
                  Cargando
                  <i class="fa fa-spinner fa-spin"></i>
                </a>
              </span>
            </button>
          </div>
          <b-collapse
            :open="false"
            class="metric_collapse"
            v-if="type != 'Walk-In' || (type == 'Walk-In' && infoReserva.displayName != 'Walk-In')"
          >
            <template #trigger="props">
              <button
                :aria-expanded="props.open"
                @click="rowCollapse = !rowCollapse"
                class="btn_collapse"
              >
                <div class="title_activity">
                  <i
                    :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In'}"
                    class="icons icon_metrics"
                  ></i>
                  <span>Actividad del comensal</span>
                </div>
                <div>
                  <i :class="{'inv' : rowCollapse}" class="icons icon_collapse"></i>
                </div>
              </button>
            </template>
            <div :class="{'walk_info' : type == 'Walk-In', 'with-assist-progress-bar' : type == 'Walk-In' && infoReserva.displayName != 'Walk-In'}" class="columns container_metrics">
              <div class="column is-4 is-4-mobile info_client_metric">
                <div class="header_metric">
                  <span class="text_client client_type">
                    <span>Clientes:</span><br v-if="lastReservation" />
                    <span class="infoType" v-if="totalReservations > 1">Recurrente</span>
                    <span class="infoType" v-else>Nuevo</span>
                  </span>
                  <span class="text_client total">
                    <span>Total de reservas:</span><br v-if="lastReservation" />
                    <span class="infoType">{{totalReservations}}</span>
                  </span>
                  <div v-if="lastReservation" class="text_client total last_res">
                    <div class="last_res_text">Última visita:
                      <strong>{{lastReservation}}</strong>
                    </div>
                    <i
                    @click="historial(infoReserva.id, 2)"
                    class="icons icon_new_alert"
                   ></i>
                  </div>
                </div>
              </div>
              <div class="column is-8 content_circles mobile">
                <div class="columns progress_up">
                  <div class="column is-6-mobile user_present">
                    <radial-progress-bar
                      :completed-steps="percentReservation(showUser)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#54d401"
                      stop-color="#54d401"
                    >
                      <p>{{percentReservationInt(showUser)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">Llegó</span>
                      <span class="value_circle">{{showUser}}/{{totalReservations}}</span>
                    </div>
                  </div>
                  <div class="column is-6-mobile user_no_present">
                    <radial-progress-bar
                      :completed-steps="percentReservation(notShowUser)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#FF7001"
                      stop-color="#FF7001"
                    >
                      <p>{{percentReservationInt(notShowUser)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">No Llegó</span>
                      <span class="value_circle">{{notShowUser}}/{{totalReservations}}</span>
                    </div>
                  </div>
                </div>
                <div class="columns progress_down">
                  <div class="column is-6-mobile user_cancelled">
                    <radial-progress-bar
                      :completed-steps="percentReservation(cancelUser)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#FF0101"
                      stop-color="#FF0101"
                    >
                      <p>{{percentReservationInt(cancelUser)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">Canceladas</span>
                      <span class="value_circle">{{cancelUser}}/{{totalReservations}}</span>
                    </div>
                  </div>
                  <div
                    class="column is-6-mobile user_visits"
                    v-if="type == 'Walk-In' && infoReserva.displayName != 'Walk-In'"
                  >
                    <radial-progress-bar
                      :completed-steps="percentReservation(walkin)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#8A51B4"
                      stop-color="#8A51B4"
                    >
                      <p>{{percentReservationInt(walkin)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">Visitas</span>
                      <span class="value_circle">{{walkin}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-8 content_circles mobile" style="margin: 10px auto auto auto!important; height: 28px;" v-if="(infoReserva.peopleMetrics.show && infoReserva.peopleMetrics.show > 1) || (infoReserva.peopleMetrics.noShow && infoReserva.peopleMetrics.noShow > 1)">
                <div class="column is-6 client-alerts-container" v-if="type !== 'Walk-In'" style="margin-top: 0px!important; height: 100%;">
                  <span class="client-alert visit" style="height: 100%;" v-if="infoReserva.peopleMetrics.show && infoReserva.peopleMetrics.show > 1">
                    <span class="alert-text">Asistencias:</span>
                    <span class="client-icon visit icon_star_fill_visit icon-mks black-pre" style="margin-top: 0px!important;"
                      v-tooltip="{ content: `Asistencias: ${infoReserva.peopleMetrics.show}`, placement: 'bottom' }">
                      <span class="visit-number">{{ infoReserva.peopleMetrics.show }}</span>
                    </span>
                  </span>
                  <span class="client-alert no-show" style="height: 100%;" v-if="infoReserva.peopleMetrics.noShow && infoReserva.peopleMetrics.noShow > 1">
                    <span class="alert-text">No-show:</span>
                    <span class="client-icon no-show icon_rombo_no_show icon-mks red" style="margin-top: 0px!important;"
                      v-tooltip="{ content: `No-show: ${infoReserva.peopleMetrics.noShow}`, placement: 'bottom' }">
                      <span class="visit-number">{{ infoReserva.peopleMetrics.noShow }}</span>
                    </span>
                  </span>
                </div>
                <div class="column is-6 client-alerts-container" v-if="type == 'Walk-In' && infoReserva.displayName != 'Walk-In'" style="margin-top: 0px!important; height: 100%;">
                  <span class="client-alert visit" v-if="infoReserva.peopleMetrics.show && infoReserva.peopleMetrics.show > 1">
                    <span class="alert-text">Asistencias:</span>
                    <span class="client-icon visit icon_star_fill_visit icon-mks black-pre" style="margin-top: 0px!important;"
                      v-tooltip="{ content: `Asistencias: ${infoReserva.peopleMetrics.show}`, placement: 'bottom' }">
                      <span class="visit-number">{{ infoReserva.peopleMetrics.show }}</span>
                    </span>
                  </span>
                  <span class="client-alert no-show" v-if="infoReserva.peopleMetrics.noShow && infoReserva.peopleMetrics.noShow > 1">
                    <span class="alert-text">No-show:</span>
                    <span class="client-icon no-show icon_rombo_no_show icon-mks red" style="margin-top: 0px!important;"
                      v-tooltip="{ content: `No-show: ${infoReserva.peopleMetrics.noShow}`, placement: 'bottom' }">
                      <span class="visit-number">{{ infoReserva.peopleMetrics.noShow }}</span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="column is-8 is-8-mobile content_circles tablet">
                <div class="columns progress_up">
                  <div class="column is-4 user_present">
                    <radial-progress-bar
                      :completed-steps="percentReservation(showUser)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#54d401"
                      stop-color="#54d401"
                    >
                      <p>{{percentReservationInt(showUser)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">Llegó</span>
                      <span class="value_circle">{{showUser}}/{{totalReservations}}</span>
                    </div>
                  </div>
                  <div class="column is-4 user_no_present">
                    <radial-progress-bar
                      :completed-steps="percentReservation(notShowUser)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#FF7001"
                      stop-color="#FF7001"
                    >
                      <p>{{percentReservationInt(notShowUser)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">No Llegó</span>
                      <span class="value_circle">{{notShowUser}}/{{totalReservations}}</span>
                    </div>
                  </div>
                  <div class="column is-4 user_cancelled">
                    <radial-progress-bar
                      :completed-steps="percentReservation(cancelUser)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#FF0101"
                      stop-color="#FF0101"
                    >
                      <p>{{percentReservationInt(cancelUser)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">Canceladas</span>
                      <span class="value_circle">{{cancelUser}}/{{totalReservations}}</span>
                    </div>
                  </div>
                  <div class="column is-4 user_visits" v-if="type == 'Walk-In' && infoReserva.displayName != 'Walk-In'">
                    <radial-progress-bar
                      :completed-steps="percentReservation(walkin)"
                      :diameter="54"
                      :innerStrokeColor="'#ededed'"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                      :total-steps="100"
                      start-color="#8A51B4"
                      stop-color="#8A51B4"
                    >
                      <p>{{percentReservationInt(walkin)}} %</p>
                    </radial-progress-bar>
                    <div class="info_circles">
                      <span class="title_circle">Visitas</span>
                      <span class="value_circle">{{walkin}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom_collapse">
                <span class="client-alert visit horizontal" v-if="infoReserva.peopleMetrics.show && infoReserva.peopleMetrics.show > 1">
                  <span class="alert-text">Asistencias:</span>
                  <span class="client-icon visit icon_star_fill_visit icon-mks black-pre"
                    v-tooltip="{ content: `Asistencias: ${infoReserva.peopleMetrics.show}`, placement: 'bottom' }">
                    <span class="visit-number">{{ infoReserva.peopleMetrics.show }}</span>
                  </span>
                </span>
                <span class="client-alert no-show horizontal" v-if="infoReserva.peopleMetrics.noShow && infoReserva.peopleMetrics.noShow > 1">
                  <span class="alert-text">No-show:</span>
                  <span class="client-icon no-show icon_rombo_no_show icon-mks red"
                    v-tooltip="{ content: `No-show: ${infoReserva.peopleMetrics.noShow}`, placement: 'bottom' }">
                    <span class="visit-number">{{ infoReserva.peopleMetrics.noShow }}</span>
                  </span>
                </span>
                <div class="stars_collapse">
                  <span
                    class="text_client stars"
                    v-if="(infoReserva.codeStatus == 2) || (infoReserva.codeStatus == 0 || infoReserva.codeStatus == 4 || infoReserva.codeStatus == 3 || infoReserva.codeStatus == 5)"
                  >Califica a tu comensal</span>
                  <b-rate v-model="reservationScore"></b-rate>
                </div>
                <div>
                  <button class="btn_historic">
                    <i class="icons icon_historic" v-if="!historyLoading"></i>
                    <a
                      @click="historial(infoReserva.id)"
                      class="sub-tittle"
                      v-if="!historyLoading"
                    >Histórico</a>
                    <span v-if="historyLoading">
                      <a class="sub-tittle">
                        Cargando
                        <i class="fa fa-spinner fa-spin"></i>
                      </a>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <!--CONTENIDO ABAJO-->
      <div class="columns content_bottom">
        <div :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In'}" class="column is-6 content_info_res">
          <div class="subcontent_info_res">
            <div :class="{'pre' : type == 'Precompra'}" class="title_info">
              <div class="text_data_info">
                <i class="icons icon_info_reservation"></i>
                <span class="assigned" v-if="type == 'Precompra'">Datos de la precompra</span>
                <span class="assigned" v-if="type == 'Reserva'">Datos de la reserva</span>
                <span class="assigned" v-if="type == 'Walk-In'">Datos del walk-in</span>
              </div>
              <div>
                <button
                  :style="{backgroundColor: (infoReserva.codeStatus === 7) ? '#D9D9D9': '#BDC30E'}"
                  class="btn_advance"
                  v-if="(type == 'Precompra' && infoReserva.vendorId != 310)"
                >
                  <span class="sz12 l300">Anticipo:&nbsp;</span>
                  <strong
                    class="sz16"
                  >{{infoReserva.balancePaid | currency('$ ', 0, { thousandsSeparator: '.' })}}</strong>
                </button>
                <button
                  :style="{backgroundColor: (infoReserva.codeStatus === 7) ? '#D9D9D9': '#BDC30E'}"
                  class="btn_advance"
                  v-if="(type == 'Precompra' && infoReserva.vendorId === 310)"
                >
                  <span class="sz12 l300">Anticipo:&nbsp;</span>
                  <strong
                    class="sz16"
                  >{{(infoReserva.balancePaid - infoReserva.tip) | currency('$ ', 0, { thousandsSeparator: '.' })}}</strong>
                </button>
              </div>
            </div>
            <div class="content_description">
              <div class="res_asigned_time">
                <span class="assigned">
                  Asignada por:
                  <br class="ofm" />
                  <span
                    class="assigned-bold"
                    v-if="(infoReserva.isSeatedByServer === 0 && infoReserva.referrer !== 'Walk-In') || infoReserva.referrer == 'Walk-In'"
                  >Restaurante</span>
                  <span
                    class="assigned-bold"
                    v-if="infoReserva.isSeatedByServer === 1 && infoReserva.referrer !== 'Walk-In'"
                  >Automáticamente</span>
                </span>
                <span class="assigned">
                  Creada:
                  <br class="ofm" />
                  <span
                    class="assigned-bold"
                  >{{infoReserva.created_at | moment('DD')}} de {{infoReserva.created_at | moment('MMMM')}} de {{infoReserva.created_at | moment('YYYY')}} / {{infoReserva.created_at | moment('timezone',timezone,'hh:mm a')}}</span>
                </span>
              </div>
              <div class="columns is-multiline info_int_res">
                <div class="column is-4 is-12-mobile content_info_des created_inf">
                  <i class="icons icon_restaurant"></i>
                  <div class="subcontent_info_des">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Reserva creada por:</span>
                      <span class="assigned">{{created_for}}</span>
                    </span>
                  </div>
                </div>
                <div
                  class="column is-5 is-6-mobile content_info_des user_inf"
                  v-if="infoReserva.referrer == 'dashboard'"
                >
                  <i class="icons icon_user"></i>
                  <div class="subcontent_info_des">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Por usuario:</span>
                      <span class="assigned">{{infoReserva.creadoPor}}</span>
                    </span>
                  </div>
                </div>
                <div class="column is-3 is-6-mobile content_info_des id_inf">
                  <i class="icons icon_world"></i>
                  <div class="subcontent_info_des">
                    <span class="subcontent_info_des_item">
                      <span class="assigned" v-if="type == 'Precompra'">Id Precompro:</span>
                      <span class="assigned" v-if="type == 'Reserva'">Id Reserva:</span>
                      <span class="assigned" v-if="type == 'Walk-In'">Id Walk-In:</span>
                      <span class="assigned">{{infoReserva.id}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div :class="{halfSpace:params.activeSelectorBoy !== 1}" class="content_info_specific data">
              <div class="date_time_persons">
                <div class="content_info_des">
                  <i class="icons icon_user"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned" v-if="params.activeSelectorBoy === 1">Personas:</span>
                      <span class="assigned">{{infoReserva.people}}</span>
                    </span>
                  </div>
                </div>
                <div class="content_info_des" v-if="params.activeSelectorBoy === 1">
                  <i class="icons icon_adult_view"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Adultos:</span>
                      <span class="assigned">{{infoReserva.adult}}</span>
                    </span>
                  </div>
                </div>
                <div class="content_info_des" v-if="params.activeSelectorBoy === 1">
                  <i class="icons icon_boy_view"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Niños:</span>
                      <span class="assigned">{{infoReserva.boy + infoReserva.baby}}</span>
                    </span>
                  </div>
                </div>
                <div class="content_info_des content_info_des_date-aux only-web-view-data-reserve" :class="{'only-web-view-data-reserve-aux-tablet' : params.activeSelectorBoy === 1}">
                  <i class="icons icon_calendar_info"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned" v-if="params.activeSelectorBoy === 1">Fecha:</span>
                      <span class="assigned">{{dateReservationFormat(infoReserva)}}</span>
                    </span>
                  </div>
                </div>
                <div class="content_info_des only-web-view-data-reserve" :class="{'only-web-view-data-reserve-aux-tablet' : params.activeSelectorBoy === 1}">
                  <i class="icons icon_clock"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned" v-if="params.activeSelectorBoy === 1">Hora:</span>
                      <span class="assigned">{{hourReservationFormat(infoReserva)}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="content_info_specific--mobile" :class="{'content_info_specific--mobile-aux' : params.activeSelectorBoy === 1}" >
              <div :class="{'fullContentInfo': params.activeSelectorBoy}" class="date_time_persons" style="gap: 14px;">
                <div class="mx-2 content_info_des">
                  <i class="icons icon_calendar_info"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Fecha:</span>
                      <span class="assigned">{{dateReservationFormat(infoReserva)}}</span>
                    </span>
                  </div>
                </div>
                <div class="mx-2 content_info_des">
                  <i class="icons icon_clock"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Hora:</span>
                      <span class="assigned">{{hourReservationFormat(infoReserva)}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="{halfSpace:params.activeSelectorBoy!==1}"
              class="content_info_specific tableInformationContainer"
            >
              <div class="zone_table main_zone_table flex-between">
                <div class="content_info_des mx-2">
                  <i class="icons icon_zone"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Zona:</span>
                      <b-notification
                        v-if="!isMobile"
                        :active.sync="activePlus.showZone"
                        aria-close-label="Close notification"
                        style="font-size: 11px;"
                        class="custom_notification_res"
                      >
                        <strong>Zona:</strong>
                        <br />
                        <br />
                        {{ infoReserva.sectionName }}
                      </b-notification>
                      <span class="assigned">
                        {{ infoReserva.sectionName.length > 15 ? infoReserva.sectionName.substring(0, 12) + '...' : infoReserva.sectionName }}
                        <span
                          :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In'}"
                          @click="showPlusF('showZone', infoReserva.sectionName, 'Zona:')"
                          class="plus_tables assigned-bold"
                          v-if="infoReserva.sectionName.length > 15"
                        >+</span>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="content_info_des mx-2">
                  <i class="icons icon_table"></i>
                  <div class="subcontent_info_des second">
                    <span class="subcontent_info_des_item">
                      <span class="assigned">Mesa(s):</span>
                      <span class="assigned">
                        {{infoReserva.tableName.length > 15 ? infoReserva.tableName.substring(0, 15) + '...' : infoReserva.tableName}}
                        <span
                          :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In'}"
                          @click="showPlusF('showTable', infoReserva.tableName, 'Mesa(s):')"
                          class="plus_tables assigned-bold"
                          v-if="infoReserva.tableName.length > 15"
                        >+</span>
                      </span>
                      <b-notification
                        v-if="!isMobile"
                        :active.sync="activePlus.showTable"
                        aria-close-label="Close notification"
                        style="font-size: 11px;"
                        class="custom_notification_res"
                      >
                        <strong>Mesa(s):</strong>
                        <br />
                        <br />
                        {{infoReserva.tableName}}
                      </b-notification>
                      <b-modal v-if="isMobile" :active.sync="activePlus.showModal" has-modal-card :width="200" :can-cancel="false">
                        <div class="card" style="border-radius: 6px; max-width:200px;">
                          <div class="card-content" style="padding: 18px !important;">
                            <div class="content">
                              <div style="display: flex; justify-content: center; align-items: center;">
                                  <strong style="position: relative; bottom: 5px;" >{{ activePlus.header }}</strong>
                                  <strong @click="activePlus.showModal = false"
                                    style="font-size: 25px; position: relative; left: 60px; bottom:5px;cursor: pointer; color: #363636;"
                                  >&times;</strong>
                              </div>
                              {{ activePlus.content }}
                            </div>
                          </div>
                        </div>
                      </b-modal>
                    </span>
                  </div>
                </div>
                <div v-if="infoReserva.adjuntFile" class="content_info_des">
                  <i class="mdi mdi-paperclip mdi-24px" style="background: transparent; color: #00ADC6;"></i>
                  <a class="subcontent_info_des_item" :href="infoReserva.adjuntFile" target="_blank" style="color: #363636; text-decoration: underline">
                    <span class="assigned">Ver adjunto</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              :style="{ height: '60px'}"
              class="content_description"
              v-if="infoReserva.codeStatus === 7"
            >
              <div class="res_asigned_time">
                <span class="assigned">
                  Estado:
                  <br class="mdn" />
                  <span class="assigned-bold">Pendiente de pago</span>
                </span>
                <span class="created_time assigned">
                  Hora limite de pago:
                  <br class="mdn" />
                  <span class="assigned-bold">{{formatLimitPayment(infoReserva.created_at)}}</span>
                </span>
              </div>
            </div>
            <div
              class="content_info_specific tableInformationContainer order-6"
              v-if="params.isActiveHotel && infoReserva.isHotel"
            >
              <div class="columns zone_table main_zone_table">
                <div class="column is-6 content_info_des">
                  <div>
                    <i class="icons clientSimbol"></i>
                  </div>
                  <div class="subcontent_info_des sub_zt">
                    <span class="content_info_des_item">
                      <span class="assigned">Cliente:</span>
                      <span class="assigned-bold">Huésped</span>
                    </span>
                  </div>
                </div>
                <div class="column is-6 content_info_des">
                  <i
                    :class="{precompra:type == 'Precompra', walk:type == 'Walk-In'}"
                    class="icons habitationSimbol"
                  ></i>
                  <div class="subcontent_info_des sub_zt">
                    <span class="content_info_des_item">
                      <span class="assigned-bold">Habitación:</span>
                      <span class="assigned-bold">{{infoReserva.habitation}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="content_info_specific date_time_persons value"
              v-if="params.saveValueConsumed"
            >
              <div
                :class="{precompra:type == 'Precompra',walk:type == 'Walk-In'}"
                class="moneySimbol"
              ></div>
              <span class="content_info_des_item">
                <span class="assigned">Valor consumo:</span>
                <span class="assigned">$ {{infoReserva.valueConsumed ? infoReserva.valueConsumed : '0' | currency('', 0, { thousandsSeparator: '.' })}}</span>
              </span>
            </div>
            <div class="content_info_specific date_time_persons value" v-if="params.saveValueConsumed">
              <div class="invoiceNumberIcon" :class="{precompra:type == 'Precompra',walk:type == 'Walk-In'}"></div>
              <span class="content_info_des_item">
                <span class="assigned">Número de factura:</span>
                <span class="assigned">{{infoReserva.invoiceNumber ? infoReserva.invoiceNumber  : 'N/A' }}</span>
              </span>
            </div>
            <div class="content_info_specific" v-if="infoReserva.clientType !== null">
              <div class="client_type_reservation">
                <div style="display:flex">
                  <i class="icons icon_user"></i>
                  <div class="subcontent_info_des">
                    <span class="content_info_des_item">
                      <span class="assigned">Tipo de Cliente:</span>
                      <div style="margin-top: 5px">
                        {{infoReserva.clientType}}
                      </div>
                    </span>
                  </div>
                </div>
                <div class="optional-icon-margin">
                  <i
                    :class="{icon_room: (infoReserva.extraInfo !== null && infoReserva.extraInfo !== undefined && infoReserva.extraInfo.extraDescription == 'Habitación'), icon_car: (infoReserva.extraInfo !== null && infoReserva.extraInfo !== undefined && infoReserva.extraInfo.extraDescription == 'Matrícula del auto')}"
                    class="icons icon_room"
                    v-if="(infoReserva.guest !== null && infoReserva.extraInfo !== null && infoReserva.extraInfo !== undefined)"
                  ></i>
                  <div class="subcontent_info_des" v-if="infoReserva.guest !== null">
                    <span class="content_info_des_item">
                      <span class="assigned">{{(infoReserva.extraInfo !== null && infoReserva.extraInfo !== undefined && infoReserva.extraInfo.extraDescription)}}:</span>
                      <div style="margin-top: 5px">
                        {{infoReserva.guest}}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="content_transaction"
              v-if="infoReserva.transactionId || infoReserva.transactionMercadoPagoId || infoReserva.transactionWompiId"
            >
              <div class="container_transaction">
                <div class="subcontent_transaction" v-if="infoReserva.transactionId">
                  <i class="icon_payu"></i>
                  <div>{{infoReserva.transactionId}}</div>
                </div>
                <div class="subcontent_transaction" v-if="infoReserva.transactionMercadoPagoId">
                  <i class="icon_mercadopago"></i>
                  <div>{{infoReserva.transactionMercadoPagoId}}</div>
                </div>
                <div class="subcontent_transaction" v-if="infoReserva.transactionWompiId">
                  <i class="icon-mks icon_wompi"></i>
                  <div>{{infoReserva.transactionWompiId}}</div>
                </div>
              </div>
            </div>
            <div
              class="container_decoration_multiple"
              v-if="infoReserva.decoration == 1 && decorationsReservation.length > 0"
            >
              <div>
                <i class="icons icon_star"></i>
              </div>
              <div class="content_info_des_item sz12_decoration" id="decorations">
                <div class="title_decoration">
                  <span class="assigned">Tipo de decoración:</span>
                </div>
                <div class="decoration_icon_more">
                  <div class="div_decorations">
                    <span
                      :class="{'val' : validateSize()}"
                      :id="'span_decorations'+i"
                      :key="i"
                      class="span_decorations"
                      v-for="(dec, i) in decorationsReservation"
                    >{{cutDecName(dec.name)}}</span>
                  </div>
                  <div class="div_icon_more_decorations">
                    <i
                      @click="openDecorations()"
                      class="icons icon_more_decorations"
                      v-if="showMoreDecorations"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              @click="openExperiences()"
              class="column is-6 container_experiences experiences"
              v-if="params.activeExperiencies === 1 && listPersonPlates.length > 0"
            >
              <div class="subcontainer_experiences">
                <i class="icons icon_experiences"></i>
                <span class="assigned">Experiencias seleccionadas: {{ listPersonPlates.length }}</span>
              </div>
              <div class="end_experiences">
                <i class="icons icon_undo"></i>
              </div>
            </div>
            <div
              @click="showCompanion"
              class="column is-5 container_experiences desktop_mobile showCompanion"
              v-if="params.activeCompanion"
            >
              <div class="subcontainer_experiences">
                <i class="icons icon_companion"></i>
                <span class="assigned">Acompañantes</span>
              </div>
              <div class="end_experiences">
                <i class="icons icon_undo"></i>
              </div>
            </div>
            <div class="columns is-multiline multiContainer" v-if="infoReserva.decoration == 1 && decorationsReservation.length == 0">
              <div
                class="column is-5 container_decoration"
              >
                <div>
                  <i class="icons icon_star"></i>
                </div>
                <span class="content_info_des_item sz12_decoration in-line-down">
                  <span class="assigned">Tipo de decoración:</span>
                  <span class="assigned">{{infoReserva.decorationName}}</span>
                </span>
              </div>
            </div>
            <div class="columns is-multiline multiContainer" v-if="infoReserva.celebrationName != null">
              <div
                class="column is-5 container_decoration"
              >
                <div>
                  <i class="icons icon_celebration"></i>
                </div>
                <span class="content_info_des_item">
                  <span class="assigned">Celebración: {{infoReserva.celebrationName}}</span>
                  <span
                    class="assigned"
                    v-if="infoReserva.celebrationComment != null"
                  >{{infoReserva.celebrationComment}}</span>
                </span>
              </div>
            </div>
            <div class="columns is-multiline multiContainer" v-if="infoReserva.vendorId === 310 && infoReserva.tip > 0">
              <div
                class="column is-4 container_tip"
              >
                <div>
                  <i class="icons icon_tip"></i>
                </div>
                <span class="content_info_des_item in-line-down">
                  <span class="assigned">Propina:</span>
                  <span class="assigned">{{ infoReserva.tip | currency('$ ', 0, { thousandsSeparator: '.' })}}</span>
                </span>
              </div>
            </div>
            <div class="content_info_specific date_time_persons value" v-if="infoReserva.alergies != null">
              <div class="alergyIcon" :class="{ precompra:type == 'Precompra', walk:type == 'Walk-In' }"></div>
              <span class="content_info_des_item">
                <span class="assigned">Restricción alimentaria o alergia:</span>
                <span class="assigned">
                  {{infoReserva.alergies.length > 15 ? infoReserva.alergies.substring(0, 15) + '...'  : infoReserva.alergies }}
                  <span
                    :class="{'res' : type == 'Reserva', 'pre' : type == 'Precompra', 'walk' : type == 'Walk-In'}"
                    @click="showPlusF('showAlergy', infoReserva.alergies, 'Alergia(s):')"
                    class="plus_tables assigned-bold"
                    v-if="infoReserva.alergies != null && infoReserva.alergies.length > 15"
                  >+</span>
                </span>
                <b-notification
                  v-if="!isMobile"
                  :active.sync="activePlus.showAlergy"
                  aria-close-label="Close notification"
                  style="font-size: 11px;"
                  class="custom_notification_res"
                >
                  <strong>Alergia(s):</strong>
                  <br />
                  <br />
                  {{infoReserva.alergies}}
                </b-notification>
                <b-modal v-if="isMobile" :active.sync="activePlus.showModal" has-modal-card :width="200" :can-cancel="false">
                  <div class="card" style="border-radius: 6px; max-width:200px; min-height: 90px;">
                    <div class="card-content" style="padding: 10px !important; height: 100%;">
                      <div class="content" style="height: 100%;">
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                            <strong style="position: relative; width: 90%; text-align: center;">{{ activePlus.header }}</strong>
                            <strong @click="activePlus.showModal = false"
                              style="font-size: 16px; position: relative; right: 5px;cursor: pointer; color: #363636;"
                            >&times;</strong>
                        </div>
                        {{ activePlus.content }}
                      </div>
                    </div>
                  </div>
                </b-modal>
              </span>
            </div>
          </div>
        </div>
        <div class="middle_line"></div>
        <div class="column is-6 is-12-mobile comments_others">
          <b-tabs :animated="false" class="main_tabs" v-model="activeTab">
            <div class="columns tab_buttons">
              <button
                :class="{active: activeTab == 0}"
                @click="activeTab = 0"
                class="column tab_others"
              >
                <div>
                  <i class="icons icon_requirements"></i>
                </div>Comentarios
                <br class="ofm" />de reserva
              </button>
              <button
                :class="{active: activeTab == 1}"
                @click="activeTab = 1"
                class="column tab_others"
              >
                <div>
                  <i
                    class="icons icon_characteristics"
                    v-if="Object.keys(userTags).length == 0 && !Object.keys(userNotes).length"
                  ></i>
                  <img
                    class="img_characteristics"
                    src="@/assets/icons/viewReservation/icon_characteristics_active_1.svg"
                    v-if="(Object.keys(userTags).length > 0 || Object.keys(userNotes).length) && activeTab == 1"
                  />
                  <img
                    class="img_characteristics"
                    src="@/assets/icons/viewReservation/icon_characteristics_active_0.svg"
                    v-if="(Object.keys(userTags).length > 0 || Object.keys(userNotes).length) && activeTab == 0"
                  />
                </div>Características
                <br class="ofm" />del cliente
              </button>
            </div>
            <b-tab-item class="tab_items_res">
              <div class="container_requirements">
                <p
                  class="text void"
                  v-if="infoReserva.services.length == 0"
                >Sin especificar requerimientos</p>
                <div
                  :key="pos"
                  class="res_requirements"
                  v-for="(service, pos) in infoReserva.services"
                >
                  <div class="box_icons">
                    <img
                      :src="service.requirements.iconURL"
                      class="icon_requirements_specific"
                      href="javascript:void(0)"
                    />
                  </div>
                  <p>{{service.requirements.displayName}}</p>
                </div>
              </div>
              <div class="container_comments">
                <div class="comentario-box comentario-box-margin-bot">
                  <span class="text" v-if="infoReserva.comments">{{infoReserva.comments}}</span>
                  <p class="text void" v-else>Comentarios</p>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="tab_items_res">
              <div class="tags_cont">
                <div class="user_tags">
                  <label class="title_tags">Tags</label>
                  <b-field class="field_tags">
                    <b-tag :key="i" class="user_tag" v-for="(tag,i) in userTags">
                      <i class="icons icon_tag"></i>
                      {{tag}}
                    </b-tag>
                  </b-field>
                </div>
              </div>
              <div class="notas_cont">
                <label class="title_notes" v-if="userNotes == ''">Notas</label>
                <div class="notes" v-if="userNotes != ''">
                  <b-notification class="note note_personalized probando">{{userNotes}}</b-notification>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
          <div class="container_status">
            <!-- INFORMACION DE LOS ESTADOS -->
            <div
              v-if=" infoReserva.isConfirmed || infoReserva.isSeated || infoReserva.isFinish || infoReserva.isCancelled > 0 || infoReserva.isNoshow > 0 || infoReserva.isFinish"
            >
              <p class="text" v-if="infoReserva.isConfirmed">
                <span class="text_status">Estado:</span>
                {{ textConfirmed(infoReserva) }}
                <span v-if="infoReserva.confirmadaPor">
                  por
                  <b>{{infoReserva.confirmadaPor}}</b>
                </span>
                <span v-else>
                  <b>Automáticamente</b>
                </span>
              </p>
              <p class="text" v-if="infoReserva.isSeated">
                <span class="text_status">Estado:</span>
                {{ textSeated(infoReserva) }}
                <span v-if="infoReserva.sentadaPor">
                  por
                  <b>{{infoReserva.sentadaPor}}</b>
                </span>
              </p>
              <p class="text" v-if="infoReserva.isFinish">
                <span class="text_status">Estado:</span>
                {{ textFinish(infoReserva) }}
                <span v-if="infoReserva.finalizadaPor">
                  por
                  <b>{{infoReserva.finalizadaPor}}</b>
                </span>
              </p>
              <p class="text" v-if="infoReserva.isCancelled > 0">
                <span class="text_status">Estado:</span>
                {{ textCancelled(infoReserva) }}
                <span v-if="infoReserva.canceladaPor">
                  por
                  <b>{{infoReserva.canceladaPor}}</b>
                </span>
              </p>
              <p class="text" v-if="infoReserva.isNoshow > 0">
                <span class="text_status">Estado:</span>
                {{ textNoShow(infoReserva) }}
                <span v-if="infoReserva.nollegoPor">
                  por
                  <b>{{infoReserva.nollegoPor}}</b>
                </span>
              </p>
              <p class="text" v-if="infoReserva.isFinish">
                <span class="text_status">Duración total :</span>
                <b>{{ timeReservation(infoReserva) }}</b>
              </p>
            </div>
            <p class="text" v-else>
              <span class="text_status">Estado:</span>
              {{infoReserva.status}}
            </p>
            <!-- FIN INFORMACION DE LOS ESTADOS -->
          </div>
          <div
            class="container_cancel_comments"
            v-if="infoReserva.codeStatus === 4 && infoReserva.commentRestaurant != null"
          >
            <span class="title_cancel">Comentarios de cancelación</span>
            <span class="description_cancel">{{ infoReserva.commentRestaurant }}</span>
          </div>
        </div>
      </div>
      <!-- ACCIONES -->
      <div class="container_main_actions" v-if="!validatePermission('ReadOnly')">
        <div class="container_action_buttons">
          <div
            v-if="(infoReserva.codeStatus == 1 || infoReserva.codeStatus == 0 || infoReserva.codeStatus == 6) && accionLoadingS == false && (selectedDate == systemDate.substr(0,10) || selectedDate == yesterday) || accionLoadingS && (validatePermission('Seated') || validatePermission('MixSFCN')) && validateSittingOnlyHour(infoReserva)"
          >
            <button
              :disabled="viewBlocked"
              @click="seated(infoReserva.id)"
              class="btn_reservations"
              v-if="(infoReserva.codeStatus == 1 || infoReserva.codeStatus == 0 || infoReserva.codeStatus == 6) && accionLoadingS == false && (selectedDate == systemDate.substr(0,10) || selectedDate == yesterday) && (validatePermission('Seated') || validatePermission('MixSFCN')) && validateSittingOnlyHour(infoReserva)"
            >
              Sentar
              <i class="icons icon_chair"></i>
            </button>
            <button :disabled="viewBlocked" class="btn_reservations" v-if="accionLoadingS">
              <i class="fa fa-spinner fa-spin"></i>
              Sentando
            </button>
          </div>
          <div v-if="params.activatePartialSeated">
            <button
              :disabled="viewBlocked"
              @click.prevent.stop="partialseat(infoReserva.id)"
              class="btn_reservations seatpartialbtn"
              v-if="(infoReserva.codeStatus == 1 || infoReserva.codeStatus == 0) && accionLoadingS == false && (selectedDate == systemDate.substr(0,10) || selectedDate == yesterday) && (validatePermission('Seated') || validatePermission('MixSFCN')) && validateSittingOnlyHour(infoReserva)"
            >
              Sentar Parcial
              <img class="seatpartial" src="../../assets/icons/sentado-parcial.svg" />
            </button>
            <button :disabled="viewBlocked" class="btn_reservations" v-if="accionLoadingS">
              <i class="fa fa-spinner fa-spin"></i>
              Sentando
            </button>
          </div>
          <div
            v-if="(infoReserva.codeStatus == 2 && (accionLoadingF == false || infoReserva.codeStatus == 6) && accionLoadingF == false) || accionLoadingF"
          >
            <button
              :disabled="viewBlocked"
              @click="finish(infoReserva.id)"
              class="btn_reservations"
              v-if="infoReserva.codeStatus == 2 && (accionLoadingF == false || infoReserva.codeStatus == 6) && accionLoadingF == false && (validatePermission('Finish') || validatePermission('MixSFCN'))"
            >
              Finalizar
              <i class="icons icon_finish"></i>
            </button>
            <button :disabled="viewBlocked" class="btn_reservations" v-if="accionLoadingF">
              <i class="fa fa-spinner fa-spin"></i>
              Finalizando
            </button>
          </div>
          <div
            v-if="(infoReserva.codeStatus == 0 || infoReserva.codeStatus == 1) && systemDate <= getFormatDateComplete(infoReserva)"
          >
            <button :disabled="viewBlocked" @click="isCancelUser()" class="btn_reservations">
              Cancelar
              <i class="icons icon_cancel"></i>
            </button>
          </div>
          <div v-if="infoReserva.codeStatus == 7">
            <button :disabled="viewBlocked" @click="isCancelUser()" class="btn_reservations">
              Cancelar
              <i class="icons icon_cancel"></i>
            </button>
          </div>
          <div
            v-if="((infoReserva.codeStatus == 0 || infoReserva.codeStatus == 1) && accionLoadingN == false && getTimeReservation(infoReserva)) || accionLoadingN"
          >
            <button
              :disabled="viewBlocked"
              @click="noShow(infoReserva.id)"
              class="btn_reservations"
              v-if="(infoReserva.kushkiSubscriptionId == null && (infoReserva.codeStatus == 0 || infoReserva.codeStatus == 1) && accionLoadingN == false && getTimeReservation(infoReserva)) && (validatePermission('NoShow') || validatePermission('MixSFCN'))"
            >
              No Llegó
              <i class="icons icon_not_show"></i>
            </button>
            <button :disabled="viewBlocked" class="btn_reservations" v-if="accionLoadingN">
              <i class="fa fa-spinner fa-spin"></i>
              No Llegó
            </button>
          </div>
          <span class v-if="message != ''">{{message}}</span>
          <span class v-if="messageError != ''">{{messageError}}</span>
        </div>
      </div>
      <!-- FIN ACCIONES -->
      <!-- ACCIONES DE REVERSADO -->
      <div
        class="container_action_undo"
        v-if="!validatePermission('ReadOnly') && infoReserva.fecha >= getValidateDay()"
      >
        <div class="subcontainer_action_undo">
          <span class="action_undo" v-if="reverseLoading">
            <i class="fa fa-spinner fa-spin" style="margin-right:10px;"></i>Procesando petición
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.codeStatus == 1 && !isConfirmadas && reverseLoading == false && infoReserva.status != 'Reconfirmada'"
          >
            <span class="sub-tittle">¿Se confirmó esta reserva por accidente?</span>
            <button
              :disabled="viewBlocked"
              @click="oldStatus('isConfirmed',infoReserva.id)"
              class="btn_undo"
            >
              <i class="icons icon_undo"></i> Deshacer
            </button>
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.codeStatus == 1 && !isConfirmadas && reverseLoading == false && infoReserva.status == 'Reconfirmada'"
          >
            <span class="sub-tittle">¿Se reconfirmó esta reserva por accidente?</span>
            <button
              :disabled="viewBlocked"
              @click="oldStatus('isConfirmed',infoReserva.id)"
              class="btn_undo"
            >
              <i class="icons icon_undo"></i> Deshacer
            </button>
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.codeStatus == 2 && infoReserva.referrer !== 'Walk-In' && reverseLoading == false"
          >
            <span class="sub-tittle">¿Sentaste esta reserva por accidente?</span>
            <button
              :disabled="viewBlocked"
              @click="oldStatus('isSeated',infoReserva.id)"
              class="btn_undo"
            >
              <i class="icons icon_undo"></i>Deshacer
            </button>
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.codeStatus == 2 && infoReserva.referrer === 'Walk-In' && reverseLoading == false"
          >
            <span class="sub-tittle">¿Sentaste este walk-in por accidente?</span>
            <button
              :disabled="viewBlocked"
              @click="oldWalkin(infoReserva.id)"
              class="btn_undo"
            >
              <i class="icons icon_undo"></i>Deshacer
            </button>
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.codeStatus == 6  && infoReserva.referrer !== 'Walk-In' && reverseLoading == false"
          >
            <span class="sub-tittle">¿Sentaste Parcial esta reserva por accidente?</span>
            <button
              :disabled="viewBlocked"
              @click="oldStatus('isPartialSeated',infoReserva.id)"
              class="btn_undo"
            >
              <i class="icons icon_undo"></i>Deshacer
            </button>
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.isPaidKushki != 1 && infoReserva.codeStatus == 3 && reverseLoading == false"
          >
            <span class="sub-tittle">¿Se finalizó esta reserva por accidente?</span>
            <button
              :disabled="viewBlocked"
              @click="oldStatus('isFinish',infoReserva.id)"
              class="btn_undo"
            >
              <i class="icons icon_undo"></i>Deshacer
            </button>
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.isPaidKushki != 1 && infoReserva.codeStatus == 4 && infoReserva.paymentLink == null && reverseLoading == false"
          >
            <span class="sub-tittle">¿Se cancelo esta reserva por accidente?</span>
            <button :disabled="viewBlocked" @click="showStatusCancelled()" class="btn_undo">
              <i class="icons icon_undo"></i>Deshacer
            </button>
          </span>
          <span
            class="action_undo"
            v-if="infoReserva.isPaidKushki != 1 && infoReserva.codeStatus == 5 && reverseLoading == false"
          >
            <span class="sub-tittle">¿Se finalizó esta reserva por accidente?</span>
            <button
              :disabled="viewBlocked"
              @click="oldStatus('isNoshow',infoReserva.id)"
              class="btn_undo"
            >
              <i class="icons icon_undo"></i>Deshacer
            </button>
          </span>
        </div>
      </div>
      <!-- FIN ACCIONES DE REVERSADO -->
      <HistoricoReserva
        :dataInfoReserva="infoReserva"
        :dataReserva="infoReservaHistory"
        :infoAdmin="infoReservaHistoryAdmin"
        @close="closeHistorico"
        :showTab="tabActive"
        v-if="historyReservationShow"
      ></HistoricoReserva>
      <editUser
        :dataUser="dataUser"
        @close="closedDetailUser"
        @reloadTags="loadTags"
        v-if="showDetail"
      ></editUser>
      <!-- MODAL CANCELACIÓN -->
      <div id="modal_cancel">
        <b-modal :active.sync="cancelShow" custom-class="new-modal-cancel" style="height: auto;z-index: 998 !important;">
          <section class="modal-card-body">
            <p class="modal-card-title warning-title mb-16">
              <i class="icon_warning_outline_rounded icon-mks red"></i>Confirmar Cancelación
            </p>
            <template>
              <div class="container-columns">
                <p class="text confirmation-text">
                  ¿Estás seguro(a) que quieres cancelar esta reserva ?
                </p>
                <p class="text small-confirmation-text mb-16">*Ten en cuenta que esta acción no se podrá deshacer, y el cliente será notificado al correo electrónico.</p>
                <div class="host-password-container">
                  <span class="label-host">A continuación debes seleccionar la persona que cancela la reserva</span>
                  <b-field
                    v-if="hostList.length > 0"
                    class="host-list"
                  >
                    <span id="select-host-input-container">
                      <b-select
                        @input="validatePin(valueId)"
                        expanded
                        placeholder="Seleccionar"
                        v-model="valueId"
                        class="host-input"
                      >
                        <option
                          :key="'selHost-' + option.value"
                          :value="option.value"
                          v-for="option in hostList"
                        >{{ option.label }}</option>
                      </b-select>
                      <i class="icon_arrow_right_map icon-mks black"></i>
                    </span>
                  </b-field>

                  <b-field custom-class="has-text-weight-normal label-custom-cancel" v-if="showPin">
                    <b-input @input="changePin(valuePin2)" type="password" v-model="valuePin2" placeholder="Clave host" class="password-input-cancel"></b-input>
                  </b-field>
                </div>

                <b-field
                  custom-class="has-text-weight-normal label-custom-cancel"
                  style="margin: 16px 0 0 0;"
                >
                  <template #label>
                    <span>Motivo de cancelación (opcional)</span>
                    <span class="message-span-modal-cancel" >*Este mensaje le llegará al cliente en su correo de cancelación</span>
                  </template>
                  <b-input
                    custom-class="textarea-modal-cancel"
                    maxlength="200"
                    placeholder="Escribe aquí tu comentario"
                    size="is-large"
                    type="textarea"
                    v-model="cancelComment"
                  ></b-input>
                </b-field>
                <b-field
                  custom-class="has-text-weight-normal label-custom-cancel"
                  style="margin-bottom: 0px"
                >
                  <template #label>
                    Comentario para el restaurante
                    <span>(opcional)</span>
                    <span class="message-span-modal-cancel" >*Este mensaje es únicamente para el restaurante</span>
                  </template>
                  <b-input
                    custom-class="textarea-modal-cancel"
                    maxlength="200"
                    placeholder="Escribe aquí tu comentario"
                    size="is-large"
                    type="textarea"
                    v-model="cancelCommentRestaurant"
                  ></b-input>
                </b-field>
                <div class="field refund-field" v-if="infoReserva.balancePaid > 0">
                  <span class="textRefund">
                    Esta reserva tiene un anticipo de
                    {{infoReserva.balancePaid | currency('$ ', 0, { thousandsSeparator: '.' })}}. ¿Quieres hacerle la devolución del dinero al cliente?
                  </span>
                  <div class="block refund-radio">
                    <b-checkbox v-model="returnYes" @input="toggleReturn(true)">Si</b-checkbox>
                    <b-checkbox v-model="returnNo" @input="toggleReturn(false)">No</b-checkbox>
                  </div>
                </div>
              </div>
              <div class="buttons-cancel">
                <a @click="cancel(infoReserva.id)" class="btn" v-if="hostList.length == 0" >Confirmar</a>
                <button @click="cancel(infoReserva.id)" class="btn" v-else :disabled="valueId == null || disableConfirm" >Confirmar</button>
                <a @click="cancelShow = false" class="btn btn-inverse">Cerrar</a>
              </div>
            </template>
          </section>
        </b-modal>

        <b-modal :active.sync="oldCancelShow" id="undo-cancellation">
          <section class="modal-card-body">
            <p class="modal-card-title warning-title mb-16">
              <i class="icon_undo_arrows icon-mks black-pre"></i>Deshacer
            </p>
            <div class="container-columns">
              <p class="undo-text">
                ¿Estás seguro(a) de que quieres <br> volver a crear esta reserva ?
              </p>
            </div>

            <div class="buttons-cancel">
              <a @click="oldStatusCancelled(infoReserva.id)" class="btn">Si</a>
              <a @click="oldCancelShow = false" class="btn btn-inverse">No</a>
            </div>
          </section>
        </b-modal>
      </div>
      <!-- OTROS MODALES -->
    </div>
  </section>
</template>
<script>
import _ from 'lodash';
import HistoricoReserva from '@/components/_shared/HistoricoReserva';
import GeneralTags from '@/components/Extends/GeneralTags';
import globalTypes from '@/store/types/global';
import reservationTypes from '@/store/types/reservation';
import { mapGetters } from 'vuex';
import editUser from '@/components/_shared/users/editUser';
import RadialProgressBar from 'vue-radial-progress';
import ModalExperiences from './ModalExperiences.vue';
import ModalDecorations from './ModalDecorations.vue';
import moment from 'moment-timezone';
import serviceVendor from '@/helpers/apis/serviceVendor';
import serviceReservation from '@/helpers/apis/serviceReservation';
// import ViewReservation from '@/components/_shared/AddReservation.vue';

export default {
  components: {
    HistoricoReserva,
    editUser,
    RadialProgressBar,
    GeneralTags
    // ViewReservation
  },
  props: ['type', 'infoReserva'],
  data () {
    return {
      showMoreDecorations: false,
      decorationsReservation: [],
      decorationMultiplePrice: 0,
      rowCollapse: false,
      tablesReservationActual: '',
      showPlus: false,
      activePlus: {
        showZone: false,
        showTable: false,
        showModal: false,
        showAlergy: false,
        content: '',
        header: ''
      },
      created_for: '',
      cancelUser: 0,
      showUser: 0,
      notShowUser: 0,
      totalReservations: 0,
      lastReservation: '',
      walkin: 0,
      weekdays: [
        'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'
      ],
      months: [
        'Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ],
      yesterday: null,
      hostList: [],
      reservationScore: 0,
      reservationComments: this.infoReserva.vendorComments
        ? this.infoReserva.vendorComments
        : '',
      editBirth: false,
      birthdayEdit: null,
      cancelShow: false,
      cancelComment: '',
      cancelCommentRestaurant: '',
      message: '',
      load: false,
      messageError: '',
      historyLoading: false,
      accionLoadingEmail: false,
      accionLoadingS: false,
      accionLoadingF: false,
      accionLoadingN: false,
      reverseLoading: false,
      accionLoadingEliminar: false,
      historyReservationShow: false,
      cancelStatus: false,
      infoReservaHistory: [],
      createdAt: '',
      valueId: null,
      viewBlocked: false,
      showPin: false,
      valuePin: null,
      valuePin2: null,
      disableConfirm: true,
      clock: moment().valueOf(),
      infoReservaHistoryAdmin: false,
      valueConsumed: this.valueConsumed ? this.valueConsumed : '',
      oldCancelShow: false,
      showValueConsumedEdit: false,
      selectedAdditional: null,
      showDetail: false,
      dataUser: [],
      activeTab: 0,
      userTags: [],
      userNotes: '',
      showLoader: false,
      isLoading: false,
      editTemp: false,
      tempNumEdit: 0,
      reservation: {
        id: this.infoReserva.id,
        people: this.infoReserva.people,
        date: null,
        hour: parseInt(this.infoReserva.date),
        selectZone: [],
        address: this.infoReserva.address,
        selectTable: [],
        displayName: this.infoReserva.displayName,
        email: (this.infoReserva.email != null) ? this.infoReserva.email : '',
        identityDocument: this.infoReserva.identityDocument,
        phone: this.infoReserva.phone,
        subscribed: this.infoReserva.subscribed,
        indicativo: this.infoReserva.indicativo,
        pais: 'Colombia ( +57 )',
        paises: [],
        bandera: this.infoReserva.bandera,
        amenities: [],
        comments: this.infoReserva.comments,
        loading: false,
        errorMail: null,
        hostId: null,
        referrer: this.infoReserva.referrer,
        originD: null,
        originP: null,
        experienceName: this.infoReserva.experienceName,
        newOriginP: null,
        newOriginD: null,
        creadoPor: this.infoReserva.creadoPor,
        tags: [],
        temperature: this.infoReserva.temperature,
        adjuntFile: this.infoReserva.adjuntFile,
        sendToClient: this.infoReserva.sendToClient,
        notes: [],
        isVip: this.infoReserva.isVip,
        isRecommended: this.infoReserva.isRecommended,
        isHabitual: this.infoReserva.isHabitual
      },
      clientTypes: [],
      listPersonPlates: [],
      listExp: [],
      listMainPlates: [],
      generateRefund: false,
      returnYes: false,
      returnNo: true,
      listFiles: [],
      tabActive: 0
    };
  },
  created () {
    this.$store.commit(reservationTypes.mutations.setFrontModal, true);
  },
  mounted () {
    this.yesterday = this.$moment(this.systemDate).subtract(1, 'days').format('YYYY-MM-DD');
    this.getDataHost();
    switch (this.infoReserva.referrer) {
      case 'whitelabel':
      case 'google':
        this.created_for = 'Cliente';
        break;
      case 'dashboard':
        this.created_for = 'Restaurante';
        break;
      case 'precompro':
        this.created_for = 'Precompro.com';
        break;
      default:
        this.created_for = '';
        break;
    }
    if (this.params.activeMainPlate === 1) {
      this.getListMainPlate();
      this.getListOrdenPlates();
    }
    if (this.params.activeExperiencies === 1) {
      this.getListExperience();
      this.getListOrdenPlates();
    }
    this.interval = setInterval(() => {
      this.clock = this.$moment().valueOf();
    }, 1000);
    var bodyEl = document.body;
    // eslint-disable-next-line
    classie.remove(bodyEl, 'show-right-bar')
    if (this.fromTimeLine) {
      // eslint-disable-next-line no-undef
      classie.remove(bodyEl, 'cronology');
    }
    if (this.infoReserva.peopleId != null) {
      this.loadTags();
    }
    if (this.params.activateAdditionalQuestions === 1) { // buscar preguntas adicionales solo para determinados vendorsId
      this.$store.dispatch({ type: 'reservation:getAdditionalQuestions', data: { vendorId: this.idVendor } })
        .then(() => {
          this.$store.dispatch({ type: 'reservation:getAnswers', data: { reservationId: this.infoReserva.id } })
            .then(response => {
              if (response) {
                this.selectedAdditional = (Object.keys(response.answers).length > 0) ? response.answers : {};
              }
            });
        });
    }
    if (this.params.activeSelectorClient === 1) {
      this.infoReserva.extraInfo = null;
      this.getDataClientType();
    }
    if (this.infoReserva.adjuntFile != null && this.params.sendToClient) {
      let items = this.infoReserva.adjuntFile;
      items = items.split(',');
      this.listFiles = items;
    } else {
      this.listFiles = [];
    }
    this.$store.dispatch({
      type: 'global:getServerTime'
    });
    if (this.infoReserva.services === null) {
      this.infoReserva.services = [];
    }
    this.searchUser();

    this.verifyTables(this.infoReserva.tableName);
    if (this.infoReserva.decoration === 1) {
      this.listDecorationMultiple();
    }
    this.showMoreDecorations = false;
    if (this.infoReserva.lastReservation) {
      this.lastReservation = this.$moment(this.infoReserva.lastReservation.fechaCompleta).format('DD/MM/YY');
      this.tabActive = 2;
    }
  },
  destroyed () {
    if (this.fromTimeLine) {
      const bodyEl = document.body;
      // eslint-disable-next-line no-undef
      classie.add(bodyEl, 'cronology');
    }
    clearInterval(this.interval);
    this.setCloseViewReservation();
  },
  computed: {
    ...mapGetters({
      additionalQuestions: [reservationTypes.getters.additionalQuestions],
      systemDate: [globalTypes.getters.serverTime],
      fromTimeLine: [reservationTypes.getters.fromTimeLine]
    }),
    isOpen: {
      get () {
        return this.value;
      },
      set () {
        this.setCloseViewReservation();
      }
    }
  },
  methods: {
    toggleReturn (value) {
      this.generateRefund = value;
      if (value) {
        this.returnNo = false;
      } else {
        this.returnYes = false;
      }
    },
    getValidateDay () {
      return this.$moment().format('YYYY-MM-DD');
    },
    getDataClientType () {
      // this.isLoading = true;
      serviceVendor.get(`origin/clientTypes/${this.vendor.id}`).then(({ data }) => {
        // this.isLoading = false;
        this.clientTypes = data;
        this.infoReserva.extraInfo = data.find(cl => cl.name === this.infoReserva.clientType) || null;
      }).catch((err) => {
        console.error(err);
        // this.isLoading = false;
      });
    },
    validateReservationEditable () {
      let response = false;
      if (this.infoReserva.fecha >= this.$moment().format('YYYY-MM-DD')) {
        response = true;
      } else {
        if (this.user.isAdmin) {
          response = true;
        }
      }
      return response;
    },
    validateSize () {
      if (this.decorationsReservation.length > 0) {
        let cond = 0;
        let cont = 0;
        const lengthDec = this.decorationsReservation.length;
        do {
          const element = document.getElementsByClassName('sz12_decoration');
          cont++;
          if ((element[0].clientHeight > 25 && screen.width > 1150) || (element[0].clientHeight > 35 && screen.width > 835) || (element[0].clientHeight > 25 && screen.width > 500) || (element[0].clientHeight > 30 && screen.width > 320)) {
            if (lengthDec > 1) {
              const dec = document.getElementById(`span_decorations${lengthDec - cont}`);
              dec.style.display = 'none';
              this.showMoreDecorations = true;
            }
          } else {
            cond = 1;
          }
          cond = 1;
        } while (cond === 0);
      }
    },
    cutDecName (name) {
      if (screen.width > 1150) {
        if (name.length > 36) {
          const cutName = name.substring(0, 34);
          return cutName + '...';
        }
        return name;
      }
      if (screen.width > 835) {
        if (name.length > 22) {
          const cutName = name.substring(0, 22);
          return cutName + '...';
        }
        return name;
      }
      if (screen.width > 500) {
        if (name.length > 36) {
          const cutName = name.substring(0, 36);
          return cutName + '...';
        }
        return name;
      }
      if (screen.width >= 320) {
        if (name.length > 10) {
          const cutName = name.substring(0, 10);
          return cutName + '...';
        }
        return name;
      }
    },
    listDecorationMultiple () {
      // this.isLoading = true;
      serviceReservation.get(`dashboard/decoration/${this.infoReserva.id}`).then(({ data }) => {
        // this.isLoading = false;
        this.decorationsReservation = data.data;
        if (this.decorationsReservation.length > 0) {
          this.obtainPriceDecorationMultiple();
        }
      }).catch((err) => {
        console.error(err);
        // this.isLoading = false;
      });
    },
    obtainPriceDecorationMultiple () {
      const { price } = this.decorationsReservation.reduce((a, b) => ({ price: a.price + b.price }));
      this.decorationMultiplePrice = price;
    },
    openDecorations () {
      this.$buefy.modal.open({
        parent: this,
        props: {
          decorations: this.decorationsReservation
        },
        canCancel: false,
        component: ModalDecorations,
        customClass: 'modalDecorations'
      });
    },
    openExperiences () {
      this.$buefy.modal.open({
        parent: this,
        props: {
          experiences: this.listPersonPlates,
          type: this.type,
          listMainPlates: this.listExp
        },
        canCancel: false,
        component: ModalExperiences,
        customClass: 'modalExperiences'
      });
    },
    verifyTables (tables) {
      this.showPlus = false;
      if (tables.split(',').length < 2) {
        this.tablesReservationActual = tables;
        return;
      }
      if (tables.split(',').length > 2 && tables.length <= 8) {
        this.tablesReservationActual = tables;
        return;
      }
      if (tables.length > 5) {
        let tablesRecorted = tables.slice(0, 5);
        tablesRecorted += '...';
        this.showPlus = true;
        this.tablesReservationActual = tablesRecorted;
        return;
      }
      this.tablesReservationActual = tables;
    },
    percentReservationInt (number) {
      if (this.totalReservations === 0) {
        return 0;
      }
      const percent = (number * 100) / this.totalReservations;
      return parseInt(percent);
    },
    percentReservation (number) {
      if (this.totalReservations === 0) {
        return 0;
      }
      const percent = (number * 100) / this.totalReservations;
      return parseFloat(percent.toFixed(2));
    },
    download (link) {
      window.open(link);
    },
    transf (file) {
      file = file.replace(/%20/g, ' ');
      const name = file.replace('https://precompro.nyc3.digitaloceanspaces.com/Adjunt/' + this.vendor.displayName + '/', '');
      return name;
    },
    getListOrdenPlates () {
      // this.isLoading = true;
      serviceReservation.get(`dashboard/plates/${this.infoReserva.id}`).then(({ data }) => {
        // this.isLoading = false;
        this.listPersonPlates = data.data;
      }).catch((err) => {
        console.error(err);
        // this.isLoading = false;
      });
    },
    getListMainPlate () {
      // this.isLoading = true;
      serviceVendor.get(`/plates/restaurant/${parseInt(this.idVendor)}`).then(({ data }) => {
        this.listMainPlates = data.data;
        // this.isLoading = false;
      }).catch((err) => {
        this.listMainPlates = [];
        console.error(err);
        // this.isLoading = false;
      });
    },
    getListExperience () {
      // this.isLoading = true;
      serviceVendor.get(`/experience/${parseInt(this.idVendor)}`).then(({ data }) => {
        this.listExp = data.data;
        // this.isLoading = false;
      }).catch((err) => {
        this.listExp = [];
        console.error(err);
        // this.isLoading = false;
      });
    },
    searchPlateName (id) {
      const obj = this.listMainPlates.find(x => x.id === id);
      return obj.name;
    },
    searchExperienceDescription (id) {
      const obj = this.listExp.find(x => x._id === id);
      return obj.description;
    },
    searchPlateDescrition (id) {
      const obj = this.listMainPlates.find(x => x.id === id);
      if (obj.description !== undefined) {
        return obj.description;
      }
      return '';
    },
    showCompanion () {
      this.$emit('openCompanion', true);
    },
    loadTags () {
      this.$store.dispatch({ type: 'people:getOneUser', data: { id: this.infoReserva.peopleId } }).then(({ data }) => {
        if (data.tags !== undefined) {
          this.userTags = data.tags;
        }
        if (data.notes !== undefined) {
          this.userNotes = data.notes;
        }
        this.walkin = data.walkin;
      });
    },
    // editValueConsumed () {
    //   this.showValueConsumedEdit = !this.showValueConsumedEdit;
    // },
    // saveNewValueConsumed () {
    //   const id = this.infoReserva.id;
    //   this.$store.dispatch({ type: 'reservation:editValueConsumed', data: { reservationId: id, userId: this.user.id, valueConsumed: this.valueConsumed } }).then(({ data }) => {
    //     if (parseInt(data.code) === 200) {
    //       this.infoReserva.valueConsumed = this.valueConsumed;
    //       this.showValueConsumedEdit = false;
    //     }
    //   });
    // },
    textConfirmed (data) {
      if (this.infoReserva.status === 'Reconfirmada') {
        return (
          'Reconfirmada el ' +
          this.$moment(parseInt(data.isConfirmed))
            .format('DD MMMM') +
          ' a las ' +
          this.$moment(parseInt(data.isConfirmed))
            .format('hh:mm:ss a')
        );
      }
      return (
        'Confirmada el ' +
        this.$moment(parseInt(data.isConfirmed))
          .format('DD MMMM') +
        ' a las ' +
        this.$moment(parseInt(data.isConfirmed))
          .format('hh:mm:ss a')
      );
    },
    partialseat (id) {
      this.closeLateralMenu();
      this.accionLoadingS = true;
      this.viewBlocked = true;
      this.$store.dispatch({ type: 'reservation:reservationIsSeatedPartial', data: { reservationId: id, userId: this.user.id } }).then(({ data }) => {
        this.closeModal('accionLoadingS');
      }).catch(() => {
        this.accionLoadingS = false;
      }).finally(() => {
        this.accionLoadingS = false;
      });
    },
    isOpenSet () {
      this.isOpen = false;
      this.$store.commit(reservationTypes.mutations.setFrontModal, false);
      var bodyEl = document.body;
      // eslint-disable-next-line
      if (!this.fromTimeLine) {
        // eslint-disable-next-line no-undef
        classie.add(bodyEl, 'show-right-bar');
      }
      this.$emit('companionClose', false);
    },
    textSeated (data) {
      if (data.referrer === 'Walk-In') {
        return (
          'Sentada el ' +
          this.$moment(parseInt(data.isSeatedWalkIn))
            .format('DD MMMM') +
          ' a las ' +
          this.$moment(parseInt(data.isSeatedWalkIn))
            .format('hh:mm:ss a')
        );
      } else {
        return (
          'Sentada el ' +
          this.$moment(parseInt(data.isSeated))
            .format('DD MMMM') +
          ' a las ' +
          this.$moment(parseInt(data.isSeated))
            .format('hh:mm:ss a')
        );
      }
    },
    textFinish (data) {
      return (
        'Finalizada: ' +
        this.$moment(parseInt(data.isFinish))
          .format('DD MMMM') +
        ' a las ' +
        this.$moment(parseInt(data.isFinish))
          .format('hh:mm:ss a')
      );
    },
    textCancelled (data) {
      return (
        'Cancelada el ' +
        this.$moment(parseInt(data.isCancelled))
          .format('DD MMMM') +
        ' a las ' +
        this.$moment(parseInt(data.isCancelled))
          .format('hh:mm:ss a')
      );
    },
    textNoShow (data) {
      return (
        'No Llegò el ' +
        this.$moment(parseInt(data.isNoshow))
          .format('DD MMMM') +
        ' a las ' +
        this.$moment(parseInt(data.isNoshow))
          .format('hh:mm:ss a')
      );
    },
    dateReservationFormat (data) {
      const infoReser = JSON.parse(JSON.stringify(data));
      this.$moment.updateLocale('es', {
        longDateFormat: {
          L: 'MM/DD/YY'
        }
      });
      if (String(infoReser.date)) {
        if (infoReser.date.length === 13) {
          return this.$moment(parseInt(infoReser.date)).format('L');
        } else {
          return this.$moment(infoReser.date).format('L');
        }
      }
      if (Number(infoReser.date)) {
        return this.$moment(parseInt(infoReser.date)).format('L');
      }
    },
    hourReservationFormat (data) {
      const infoReser = JSON.parse(JSON.stringify(data));
      if (String(infoReser.date)) {
        if (infoReser.date.length === 13) {
          return this.$moment(parseInt(infoReser.date)).format('hh:mm a');
        } else {
          return this.$moment(infoReser.date).format('hh:mm a');
        }
      }
      if (Number(infoReser.date)) {
        return this.$moment(parseInt(infoReser.date)).format('hh:mm a');
      }
    },
    closeHistorico () {
      this.infoReservaHistory = [];
      this.historyReservationShow = false;
    },
    getDataHost () {
      this.$store
        .dispatch({
          type: 'reservation:searchHost',
          data: { vendorId: this.vendorId, userId: this.user.id }
        })
        .then(data => {
          const aux = [];
          const hostes = data.data;
          hostes.map(function (data) {
            aux.push({ value: data.id, label: data.nombres, pin: data.pin });
          });
          if (aux.length > 0) {
            this.hostList = [];
            this.hostList = aux;
          } else {
            this.hostList = [];
            this.hostList = aux;
          }
        });
    },
    historial (id, tab = 0) {
      this.historyLoading = true;
      this.$store
        .dispatch({
          type: 'reservation:getInfoTracking',
          data: { reservationId: id, userId: this.user.id }
        })
        .then(({ data }) => {
          if (parseInt(data.code) === 200) {
            this.historyLoading = false;
            this.historyReservationShow = true;
            const info = data.data;
            this.infoReservaHistoryAdmin = this.user.isAdmin; // si es admin
            this.infoReservaHistory = info;
            this.tabActive = tab;
          }
        });
    },
    // copiaEmail (reservationId) {
    //   this.accionLoadingEmail = true;
    //   this.viewBlocked = true;
    //   if (this.viewBlocked) {
    //     const userId = this.user.id;
    //     this.$store
    //       .dispatch({
    //         type: 'reservation:reservationEmail',
    //         data: { reservationId: reservationId, userId: userId }
    //       })
    //       .then(() => {
    //         this.$buefy.notification.open({
    //           message: '<i class="icon_alert_success icon-mks green"></i> ¡ La copia del email fue enviado con éxito !',
    //           type: 'is-success'
    //         });
    //         this.accionLoadingEmail = false;
    //         this.viewBlocked = false;
    //       })
    //       .catch(() => {
    //         this.$buefy.notification.open({
    //           message: '<i class="icon_alert_danger icon-mks red"></i> ¡ La copia del email no pudo ser enviado !',
    //           type: 'is-danger'
    //         });
    //         this.accionLoadingEmail = false;
    //         this.viewBlocked = false;
    //       });
    //   }
    // },
    // eliminarReserva (reservationId) {
    //   this.isLoading = true;
    //   this.accionLoadingEliminar = true;
    //   this.viewBlocked = true;
    //   if (this.viewBlocked) {
    //     const userId = this.user.id;
    //     this.$store
    //       .dispatch({
    //         type: 'reservation:reservationEliminar',
    //         data: { reservationId: reservationId, userId: userId }
    //       })
    //       .then(({ data }) => {
    //         if (data.code === 200) {
    //           this.$buefy.notification.open({
    //             message: '<i class="icon_alert_success icon-mks green"></i> ¡ La reserva fue eliminada con éxito !',
    //             type: 'is-success'
    //           });
    //           this.isOpen = false;
    //           var bodyEl = document.body;
    //           // eslint-disable-next-line
    //           classie.add(bodyEl, 'show-right-bar')
    //         } else {
    //           this.messageError = data.message;
    //         }
    //         this.viewBlocked = false;
    //         this.isLoading = false;
    //         this.accionLoadingEliminar = false;
    //       });
    //   }
    // },
    finish (reservationId) {
      this.closeLateralMenu();
      this.accionLoadingF = true;
      this.viewBlocked = true;
      if (this.viewBlocked) {
        const userId = this.user.id;
        const score = this.reservationScore;
        const comments = this.reservationComments;
        const valueConsumed = this.infoReserva.valueConsumed;
        this.$store
          .dispatch({
            type: 'reservation:reservationIsFinish',
            data: {
              reservationId: reservationId,
              userId: userId,
              comments: comments,
              score: score,
              valueConsumed: valueConsumed
            }
          })
          .then(() => {
            this.closeModal('accionLoadingF');
          });
      }
    },
    seated (reservationId) {
      this.closeLateralMenu();
      this.accionLoadingS = true;
      this.viewBlocked = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsSeated',
          data: { reservationId: reservationId, userId: userId }
        })
        .then(() => {
          this.closeModal('accionLoadingS');
        });
    },
    cancel (reservationId) {
      this.isLoading = true;
      this.viewBlocked = true;
      const userId = this.user.id;
      const hostId = this.createdAt;
      const comments = this.cancelComment;
      const commnetsRestaurant = this.cancelCommentRestaurant;
      const generateRefund = this.generateRefund;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsCancelled',
          data: {
            reservationId: reservationId,
            userId: userId,
            hostId: hostId,
            comments: comments,
            commentRestaurant: commnetsRestaurant,
            generateRefund: generateRefund,
            vendorId: this.vendor.id
          }
        })
        .then(({ data }) => {
          this.isOpen = false;
          this.isLoading = false;
          this.showLoader = false;
          if (data.code === 200) {
            this.$buefy.notification.open({
              message: '<i class="icon_alert_success icon-mks green"></i> Se ha cancelado la reserva con éxito',
              type: 'is-success'
            });
            this.closeModal();
          } else {
            this.$buefy.notification.open({
              message: '<i class="icon_alert_danger icon-mks red"></i> No se ha podido realizar la cancelación de la reserva',
              type: 'is-danger'
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    noShow (reservationId) {
      this.closeLateralMenu();
      this.showLoader = false;
      this.accionLoadingN = true;
      this.viewBlocked = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:reservationIsNoShow',
          data: { reservationId: reservationId, userId: userId }
        })
        .then(() => {
          this.closeModal('accionLoadingN');
        });
    },
    oldStatus (type, reservationId) {
      this.viewBlocked = true;
      this.reverseLoading = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatus',
          data: { reservationId: reservationId, item: type, userId: userId }
        })
        .then(() => {
          this.closeModal('accionLoadingS');
        });
    },
    oldStatusCancelled (reservationId) {
      this.viewBlocked = true;
      this.reverseLoading = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatusCancelled',
          data: { reservationId, userId }
        })
        .then(({ data }) => {
          this.reverseLoading = false;
          if (data.message === 'updated') {
            this.$buefy.notification.open({
              duration: 4000,
              message: '<i class="icon_alert_success icon-mks green"></i> Reserva creada exitosamente,Recuerda validar que esta reserva aún tenga disponibilidad',
              type: 'is-success'
            });
          } else {
            this.$buefy.notification.open({
              message: '<i class="icon_alert_danger icon-mks red"></i>Ocurrio un error creando la reserva',
              type: 'is-danger'
            });
          }
          this.isOpen = false;
          var bodyEl = document.body;
          // eslint-disable-next-line
          if (!this.fromTimeLine) {
            // eslint-disable-next-line no-undef
            classie.add(bodyEl, 'show-right-bar');
          }
        });
    },
    showStatusCancelled () {
      this.oldCancelShow = true;
    },
    getTimeReservation (reservation) {
      const infoReser = JSON.parse(JSON.stringify(reservation));
      if (this.clock >= parseInt(infoReser.date)) {
        return true;
      } else {
        return false;
      }
    },
    timeReservation (reservation) {
      if (reservation.referrer === 'Walk-In') {
        let difference =
          parseInt(reservation.isFinish) - parseInt(reservation.isSeatedWalkIn);
        const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24;
        const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60;
        const minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;
        const secondsDifference = Math.floor(difference / 1000);
        let days = '00';
        let hours = '00';
        let minutes = '00';
        let seconds = '00';
        if (daysDifference > 0) {
          days = daysDifference;
        }
        if (hoursDifference >= 0) {
          hours =
            hoursDifference <= 9 ? '0' + hoursDifference : hoursDifference;
        }
        if (minutesDifference >= 0) {
          minutes =
            minutesDifference <= 9
              ? '0' + minutesDifference
              : minutesDifference;
        }
        if (secondsDifference >= 0) {
          seconds =
            secondsDifference <= 9
              ? '0' + secondsDifference
              : secondsDifference;
        }

        if (days > 0) {
          return days + ' dia(s) con ' + hours + ':' + minutes + ':' + seconds;
        } else {
          return hours + ':' + minutes + ':' + seconds;
        }
      } else {
        let difference =
          parseInt(reservation.isFinish) - parseInt(reservation.isSeated);
        const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24;
        const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60;
        const minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;
        const secondsDifference = Math.floor(difference / 1000);
        let days = '00';
        let hours = '00';
        let minutes = '00';
        let seconds = '00';
        if (daysDifference > 0) {
          days = daysDifference;
        }
        if (hoursDifference >= 0) {
          hours =
            hoursDifference <= 9 ? '0' + hoursDifference : hoursDifference;
        }
        if (minutesDifference >= 0) {
          minutes =
            minutesDifference <= 9
              ? '0' + minutesDifference
              : minutesDifference;
        }
        if (secondsDifference >= 0) {
          seconds =
            secondsDifference <= 9
              ? '0' + secondsDifference
              : secondsDifference;
        }

        if (days > 0) {
          return days + ' dia(s) con ' + hours + ':' + minutes + ':' + seconds;
        } else {
          return hours + ':' + minutes + ':' + seconds;
        }
      }
    },
    isCancelUser () {
      this.closeLateralMenu();
      this.cancelShow = true;
      if (
        this.user.isShared !== undefined &&
        !this.user.isShared
      ) {
        this.isShared = false;
      }
    },
    validatePin (t) { // idhots
      this.valuePin = null;
      this.valuePin2 = null;
      const data = _.find(this.hostList, { value: t });
      if (data !== undefined) {
        this.createdAt = '';
        if (data.pin != null) {
          this.showPin = true;
          this.valuePin = data.pin;
          this.disableConfirm = true;
        } else {
          this.showPin = false;
          this.createdAt = t;
          this.disableConfirm = false;
        }
      } else {
        this.createdAt = t;
        this.showPin = false;
      }
    },
    changePin (pin) {
      if (pin === this.valuePin) {
        this.createdAt = this.valueId;
        this.showPin = false;
        this.valuePin = null;
        this.valuePin2 = null;
        this.disableConfirm = false;
      }
    },
    copyToClipboard () {
      const testingCodeToCopy = document.querySelector('#testing-code');
      testingCodeToCopy.setAttribute('type', 'text');
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand('copy');
        if (successful) {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_success icon-mks green"></i>Copiado con éxito',
            type: 'is-success'
          });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i>No se pudo copiar',
            type: 'is-danger'
          });
        }
      } catch (err) {
        this.$buefy.notification.open({
          message: '<i class="icon_alert_danger icon-mks red"></i>No se pudo copiar',
          type: 'is-danger'
        });
      }
      testingCodeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
    searchUser () {
      this.cancelUser = 0;
      this.showUser = 0;
      this.notShowUser = 0;
      this.totalReservations = 0;
      if (this.infoReserva.peopleId !== '' && this.infoReserva.peopleId !== null) {
        const idUser = this.infoReserva.peopleId;
        this.$store.dispatch({
          type: 'people:getMetricsUser',
          data: { userId: idUser }
        }).then((data) => {
          this.cancelUser = data.data.cancelled;
          this.showUser = data.data.show;
          this.notShowUser = data.data.noShow;
          this.totalReservations = data.data.totalReservations;
        });
      }
    },
    showEditUser (idUser) {
      this.$store.dispatch({ type: 'people:getOneUser', data: { id: idUser } }).then((data) => {
        this.dataUser = data.data;
        this.showDetail = true;
      });
    },
    closedDetailUser () {
      this.showDetail = false;
    },
    editBirthday () {
      this.editBirth = true;
    },
    closeEditBirthday () {
      this.editBirth = false;
    },
    updateBirthday () {
      const { amenities, comments, indicativo, bandera, phone, email, identityDocument, address, hostId, people, hour, selectZone, selectTable, displayName, referrer, originD, originP, experienceName, subscribed } = this.reservation;
      const { notificationEmail, notificationSms } = this;
      const userId = this.user.id;
      const vendorId = this.vendorId;
      const id = this.reservation.id;
      // selectZone = this.purgeZone(selectZone, selectTable)
      const dataReservationEdit = {
        reservationId: id,
        displayName,
        address,
        amenities,
        phone,
        indicativo,
        bandera,
        email,
        identityDocument,
        comments,
        createdAt: hostId,
        people,
        date: hour,
        seatingSectionId: selectZone,
        seatingTableId: selectTable,
        vendorId,
        userId,
        referrer,
        birthday: this.birthdayEdit,
        isConfirmed: this.infoReserva.isConfirmed,
        notificationSms,
        notificationEmail,
        originDetails: originD,
        originPeople: originP,
        experienceName,
        subscribed,
        userTags: this.reservation.tags,
        userNotes: this.reservation.notes,
        isVip: this.reservation.isVip,
        isRecommended: this.reservation.isRecommended,
        isHabitual: this.reservation.isHabitual
      };
      this.$store.dispatch({
        type: 'reservation:updateReservation',
        data: dataReservationEdit
      }).then(({ data }) => {
        if (data.code === 200) {
          this.editBirth = false;
          this.infoReserva.birthday = this.birthdayEdit;
          this.$buefy.notification.open({
            message: '<i class="icon_alert_success icon-mks green"></i> Se ha actualizado la temperatura',
            type: 'is-success'
          });
        } else {
          this.editBirth = false;
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> Error actualizando la temperatura',
            type: 'is-danger'
          });
        }
      }).catch(() => {
        this.editBirth = false;
        this.$buefy.notification.open({
          message: '<i class="icon_alert_danger icon-mks red"></i> Error actualizando la temperatura',
          type: 'is-danger'
        });
      });
    },
    editTemperature () {
      this.editTemp = true;
    },
    closeEditTemperature () {
      this.editTemp = false;
    },
    updateTemp () {
      let { amenities, comments, indicativo, bandera, phone, email, identityDocument, address, hostId, people, hour, selectZone, selectTable, displayName, referrer, originD, originP, experienceName, subscribed } = this.reservation;
      const { notificationEmail, notificationSms } = this;
      const userId = this.user.id;
      const vendorId = this.vendorId;
      const id = this.reservation.id;
      selectZone = this.purgeZone(selectZone, selectTable);
      const dataReservationEdit = {
        reservationId: id,
        displayName,
        address,
        amenities,
        phone,
        indicativo,
        bandera,
        email,
        identityDocument,
        comments,
        createdAt: hostId,
        people,
        date: hour,
        seatingSectionId: selectZone,
        seatingTableId: selectTable,
        vendorId,
        userId,
        referrer,
        temperature: this.tempNumEdit,
        isConfirmed: this.infoReserva.isConfirmed,
        notificationSms,
        notificationEmail,
        originDetails: originD,
        originPeople: originP,
        experienceName,
        subscribed,
        userTags: this.reservation.tags,
        userNotes: this.reservation.notes,
        isVip: this.reservation.isVip,
        isRecommended: this.reservation.isRecommended,
        isHabitual: this.reservation.isHabitual
      };
      this.$store.dispatch({
        type: 'reservation:updateReservation',
        data: dataReservationEdit
      }).then(({ data }) => {
        if (data.code === 200) {
          this.editTemp = false;
          this.infoReserva.temperature = this.tempNumEdit;
          this.$buefy.notification.open({
            message: '<i class="icon_alert_success icon-mks green"></i> Se ha actualizado la temperatura',
            type: 'is-success'
          });
        } else {
          this.editTemp = false;
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> Error actualizando la temperatura',
            type: 'is-danger'
          });
        }
      }).catch(() => {
        this.editTemp = false;
        this.$buefy.notification.open({
          message: '<i class="icon_alert_danger icon-mks red"></i> Error actualizando la temperatura',
          type: 'is-danger'
        });
      });
    },
    purgeZone (selectZone, selectTable) {
      let purgedZones = [];
      if (selectZone.length > 1) {
        _.forEach(selectTable, function (value) {
          purgedZones = _.concat(purgedZones, value.idSection);
        });
        purgedZones = _.uniq(purgedZones);
      } else {
        purgedZones = selectZone;
      }
      return purgedZones;
    },
    closeLateralMenu () {
      this.$store.commit(reservationTypes.mutations.setSideLateralSearch, false);
    },
    goToAction (action) {
      switch (action) {
        case 'phone':
          window.location.href = `tel:${this.infoReserva.phone}`;
          break;
        case 'whatsapp':
          window.open(`https://api.whatsapp.com/send?phone=${this.infoReserva.indicativo}${this.infoReserva.phone}&text=Hola`, 'blank');
          break;
      }
    },
    formatLimitPayment (time) {
      const limit = this.params.paymentTime ?? 20;
      return this.$moment(time).add(limit, 'minutes').format('DD [de] MMMM [de] YYYY [/] hh:mm a');
    },
    validateSittingOnlyHour (reservation) {
      if (reservation.isSeated) {
        return true;
      }
      const momentDate = this.$moment();
      const momentReservationDate = this.$moment(parseInt(reservation.date));
      let dif = null;
      if (momentDate.isBefore(momentReservationDate)) {
        dif = momentReservationDate.diff(momentDate, 'minutes');
      } else {
        return true;
      }
      if (dif != null && dif <= 60) {
        return true;
      }
      return false;
    },
    closeModal (field = null) {
      this.$emit('companionClose', false);
      if (field) this[field] = false;
      this.isOpen = false;
      this.$store.commit(reservationTypes.mutations.setFrontModal, false);
      var bodyEl = document.body;
      // eslint-disable-next-line
      if (!this.fromTimeLine) {
        // eslint-disable-next-line no-undef
        classie.add(bodyEl, 'show-right-bar');
      }
    },
    showPlusF (type, content, header) {
      if (!this.isMobile) this.activePlus[type] = true;
      this.activePlus.content = content;
      this.activePlus.header = header;
      if (this.isMobile) this.activePlus.showModal = true;
    },
    getFormatDateComplete (reservation) {
      return this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss');
    },
    oldWalkin (reservationId) {
      this.viewBlocked = true;
      this.reverseLoading = true;
      const userId = this.user.id;
      this.$store
        .dispatch({
          type: 'reservation:undoReservationStatus',
          data: { reservationId: reservationId, item: 'walk-in', userId: userId }
        })
        .then(() => {
          this.closeModal('accionLoadingS');
        });
    },
    getIconRate (active) {
      return active
        ? require('@/assets/icons/star_active.svg') // Icono activo
        : require('@/assets/icons/star_empty.svg'); // Icono inactivo
    }
  }
};
</script>
<style lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
@import "../../assets/styles/ViewReservation/viewReservation.scss";
.probando {
  background-color: transparent !important;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
@media (max-width: 668px) {
  .zone_table.main_zone_table.flex-between {
    justify-content: flex-start;
    gap: 45px!important;
  }
}

/* Íconos personalizados */
.rate {
  gap: 5px;
  margin-bottom: 13px!important;
}
.rate .icon i.mdi-star {
  display: none; /* Ocultamos el ícono mdi por defecto */
}
.rate .icon {
  width: 22px !important;
  height: 21px !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  @media screen and (max-width: 845px) {
    width: 20px!important;
    height: 19px!important;
  }
}
.rate-item.set-on .icon {
  background-image: url('../../assets/icons/star_active.svg') !important; /* Ícono activo */
}
.rate-item .icon  {
  background-image: url('../../assets/icons/star_empty.svg') !important; /* Ícono inactivo */
}
</style>
