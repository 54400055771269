<template>
  <div class="general-metrics-layer__container__charts-general__card__detail-section">
  <h2>Convenciones</h2>
  <div v-if="sectionId !== 3 && graph !== 'perTime'" class="general-metrics-layer__container__charts-general__card__detail-section__container" :class="{'no-before-fix' : dataConventionsArray.length <= 8}" :style="`${dataConventionsArray.length <= 4 ? 'grid-template-columns: 1fr;' : ( dataConventionsArray.length <= 8 ? 'overflow: hidden!important;': '')}`">
    <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info" v-for="(data, index) in dataConventionsArray" :key="index">
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__icon-bg" :style="`border-color: ${data.color ? data.color : ''}` ">
        <img
          :key="index"
          :src="getUrlImageReferrer(data.referrer)"
          v-if="getUrlImageReferrer(data.referrer) !== null"
        />
        <i
          :class="iconReferrer(data.name)"
          :key="data.name"
          class="icon-mks gray-blue small-grafica"
          v-else
        ></i>
      </div>
      <div v-if="sectionId !== 4" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__people">{{filtersBy === 1 ? data.reservations : data.people }}</div>
      <div v-if="graph === 'perDay' && sectionId !== 4" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__percent">{{ (((filtersBy === 1 ? data.reservations : data.people) * 100) / getTotalsByType(dataConventions, `${filtersBy === 1 ? 'reservations' : 'people'}`)).toFixed(decimals) }}%</div>
      <div v-if="graph === 'perGroup' && sectionId !== 4" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__percent">{{ (((filtersBy === 1 ? data.reservations : data.people) * 100) / getTotalsByRange(dataConventions, `${filtersBy === 1 ? 'reservations' : 'people'}`)).toFixed(decimals) }}%</div>
      <!-- Si la vista es generales -->
      <div v-if="sectionId === 4 && data.name === 'reservations'" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__people">
        {{filtersBy === 1 ? generalData.resevationReservations : generalData.resevationPeople}}
      </div>
      <div v-if="sectionId === 4 && data.name === 'walkIn'" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__people">
        {{filtersBy === 1 ? generalData.walkinReservations : generalData.walkinPeople}}
      </div>
      <div v-if="sectionId === 4 && data.name === 'precompra'" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__people">
        {{filtersBy === 1 ? generalData.prePurchaseReservations : generalData.prePurchasePeople}}
      </div>
      <div v-if="sectionId === 4 && data.name === 'reservations'" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__percent">
        {{ (((filtersBy === 1 ? generalData.resevationReservations : generalData.resevationPeople) * 100) / (filtersBy === 1 ? generalData.reservas : generalData.people)).toFixed(decimals) }}%
      </div>
      <div v-if="sectionId === 4 && data.name === 'walkIn'" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__percent">
        {{ (((filtersBy === 1 ? generalData.walkinReservations : generalData.walkinPeople) * 100) / (filtersBy === 1 ? generalData.reservas : generalData.people)).toFixed(decimals) }}%
      </div>
      <div v-if="sectionId === 4 && data.name === 'precompra'" class="general-metrics-layer__container__charts-general__card__detail-section__container__info__percent">
        {{ (((filtersBy === 1 ? generalData.prePurchaseReservations : generalData.prePurchasePeople) * 100) / (filtersBy === 1 ? generalData.reservas : generalData.people)).toFixed(decimals) }}%
      </div>
    </div>
  </div>
  <div v-if="sectionId === 3 && walkingData" class="general-metrics-layer__container__charts-general__card__detail-section__container no-before-fix" :style="`grid-template-columns: 1fr; overflow: hidden`">
    <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info">
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__icon-bg" :style="`width: 20px;height: 24px;background-color: #8A51B4!important;`">
        <i
          class="icon_walkin big-walking icon-mks"
        ></i>
      </div>
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__people">{{filtersBy === 1 ? walkingData.reservations : walkingData.people }}</div>
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__percent">100%</div>
    </div>
  </div>
  <div v-if="sectionId === 4 && graph === 'perTime'" class="general-metrics-layer__container__charts-general__card__detail-section__container no-before-fix" :style="`grid-template-columns: 1fr; overflow: hidden`">
    <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info">
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__icon-bg default-conventions-prec" :style="`width: 37px;height: 24px;background-color: ${colorsByDefault.reservations}!important;`">
        <i
          class="icon-mks icon_reservation"
        ></i>
      </div>
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__tag">Reservas</div>
    </div>
    <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info">
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__icon-bg default-conventions-prec" :style="`width: 37px;height: 24px;background-color: ${colorsByDefault.prepurchase}!important;`">
        <i
          class="icon-mks icon_reserva_precompro"
        ></i>
      </div>
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__tag">Precompras</div>
    </div>
    <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info">
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__icon-bg default-conventions-prec" :style="`width: 37px;height: 24px;background-color: ${colorsByDefault.walkin}!important;`">
        <i
          class="icon-mks icon_metrics_walkin"
        ></i>
      </div>
      <div class="general-metrics-layer__container__charts-general__card__detail-section__container__info__tag">Walkin</div>
    </div>
  </div>
  <div class="general-metrics-layer__container__charts-general__card__detail-section__total" v-if="graph !== 'perTime'">
    <div>Total {{ filtersBy === 1 ? 'reservas': 'personas'}}</div>
    <span v-if="sectionId === 4">{{getTotalsGenerals(generalData , filtersBy)}}</span>
    <span v-if="sectionId === 3">{{filtersBy === 1 ? walkingData.reservations : walkingData.people}}</span>
    <span v-if="sectionId !== 4 && sectionId !== 3">{{ this.graph === 'perDay' ? getTotalsByType(dataConventions , (filtersBy === 1 ? 'reservations' : 'people')) : getTotalsByRange(dataConventions , (filtersBy === 1 ? 'reservations' : 'people')) }}</span>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
export default {
  props: ['sectionId', 'filtersBy', 'data', 'dataConventions', 'graph', 'type', 'walkingData', 'generalData', 'colorsByDefault'],
  data () {
    return {
      decimals: 1
    };
  },
  computed: {
    dataConventionsArray () {
      if (this.graph === 'perDay') {
        if (this.sectionId === 4) {
          return this.getTotalsByRange(this.dataConventions);
        } else {
          return this.dataConventions;
        }
      }
      if (this.graph === 'perGroup') {
        return this.getTotalsByRange(this.dataConventions);
      }
      /* if (this.graph === 'perTime') {
        return this.getTotalsByRange(this.dataConventions);
      } */
      return [];
    }
  },
  methods: {
    getColorByName (name) {
      switch (name) {
        case 'telefono':
          return '#FD7403';
        case 'google':
          return '#FF0000';
        case 'facebook':
          return '#0070C0';
        case 'mail':
          return '#FFFF00';
        case 'precompro':
          return '#00FF00';
        case 'instagram':
          return '#FF3399';
        case 'twitter':
          return '#00acee';
        case 'página web':
          return '#00B0F0';
        case 'whatsapp':
          return '#008000';
        case 'ads':
          return '#000066';
        case 'adsinstagram':
          return '#800080';
        case 'tripadvisor':
          return '#33FF90';
        case 'Web Service':
          return '#000000';
        case 'Walk-In':
          return '#9453CB';
        case 'event':
          return '#9b9b9b';
        case 'yate':
          return '#000099';
        default:
          return '#' + Math.floor(Math.random() * 16777215).toString(16);
      }
    },
    getTotalsGenerals (data, type) {
      if (type === 1) {
        return (parseInt(data.resevationReservations) + parseInt(data.walkinReservations) + parseInt(data.prePurchaseReservations));
      }
      return (parseInt(data.resevationPeople) + parseInt(data.walkinPeople) + parseInt(data.prePurchasePeople));
    },
    getTotalsByRange (ranges, type = null) {
      if (ranges === null || ranges === undefined) {
        return 0;
      }
      const newRanges = ranges.map((range) => {
        const name = (range.name === 'whitelabel') ? 'página web' : (range.name === 'dashboard') ? 'telefono' : range.name;
        let sum = 0;
        let sumPeople = 0;
        sum += (range.dataReservation['0-5']) !== undefined ? range.dataReservation['0-5'] : 0;
        sum += (range.dataReservation['6-12']) !== undefined ? range.dataReservation['6-12'] : 0;
        sum += (range.dataReservation['+12']) !== undefined ? range.dataReservation['+12'] : 0;
        sumPeople += (range.dataPeople['0-5']) !== undefined ? parseInt(range.dataPeople['0-5']) : 0;
        sumPeople += (range.dataPeople['6-12']) !== undefined ? parseInt(range.dataPeople['6-12']) : 0;
        sumPeople += (range.dataPeople['+12']) !== undefined ? parseInt(range.dataPeople['+12']) : 0;
        return {
          name: range.name,
          reservations: sum,
          people: sumPeople,
          color: this.getColorByName(name)
        };
      });
      if (type === null) {
        return newRanges;
      }
      if (newRanges.length === 0) {
        return 0;
      }
      if (type === 'reservations') {
        return newRanges.reduce((a, b) => {
          return { reservations: a.reservations + b.reservations };
        }).reservations;
      }
      return newRanges.reduce((a, b) => {
        return { people: a.people + b.people };
      }).people;
    },
    getTotalsByType (list, type) {
      const total = _.sumBy(list, (data) => {
        if (typeof data[type] === 'string') {
          return parseInt(data[type]);
        }
        return data[type];
      });
      return total;
    }
  }
};
</script>

<style>

</style>
