<template>
  <div id="menuTableProducts">
    <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
    <div class="menu-table-products__actions">
      <div>
        <b-button class="menu-table-products__actions--create" @click="openModalCreateEditProducts()">
          <img src="@/assets/icons/menuQr/plus_alternative.svg" alt="plus_alternative">
          <div>Crear producto</div>
        </b-button>
        <b-button :disabled="checkedRows.length === 0" class="menu-table-products__actions--delete" @click="executeAlert">
          <img src="@/assets/icons/menuQr/trash.svg" alt="trash">
          <div>Eliminar producto</div>
        </b-button>
      </div>
      <!-- <div class="menu-table-products__actions__change-currency">
        <ul>
          <li :class="{'menu-table-products__actions__change-currency--active' : typeCurrency === 'COP'}" @click="changeTypeCurrency('COP')">COP</li>
          <li :class="{'menu-table-products__actions__change-currency--active' : typeCurrency === 'USD'}" @click="changeTypeCurrency('USD')">USD</li>
          <li :class="{'menu-table-products__actions__change-currency--active' : typeCurrency === 'EUR'}" @click="changeTypeCurrency('EUR')">EUR</li>
        </ul>
      </div> -->
      <div class="menu-table-products__actions__searcher-list-products">
        <img src="@/assets/icons/menuQr/search.svg" alt="">
        <b-input placeholder="Bucar por nombre" v-model="keyword" />
      </div>
    </div>
    <h3 style="text-align: center;" v-if="allProducts.length === 0">No hay productos disponibles</h3>
    <b-table
      :data="allProducts"
      :checked-rows.sync="checkedRows"
      checkable
      :checkbox-type="checkboxType"
      :mobile-cards="false"
      id="table-products-menu"
      sort-icon="chevron-up"
      :style="`${currentPage <= 1 && allProducts.length >= 15 ? 'height: 70vh;': ''}`"
    >
      <template v-slot="props">
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="order" label="Orden" centered width="30" sortable>
          {{props.row.order}}
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="typeLanguage" label="" centered>
          <div v-if="activeMultiLang(props.row, 'ES')">ES</div>
          <div v-if="activeMultiLang(props.row, 'IN')">IN</div>
          <div v-if="activeMultiLang(props.row, 'FR')">FR</div>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="name" label="Nombre de producto" :width="160" class="name-product" sortable>
          <div class="ellipsis-word-single-line" v-if="props.row.name">{{props.row.name}}</div>
          <div class="ellipsis-word-single-line" v-if="props.row.nameEnglish">{{props.row.nameEnglish}}</div>
          <div class="ellipsis-word-single-line" v-if="props.row.nameFrench">{{props.row.nameFrench}}</div>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="description" label="Descripción de producto" :width="310" class="description-product">
            <div v-if="props.row.description" v-html="typeof props.row.description === 'object' ? props.row.description.description : props.row.description"></div>
            <div v-if="props.row.descriptionEnglish" v-html="props.row.descriptionEnglish"></div>
            <div v-if="props.row.descriptionFrench" v-html="props.row.descriptionFrench"></div>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="nameMenu" label="Menú" width="90" centered sortable v-if="idMenuMultipleForCategoriesProducts === null">
          {{props.row.nameMenu ? props.row.nameMenu : ''}}
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="categoryInfo" label="Categoría" width="90" centered sortable>
          <div v-if="props.row.categoryInfo">
            <div class="ellipsis-word-single-line" v-if="props.row.categoryInfo.name">{{props.row.categoryInfo.name}}</div>
            <div class="ellipsis-word-single-line" v-if="props.row.categoryInfo.nameEnglish">{{props.row.categoryInfo.nameEnglish}}</div>
            <div class="ellipsis-word-single-line" v-if="props.row.categoryInfo.nameFrench">{{props.row.categoryInfo.nameFrench}}</div>
          </div>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="categoryInfo" label="Subcategoría" width="120" centered sortable>
          <div v-if="props.row.categoryInfo && props.row.categoryInfo.subcategory && props.row.categoryInfo.subcategory && props.row.categoryInfo.subcategory.id !== null">
            <div class="ellipsis-word-single-line" v-if="props.row.categoryInfo.subcategory.name">{{props.row.categoryInfo.subcategory.name}}</div>
            <div class="ellipsis-word-single-line" v-if="props.row.categoryInfo.subcategory.nameEnglish">{{props.row.categoryInfo.subcategory.nameEnglish}}</div>
            <div class="ellipsis-word-single-line" v-if="props.row.categoryInfo.subcategory.nameFrench">{{props.row.categoryInfo.subcategory.nameFrench}}</div>
          </div>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="price" label="Precio der."  width="100" centered class="price-product" sortable>
          <!-- {{props.row.price | currency(currencyDetails.symbol, currencyDetails.decimals, { thousandsSeparator: currencyDetails.thousandsSeparator })}} -->
          {{props.row.price ? `$${props.row.price}`: ''}}
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="priceTwo" label="Precio izq."  width="100" centered class="price-product" sortable>
          {{props.row.priceTwo ? `$${props.row.priceTwo}`: ''}}
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="image" label="Imagen" :width="57" centered class="image-product">
          <div v-if="props.row.isImage === 0">
            <div :style="{'background-image': `url('${props.row.image}')`}"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="33" viewBox="0 0 44 33" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.35254 30.6443C4.97979 32.0331 6.37694 32.9996 7.99984 32.9996H39.9998C42.209 32.9996 43.9998 31.2087 43.9998 28.9996V6.99957C43.9998 5.37775 43.0346 3.98136 41.6473 3.35352C41.8739 3.8552 42 4.41198 42 4.99823V26.9982C42 29.2074 40.2091 30.9982 38 30.9982H6C5.41269 30.9982 4.85494 30.8717 4.35254 30.6443Z" fill="#444B57"/>
              <rect width="40" height="30" rx="4" fill="#C9C9C9"/>
            </svg>
          </div>
          <div v-if="props.row.isImage === 1">
            <div :style="{'background-image': `url('${props.row.imageProducts}')`}"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="33" viewBox="0 0 44 33" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.35254 30.6443C4.97979 32.0331 6.37694 32.9996 7.99984 32.9996H39.9998C42.209 32.9996 43.9998 31.2087 43.9998 28.9996V6.99957C43.9998 5.37775 43.0346 3.98136 41.6473 3.35352C41.8739 3.8552 42 4.41198 42 4.99823V26.9982C42 29.2074 40.2091 30.9982 38 30.9982H6C5.41269 30.9982 4.85494 30.8717 4.35254 30.6443Z" fill="#444B57"/>
              <rect width="40" height="30" rx="4" fill="#C9C9C9"/>
            </svg>
          </div>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="_id" centered width="20" class="edit-product">
          <i class="icon-mks icon_edit" @click="openModalCreateEditProducts(props.row)"></i>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="active" label="Activo / Inactivo" centered width="20" class="status-product" sortable>
          <b-checkbox @input="changeStateProducts(props.row._id, props.row.active)" v-model="props.row.active" :true-value="1" :false-value="0" ></b-checkbox>
        </b-table-column>
        <b-table-column :class="{'inactive-row': props.row.active === 0}" field="recommended" centered width="20" class="recommended-product">
          <i class="icon-mks icon_star_bordered" :class="{'active-star' : props.row.recommended === 1}" @click="changeStatusRecommendedProduct(props.row._id, props.row.recommended)"></i>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import CreateEditProduct from './CreateEditProduct.vue';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['restaurant', 'idMenuMultipleForCategoriesProducts', 'listCategories'],
  components: {
  },
  data () {
    /* const data = [
      {
        order: 1,
        language: 'ES',
        name_product: 'Empandas con carne...',
        description_product: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ase...',
        category: 'Entradas',
        sub_category: 'Empanadas',
        price_right: '$ 000.000',
        price_left: '$ 000.000',
        image: null,
        status: null,
        favorite: false
      }
    ]; */
    return {
      allProducts: [],
      listProducts: [],
      currentPage: 1,
      checkboxType: 'is-primary',
      checkedRows: [],
      isLoading: false,
      keyword: '',
      noMoreData: false,
      miniLoader: true,
      typeCurrency: 'COP'
    };
  },
  computed: {
    currencyDetails () {
      switch (this.typeCurrency) {
        case 'COP':
          return {
            symbol: '$',
            decimals: 0, // No hay decimales para COP
            thousandsSeparator: '.' // Separador de miles para COP
          };
        case 'USD':
          return {
            symbol: '$',
            decimals: 0, // Dos decimales para USD
            thousandsSeparator: ',' // Separador de miles para USD
          };
        case 'EUR':
          return {
            symbol: '€',
            decimals: 0, // Dos decimales para EUR
            thousandsSeparator: '.' // Separador de miles para EUR
          };
        default:
          return {
            symbol: '$',
            decimals: 0,
            thousandsSeparator: '.'
          };
      };
    },
    activeMultiLang () {
      return (rowData, lang) => {
        const languageFields = {
          ES: ['name', 'description', 'categoryInfo.name', 'categoryInfo.subcategory.name'],
          IN: ['nameEnglish', 'descriptionEnglish', 'categoryInfo.nameEnglish', 'categoryInfo.subcategory.nameEnglish'],
          FR: ['nameFrench', 'descriptionFrench', 'categoryInfo.nameFrench', 'categoryInfo.subcategory.nameFrench']
        };
        // Obtener los campos correspondientes al idioma
        const fields = languageFields[lang] || [];
        // Verificar si alguno de los campos está definido
        return fields.some(field => {
          const fieldValue = field.split('.').reduce((obj, key) => (obj && obj[key] !== undefined) ? obj[key] : null, rowData);
          return fieldValue !== null;
        });
      };
    }
  },
  mounted () {
    this.getListProducts();
    const tableProductsMenu = document.getElementById('table-products-menu');
    if (tableProductsMenu) {
      tableProductsMenu.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy () {
    const tableProductsMenu = document.getElementById('table-products-menu');
    if (tableProductsMenu) {
      tableProductsMenu.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    changeTypeCurrency (type) {
      this.typeCurrency = type;
    },
    getListProducts () {
      this.isLoading = true;
      if (this.noMoreData) return;
      const params = {
        page: this.currentPage
      };
      if (this.keyword) {
        params.q = this.keyword;
      }
      this.isLoading = true;
      if (this.idMenuMultipleForCategoriesProducts !== null) {
        serviceQr.get(`/menuCustom/products/${this.idMenuMultipleForCategoriesProducts}`, { params }).then((response) => {
          const { data } = response;
          const newProducts = data.data.data;
          this.allProducts = this.allProducts.concat(newProducts);
          // Si no se obtienen más productos, marcar noMoreData como verdadero
          if (data.data.last_page === this.currentPage) {
            this.noMoreData = true;
          }
          this.isLoading = false;
        }).catch(() => {
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Ha ocurrido un error',
            position: 'is-top',
            type: 'is-danger'
          });
          this.isLoading = false;
        });
      }
      // consulta los productos globalmente
      if (this.idMenuMultipleForCategoriesProducts === null) {
        serviceQr.get(`/menuCustom/listProduct/${this.restaurant.vendorId}`, { params }).then((response) => {
          const { data } = response;
          const newProducts = data.data.data;
          this.allProducts = this.allProducts.concat(newProducts);
          // Si no se obtienen más productos, marcar noMoreData como verdadero
          if (data.data.last_page === this.currentPage) {
            this.noMoreData = true;
          }
          this.isLoading = false;
        }).catch(() => {
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Ha ocurrido un error',
            position: 'is-top',
            type: 'is-danger'
          });
          this.isLoading = false;
        });
      }
    },
    handleScroll (event) {
      const table = event.target;
      if (table.scrollHeight - Math.round(table.scrollTop) === table.clientHeight) {
        // Si el usuario ha llegado al final de la tabla
        if (!this.isLoading && !this.noMoreData) {
          this.currentPage++;
          this.getListProducts();
        }
      }
    },
    handleWheel (event) {
      const table = document.getElementById('table-products-menu');
      if (table && table.scrollHeight <= table.clientHeight) {
        // Si no hay scroll visible
        if (!this.isLoading && !this.noMoreData) {
          this.currentPage++;
          this.getListProducts();
        }
      }
    },
    changeStateProducts (id, status) {
      this.isLoading = true;
      const data = {
        productIds: [id],
        active: status ? 1 : 0
      };
      serviceQr.post('/menuCustom/products/state', data).then((response) => {
        this.isLoading = false;
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
        this.isLoading = false;
      });
    },
    executeAlert () {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete',
        props: {
          idMenu: null,
          type: 'delete',
          title: 'CONFIRMAR',
          subtitle: '¿Estás seguro(a) que quieres eliminar los productos seleccionados?',
          typeDelete: 'product'
        },
        events: {
          deleteProducts () {
            instance.deleteProducts();
          }
        }
      });
    },
    deleteProducts () {
      this.isLoading = true;
      const data = {
        productIds: this.checkedRows.map(product => product._id),
        vendorId: this.vendor.id
      };
      serviceQr.post('/menuCustom/products/delete', data).then((response) => {
        this.checkedRows = [];
        this.resetPaginationDataTable();
        this.getListProducts();
      }).catch(() => {
        this.isLoading = false;
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
      });
    },
    openModalCreateEditProducts (productForEdit = null) {
      const instance = this;
      if (productForEdit === null) {
        this.$buefy.modal.open({
          parent: this,
          component: CreateEditProduct,
          hasModalCard: true,
          canCancel: false,
          customClass: 'modal-create-edit-products',
          props: {
            restaurant: instance.restaurant,
            listCategories: instance.listCategories,
            product: null,
            type: 'create',
            idMenuMultipleForCategoriesProducts: instance.idMenuMultipleForCategoriesProducts
          },
          events: {
            getListProducts () {
              instance.resetPaginationDataTable();
              instance.getListProducts();
            }
          }
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: CreateEditProduct,
          hasModalCard: true,
          canCancel: false,
          customClass: 'modal-create-edit-products',
          props: {
            restaurant: instance.restaurant,
            listCategories: instance.listCategories,
            product: productForEdit,
            type: 'edit',
            idMenuMultipleForCategoriesProducts: instance.idMenuMultipleForCategoriesProducts
          },
          events: {
            getListProducts () {
              instance.resetPaginationDataTable();
              instance.getListProducts();
            }
          }
        });
      }
    },
    changeStatusRecommendedProduct (productId, status) {
      this.isLoading = true;
      const data = {
        productId: productId,
        recommended: status === 1 ? 0 : 1
      };
      serviceQr.post('/menuCustom/recommended/product', data).then((response) => {
        this.resetPaginationDataTable();
        this.getListProducts();
        this.isLoading = false;
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
        this.isLoading = false;
      });
    },
    resetPaginationDataTable () {
      this.noMoreData = false;
      this.allProducts = [];
      this.currentPage = 1;
    }
  },
  watch: {
    keyword () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.resetPaginationDataTable();
        this.getListProducts();
      }, 1500);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/menuQr/products/_list_products.scss";
</style>
