<template>
  <div class="container-menu-qr-config__categories__card">
    <div class="container-menu-qr-config__categories__card__disabled-container" v-if="category.active === 0">
    </div>
    <div class="container-menu-qr-config__categories__card__category-description">
      <div class="container-menu-qr-config__categories__card__category-description__lang">
        <div class="container-menu-qr-config__categories__card__category-description__lang__img-category-container">
          <div class="container-menu-qr-config__categories__card__category-description__lang__img-category-container__title-menu" v-if="globalCategories">Menú: <b>{{category.vendorCustomName}}</b></div>
          <div
            v-if="category.imageCategory"
            class="container-menu-qr-config__categories__card__category-description__lang__img-category-container__img"
            :style="`background-image: url('${category.imageCategory}')`"
          ></div>
        </div>
        <SelectLanguages type="" @changeTypeLanguage="changeTypeLanguage"/>
      </div>
      <div class="grid">
        <label for="">Categoría</label>
        <b-input disabled type="text" :value="getNameDescriptionCategoryByLang('name')" class="input-grid-aux" />
      </div>
      <div class="grid">
        <label for="">Descripción</label>
        <b-input disabled type="textarea" :value="getNameDescriptionCategoryByLang('description')"></b-input>
      </div>
    </div>
    <div class="container-menu-qr-config__categories__card__options">
      <div class="">
        <div>
          <label for="">Subcategoría</label>
          <b>{{category.children && category.children.length > 0 ? 'Sí' : 'No'}}</b>
        </div>
        <div>
          <label for="">Productos</label>
          <b>{{category.products}}</b>
        </div>
      </div>
      <div class="">
        <div>
          <label for="">Orden</label>
          <b-input v-model="category.order" @input="editCategory" />
        </div>
        <div>
          <label for="">{{category.active === 1 ? 'Activa': 'Inactiva'}}</label>
          <b-checkbox v-model="category.active" :true-value="1" :false-value="0" @input="editCategory" />
        </div>
      </div>
      <div class="">
        <div>
          <label for="">Recomendado</label>
          <b-checkbox v-model="category.recommended" :true-value="1" :false-value="0" @input="editCategory"/>
        </div>
        <div>
          <label for="">Dos precios</label>
          <b-checkbox v-model="category.twoPrices" :true-value="1" :false-value="0" disabled />
        </div>
      </div>
    </div>
    <div class="container-menu-qr-config__categories__card--actions">
      <b-button class="container-menu-qr-config__categories__card--actions__go-to-category" @click="$emit('setCategoryDetail', category)">Ir a la categoría</b-button>
      <b-button class="container-menu-qr-config__categories__card--actions__delete" @click="deleteCategory">
        <img src="@/assets/icons/menuQr/trash.svg" alt="">
      </b-button>
    </div>
  </div>
</template>

<script>
import SelectLanguages from '@/components/schedules/menu-qr/complements/SelectLanguages.vue';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['category', 'restaurant', 'idMenuMultipleForCategoriesProducts', 'globalCategories'],
  components: {
    SelectLanguages
  },
  data () {
    return {
      active: false,
      typeLang: 'spanish'
    };
  },
  computed: {
    getNameDescriptionCategoryByLang () {
      return (type = 'name') => {
        if (type === 'name') {
          if (this.typeLang === 'spanish') {
            return this.category.name;
          }
          if (this.typeLang === 'english') {
            return this.category.nameEnglish;
          }
          if (this.typeLang === 'french') {
            return this.category.nameFrench;
          }
        } else {
          if (this.typeLang === 'spanish') {
            return this.category.description;
          }
          if (this.typeLang === 'english') {
            return this.category.descriptionEnglish;
          }
          if (this.typeLang === 'french') {
            return this.category.descriptionFrench;
          }
        }
        return null;
      };
    }
  },
  mounted () {
  },
  methods: {
    changeTypeLanguage (typeLang = 'spanish') {
      this.typeLang = typeLang;
    },
    deleteCategory () {
      var instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete alert-categories',
        props: {
          idMenu: null,
          type: 'delete',
          title: 'Confirmar',
          subtitle: `
            <div style="margin-bottom: 10px;">¿Estás seguro(a) que quieres eliminar esta categoría?</div>
            <div style="font-size: 12px;"><b>Nota:</b> los productos que están asignados a esta categoría, los encontrarás en la sección de productos, sin categoría.</div>
          `,
          typeDelete: 'category'
        },
        events: {
          changeStatusLoader (status) {
            instance.$emit('changeStatusLoader', status);
          },
          deleteCategory () {
            instance.serviceDeleteCategory();
          }
        }
      });
    },
    serviceDeleteCategory () {
      this.$emit('changeStatusLoader', true);
      serviceQr.delete(`/menuCustom/deleteCategory/${this.category._id}/${this.restaurant.vendorId}`).then(() => {
        this.$emit('clearListCategories');
        this.$emit('getListCategories');
        /* this.$emit('changeStatusLoader', false); */
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
        this.$emit('changeStatusLoader', false);
      });
    },
    editCategory () {
      serviceQr.put(`/menuCustom/editCategory/${this.category._id}`, this.category).then((resp) => {
        if (resp.data.code === 200) {
          /* this.$emit('getListCategories'); */
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Categoría actualizada',
            position: 'is-top',
            type: 'is-success'
          });
        }
      }).catch(() => {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Ha ocurrido un error',
          position: 'is-top',
          type: 'is-danger'
        });
      });
    }
  },
  watch: {
    typeLang () {
      this.category.typeLanguage = 'both';
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/menuQr/categories/_list_categories.scss";
</style>
