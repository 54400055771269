<template>
  <div class="content" >
    <ve-line :data="dataRow" :settings="chartSettings" :colors="colors"></ve-line>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import metricsTypes from '@/store/types/metrics';
export default {
  props: ['data'],
  data () {
    this.chartSettings = {
      area: true
    };
    return {
      colors: ['#9453cb', '#00ADC6', '#CAD300'],
      dataRow: {
        columns: ['mes', 'Walkin', 'Reservas', 'Precompras'],
        rows: []
      }
    };
  },
  computed: {
    ...mapGetters({
      byMonth: [metricsTypes.getters.byMonth]
    })
  },
  watch: {
    byMonth () {
      this.dataRow.rows = this.byMonth;
    }
  }
};
</script>
