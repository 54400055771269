import googleCloudTypes from '@/store/types/googleCloud';
import serviceAdminPhotos from '@/helpers/apis/serviceAdminPhotos';

const state = {
};

export const actions = {
  [googleCloudTypes.actions.uploadImage]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      /* serviceAdminPhotos.post('digitalOcean/upload/image', payload.data) */
      serviceAdminPhotos.post('cloudStorage/upload/image', payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [googleCloudTypes.actions.uploadImageMultiple]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      /* serviceAdminPhotos.post('digitalOcean/upload/images/multiple', payload.data) */
      serviceAdminPhotos.post('cloudStorage/upload/images/multiple', payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const getters = {
};

const mutations = {
};

export default {
  state,
  actions,
  getters,
  mutations
};
