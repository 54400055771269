<template>
  <div>
    <div class="container-menu__content__cards__card-image list-group-item" :class="{inactiveMenu : menu.isActive === 0}">
      <div class="container-menu__content__cards__card-image__background"  :style="{'background-image': `url('${typeMenu === 'multimenu' ? menu.image : menu.url}')`, backgroundSize: 'cover', backgroundPosition: 'center'}">
        <div class="container-menu__content__cards__card-image__background--actions" v-if="!isForDelete">
          <div class="container-menu__content__cards__card-image__background--actions__order">
            <b-input type="text" v-model="menu.order"/>
          </div>
          <div class="container-menu__content__cards__card-image__background--actions__delete" @click="modeDelete">
            <img src="@/assets/icons/menuQr/trash.svg" alt="delete">
          </div>
          <div class="container-menu__content__cards__card-image__background--actions__zoom" @click="()=>{
              this.typeMenu === 'menu' ? this.isImageModalActive = true : this.$emit('openModalPreviewImage')
            }">
            <img src="@/assets/icons/menuQr/zoom.svg" alt="zoom">
          </div>
          <div class="container-menu__content__cards__card-image__background--actions__check">
            <b-checkbox v-model="menu.isActive" :true-value="1" :false-value="0"></b-checkbox>
          </div>
        </div>
        <div class="container-menu__content__cards__card-image__background--actions" v-if="isForDelete">
          <div class="container-menu__content__cards__card-image__background--actions__check">
            <b-checkbox v-model="forDelete" :true-value="menu.id" :false-value="0" @input="idForDelete"></b-checkbox>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-if="typeMenu === 'menu'" :active.sync="isImageModalActive" class="image-modal-preview" :can-cancel="false">
      <div class="image-modal-preview__background" :style="{'background-image': `url('${menu.url}')`, backgroundSize: 'cover', backgroundPosition: 'center'}">
        <div class="image-modal-preview__close" @click="() => isImageModalActive = false">
          <img src="@/assets/icons/menuQr/close.svg">
        </div>
        <div class="image-modal-preview__background--actions">
          <div class="image-modal-preview__background--actions__order">
            <input type="text" v-model="menu.order"/>
          </div>
          <div class="image-modal-preview__background--actions__delete" @click="modeDelete">
            <img src="@/assets/icons/menuQr/trash.svg" alt="delete">
          </div>
          <div class="image-modal-preview__background--actions__check">
            <b-checkbox v-model="menu.isActive" :true-value="1" :false-value="0"></b-checkbox>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import serviceQr from '@/helpers/apis/serviceQr.js';
import AlertConfirmDelete from '@/components/schedules/menu-qr/complements/AlertConfirmDelete.vue';

export default {
  props: {
    menu: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isForDelete: {
      type: Boolean,
      default: false
    },
    typeMenu: {
      type: String,
      default: 'menu'
    },
    arrayDeleteMassive: {
      type: Array,
      default: () => []
    },
    auxDataMenu: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data () {
    return {
      timeout: null,
      isImageModalActive: false,
      forDelete: false
    };
  },
  mounted () {
  },
  methods: {
    updateMenu () {
      const data = {
        ...this.menu,
        userId: this.user.id
      };
      this.$emit('changeStatusLoader', true);
      serviceQr.put('/menudigital/single/' + this.menu.id, data).then(({ data }) => {
        this.$emit('changeStatusLoader', false);
        /* this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        }); */
        this.$emit('getMenu');
      }).catch(() => {
        this.$emit('changeStatusLoader', false);
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    deleteItem (id) {
      var instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: AlertConfirmDelete,
        hasModalCard: true,
        canCancel: false,
        customClass: 'alert-confirm-delete',
        props: {
          idMenu: id,
          type: 'delete',
          title: 'Confirmar',
          subtitle: '¿Estás seguro(a) que quieres eliminar esta imagen?',
          typeDelete: 'menu'
        },
        events: {
          changeStatusLoader (status) {
            instance.$emit('changeStatusLoader', status);
          },
          getMenu () {
            instance.$emit('getMenu');
          }
        }
      });
    },
    modeDelete () {
      // si el menu está en modo de editar, borra el menu por api, si no, borra localmente el menu
      if (this.typeMenu === 'multimenu') {
        this.$emit('deleteImg');
      } else {
        if (this.isEdit) {
          this.deleteItem(this.menu.id);
        } else {
          this.$emit('deleteMenuLocal');
        }
      }
    },
    deactivateItem () {
      const data = {
        ...this.menu,
        userId: this.user.id
      };
      this.$emit('changeStatusLoader', true);
      serviceQr.post('/menudigital/single/state', data).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('getMenu');
        this.$emit('changeStatusLoader', false);
      }).catch(() => {
        this.$emit('changeStatusLoader', false);
        this.$buefy.toast.open({
          /* message: this.$t('errors.request'), */
          message: 'Ha ocurrido un error',
          type: 'is-danger'
        });
      });
    },
    deactivateItemMultiMenuImage () {
      const data = {
        vendorId: this.vendor.id,
        id: this.menu.id,
        userId: this.menu.userIdd,
        isActive: this.menu.isActive
      };
      this.$emit('changeStatusLoader', true);
      serviceQr.post('/menudigital/multiple/item/state', data).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('getItems');
        this.$emit('changeStatusLoader', false);
      }).catch(() => {
        this.$emit('changeStatusLoader', false);
        this.$buefy.toast.open({
          message: 'Ha ocurrido un error',
          type: 'is-danger'
        });
      });
    },
    idForDelete () {
      this.$emit('modifyMassiveDeleteArray', this.menu.id, this.forDelete !== 0 ? 'push' : 'filter');
    }
  },
  watch: {
    'menu.order': {
      handler () {
        if (this.typeMenu === 'menu') {
          if (this.isEdit) {
            if (this.timeout) {
              clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
              this.updateMenu();
              this.isImageModalActive = false;
            }, 2000);
          }
        }
      }
    },
    'menu.isActive': {
      handler () {
        if (this.isEdit) {
          if (this.typeMenu === 'menu') {
            this.deactivateItem();
          } else {
            this.deactivateItemMultiMenuImage();
          }
        }
      }
    },
    arrayDeleteMassive (value) {
      if (value.find((item) => item === this.menu.id)) {
        this.forDelete = this.menu.id;
      } else {
        this.forDelete = false;
      }
    },
    auxDataMenu () {
      if (this.typeMenu === 'multimenu') {
        if (this.isEdit) {
          if (this.auxDataMenu.id === this.menu.id) {
            this.menu = this.auxDataMenu;
          }
        } else {
          if (this.auxDataMenu.index === this.index) {
            this.menu = this.auxDataMenu;
          }
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/menuQr/_card_menu_image.scss";
</style>
