<template>
  <div id="sumary">
    <div
      :class="{'reservas': type == 'reservation', 'precompra': type == 'prePurchase', 'walking': type == 'Walk-in'}"
      class="header-color margin-bot"
    ></div>
    <div v-if="type !== 'Walk-in'">
      <p class="text bold tittle">Origen de reservas</p>
      <p class="text small margin-bot">Filtrar origen de reservas por rango de fechas</p>
      <div class="flex-container evenly margin-bot">
        <b-radio :native-value="false" name="fecha" type="is-dark">
          <p class="text bold small black">FECHA</p>
        </b-radio>
        <b-radio :native-value="true" disabled name="fecha/hora" type="is-dark">
          <p class="text bold small black">FECHA/HORAS</p>
        </b-radio>
        <div
          @click="generateReport()"
          class="report-icon"
          v-if="(!generate && (sectionId === 1 || sectionId === 2))"
        >
          <i class="icon_download icon-mks black"></i>
          <i
            class="fa fa-spinner fa-spin"
            v-if="(generate && (sectionId === 1 || sectionId === 2))"
          ></i>
        </div>
        <div class="report-icon" v-if="(generate && (sectionId === 1 || sectionId === 2))">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
      <div class="flex-container evenly" id="datePicker">
        <div class="col">
          <b-field>
            <i class="icon_calendar_voild calendar icon-mks black"></i>
            <span class="the_calendar_day">{{ dateBar_day }}</span>
            <p class="text bold small paddin-rigth">Fecha De inicio</p>
            <date-pick :inputAttributes="{placeholder: 'Seleccione Fecha'}" v-model="dateFrom"></date-pick>
          </b-field>
        </div>
        <div class="col">
          <b-field>
            <i class="icon_calendar_voild icon-mks calendar black"></i>
            <span class="the_calendar_day">{{ dateBar_day2 }}</span>
            <p class="text bold small paddin-rigth">Fecha Fin</p>
            <date-pick :inputAttributes="{placeholder: 'Seleccione Fecha'}" v-model="dateTo"></date-pick>
          </b-field>
        </div>
      </div>
      <div class="aux-overflow-sumary">
        <div class="flex-container evenly margin-bot">
          <a
            :class="{'active': daySection == 3}"
            @click="getDataByFilter('DESAYUNO',3)"
            class="day-b"
          >
            <i class="icon_moon icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Desayuno</p>
          </a>
          <a
            :class="{'active': daySection == 1}"
            @click="getDataByFilter('ALMUERZO',1)"
            class="day-b"
          >
            <i class="icon_sun icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Almuerzo</p>
          </a>
          <a :class="{'active': daySection == 2}" @click="getDataByFilter('CENA',2)" class="day-b">
            <i class="icon_moon icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Cena</p>
          </a>
        </div>
        <div class="flex-container center margin-bot padding-bot border-bot">
          <a @click="clearDate()" class="btn">
            <i class="icon_trash icon-mks black trash"></i>LIMPIAR
          </a>
        </div>
        <div class="year">
          <b-field label="Año">
            <b-select expanded placeholder="Seleccione un año" v-model="year">
              <option :key="option.id" :value="option.id" v-for="option in years">{{ option.id }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="flex-container border-bot margin-bot">
          <div class="col">
            <i class="icon-mks normal icon_origin gray-blue"></i>
            <p class="text bold margin-bot-small">Origen</p>
            <div :key="index" v-for=" (data,index) in dataReservations.byReferrer">
              <img
                :key="index"
                :src="getUrlImageReferrer(data.referrer)"
                style="height: 25px; width: 25px; margin-right: 5px; margin-bottom: 5px;"
                v-if="getUrlImageReferrer(data.referrer) !== null"
                v-tooltip="{content: validateName(data.referrer), placement: 'right'}"
              />
              <i
                :class="iconReferrer(data.referrer)"
                :key="data.referrer"
                style="margin-bottom: 12px;"
                class="icon-mks gray-blue "
                v-else
                v-tooltip="{content: validateName(data.referrer), placement: 'right'}"
              ></i>
            </div>
          </div>
          <div class="col">
            <i class="icon-mks normal icon_reservation gray-blue"></i>
            <p class="text bold margin-bot-small">#Reservas</p>
            <p
              :key="index"
              class="text black bold-ligth margin-bot-small"
              v-for=" (data,index) in dataReservations.byReferrer"
            >{{ data.reservations }}</p>
          </div>
          <div class="col">
            <i class="icon-mks normal icon_person gray-blue"></i>
            <p class="text bold margin-bot-small">#Personas</p>
            <p
              :key="index"
              class="text black bold-ligth margin-bot-small"
              v-for=" (data,index) in dataReservations.byReferrer"
            >{{ data.people }}</p>
          </div>
        </div>
        <div class="flex-container" v-if=" type === 'prePurchase'">
          <div class="col">
            <p class="text bold margin-bot-small">Total recaudo</p>
            <p
              class="text big bold"
            >{{ (dataReservations.balance && dataReservations.balance>0)?dataReservations.balance:0 | currency('$', 0, { thousandsSeparator: '.' }) }}</p>
          </div>
        </div>
        <hr />
        <div
          class="flex-container"
          v-if=" (type === 'prePurchase' && dataReservations.totalBalance && dataReservations.totalBalance>0)"
        >
          <div class="col">
            <p class="text bold margin-bot-small">Total recaudo + (No Llegaron)</p>
            <p class="text big bold">{{ getPrice() }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text bold tittle">Datos Generales</p>
      <div class="flex-container border-bot padding-bot margin-top" style="position:relative;">
        <div
          @click="generateReport()"
          class="report-icon"
          style="padding-right: 10px; position: absolute; top: -50px; right: 0px;"
          v-if="(!generate && (sectionId === 3))"
        >
          <i class="icon_download icon-mks black"></i>
          <i class="fa fa-spinner fa-spin" v-if="(generate && (sectionId === 3))"></i>
        </div>
        <div
          class="report-icon"
          style="padding-right: 10px; position: absolute; top: -50px; right: 0px;"
          v-if="(generate && (sectionId === 3))"
        >
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <div class="col">
          <i class="icon_person icon-mks person gray-blue"></i>
          <p class="text tittle grey bold">{{ dataReservations.people }}</p>
          <p class="text small">Nro de Personas</p>
        </div>
        <div class="col">
          <i class="icon_walkin icon-mks big gray-blue"></i>
          <p class="text tittle grey">{{ dataReservations.reservas }}</p>
          <p class="text small">Nro de Walk-in</p>
        </div>
      </div>
      <div style="padding:0 10px; margin-top: 20px">
        <p class="label-date">Filtrar Rango Fechas</p>
        <div class="header-Date"></div>
        <div class="flex-container evenly" id="datePicker2">
          <div class="col">
            <b-field>
              <i class="icon_calendar_voild calendar icon-mks black"></i>
              <span class="the_calendar_day">{{ dateBar_day }}</span>
              <p class="text bold small paddin-rigth">Fecha De inicio</p>
              <date-pick :inputAttributes="{placeholder: 'Seleccione Fecha'}" v-model="dateFrom"></date-pick>
            </b-field>
          </div>
          <div class="col">
            <b-field>
              <i class="icon_calendar_voild calendar icon-mks black"></i>
              <span class="the_calendar_day">{{ dateBar_day2 }}</span>
              <p class="text bold small paddin-rigth">Fecha Fin</p>
              <date-pick :inputAttributes="{placeholder: 'Seleccione Fecha'}" v-model="dateTo"></date-pick>
            </b-field>
          </div>
        </div>
        <div class="flex-container evenly margin-bot">
          <a
            :class="{'active': daySection == 3}"
            @click="getDataByFilter('DESAYUNO',3)"
            class="day-b"
          >
            <i class="icon_moon icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Desayuno</p>
          </a>
          <a
            :class="{'active': daySection == 1}"
            @click="getDataByFilter('ALMUERZO',1)"
            class="day-b"
          >
            <i class="icon_sun icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Almuerzo</p>
          </a>
          <a :class="{'active': daySection == 2}" @click="getDataByFilter('CENA',2)" class="day-b">
            <i class="icon_moon icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Cena</p>
          </a>
        </div>
        <div class="flex-container center margin-bot padding-bot border-bot">
          <a @click="clearDate()" class="btn">
            <i class="icon_trash icon-mks black trash"></i>LIMPIAR
          </a>
        </div>
        <div class="year">
          <b-field label="Año">
            <b-select expanded placeholder="Seleccione un año" v-model="year">
              <option :key="option.id" :value="option.id" v-for="option in years">{{ option.id }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import metricsTypes from '@/store/types/metrics';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import axios from 'axios';
const MIN_YEARS = 5;
export default {
  name: 'Sumary',
  components: { DatePick },
  props: ['color', 'colorAvatar', 'colorIcon', 'dataReservations', 'showOrigen', 'percent', 'type', 'sectionId'],
  data () {
    return {
      dateFrom: null,
      dateTo: null,
      dateBar_day: '00',
      dateBar_day2: '00',
      load: false,
      daySection: 0,
      filter: null,
      generate: false,
      years: [],
      year: null
    };
  },
  computed: {
    ...mapGetters({
      dataReservation: [metricsTypes.getters.dataReservation],
      dataReservationRange: [metricsTypes.getters.dataReservationRange],
      byMonth: [metricsTypes.getters.byMonth],
      byDay: [metricsTypes.getters.byDay],
      byRange: [metricsTypes.getters.byRange],
      notEffective: [metricsTypes.getters.notEffective],
      timeState: [metricsTypes.getters.timeState],
      yearGlobal: [metricsTypes.getters.year],
      dateFromGlobal: [metricsTypes.getters.dateFrom],
      dateToGlobal: [metricsTypes.getters.dateTo]
    })
  },
  mounted () {
    const max = parseInt(this.$moment().format('YYYY'));
    const min = (max - MIN_YEARS);
    const years = [];
    for (let index = max; index >= min; index--) {
      years.push({ id: index });
    }
    this.year = (this.yearGlobal !== max) ? this.yearGlobal : max;
    this.years = years;
  },
  methods: {
    getPrice () {
      const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      const priceNoShow = formatter.format(Math.abs(this.dataReservations.totalBalance - this.dataReservations.balance));
      const text = `${formatter.format(this.dataReservations.balance)} + ${priceNoShow} = ${formatter.format(this.dataReservations.totalBalance)}`;
      return text;
    },
    generateReport () {
      switch (this.sectionId) {
        case 1:
          this.reportReservation();
          break;
        case 2:
          this.reportReservationPrepurchase();
          break;
        case 3:
          this.reportWalkIn();
          break;
        default:
          this.reportReservation();
          break;
      }
    },
    reportReservation () {
      this.generate = true;
      this.$store.dispatch({ type: 'metrics:generateReportMetrics', data: { vendorId: this.vendor.id, dateFrom: this.dateFromGlobal, dateTo: this.dateToGlobal, filter: this.filter, year: this.year } }).then(({ data }) => {
        this.generate = false;
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
        this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
        this.isDownload();
      }).catch(() => {
        this.generate = false;
      });
    },
    reportReservationPrepurchase () {
      this.generate = true;
      this.$store.dispatch({ type: 'metrics:generateReportPrepurchaseMetrics', data: { vendorId: this.vendor.id, dateFrom: this.dateFromGlobal, dateTo: this.dateToGlobal, filter: this.filter, year: this.year } }).then(({ data }) => {
        this.generate = false;
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
        this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
        this.isDownload();
      }).catch(() => {
        this.generate = false;
      });
    },
    reportWalkIn () {
      this.generate = true;
      this.$store.dispatch({ type: 'metrics:generateReportWalkIn', data: { vendorId: this.vendor.id, dateFrom: this.dateFromGlobal, dateTo: this.dateToGlobal, filter: this.filter, year: this.year } }).then(({ data }) => {
        this.generate = false;
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
        this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
        this.isDownload();
      }).catch(() => {
        this.generate = false;
      });
    },
    getDataByFilter (type, section) {
      console.log('entra?');
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      if (section === this.daySection) {
        this.$store.dispatch({
          type: 'metrics:getMetricReservations',
          data: {
            year: this.year,
            vendorId: this.$store.state.idVendor,
            type: this.sectionId
          }
        });
        this.$store.dispatch({
          type: 'metrics:getDataReservation',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        }).then(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.daySection = 0;
          this.filter = null;
        }).catch(e => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.notification.open({
            message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
            type: 'is-danger'
          });
        });
        return;
      }
      this.$store.dispatch({
        type: 'metrics:getMetricReservations',
        data: {
          year: this.year,
          vendorId: this.$store.state.idVendor,
          type: this.sectionId,
          dateTo: this.dateTo,
          dateFrom: this.dateFrom,
          filter: type
        }
      });
      this.$store.dispatch({
        type: 'metrics:getDataReservationFilter',
        data: {
          vendorId: this.$store.state.idVendor,
          type: this.sectionId,
          dateTo: this.dateTo,
          dateFrom: this.dateFrom,
          filter: type,
          year: this.year
        }
      }).then(() => {
        this.daySection = section;
        this.filter = type;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    convertText (text) {
      if (text === 'prePurchase') {
        return 'Precompras';
      }
      if (text === 'reservation') {
        return 'Reservas';
      }
      return text;
    },
    isDownload () {
      this.download = true;
      axios({
        url: process.env.VUE_APP_URL_REPORT + '/downloadReport/' + this.apiReportDownload,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      }).then((response) => {
        this.download = false;
        const year = new Date().getFullYear();
        const hour = new Date().getHours();
        const hour2 = (hour <= 9) ? '0' + hour : hour;
        const minute = new Date().getMinutes();
        const minute2 = (minute <= 9) ? '0' + minute : minute;
        const second = new Date().getSeconds();
        const second2 = (second <= 9) ? '0' + second : second;
        const month = new Date().getMonth() + 1;
        const month2 = (month <= 9) ? '0' + month : month;
        const date = new Date().getDate();
        const date2 = (date <= 9) ? '0' + date : date;
        const filename = year + '_' + month2 + '_' + date2 + '_' + hour2 + ':' + minute2 + ':' + second2;
        if (this.typeApiReportDownload === 'xlsx') {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Metricas' + filename + '.xlsx';
          link.click();
        } else {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Metricas' + filename + '.pdf';
          link.click();
        }
      }).catch((e) => {
        console.error(e);
        this.download = false;
        this.$buefy.notification.open({
          message: 'Archivo no encontrado',
          type: 'is-danger'
        });
      });
    },
    clearDate () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.dateFrom = null;
      this.dateTo = null;
      this.$store.commit(metricsTypes.mutations.setDateFrom, null);
      this.$store.commit(metricsTypes.mutations.setDateTo, null);
      this.$store.dispatch({
        type: 'metrics:getMetricReservations',
        data: {
          year: this.year,
          vendorId: this.$store.state.idVendor,
          type: this.sectionId
        }
      });
      this.$store.dispatch({
        type: 'metrics:getDataReservation',
        data: {
          vendorId: this.$store.state.idVendor,
          type: this.sectionId,
          year: this.year
        }
      }).then(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.daySection = 0;
      });
    },
    validateName (name) {
      switch (name) {
        case 'whitelabel':
          return 'Página Web';
        case 'cross-selling':
          return 'Venta cruzada';
        case 'dashboard':
          return 'Teléfono';
        case 'resy':
          return 'Resy';
        case 'pautafacebook':
          return 'Facebook ads';
        default:
          return name;
      }
    }
  },
  watch: {
    dateFrom () {
      this.$store.commit(metricsTypes.mutations.setDateFrom, this.dateFrom);
      if (this.dateFrom === '' || this.dateFrom == null) {
        this.dateBar_day = '00';
        this.$store.commit(metricsTypes.mutations.setDateFrom, null);
      } else {
        this.dateBar_day = this.$moment(this.dateFrom).format('DD');
      }
    },
    dateTo () {
      this.$store.commit(metricsTypes.mutations.setDateTo, this.dateTo);
      if (this.dateTo === '' || this.dateTo == null) {
        this.dateBar_day2 = '00';
        this.$store.commit(metricsTypes.mutations.setDateTo, null);
      } else {
        this.dateBar_day2 = this.$moment(this.dateTo).format('DD');
      }
    },
    year () {
      this.$store.commit(metricsTypes.mutations.setYear, this.year);
    }
  }
};
</script>
<style lang="scss">
#sumary {
  display: flex;
  flex-direction: column;
  background: white;
  width: 280px;
  min-width: 215px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  .vdpPositionTop{
    z-index: 1000;
  }
  .year {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding-left: 6px;
    padding-right: 6px;
  }
  .header-color {
    height: 15px;
    &.reservas {
      background: rgba(0, 173, 198, 1);
    }
    &.precompra {
      background: rgba(202, 211, 0, 1);
    }
    &.walking {
      background: rgba(162, 91, 223, 1);
    }
    &.margin-bot {
      margin-bottom: 12px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    border: 1px solid rgba(112, 112, 112, 1);
    color: black;
    background-color: transparent;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    width: 120px;
    height: 35px;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(112, 112, 112, 1);
      color: white;
      transition: all 0.2s ease-in;
      i {
        background: #fff;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    &-right {
      justify-content: flex-end;
    }
  }
  .flex-container {
    display: flex;
    width: 100%;
    &.evenly {
      justify-content: space-evenly;
    }
    &.center {
      justify-content: center;
    }
    &.border-bot {
      border-bottom: 1px solid rgba(230, 230, 230, 1);
    }
    &.padding-bot {
      padding-bottom: 30px;
    }
    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &.margin-bot {
      margin-bottom: 30px;
    }
    &.margin-top {
      margin-top: 30px;
    }
  }
  .columns {
    width: 100%;
  }
  .column {
    &-color {
      background: rgb(0, 173, 198);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-margin-top {
      align-self: center;
    }
  }
  .back-reservas {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 173, 198);
  }
  .back-precompra {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cad300;
  }
  .back-walking {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #9453cb;
  }
  .icon-mks {
    z-index: 100;
    &-tittle {
      width: 120px !important ;
      height: 120px !important;
    }
    &.small {
      height: 20px;
      width: 20px;
    }
    &.trash {
      height: 17px;
      width: 17px;
    }
    &.normal {
      height: 30px;
      width: 35px;
    }
    &.margin-bot {
      margin-bottom: 15px;
    }
    &.big {
      height: 45px;
      width: 45px;
    }
    &.person {
      height: 45px;
      width: 40px;
    }
    &.calendar {
      position: absolute;
      margin: 0 0 0 4px !important;
      height: 22px !important;
      width: 22px !important;
    }
  }
  .fa {
    align-self: center;
  }
  .day-b {
    width: 100%;
    cursor: pointer;
    background: rgba(242, 242, 242, 1);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      border-right: 1px solid rgba(230, 230, 230, 1);
    }
    &.active {
      opacity: 0.2;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .text {
    &.margin-bot {
      margin-bottom: 40px;
      &-small {
        margin-bottom: 15px;
      }
    }
    &.bold-ligth {
      font-weight: 500;
    }
    &.big {
      font-size: 24px;
    }
    &.paddin-rigth {
      padding: 0 0 0 25px;
    }
  }
  .header-Date {
    width: 100%;
    height: 5px;
    background: #444b57;
    border-radius: 3px 3px 0 0;
  }
  #datePicker {
    .vdpComponent {
      &:hover{
        background: transparent!important;
      }
    }
    #datePickRigth {
      .vdpFloating.vdpPositionLeft {
        left: inherit;
      }
    }
    .vdpComponent.vdpWithInput {
      padding: 0 0 0 25px;
    }
    .vdpComponent.vdpWithInput > input {
      padding-right: 0px;
      font-size: 10px;
    }
    input {
      padding: 0px;
      width: 100%;
      border: 0px;
      outline: inherit;
    }

    .column {
      &-display-flex {
        display: flex;
      }
    }
    .field {
      display: flex;
      position: relative;
      flex-direction: column;
      text-align-last: center;
      height: 50px;
      justify-content: center;
      border-top: 1px solid rgba(230, 230, 230, 1);
      &:first-child {
        border-right: 1px solid rgba(230, 230, 230, 1);
      }
    }
    .icon-mks {
      margin: 0 0 0 15px;
    }
    .vdpInnerWrap {
      border-radius: 20px;
      background: rgba(68, 75, 87, 1);
    }
    .vdpHeader {
      background: rgba(68, 75, 87, 1);
      border-radius: 20px;
    }
    .selectable {
      color: white;
    }
    .outOfRange {
      color: rgba(112, 112, 112, 1);
    }
    .vdpHeadCellContent {
      color: white;
      font-weight: bold;
    }
    .vdpPeriodControl > button {
      color: white;
      font-weight: bold;
    }
    .vdpArrowPrev:after {
      border-right-color: white;
    }
    .vdpArrowNext:after {
      border-left-color: white;
    }
    .vdpCell.selected .vdpCellContent {
      color: black;
      background: white;
      &:hover {
        color: black;
        background: white;
      }
    }
  }
  #datePicker2 {
    border: 1px solid rgba(230, 230, 230, 1);
    #datePickRigth {
      .vdpFloating.vdpPositionLeft {
        left: inherit;
      }
    }
    .vdpComponent.vdpWithInput {
      padding: 0 0 0 25px;
    }
    .vdpComponent.vdpWithInput > input {
      padding-right: 0px;
      font-size: 10px;
    }
    input {
      padding: 0px;
      width: 100%;
      border: 0px;
      outline: inherit;
    }

    .column {
      &-display-flex {
        display: flex;
      }
    }
    .field {
      display: flex;
      flex-direction: column;
      text-align-last: center;
      position: relative;
      height: 50px;
      justify-content: center;
      &:first-child {
        border-right: 1px solid rgba(230, 230, 230, 1);
      }
    }
    .icon-mks {
      margin: 0 0 0 15px;
    }
    .vdpInnerWrap {
      border-radius: 20px;
      background: rgba(68, 75, 87, 1);
    }
    .vdpHeader {
      background: rgba(68, 75, 87, 1);
      border-radius: 20px;
    }
    .selectable {
      color: white;
    }
    .outOfRange {
      color: rgba(112, 112, 112, 1);
    }
    .vdpHeadCellContent {
      color: white;
      font-weight: bold;
    }
    .vdpPeriodControl > button {
      color: white;
      font-weight: bold;
    }
    .vdpArrowPrev:after {
      border-right-color: white;
    }
    .vdpArrowNext:after {
      border-left-color: white;
    }
    .vdpCell.selected .vdpCellContent {
      color: black;
      background: white;
      &:hover {
        color: black;
        background: white;
      }
    }
  }
  .label-date {
    font-weight: 500;
    font-size: 10px;
    text-align: initial;
    padding-left: 3px;
  }
  .the_calendar_day {
    z-index: 100;
    position: absolute;
    left: 8.5px;
    font-size: 11px;
    top: 21px;
  }
  .vdpComponent:hover {
    background: transparent!important;
  }
  .vdpOuterWrap.vdpPositionFixed{
    z-index: 10000!important;
  }
}
.aux-overflow-sumary{
  /*  overflow: auto;
  @media screen and (max-width: 1024px) {
    height: 61vh;
  }
  @media screen and (max-height: 768px) {
    height: 61vh;
  } */
}
@media (max-width: 720px) {
  #sumary {
    font-size: 10px;
  }
  .day-b {
    width: 80%;
  }
}
</style>
