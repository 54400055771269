<template>
  <div id="usersIndex" style="background-color:#ededed">
    <Sidenav @change-full="toggle"></Sidenav>
    <div class="content-wrap">
      <b-navbar id="navbar_users">
        <template #start>
          <div class="img_client hamburguer_image" v-if="vendor">
            <img :src="vendor.thumbnailURL" @click="openSideBar" />
          </div>
          <div class="user_restaurant">
            <span class="title_nav ffr">Usuarios del restaurante</span>
          </div>
        </template>
        <template #end>
          <b-navbar-item class="dnm" tag="div">
            <div class="buttons dnm">
              <a @click="openModalDate" class="button btn_date">
                <span class="content_btn ffr">Buscar por fecha</span>
              </a>
              <a class="button btn_search">
                <div class="content_icon">
                  <i class="icons icon_search"></i>
                </div>
                <div class="content_input">
                  <input class="content_btn ffr" type="text" v-model="search" />
                </div>
              </a>
              <div>
                <i
                  @click="generateReport()"
                  class="icons icon_download"
                  v-if="(!generate && !disableButton)"
                ></i>
                <i class="fa fa-spinner fa-spin" v-else></i>
              </div>
            </div>
          </b-navbar-item>
          <div class="buttons_mobile dnd">
            <a class="button btn_search">
              <div class="content_icon">
                <i class="icons icon_search"></i>
              </div>
              <div class="content_input">
                <input
                  class="content_btn ffr"
                  placeholder="Buscar por nombre"
                  type="text"
                  v-model="searchMobile"
                />
              </div>
            </a>
            <div class="container_dates">
              <div class="container_title">
                <span class="text_select ffr">Seleccione la fecha de búsqueda</span>
              </div>
              <div class="content_button_dates">
                <div class="buttons_dates start">
                  <date-pick
                    :months="months"
                    :startWeekOnSunday="true"
                    :weekdays="weekdays"
                    @input="cleanEndDate"
                    v-model="startDate"
                  >
                    <template v-slot:default="{toggle}">
                      <button @click="toggle" class="btn_dates ffr">{{ 'Fecha inicial' }}</button>
                    </template>
                  </date-pick>
                  <div class="main_text_dates" v-if="startDate !== null">
                    <span
                      class="texts_dates ffr"
                    >{{ startDate | moment('MMMM DD') }} de {{ startDate | moment('YYYY') }}</span>
                    <i @click="clean(0, $event)" class="icons icon_close_modal"></i>
                  </div>
                </div>
                <div class="buttons_dates end">
                  <date-pick
                    :isDateDisabled="disabledDates"
                    :months="months"
                    :startWeekOnSunday="true"
                    :weekdays="weekdays"
                    v-model="endDate"
                  >
                    <template v-slot:default="{toggle}">
                      <button @click="toggle" class="btn_dates ffr">{{ 'Fecha final' }}</button>
                    </template>
                  </date-pick>
                  <div class="main_text_dates" v-if="endDate !== null">
                    <span
                      class="texts_dates ffr"
                    >{{ endDate | moment('MMMM DD') }} de {{ endDate | moment('YYYY') }}</span>
                    <i @click="clean(1, $event)" class="icons icon_close_modal"></i>
                  </div>
                </div>
              </div>
            </div>
            <div :class="{'date' : startDate !== null || endDate !== null}" class="div_search">
              <button @click="searchUser" class="btn_search_user ffr">Buscar usuario</button>
            </div>
          </div>
        </template>
      </b-navbar>
      <div id="userModule">
        <ViewReservation
          :infoReserva="infoReservation"
          :type="getType"
          v-if="getViewReservation && vendor"
        ></ViewReservation>
        <Users
          :datesFilter="datesFilter"
          :inputSearch="inputSearch"
          @deleteFilters="deleteDates"
          class="users"
        ></Users>
        <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
      </div>
    </div>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import Sidenav from '@/components/_shared/Sidenav';
import Users from './users/usersList';
import LocalStorage from 'store';
import DatePick from 'vue-date-pick';
import ViewReservation from '@/components/_shared/ViewReservation';
import globalTypes from '@/store/types/global';
import modalReport from './users/modalReport';
import ModalReportEvent from './users/ModalReportEvent';
import ModalDates from '@/components/polls_v2/atoms/modalDates';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import TempPassword from '@/components/_shared/TempPassword';
import VendorGroupsModal from './users/vendorGroupsModal';

export default {
  components: { Sidenav, Users, ViewReservation, DatePick, ChangedPassword, TempPassword },
  data () {
    return {
      inputSearch: '',
      datesFilter: [],
      disableButton: false,
      search: '',
      searchMobile: '',
      startDate: null,
      endDate: null,
      windowWidth: 1000,
      weekdays: ['LUN', 'MAR', 'MIE', 'JUV', 'VIE', 'SAB', 'DOM'],
      months: ['Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      generate: false,
      openMenu: false,
      showSideBar: false,
      intervalId: null,
      vendorGroups: [],
      idsForDownload: '',
      showVendorGroups: false
    };
  },
  mounted () {
    this.windowWidth = window.innerWidth;
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: this.user.id
      }
    });
    this.getStatusReport();
    this.intervalId = setInterval(() => {
      this.getStatusReport();
    }, 5000);
    this.getVendorGroups();
  },
  beforeDestroy () {
    // Limpiar el intervalo cuando el componente se desmonte para evitar fugas de memoria
    clearInterval(this.intervalId);
  },
  methods: {
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    openSideBar () {
      this.$store.commit(globalTypes.mutations.setShowSideBar, true);
    },
    searchUser () {
      const el = document.getElementsByClassName('navbar-menu');
      el[0].classList.remove('is-active');
      this.inputSearch = this.searchMobile;
      const tempDates = [];
      tempDates.push(this.startDate);
      tempDates.push(this.endDate);
      this.datesFilter = tempDates;
    },
    cleanDates () {
      this.startDate = null;
    },
    cleanEndDate () {
      this.endDate = null;
      this.disabledDates();
    },
    disabledDates (endDate) {
      if (this.startDate !== null) {
        return endDate < new Date(this.startDate);
      }
    },
    openModalDate () {
      this.datesFilter = [];
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalDates,
        hasModalCard: true,
        customClass: 'modal_dates_poll',
        canCancel: false,
        events: {
          searchForDates (payload) {
            thes.datesFilter = payload;
          },
          filterDateRange (payload) {
            if (payload !== null) {
              thes.datesFilter.push(thes.$moment(payload.start).format('YYYY-MM-DD'));
              thes.datesFilter.push(thes.$moment(payload.end).format('YYYY-MM-DD'));
            }
          }
        }
      });
    },
    sendInputSearch () {
      clearTimeout(this.timeout);
      var thes = this;
      this.isLoading = true;
      this.timeout = setTimeout(function () {
        thes.inputSearch = thes.search;
      }, 500);
    },
    generateReport () {
      this.generate = true;
      if (this.vendorGroups.length === 0) {
        this.$store.dispatch({ type: 'reservation:generateReportUsersEvent', data: { userId: LocalStorage.get('_userId'), vendorId: this.vendor.id, dateIn: this.datesFilter[0] || null, dateOut: this.datesFilter[1] || null, search: this.search, tab: window.localStorage.getItem('_tab_users') || 0 } }).then(({ data }) => {
          this.generate = false;
          this.openModalEvent(data.data);
          this.disableButton = true;
        }).catch(() => {
          this.generate = false;
        });
      } else {
        const instance = this;
        this.$buefy.modal.open({
          parent: this,
          component: VendorGroupsModal,
          hasModalCard: true,
          customClass: 'vendorGroupsModal',
          canCancel: false,
          props: {
            vendorGroups: instance.vendorGroups
          },
          events: {
            download (stringIds) {
              console.log('stringIds', stringIds);
              instance.$store.dispatch({ type: 'reservation:generateReportUsersEvent', data: { vendorsGroup: stringIds, userId: LocalStorage.get('_userId'), vendorId: instance.vendor.id, dateIn: instance.datesFilter[0] || null, dateOut: instance.datesFilter[1] || null, search: instance.search, tab: window.localStorage.getItem('_tab_users') || 0 } }).then(({ data }) => {
                instance.generate = false;
                instance.openModalEvent(data.data);
                instance.disableButton = true;
              }).catch(() => {
                instance.generate = false;
              });
            },
            closeModal () {
              instance.generate = false;
            }
          }
        });
      }
    },
    getStatusReport () {
      this.$store.dispatch({ type: 'reservation:getStatusReport', data: { vendorId: this.vendor.id } }).then(({ data }) => {
        if (data.data === null) {
          this.disableButton = false;
          return;
        }
        this.disableButton = true;
      });
    },
    async getVendorGroups () {
      try {
        const { data } = await this.$store.dispatch({
          type: 'reservation:getVendorGroups',
          data: { vendorId: this.vendor.id }
        });
        if (data.data !== null) {
          this.vendorGroups = data.data;
        }
      } catch (error) {
        console.error('Error fetching vendor groups:', error);
      }
    },
    openModalEvent (email) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          email: email
        },
        canCancel: false,
        hasModalCard: true,
        component: ModalReportEvent
      });
    },
    openModalReport () {
      this.$buefy.modal.open({
        parent: this,
        component: modalReport,
        hasModalCard: true,
        canCancel: false
      });
    },
    deleteDates () {
      this.startDate = null;
      this.endDate = null;
      this.datesFilter = [];
    },
    clean (i, e) {
      e.stopPropagation();
      if (i === 0) {
        this.startDate = null;
        return;
      }
      this.endDate = null;
    }
  },
  watch: {
    search () {
      this.sendInputSearch();
    }
  }
};
</script>
<style lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
@import "../../assets/styles/users/usersIndex.scss";
</style>
