<template>
  <div id="usersList">
    <div class="side_bar">
      <div class="container_cards_info users_pc_module">
        <div class="cards_info total_users" :class="{'user_filter_active': filterUser === 0}" @click="setFilterUser(0)">
          <div class="header_card">
            <i class="icons icon_total_users"></i>
            <span class="title_header">Total<br>de usuarios</span>
          </div>
          <div class="content_card_users">
            <span class="info_users">{{ totalUser }}</span>
          </div>
        </div>
        <div class="cards_info recurrent_users" :class="{'user_filter_active': filterUser === 1}" @click="setFilterUser(1)">
          <div class="header_card">
            <i class="icons icon_recurrent_user"></i>
            <span class="title_header">Usuarios<br>recurrentes</span>
          </div>
          <div class="content_card_users">
            <span class="info_users">{{ userRecurrent }}</span>
          </div>
        </div>
        <div class="cards_info new_users" :class="{'user_filter_active': filterUser === 2}" @click="setFilterUser(2)">
          <div class="header_card">
            <i class="icons icon_new_user"></i>
            <span class="title_header">Usuarios<br>nuevos</span>
          </div>
          <div class="content_card_users">
            <span class="info_users">{{ userNews }}</span>
          </div>
        </div>
        <div class="cards_info new_vip" :class="{'user_filter_active': filterUser === 3}" @click="setFilterUser(3)">
          <div class="header_card">
            <i class="icons icon_new_vip"></i>
            <span class="title_header">Usuarios<br>VIP</span>
          </div>
          <div class="content_card_users">
            <span class="info_users">{{ userVip }}</span>
          </div>
        </div>
      </div>
      <div class="container_cards_info users_mobile_module">
        <div class="cards_info total_users" :class="{'user_filter_active': filterUser === 0}" @click="setFilterUser(0)">
          <div class="header_card">
            <span class="title_header">Total de usuarios</span>
          </div>
          <div class="content_card_users">
            <i class="icons icon_total_users"></i>
            <span class="info_users">{{ totalUser }}</span>
          </div>
        </div>
        <div class="cards_info recurrent_users" :class="{'user_filter_active': filterUser === 1}" @click="setFilterUser(1)">
          <div class="header_card">
            <span class="title_header">Usuarios recurrentes</span>
          </div>
          <div class="content_card_users">
            <i class="icons icon_recurrent_user"></i>
            <span class="info_users">{{ userRecurrent }}</span>
          </div>
        </div>
        <div class="cards_info new_users" :class="{'user_filter_active': filterUser === 2}" @click="setFilterUser(2)">
          <div class="header_card">
            <span class="title_header">Usuarios nuevos</span>
          </div>
          <div class="content_card_users">
            <i class="icons icon_new_user"></i>
            <span class="info_users">{{ userNews }}</span>
          </div>
        </div>
        <div class="cards_info new_vip" :class="{'user_filter_active': filterUser === 3}" @click="setFilterUser(3)">
          <div class="header_card" style="display: flex;">
            <span class="title_header">Usuarios VIP</span>
          </div>
          <div class="content_card_users">
            <i class="icons icon_new_vip"></i>
            <span class="info_users">{{ userVip }}</span>
          </div>
        </div>
      </div>
      <div class="mdn">
        <button class="btn_create_user" @click="openModalCreate">
          <span class="text_create ffssp">Crear usuario</span>
          <div>
            <i class="icons icon_plus_create"></i>
          </div>
        </button>
      </div>
    </div>
    <div class="content_info_table" :class="{'h100' : desserts.length == 0}">
      <div class="content_table">
        <div class="status_filter_active">
          <span v-if="filterUser === 0">Total de usuarios</span>
          <span v-if="filterUser === 1">Usuarios recurrentes</span>
          <span v-if="filterUser === 2">Usuarios nuevos</span>
          <span v-if="filterUser === 3">Usuarios VIP</span>
          <span class="action_btn_combinar" v-if="filterUser === 0">
            <b-button @click="openModalCombinarManual()">Unificar contactos manualmente</b-button>
            <b-button @click="openModalCombinar()">Unificar contactos</b-button>
          </span>
        </div>
        <div class="filter_active" v-if="(dateIn !== null && dateIn !== '') || (dateOut !== null && dateOut !== '')">
          <span class="text_filter ffssp">Filtro activo: &nbsp;</span>
          <span class="detail_filter ffssp" v-if="dateIn != null && dateOut != null">Fecha: Desde {{ dateIn | moment('MMMM DD') }} hasta {{ dateOut | moment('MMMM DD') }}</span>
          <span class="detail_filter ffssp" v-if="dateIn != null && dateOut == null">Fecha: Desde {{ dateIn | moment('MMMM DD') }}</span>
          <span class="detail_filter ffssp" v-if="dateIn == null && dateOut != null">Fecha: Hasta {{ dateOut | moment('MMMM DD') }}</span>
          <i class="icons icon_close_modal" @click="deleteFilters"></i>
        </div>
        <div class="header_table desktop_tablet" :class="{'filter' : dateIn !== null || dateOut !== null, 'no_date' : !params.activeBirthDate}">
          <div class="title_table ffssp name" @click="sortTable('name')"><i class="icons icon_rows_table"></i>Nombre</div>
          <div class="title_table ffssp email" @click="sortTable('email')"><i class="icons icon_rows_table"></i>Email</div>
          <div class="title_table ffssp date" @click="sortTable('date')"><i class="icons icon_rows_table"></i>Fecha</div>
          <div class="title_table ffssp phone" @click="sortTable('phone')"><i class="icons icon_rows_table"></i>Teléfono</div>
          <div class="title_table ffssp reservation" @click="sortTable('reservation')">
            <div>
              <i class="icons icon_rows_table"></i>
            </div>
            Reserva
          </div>
          <div class="title_table ffssp show" @click="sortTable('show')">
            <div>
              <i class="icons icon_rows_table"></i>
            </div>
            Llegó
          </div>
          <div class="title_table ffssp sub">
            <i class="icons icon_rows_table"></i>
            Suscripción
          </div>
        </div>
        <div class="cards_info_table desktop_tablet" :class="{'active' : verifyActive(user._id), 'no_date' : !params.activeBirthDate }" v-for="(user, i) in desserts" :key="i" @click="showDetailUser(user)">
          <div class="texts_table ffssp name">
            <div class="container_vip" :class="{'isVip' : user.isVip}"></div>
            <span class="text_name">{{ user.name }}</span>
          </div>
          <div class="texts_table ffssp email">
            <span>{{ user.email }}</span>
          </div>
          <div class="texts_table ffssp date">
            <span>{{ user.birthday | moment('DD/MM/YYYY') }}</span>
          </div>
          <div class="texts_table ffssp phone">
            <span>{{ user.phone }}</span>
          </div>
          <div class="texts_table ffssp reservation">
            <span>{{ user.visits }}</span>
          </div>
          <div class="texts_table ffssp show">
            <span>{{ user.finished }}</span>
          </div>
          <div class="texts_table ffssp sub">
            <span v-if="user.isUnSubscription === undefined || user.isUnSubscription === 0" >
              <i class="icon-mks icon_check icon_check_marketing"></i>
            </span>
          </div>
        </div>
        <div class="cards_whitout_filter ddn" :class="{'no_view' : dateIn !== null || dateOut !== null, 'active' : verifyActive(user._id)}" v-for="(user, i) in desserts" :key="i+'wf'" @click="showDetailUser(user)">
          <div class="container_vip" :class="{'isVip' : user.isVip}"></div>
          <div class="container_info">
            <div class="user_name">
              <span class="text_name ffssp">{{ user.name }}</span>
              <div style="">
                <span style="display: flex; align-items: center; gap: 8px; margin-right: 15px;" class="text_name ffssp" v-if="user.isUnSubscription === undefined || user.isUnSubscription === 0">Suscripción <i class="icon-mks icon_check icon_check_marketing"></i></span>
              </div>
            </div>
            <div class="user_email_phone">
              <div class="user_email">
                <div>
                  <i class="icons icon_mail"></i>
                </div>
                <span class="texts_user ffssp">{{ user.email }}</span>
              </div>
              <div class="user_phone">
                <div>
                  <i class="icons icon_phone"></i>
                </div>
                <span class="texts_user ffssp">{{ user.phone }}</span>
              </div>
              <div class="user_statics">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="11" viewBox="0 0 24 11" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0078 7.91367L21.2601 1.66187C21.0322 0.633093 20.1963 0 19.2085 0H3.70717C3.5552 0 3.40322 0.0791367 3.25125 0.23741L0.211777 6.09353C-0.244144 6.96403 0.059803 8.15108 0.895658 8.6259C1.19961 8.70504 1.50355 8.78417 1.8075 8.78417H4.92296L4.99895 9.25899C5.22691 10.2878 6.06276 11 7.05059 11L21.1082 10.446C22.172 10.446 23.0078 9.57554 23.0078 8.46763C23.0598 8.30539 23.0407 8.1801 23.0234 8.06652C23.0154 8.0139 23.0078 7.96378 23.0078 7.91367ZM1.8075 7.67626C1.42757 7.67626 1.04763 7.35971 1.04763 6.88489C1.04763 6.72662 1.04763 6.64748 1.12362 6.48921L3.47921 1.97842L4.695 7.59712L1.8075 7.67626ZM21.1082 9.33813L7.05059 9.89209C6.51868 9.89209 6.13875 9.4964 6.06276 9.02158L4.39105 1.10791H19.2845C19.7404 1.10791 20.1963 1.42446 20.2723 1.89928L22.02 8.15108V8.38849C22.02 8.94245 21.6401 9.33813 21.1082 9.33813ZM17.2329 3.9576L9.0263 4.27415C8.72235 4.27415 8.49439 4.51156 8.49439 4.8281C8.49439 5.14465 8.72235 5.38206 9.0263 5.38206L17.2329 5.06551C17.5368 5.06551 17.7648 4.8281 17.7648 4.51156C17.7648 4.19501 17.5368 3.9576 17.2329 3.9576ZM10.5463 6.48936L15.6374 6.25195C15.9414 6.25195 16.1693 6.48936 16.1693 6.80591C16.1693 7.12246 15.9414 7.35987 15.6374 7.35987L10.5463 7.59728C10.2424 7.59728 10.0144 7.35987 10.0144 7.04332C10.0144 6.72677 10.2424 6.48936 10.5463 6.48936Z" fill="#444B57"/>
                </svg>
                <span class="texts_user ffssp">{{ user.visits }}</span>
                <span class="texts_show ffssp">Llegó</span>
                <span class="texts_user ffssp">{{ user.finished }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cards_filter ddn" :class="{'no_view' : dateIn === null && dateOut === null, 'active' : verifyActive(user._id)}" v-for="(user, i) in desserts" :key="i+'f'" @click="showDetailUser(user)">
          <div class="container_vip" :class="{'isVip' : user.isVip}"></div>
          <div class="container_cards">
            <div class="name_email">
              <div>
                <span class="fs12 ffssp">{{ user.name }}</span>
              </div>
              <div>
                <span class="fs10 ffssp">{{ user.email }}</span>
              </div>
            </div>
            <div class="cont_data">
              <div class="cont_date" v-if="params.activeBirthDate">
                <span class="fs10 ffssp fw3">Fecha</span>
                <span class="fs10 ffssp">{{ user.birthday | moment('DD/MM/YYYY') }}</span>
              </div>
              <div class="cont_phone">
                <span class="fs10 ffssp fw3">Teléfono</span>
                <span class="fs10 ffssp">{{ user.phone }}</span>
              </div>
              <div class="cont_res">
                <span class="fs10 ffssp fw3">Reserva</span>
                <span class="fs10 ffssp">{{ user.visits }}</span>
              </div>
              <div class="cont_show">
                <span class="fs10 ffssp fw3 green">Llegó</span>
                <span class="fs10 ffssp">{{ user.finished }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cont_text_noresults" v-if="desserts.length == 0 && !$store.state.loaderPrecompro.status">
          <span class="ffssp text_noresults" v-if="dateIn == null && dateOut == null">No se encontraron resultados para esa búsqueda</span>
          <span class="ffssp text_noresults" v-else>No se encontraron resultados para esta fecha específica</span>
        </div>
      </div>
      <b-pagination
        @change="changePag"
        :total="total"
        :current.sync="current"
        :range-before="1"
        :range-after="1"
        :order="order"
        :per-page="perPage"
        :icon-prev="'chevron-left'"
        :icon-next="'chevron-right'"
        :simple="false"
        :rounded="false"
        :size="'is-small'"
        icon-pack="fa"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        class="pagination_users"
        v-if="desserts.length > 0">
      </b-pagination>
      <div class="container_button_m ddn">
        <button class="btn_create_user" @click="openModalCreate">
          <span class="text_create ffssp">Crear usuario</span>
          <div>
            <i class="icons icon_plus_create"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import infoUser from './infoUser';
import reservationTypes from '@/store/types/reservation';
import AddUserModal from './addUserModal';
import ModalCombineUser from './modalCombineUser';
import ModalCombineUserManual from './modalCombineUserManual';
import 'vue-date-pick/dist/vueDatePick.css';
import globalTypes from '@/store/types/global';
import serviceUser from '@/helpers/apis/serviceUser.js';
import _ from 'lodash';
export default {
  props: ['inputSearch', 'datesFilter'],
  data: () => ({
    orderName: false,
    orderEmail: false,
    orderDate: false,
    orderPhone: false,
    orderReservation: false,
    orderShow: false,
    query: '',
    flexing: 'flex',
    showDetail: false,
    dateIn: null,
    dateOut: null,
    totalUser: 0,
    currentUser: 0,
    currentUserEmail: 0,
    currentUserPhone: 0,
    dataUser: [],
    todayReservations: [],
    currentFilter: 'active',
    loadingReserva: false,
    text: 'Filas por página',
    noDataText: 'No hay información disponible aún.',
    forItem: [10],
    additional: false,
    history: false,
    loadUser: false,
    reservaUser: [],
    add_user: false,
    add_user_list: false,
    add_user_file: false,
    search: '',
    generate: false,
    column_mobile: true,
    desserts: [],
    total: 0,
    userRecurrent: 0,
    userNews: 0,
    userVip: 0,
    current: 0,
    order: 'is-centered',
    perPage: 0,
    mountkey: 0,
    idActive: '',
    activeModal: 0,
    filterUser: 0
  }),
  mounted () {
    this.loadUsers();
    this.loadUserStaticts();
    window.localStorage.setItem('_tab_users', 0);
  },
  computed: {
    amounts () {
      return _.mapValues({ active: {}, seated: {}, finish: {} }, (item, key) => this.getAmount(key));
    }
  },
  methods: {
    verifyActive (id) {
      if (this.idActive === id) {
        return true;
      }
      return false;
    },
    openModalCreate () {
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        component: AddUserModal,
        hasModalCard: true,
        canCancel: false,
        events: {
          loadUsers () {
            thes.loadUsers();
          }
        },
        customClass: 'modalAddUser'
      });
    },
    openModalCombinar () {
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalCombineUser,
        hasModalCard: true,
        canCancel: false,
        events: {
          loadUsers () {
            thes.loadUsers();
          }
        },
        customClass: 'modalCombineUser'
      });
    },
    openModalCombinarManual () {
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        component: ModalCombineUserManual,
        hasModalCard: true,
        canCancel: false,
        events: {
          loadUsers () {
            thes.loadUsers();
          }
        },
        customClass: 'modalCombineUserManual'
      });
    },
    deleteFilters () {
      this.dateOut = null;
      this.dateIn = null;
      this.$emit('deleteFilters');
    },
    uploadUser () {
      this.add_user_file = true;
    },
    addUser () {
      this.mountkey += 1;
      this.add_user = true;
    },
    changePagEdit () {
      this.changePag(this.current);
    },
    changePag (e) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.$store.dispatch({ type: 'reservation:getListUsers', data: { vendorId: this.idVendor, dateIn: this.dateIn, dateOut: this.dateOut, page: e, search: this.search, tab: this.filterUser } }).then((data) => {
        this.desserts = data.data.data;
        this.total = data.data.total;
        this.current = data.data.current_page;
        this.perPage = data.data.per_page;
        this.loadUser = true;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }).catch(() => {
        this.loadUser = true;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    loadUserStaticts () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceUser.post('/getPeopleStatistics', { vendorId: this.idVendor, dateIn: this.dateIn, dateOut: this.dateOut, search: this.search, tab: this.filterUser }).then(({ data }) => {
        this.totalUser = data.isRecurrent + data.isNew + data.isVip;
        this.userRecurrent = data.isRecurrent;
        this.userNews = data.isNew;
        this.userVip = data.isVip;
        this.loadUser = true;
        setTimeout(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        }, 1000);
      }).catch(() => {
        this.loadUser = true;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    loadUsers () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      this.$store.dispatch({ type: 'reservation:getListUsers', data: { vendorId: this.idVendor, dateIn: this.dateIn, dateOut: this.dateOut, search: this.search, tab: this.filterUser } }).then((data) => {
        this.desserts = data.data.data;
        this.total = data.data.total;
        this.current = data.data.current_page;
        this.perPage = data.data.per_page;
        this.loadUser = true;
        setTimeout(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        }, 1000);
      }).catch(() => {
        this.loadUser = true;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    sortTable (column) {
      switch (column) {
        case 'name':
          this.orderName = !this.orderName;
          if (this.orderName === true) {
            this.desserts.sort((a, b) => {
              if (a.name === b.name) {
                return 0;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 1;
            });
          } else {
            this.desserts.sort((a, b) => {
              if (a.name === b.name) {
                return 0;
              }
              if (b.name < a.name) {
                return -1;
              }
              return 1;
            });
          }
          break;
        case 'email':
          this.orderEmail = !this.orderEmail;
          if (this.orderEmail === true) {
            this.desserts.sort((a, b) => {
              if (a.email === b.email) {
                return 0;
              }
              if (a.email < b.email) {
                return -1;
              }
              return 1;
            });
          } else {
            this.desserts.sort((a, b) => {
              if (a.email === b.email) {
                return 0;
              }
              if (b.email < a.email) {
                return -1;
              }
              return 1;
            });
          }
          break;
        case 'date':
          this.orderDate = !this.orderDate;
          if (this.orderDate === true) {
            this.desserts.sort((a, b) => {
              if (a.birthday === b.birthday) {
                return 0;
              }
              if (a.birthday < b.birthday) {
                return -1;
              }
              return 1;
            });
          } else {
            this.desserts.sort((a, b) => {
              if (a.birthday === b.birthday) {
                return 0;
              }
              if (b.birthday < a.birthday) {
                return -1;
              }
              return 1;
            });
          }
          break;
        case 'phone':
          this.orderPhone = !this.orderPhone;
          if (this.orderPhone === true) {
            this.desserts.sort((a, b) => {
              if (a.phone === b.phone) {
                return 0;
              }
              if (a.phone < b.phone) {
                return -1;
              }
              return 1;
            });
          } else {
            this.desserts.sort((a, b) => {
              if (a.phone === b.phone) {
                return 0;
              }
              if (b.phone < a.phone) {
                return -1;
              }
              return 1;
            });
          }
          break;
        case 'reservation':
          this.orderReservation = !this.orderReservation;
          if (this.orderReservation === true) {
            this.desserts.sort((a, b) => {
              if (a.visits === b.visits) {
                return 0;
              }
              if (a.visits < b.visits) {
                return -1;
              }
              return 1;
            });
          } else {
            this.desserts.sort((a, b) => {
              if (a.visits === b.visits) {
                return 0;
              }
              if (b.visits < a.visits) {
                return -1;
              }
              return 1;
            });
          }
          break;
        case 'show':
          this.orderShow = !this.orderShow;
          if (this.orderShow === true) {
            this.desserts.sort((a, b) => {
              if (a.finished === b.finished) {
                return 0;
              }
              if (a.finished < b.finished) {
                return -1;
              }
              return 1;
            });
          } else {
            this.desserts.sort((a, b) => {
              if (a.finished === b.finished) {
                return 0;
              }
              if (b.finished < a.finished) {
                return -1;
              }
              return 1;
            });
          }
          break;
      }
    },
    generateReport () {
      this.generate = true;
      this.$store.dispatch({ type: 'reservation:generateReportUsers', data: { vendorId: this.vendor.id, dateIn: this.dateIn, dateOut: this.dateOut, search: this.search } }).then(({ data }) => {
        this.generate = false;
        this.$emit('open2', true);
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, data.urlApi);
        this.$store.commit(reservationTypes.mutations.setTypeApiReportDownload, data.type);
      }).catch(() => {
        this.generate = false;
      });
    },
    getInitials (name) {
      return _(name.split(' '))
        .filter(text => text)
        .map(text => text[0])
        .take(2)
        .join('')
        .trim();
    },
    showDetailUser (user) {
      if (this.activeModal === 0) {
        this.activeModal = 1;
        this.idActive = user._id;
        this.currentUser = user.name;
        this.currentUserEmail = user.email;
        this.currentUserPhone = user.phone;
        user.calification = this.calculatorCalification(user.reservations);
        this.dataUser = user;
        this.flexing = '75';
        const thes = this;
        this.$buefy.modal.open({
          parent: this,
          props: {
            dataUser: this.dataUser
          },
          component: infoUser,
          hasModalCard: true,
          canCancel: false,
          customClass: 'infoUserModal',
          events: {
            loadUsers () {
              thes.loadUsers();
            },
            close () {
              thes.idActive = '';
              thes.loadUsers();
            }
          },
          animation: 'slide-prev'
        });
      }
      setTimeout(() => {
        this.activeModal = 0;
      }, 1500);
    },
    closedDetailUser () {
      this.currentUser = '';
      this.currentUserEmail = '';
      this.currentUserPhone = '';
      this.dataUser = [];
      this.flexing = 'flex';
      this.showDetail = false;
    },
    getReservas () {
      this.loadingReserva = true;
      this.reservaUser = [];
      const arrayReservations = [];
      this.dataUser.reservations.map(item => { arrayReservations.push(item.id); });
      this.$store.dispatch({ type: 'reservation:getReservationUser', data: { reservations: arrayReservations, vendorId: this.vendorId } }).then(({ data }) => {
        this.reservaUser = data;
      }).then(() => {
        this.loadingReserva = false;
      });
    },
    getAmount (filter) {
      const amount = _(this.reservaUser)
        .filter(reservation => {
          let returnable = true;
          switch (filter) {
            case 'finish':
              returnable = reservation.codeStatus === 4 || reservation.codeStatus === 5 || reservation.codeStatus === 3;
              break;
            case 'seated':
              returnable = reservation.codeStatus === 2;
              break;
            case 'active':
              returnable = reservation.codeStatus === 1 || reservation.codeStatus === 0;
              break;
          }
          return returnable;
        })
        .size();
      return amount;
    },
    calculatorCalification (reservations) {
      let calification = 0;
      reservations.map(item => {
        if (item.score > 0) {
          calification += calification;
          return calification;
        }
      });
      return calification;
    },
    searchUser () {
      clearTimeout(this.timeout);
      var thes = this;
      this.isLoading = true;
      this.timeout = setTimeout(function () {
        if (thes.search !== '' && thes.search.length >= 3) {
          thes.loadUsers();
          thes.loadUserStaticts();
        }
      }, 500);
    },
    clearSearch () {
      this.search = '';
      this.loadUsers();
    },
    setFilterUser (value) {
      if (this.filterUser !== value) {
        window.localStorage.setItem('_tab_users', value);
        this.filterUser = value;
        this.loadUsers();
      }
    }
  },
  watch: {
    currentUser () {
      if (this.currentUser !== '') {
        this.getReservas();
        this.currentFilter = 'active';
      }
    },
    dateOut () {
      if (this.dateIn != null && this.dateOut != null) {
        if (this.dateOut >= this.dateIn) {
          this.loadUsers();
          this.loadUserStaticts();
        } else {
          this.dateIn = null;
        }
      } else {
        this.loadUsers();
        this.loadUserStaticts();
      }
    },
    dateIn () {
      if (this.dateIn != null && this.dateOut != null) {
        if (this.dateOut >= this.dateIn) {
          this.loadUsers();
          this.loadUserStaticts();
        } else {
          this.dateOut = null;
        }
      } else {
        this.loadUsers();
        this.loadUserStaticts();
      }
    },
    inputSearch () {
      if (this.inputSearch === '') {
        this.search = this.inputSearch;
        this.loadUsers();
        this.loadUserStaticts();
      } else {
        this.search = this.inputSearch;
        this.searchUser();
      }
    },
    datesFilter () {
      if (this.datesFilter.length > 0) {
        this.dateIn = this.datesFilter[0];
        this.dateOut = this.datesFilter[1];
      } else {
        this.dateIn = null;
        this.dateOut = null;
      }
    }
  }
};
</script>
<style lang="scss">
@import '../../../assets/styles/users/usersList.scss';

</style>
